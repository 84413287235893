import * as React from 'react';
import { observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { useTranslation } from "react-i18next";

function NumberFormatCustom(props) {
    const { inputRef, onChange, mask = "#### #### #### ####", ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }} format={mask}
        />
    );
}

function TextMaskCustom(props) {
    const { inputRef, pipe, mask = [/[1-9]/, ' ', /\d/], ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            pipe={pipe}
            mask={mask}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}


export default observer(({
    field, InputProps,
    ...rest
}) => {
    const { t } = useTranslation();

    let mask = field.extra;

    let pipe;
    if (field.extra && typeof field.extra === "object"  && field.extra.mask)
        mask = field.extra.mask;
    if (field.extra && typeof field.extra === "object"  && field.extra.pipe)
        pipe = field.extra.pipe;
    const inputComponent = !!field.extra?.textMask ? TextMaskCustom : NumberFormatCustom;


    console.log( mask,pipe )
    return <div>
        <TextField error={!!field.error} helperText={t(field.error)} fullWidth
            {...field.bind()}
            {...rest} label={t(field.label)} placeholder={t(field.placeholder)}
            mask={mask}
            InputProps={{ inputComponent }}
            inputProps={{ mask: mask ? mask : undefined, pipe: pipe ? pipe : undefined }}
        />
    </div>;
});