import {Route, Switch} from "react-router";
import {joinPath} from "../../utils/helpers";
import {Box, makeStyles, Typography, Link as MuiLink} from "@material-ui/core";
import {Link, Redirect, useHistory, useRouteMatch} from "react-router-dom";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import IFrame from "../../components/IFrame";
import PageTitle from "../../components/PageTitle";
import React, {useEffect, useMemo, useState} from "react";
import menu from "./menu.json";
import Life from "../Starfish/Life";
import {starFishColor} from "../../components/styles";
import {useStores} from "../../hooks/use-stores";
import _ from "lodash";
import LibraryRoutes from "../LibraryRoutes";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer'
    },
    redBorder: {
        border: '1px solid #007BFF',
        borderRadius: '20px'
    },
    menuTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    backLink:{
        ...starFishColor()
    }
}));

function  startsWithOneOf ( value, list ){
    return _.some( list, x=>value.startsWith(x))
}

const internalPages = ["https://shop.lifeinfoapp.com"
    , "https://appview.mainhomepage.com"
    , "https://my.lifeinfoapp.com"
    , "https://legacy.lifeinfoapp.com/"];

const newTabTags = ["thing2travel"];
const fullScreenFramePages = ["autoship"];


const MenuItem = ({ item, title, subtitle, path, url, match, parentMatch, isBack, onClick, collapsePath  }) => {
    const classes = useStyles();
    const {commonStore, authStore, videoStore} = useStores();
    const history = useHistory();
    const { shoppingBossStore } = useStores();

    const {t} = useTranslation("starfish");
    const props = {onClick}
    const handleOpenAlbum  = (albumId) => {
        videoStore.setVimeoAlbum(albumId)
    }



    let isUrlAbsolute = url && ( url.startsWith("http://") || url.startsWith("https://") )

    if ( item && item.loginGuid &&  url ) {
        url =  url + (url.indexOf("?")>-1?"&":"?") + `loginguid=${authStore.loginGuid}`
    }

    if ( item && item.tag === "thing2travel" ) {
         url = `https://secure.rezserver.com/ivm/inbound/?refid=8827&mk=363497f7ed862911d49ee491479413c635e077836ed9cd6d423824c62441053df717929c41b48fbd&refclickid=${authStore.currentUser.userID}`;
    }
    if ( item && item.tag === "logout" ) {
        props.onClick = ()=> { authStore.logout(); history.push("/") }
        props.component = MuiLink
    }
    if ( item && item.tag === "step-starfish" ) {
        props.onClick = ()=> {
            commonStore.showConfirm( "Congratulations, you are a Starfish Subscriber"
                , null
                , "Congratulations",  null, "OK" );
        }
        props.component = MuiLink
    }
    else if ( item && item.tag === "step-top-customer" ) {
        props.onClick = ()=> {
            if( shoppingBossStore.eAcceleratorValid  ){
                commonStore.showConfirm( "Congratulations, you are already a Top Customer"
                    , null
                    , "Congratulations",  null, "OK" );
            }
            else{
                history.push("/royalstarfish/royal/accelerator")
            }
        }
        props.component = MuiLink
    }  else if ( item && item.tag === "step-member" ) {
        props.onClick = ()=> {
            if( authStore.currentUser.isMember  ){
                commonStore.showConfirm( "Congratulations, you are already a Member"
                    , null
                    , "Congratulations",  null, "OK" );
            }
            else{
                history.push("/member/enroll")
            }
        }
        props.component = MuiLink
    }
    else if ( item && item.albumID){
        props.onClick = ()=> handleOpenAlbum( item.albumID )
        props.component = MuiLink
    }
    else if ( url && isUrlAbsolute &&  item && newTabTags.includes( item.tag ) ){
        props.target = "_blank"
        props.href = url
        props.component = MuiLink
    }
    else if(url && isUrlAbsolute && url.startsWith( "https://my.lifeinfoapp.com") && item && !fullScreenFramePages.includes( item.tag )  ) {

        props.component = Link
        let targetPath = (url).replace("https://my.lifeinfoapp.com/",
            parentMatch.path.endsWith('/')
                ? parentMatch.path
                : parentMatch.path + '/' )
        if ( collapsePath ){
            let parts =  targetPath.split('/')
            let result = []
            parts.forEach( (part, index)=>{
              if ( part != result[result.length-1] )
                  result.push(part)

            })
            targetPath  = result.join('/')
        }
        props.to = targetPath
    }
    else if(url && isUrlAbsolute && url.startsWith( "https://shop.lifeinfoapp.com") && ( !item || item.tag !=='accelerator' ) ) {
        props.component = Link
        let targetPath = (url).replace("https://shop.lifeinfoapp.com/",
            "/royalstarfish/shop/" )
        props.to = targetPath
    }
    else if (path) {
        props.component = Link
        props.to = match ? joinPath(match.path, path) : path
    }


    return (
        <>
            <Box {...props} className={clsx(classes.menuItem, classes.redBorder, { [classes.backLink]: isBack })}>
            <Box display="flex" alignItems={"center"}>
                <Typography className={classes.menuTitle} variant="h4">{title}</Typography>
            </Box>
            {subtitle && <Box>
                <Typography variant="body1">{subtitle}</Typography>
            </Box>
            }
        </Box>
        </>

    );
};

function defaultComponentFactory(authStore, match, item){
    let result
    let tag = item.tag||item.url;

    let url = item.url;
    let isUrlAbsolute = url && ( url.startsWith("http://") || url.startsWith("https://") )

    if ( item && item.loginGuid &&  url ) {
        url =  url + (url.indexOf("?")>-1?"&":"?") + `loginguid=${authStore.loginGuid}`
    }

    switch (tag) {
        case "#":
            result = () => <LinksMenu parentMatch={match} title={item.title} items={[]} showBack iFrame />
            break;
        case "memberfulloffice":
        case "events":
        case "programandexplanation":
            result = () => <LinksMenu parentMatch={match} items={menu.find(x=>x.tag===(tag)).items} showBack iFrame />
            break;
        case 'rascal':
            result = () =><IFrame url={`https://my.rascal-radio.com/index.aspx?loginguid=${authStore.loginGuid}`} />
            break
        case 'accelerator':
            result = () => <Life/>
            break
        case 'thing2travel':
            result = () => <IFrame url={item.url} />
            break
        case "marketplaceproducts":
            result = () =>    <LinksMenu parentMatch={match} items={menu.find(x=>x.tag==="marketplaceproducts").items} showBack iFrame />
            break
        case "marketplaceservices":
            result = () =>    <LinksMenu parentMatch={match} items={menu.find(x=>x.tag==="marketplaceservices").items} showBack iFrame />
            break
        case "lifeproducts":
            result = () =>    <LinksMenu parentMatch={match} items={menu.find(x=>x.tag==="lifeproducts").items} showBack iFrame />
            break
        default:

            break
    }
    if ( !result ) {
        if( item.url.startsWith("https://my.lifeinfoapp.com")  && !fullScreenFramePages.includes( item.tag ) )
            result = () => <Redirect to={(tag).replace("https://my.lifeinfoapp.com","")}/>

    }
    if ( !result ){
        const url = item.url
        const fullScreenFrame =  url && isUrlAbsolute && ( fullScreenFramePages.includes( item.tag ) || !startsWithOneOf( url, internalPages ) )
            result = () => <IFrame url={item.url} title={item.title}  fullScreenFrame={fullScreenFrame} returnTitle="Back To Royal"  />
    }

    return result
}

function  getComponent(authStore, componentFactory, match, item){

    let result
    if ( componentFactory )
        result = componentFactory(match, item)
    if( !result )
        result = defaultComponentFactory(authStore, match, item)
    return result
}

function LinksMenu( {parentMatch, items, title, showBack=true, linkFactory, componentFactory, collapsePath=true }){
    const match = useRouteMatch();
    const history = useHistory();
    const {authStore} = useStores();
    const { t } = useTranslation("starfish");


    const handleBack = (event)=>{
        event.preventDefault();
        window.history.back()
    }

    return  <Switch>
        {items.find(x=> x.tag ==="library") && LibraryRoutes(match.path) }
        {items.map(link => {
                    let p = joinPath(match.path, link.tag || link.title.toLowerCase())
                    return <Route path={p}
                                  render={() => {
                                      const component = getComponent(authStore, componentFactory, match, link)
                                      return <Box style={{marginBottom: '60px'}}>
                                          {!component
                                              ? <>
                                                  {
                                                      link.url === "#"
                                                          ? <>
                                                              {<PageTitle>{link.title}</PageTitle>}
                                                              {showBack && <MenuItem
                                                                  title={t(`royal.back`)}
                                                                  path={match.path}
                                                                  onClick={(event) => {
                                                                      if (!match || !match.path)
                                                                          handleBack(event)
                                                                  }}
                                                              />}
                                                          </>

                                                          : <IFrame url={link.url} title={link.title}/>
                                                  }
                                              </>
                                              : component()}
                                      </Box>
                                  }
                                  }
                    />
                }
            )
        }

        <Route path="*"
               render={() => {
                   let backpath = parentMatch && parentMatch.path
                   return <>
                       {title && <PageTitle>{title}</PageTitle>}
                      {showBack && <MenuItem
                          isBack
                          title={t(`royal.back`)}
                          onClick={(event) => {
                            if( !backpath )
                                handleBack(event)
                          }}
                          path={backpath}

                      />}
                       {items.map((link) => {
                       return <MenuItem
                           collapsePath={collapsePath}
                           key={link.tag || link.title.toLowerCase() }
                           title={link.title}
                           path={link.tag || link.title.toLowerCase() }
                           url={link.url }
                           item={link}
                           match={match}
                           parentMatch={parentMatch||match}
                       />;
                   })}
                   </>
               }}
        />
    </Switch>

}


export default LinksMenu;