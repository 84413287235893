import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    Container,
    TextField,
    Typography,
    FormHelperText,
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faCopy, faShare, } from "@fortawesome/pro-light-svg-icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import services from '../services';
import { useStores } from '../hooks/use-stores';
import { extractErrorMessage } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
    qrCodeHelperText: {
        marginTop: `${theme.spacing(3)}px`
    },
    faIcons: {
        marginRight: "5px"
    },
    divider: {
        margin: "20px 0"
    },
    root: {

        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
    card: {
        width: "100%",
    },
    qrCodeSpacing: {
        marginBottom: `${theme.spacing(2)}px`
    },
    enrollmentLink: {
        textAlign: 'center',
        width: '100%'
    },
    generateNewLink: {
        marginTop: '18px'
    }
}));

const ShareEnrollment = () => {
    const { commonStore, authStore, } = useStores();
    const classes = useStyles();
    const { t } = useTranslation();
    const { currentUser } = authStore;
    const { isSuperApp, isSuperAppPlus, isStarfish } = currentUser;

    const [enrollmentLink, setEnrollmentLink] = useState('');
    const [qrCodeShown, setQRCodeShown] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [links, setLinks] = React.useState(null);
    const [selection, setSelection] = React.useState('');
    const [selectionError, setSelectionError] = React.useState(null);
    const [page, setPage] = React.useState('0');

    useEffect(() => {
        if (["HT", "AU", "BS"].includes(currentUser?.country)) {
            setLinks([...platformLinks, ...starfishLinks]);
        } else {
            setLinks(starfishLinks);
        }
    }, []);

    const generateEnrollmentLink = async () => {
        try {
            if( selection === '' ) {
                setSelectionError('Please select a link type to generate');
                return;
            }
            setError(null);
            setLoading(true);
            setPage('1');
            const response = ( selection === "life" )
                ? { url: "https://my.lifeinfoapp.com/starfish/life"}
                : await services.Enrollment.generateEnrollmentLink(selection, 'en', selection && selection.includes('starfish') ? true : false);
            setEnrollmentLink(response.url);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setPage('0');
            setError(extractErrorMessage(e));
        }
    };

    const hasAccess = React.useMemo(() => {
        if (!!isStarfish || !!isSuperApp || !!isSuperAppPlus) {
            return true;
        } else {
            return false;
        }
    }, [isStarfish, isSuperApp, isSuperAppPlus]);

    const showQRCode = () => {
        setQRCodeShown(!qrCodeShown);
    };

    const platformLinks = [
        {
            value: 'superapp', text: 'Platform Enrollment',
        },
        {
            value: 'superappplus', text: 'Platform + Enrollment'
        }
    ];

    const starfishLinks = [
        {
            value: 'topcustomer-starfish', text: 'Top Customer (Starfish + Life Accelerator)'
        },
        {
            value: 'starfish', text: 'Starfish'
        },
        {
            value: 'life', text: 'Life Accelerator (For existing Starfish customers)'
        }
    ];

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
        setSelectionError(null);
    }

    return (
        <Container maxWidth={"md"} className={classes.root}>
            <Grid container spacing={3}>
                <Card variant="outlined" className={classes.card}>
                    <CardContent>
                        {error && <Alert severity={"error"}>{error}</Alert>}
                        {
                            page === '0'
                                ?
                                <>
                                    <h2>  {!!currentUser.isMember ? "Enrollment Links" : "Invite Friends"}    </h2>
                                    <h4>  {!!currentUser.isMember ? "A Customer Enrollment Link is a unique multi-use link that is active for 48 hours. After the 48 hours, it expires and a new link has to be created. " : ""}</h4>
                                    <FormControl fullWidth className={classes.margin} variant="outlined" error={Boolean(selectionError)}>
                                        <InputLabel htmlFor="age-native-simple">Please Select</InputLabel>
                                        {links &&
                                            links.length > 0 && <Select
                                                required
                                                placeholder="Please Select"
                                                label="Enrollment Link Type:"
                                                className={classes.enrollmentLink}
                                                onChange={handleSelectionChange}>
                                                {links.map((val, index) =>
                                                    <MenuItem key={index} value={val.value} >{val.text}</MenuItem>
                                                )}
                                            </Select>
                                        }
                                        <FormHelperText>{selectionError}</FormHelperText>
                                    </FormControl>
                                    <Grid item xs={12} lg={12}>
                                        <Button fullWidth
                                            className={classes.generateNewLink}
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            style={{ color: 'white' }}
                                            onClick={generateEnrollmentLink}
                                            disabled={!!hasAccess ? loading : true}>
                                            <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                            Generate New Link
                                        </Button>
                                    </Grid>
                                </>
                                : ''
                        }
                        {
                            page === '1'
                                ?
                                <>
                                    {
                                        loading === true
                                            ?
                                            <>
                                                <CircularProgress />
                                            </>
                                            :
                                            !enrollmentLink
                                                ? ''
                                                :
                                                <>
                                                    <TextField disabled={true}
                                                        fullWidth
                                                        variant={"filled"}
                                                        label={t("starfish:share.shareLink")}
                                                        value={enrollmentLink} />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <Grid container spacing={3} justify={"center"}>
                                                        <Grid item xs={10} md={7} >
                                                            <CopyToClipboard text={enrollmentLink}
                                                                onCopy={() => commonStore.success("Link copied")}>
                                                                <Button fullWidth color="primary" variant="contained" size="large" style={{ color: 'white' }} disabled={!enrollmentLink}>
                                                                    <FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" />
                                                                    {t("starfish:share.copyLink")}
                                                                </Button>
                                                            </CopyToClipboard>
                                                        </Grid>
                                                        <Grid item xs={10} md={7} >
                                                            <Button fullWidth
                                                                color="primary"
                                                                variant="contained"
                                                                size="large"
                                                                style={{ color: 'white' }}
                                                                href={`sms:?&body=${enrollmentLink}`}
                                                                disabled={!enrollmentLink}>
                                                                <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                                                {t("starfish:share.textLink")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={10} md={7} container justify={"center"}>
                                                            <Button fullWidth
                                                                className={classes.qrCodeSpacing}
                                                                color="primary"
                                                                variant="contained"
                                                                size="large"
                                                                style={{ color: 'white' }}
                                                                onClick={showQRCode}
                                                                disabled={!enrollmentLink}>
                                                                <FontAwesomeIcon className={classes.faIcons} icon={faQrcode} size="1x" />
                                                                {t("starfish:share.qrCode")}
                                                            </Button>
                                                            {qrCodeShown
                                                                ?
                                                                <>
                                                                    <Grid item xs={12} md={7} container justify={"center"}>
                                                                        <QRCode value={enrollmentLink} />
                                                                        <Typography className={classes.qrCodeHelperText}>
                                                                            Scan code above to share link
                                                                        </Typography>
                                                                    </Grid>
                                                                </>
                                                                : null}
                                                        </Grid>
                                                        <Divider className={classes.divider} />
                                                        <Grid item xs={10} md={7} >
                                                            <Button fullWidth
                                                                className={classes.generateNewLink}
                                                                color="default"
                                                                variant="contained"
                                                                size="large"
                                                                style={{ color: 'black' }}
                                                                onClick={() => setPage('0')}
                                                            >
                                                                <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                                                Generate Another Link
                                                            </Button>
                                                        </Grid>
                                                        <Divider className={classes.divider} />
                                                    </Grid>
                                                </>
                                    }
                                </>
                                : ''
                        }
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Grid >
        </Container>
    );
};

export default ShareEnrollment;