import React, {useEffect, useRef, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import  debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import CustomCarousel from '../Carousel/CustomCarousel';
import FeaturedProductsCarousel from '../Carousel/FeaturedProductsCarousel';
import ProductList from '../../../containers/shop/ProductList/ProductList';
import MainCategoryListContainer from '../../../components/shop/MainCategoryListContainer/MainCategoryListContainer';
import { getCookie, deleteCookie } from '../../../utils/cookieUtil';
import {useHistory, useLocation} from "react-router";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useStores} from "../../../hooks/use-stores";
import services from "../../../services"
import Container from "@material-ui/core/Container";
import  "../../../sass/components/_searchPage.scss";


function Search ({shoppingMatch, categoryId}) {

  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     searchValue: '',
  //     lastSearch: {
  //       value: '',
  //       results: []
  //     },
  //     suggestions: [],
  //     showResultsView: false,
  //     showCategories: false,
  //     publicCategories: [],
  //     memberCategories: [],
  //     noResultsFound: false,
  //     userIsSearching: false,
  //     showFooter: false
  //   }
  //
  //   valueBeforeUpDownonSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 250)
  // }



  const location = useLocation()
  const history = useHistory()
  const { speaker } = location.state || {}
  const [searchValue, setSearchValue]  = useState(speaker||"")
  const [suggestions, setSuggestions]  = useState([])
  const [showResultsView, setShowResultsView]  = useState(false)
  const [userIsSearching, setUserIsSearching]  = useState(false)
  const [noResultsFound, setNoResultsFound]  = useState(false)
  const [showCategories, setShowCategories]  = useState(false)
  const [categoryItems, setCategoryItems]  = useState(false)
  const [lastSearch, setLastSearch]  = useState( { value: '', results: []})

  const {t} = useTranslation();
  const {authStore, shopStore, commonStore} = useStores()
  const {isGuest, currentUser} = authStore
  const {language} = commonStore;
  const input= useRef();
  const {memberCategories, publicCategories, promotionalProducts, featuredProducts, market, customerLevelID } = shopStore

  useEffect(() => {
    if (language && (!isGuest || ( market &&  customerLevelID))) {
      shopStore.loadPromotionalProducts(
          {
            customerLevelID
            , marketID: market, language
          });
      shopStore.loadFeaturedProducts(
          {
            customerLevelID
            , marketID: market, language
          });
    }
  }, [market, customerLevelID, language])

  useEffect(()=>{
    if (speaker){
      setSearchValue(speaker)
      onSuggestionsFetchRequested({value: speaker})
    }
  }

  ,[speaker])





  const handleChange = (event, { newValue, method }) => {
    if (event.type === "keydown") {
      setSearchValue (newValue)
      return
    }

    if (event.type !== "change") return
    setSearchValue (event.target.value)
    setShowResultsView (false)

    if (event.target.value !== undefined && event.target.value.length > 0) {
      setSuggestions([])
      setUserIsSearching(true)
      setNoResultsFound(false)
    } else {
      setSuggestions([])
      setUserIsSearching(false)
      setNoResultsFound(false)
    }
  }

  const getSuggestionValue = (suggestion) => {
    if (suggestion.header) return suggestion.header

    return suggestion.productName;
  }

  const onSuggestionsClearRequested = () => {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) { return }
    setSuggestions([])
    setUserIsSearching(false)
    setNoResultsFound(false)
  };
  const onSuggestionsFetchRequested = React.useCallback(  debounce (async ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      const suggestionHeader = suggestions[0]
      if (suggestionHeader && suggestionHeader.header === value) {

        onSuggestionHeaderClick()
        return
      }
      onSuggestionClick(suggestions.filter(suggestion => suggestion.productName === value).shift())
      return
    }

    if (reason === 'input-focused' && value !== '')
        setUserIsSearching(true)

    if (value === lastSearch.value) {
      if (lastSearch.results === null) {
        setSuggestions([])
        setUserIsSearching(true)
        setNoResultsFound(true)
        setShowResultsView(false)

      } else {
        setSuggestions(lastSearch.results )
        setNoResultsFound(false)
      }
      
      return
    }

    let postBody = {
      audio: true,
      book: true,
      video: true,
      audiobook: true,
      790: true,
      customerLevelID,
      marketID: market
    };

    
    if(value.trim() !== "") {
      try{
          let response = await services.ShoppingCart.searchProducts(value.trim(), language, postBody );
          let suggestions = []

          if (response) {
            suggestions = response
          }

          if (suggestions.length < 1) {
            setSuggestions([])
            setNoResultsFound(true)
            setLastSearch( {value: value, results: null})
          } else {
            suggestions.unshift({header: value})
            setSuggestions(suggestions)
            setNoResultsFound(false)
            setLastSearch( {value: value, results: suggestions} )

          }
        }
        catch(error){
          setSuggestions([])
          setNoResultsFound(true)
          setLastSearch( {value: value, results: null} )
        }
    } 
  }, 250), []);

  const onSuggestionHeaderClick = () => {
    setSuggestions([])
    setNoResultsFound(false)
    setUserIsSearching(false)
    setShowResultsView(true)
  }

  const onSuggestionClick = (item) => {
    if (item === undefined) return

    let parentCategoryID = categoryId;
    if (!parentCategoryID) {
      parentCategoryID = item.parentCategoryID
    }
    // change routeID if promotional or featured items
    if (parentCategoryID === '793' || parentCategoryID === '792') {
      history.push(`${shoppingMatch.url}/category/${parentCategoryID}/sub-category/${parentCategoryID}/product-detail/${item.productSKU}`)
      return 
    }

    history.push(`${shoppingMatch.url}/category/${parentCategoryID}/product-detail/${item.productSKU}/${item.productCategoryID}`)
  }

  const renderSuggestion = suggestion => {
    if (suggestion.header) {
      return (
      <div className="suggestion-header" onClick={() => onSuggestionHeaderClick()}>
        <FontAwesomeIcon style={{ alignSelf: "center", justifySelf: "center" }} data-dismiss="modal" icon={faSearch} size="2x" />
        <div className="suggestion-product-name" >Search for "{suggestion.header}"</div>
      </div>
      )
    }

    return (
    <div className="suggestion-container" onClick={() => onSuggestionClick(suggestion)}>
      <img src={suggestion.productImage} className="suggestion-img" />
      <div className="suggestion-product-name" >{suggestion.productName}</div>
      <div className="suggestion-category-text" >{t(`shopping:categories.${suggestion.parentCategoryName}`)}</div>
    </div>
    )
  }

  const onFilterCategoryItems = (parentCategory, value) => {
    return value.filter((searchResult) => {
      return searchResult.parentCategoryName === parentCategory
    })
  }

  const onCategoryClick = (category) => {
    const categoryItems = lastSearch.results.filter((product) => {
      return product.parentCategoryID === category
    })
    setCategoryItems(categoryItems)
    setShowCategories(true)
  }

  const onGoBackHandler = () => {
    setShowCategories(false)
  }
  
  const ResultsView = (props) => {

    let headers;
    let ids;

    if (props.suggestions) {
      headers = props.suggestions
        .map((suggestion) => suggestion.parentCategoryName)
        .filter((suggestion, index, _arr) => _arr.indexOf(suggestion) === index);
  
      ids = props.suggestions
        .map((suggestion) => suggestion.parentCategoryID)
        .filter((suggestion, index, _arr) => _arr.indexOf(suggestion) === index);
  console.log("suggestions",props.suggestions)
  console.log("headers",headers)
  console.log("ids",ids)
      return headers.map((category, i) => {
        if (category === undefined) return ('')
        return (
          <div className="search-main-content__search-result-products">
            <div className="search-main-content__headers">
              <h1 className="search-main-content__top-products-title">{props.t(`shopping:categories.${category}`)}</h1>
              <p onClick={() => props.onCategoryHandler(ids[i])}>{props.t('shopping:subcategories.seeall')}</p>
            </div>
            <CustomCarousel
              parentCategoryID={categoryId}
              products={props.onFilterCategoryItemsHandler(category, props.suggestions)}
            />
          </div>
        )
      })
    }
     return ('???')
  }

    const inputProps = {
      placeholder: t('shopping:home.search'),
      value: searchValue,
      onChange: handleChange
    };

    return (
      <>
        <Container className="search-main-content" maxWidth={"md"}>
          {
            showCategories ? (
              <>
                <div className="search-main-content__category-header">
                  <FontAwesomeIcon icon={faAngleLeft} size="4x" />
                  <p onClick={() => onGoBackHandler()}>{t('shopping:subcategories.goback')}</p>
                </div>
                <Row>
                  <ProductList
                    category={'category-view-img'}
                    products={categoryItems}
                  /> 
                </Row>
              </>
            ) : (
              <>
                <Autosuggest
                  alwaysRenderSuggestions={true}
                  focusInputOnSuggestionClick={false}
                  highlightFirstSuggestion={true}
                  className="search-main-content__search-bar"
                  suggestions={suggestions}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  ref={input}
                />
                <br/>
                <br/>
                <br/>
                <br/>
                {userIsSearching ? (
                  <>
                    {noResultsFound ? <h1 className="message-error">Sorry, No results found for "{searchValue}".</h1> :
                      suggestions.length >= 1 ? '' :
                        <h1 className="message-loading">Loading <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </h1>}
                  </>
                ) : ('')}
                {!userIsSearching && !showResultsView ? (
                  <>
                    <div className="search-main-content__top-products animated fadeIn">
                      <h1 className="search-main-content__top-products-title">{t('shopping:search.topsellingproducts')}</h1>
                      <FeaturedProductsCarousel
                        id="additional"
                        products={featuredProducts}
                        parentMatch={shoppingMatch}
                      />
                    </div>
                    <div className="search-main-content__categories animated fadeIn">
                      <Row>
                        <Col sm={10}>
                          <h1 className="search-main-content__top-products-title">{t('shopping:search.categories')}</h1>
                        </Col>
                      </Row>
                      <MainCategoryListContainer
                        homeContainerTitle={'Categories'}
                        categoryItems={publicCategories}
                      />
                    </div>
                    {
                      (isGuest || !currentUser || !currentUser.isMember) ? (
                        ''
                      ) : (
                          <div className="main-content__member">
                            <hr />
                            <MainCategoryListContainer
                              homeContainerTitle={'Membery Categories Only'}
                              categoryItems={memberCategories}
                            />
                          </div>
                        )
                    }
                  </>
                ) : (
                  <>
                  {showResultsView && <ResultsView
                                        t={t}
                                        suggestions={lastSearch.results}
                                        onCategoryHandler={onCategoryClick}
                                        onFilterCategoryItemsHandler={onFilterCategoryItems} />}
                  </>
                )}
              </>
            )
          }
        </Container>
      </>
    )

}


export default observer (Search);