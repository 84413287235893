import { makeAutoObservable } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";
import createRecipientForm from "../forms/recipient";

class StatStore {
  constructor() {
    makeAutoObservable(this);
  }

  sendout = null;
  searchResult = null;
  recipientForm = null;
  sendoutLoading = false;
  sendoutSearching = false;
  error = null;
  purchasingSendout = false;

  setError(error, type = "sendout") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  createRecipientForm(onDone) {
    this.recipientForm = createRecipientForm({
      onSuccess: onDone,
      onError: (form) => {
        this.setError(form.errors());
      }
    });
  }

  clearSendoutBaseline() {
    this.sendout = null;
  }

  get baselineLoad() {
    if (!this.sendout) return [];
    return this.sendout[0][0] || [];
  }

  get salesAchiever() {
    if (!this.sendout) return [];
    return this.sendout[1] || [];
  }

  get rascalPodcast() {
    if (!this.sendout) return [];
    return this.sendout[2] || [];
  }

  get recommended() {
    if (!this.sendout) return [];
    return this.sendout[3] || [];
  }

  get sendoutAudios() {
    if (!this.sendout) return [];
    return this.sendout[4] || [];
  }

  get sendoutVideos() {
    if (!this.sendout) return [];
    return this.sendout[5] || [];
  }

  get sendOutProductID() {
    if (!this.sendout) return -1;
    return this.baselineLoad.productIDSendout;
  }

  async purchaseSendouts(qty) {
    if (this.purchasingSendout) return;
    this.purchasingSendout = true;
    try {
      const { cartID: cart_guid = '' } = await services.ShoppingCart.addItemToCart2(this.sendOutProductID, qty, 'en');
      return { cart_guid };
    }
    catch (e) {
      this.setError(extractErrorMessage(e), "sendout");
    }
    finally {
      this.purchasingSendout = false;
    }
  }

  loadSendout = generateLoadEntity("sendout", this, "sendoutLoading"
    , (options) => { return services.Sendout.list(options); }, "sendout");

  search = generateLoadEntity("sendoutSearch", this, "sendoutSearching"
    , (options) => { return services.Sendout.search(options); }, "searchResult");


}

export default StatStore;