import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import {useHistory} from "react-router";
import {isAudio, isBook, isPack, isVideo} from "../utils/mediaUtils";
import {useStores} from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({

    root:{
        width:"100%",
        overflow:"auto",
        marginBottom:40

    },
    items:{
        display:"flex"

    },
    colorWhite:{
        color:"white",
    },
    item:{
        marginRight: theme.spacing(2),
    },
    thumb: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(9),
            height: theme.spacing(9),
        },
    },

    lineClamp2: {
        "-webkit-line-clamp": "2 !important"
    },

    lineClamp: {
        margin: "5px 0",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        lineClamp: 2,
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        paddingRight: theme.spacing(2),
    },

    currentItem:{
        color: "#1db954"
    },
    thumbContainer:{
        marginRight: theme.spacing(2),
        position: "relative"
    },

    loadOverlay:{
        position: "absolute",
        top:0,
        bottom:0,
        left:0,
        right:0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }
}));


export default observer(({items, onSelect, onVideOpen, onOpenBook, onAudioPlay }) => {
    const classes = useStyles();
    const history = useHistory();
    const { libraryStore } = useStores();
    const { detailsLoadingFor } = libraryStore;

    const handleMediaButton = (media)=>{
            onAudioPlay(media)
    }


    const handleMediaRowClick = (media)=>{
        if ( onSelect ){
            onSelect(media);
            return
        }
        if ( isPack(media) ){
            history.push( `/library/packs/${media.mediaID}`)
        }
        else if (isVideo(media) ){
            onVideOpen(media)
        }
        else if (isAudio(media) ){
            handleMediaButton(media)
        }
        else if ( isBook(media) ){
            onOpenBook(media)
        }
    }


    return <div className={classes.root}>
        <div className={classes.items}>
            {items.map(s=>{
                return <Fragment key={s.mediaID}>
                        <div className={classes.item}>
                            <div className={classes.thumbContainer}>
                                <Avatar variant="rounded" className={classes.thumb}  onClick={e=>{ handleMediaRowClick(s) }}
                                        alt={` `}
                                        src={`${s.imageURL}`}
                                />
                                {detailsLoadingFor && detailsLoadingFor.includes(s.mediaID) &&
                                <div className={classes.loadOverlay }>
                                    <CircularProgress color={"secondary"} size={70} thickness={4}/>
                                </div>}
                            </div>
                            <span className={clsx(classes.lineClamp, classes.lineClamp2)}>{s.title}</span>
                        </div>
                </Fragment>
            }) }
        </div>
    </div>
})


