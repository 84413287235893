import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import '../css/owl.scss';
import ItemWrapper from "../containers/ItemWrapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getItemUrl } from "../utils/menuUtils";
import {joinPath} from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "relative",
        background: "black"
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    link: {
        display: "block",
        [theme.breakpoints.up('sm')]: {
            height: 240,
        }
    },
    img: {
        height: 240,
        width: "auto !important",
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            width: "80vw !important",
        },
    },
    singleImg: {
        height: "auto",
        maxHeight: 350,
        maxWidth: "100%"
    },
    thumbContainer: {
        position: "relative"
    },

    albumContainer: {
        position: "relative",
        display: "flex",
    },

    loadOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }

}));

export default observer(({ banner, menu, match, playMedia, detailsLoadingFor, patchUrls=false , prefix="" }) => {
    const classes = useStyles();
    const ref = React.useRef(0);


    const options = {
        items: 1,
        nav: false,
        center: true,
        autoWidth: true,
        rewind: true,
        autoplay: true
    };

    const events = {
        onDragged: function (event) { },
        onChanged: function (event) { }
    };

    return (banner && banner.length > 0) && <div className={classes.root} >
        {
            banner.length > 1
                ? <>
                    <OwlCarousel ref={ref} options={options} events={events}  >
                        {banner.map((step, index) => {
                            let itemUrl = getItemUrl(step);
                            if ( patchUrls) {
                                if ( itemUrl.startsWith("https://my.lifeinfoapp.com"))
                                    itemUrl = window.location.origin + joinPath(prefix, itemUrl.replace("https://my.lifeinfoapp.com", ""))
                            }
                            const mediaID = itemUrl && itemUrl.startsWith("playmedia://") && itemUrl.substr("playmedia://".length);
                            const isLoading = detailsLoadingFor && detailsLoadingFor.includes(mediaID);

                            return <ItemWrapper key={step.tag + index} classes={{
                                link: classes.link,
                            }} item={{...step, url: itemUrl}} menu={menu} lookupInMenu={true} onPlay={playMedia} vertical={false} notMatchedInNewWindow={true}>
                                <div className={classes.thumbContainer}>
                                    <img className={classes.img} src={step.thumbnail} alt="The Last of us" />
                                    {isLoading && <div className={classes.loadOverlay}>
                                        <CircularProgress color={"secondary"} thickness={3} />
                                    </div>}
                                </div>
                            </ItemWrapper>;

                        })}
                    </OwlCarousel>
                </>
                : <img className={classes.singleImg} src={banner[0].thumbnail} alt={banner[0].title} />

        }

    </div>;
})


