export function actions(theme) {
    return {
        actions: {
            justifyContent: "flex-end"
        }
    }
}
export function avatar(theme, zoom=1) {
    return {
        avatar:{
            height:70*zoom,
            width:70*zoom,
        }
    }
}
export function card(theme) {
    return {
        cardWrapper:{
            display: "inline-flex"
        },
        card:{
            flexGrow: 1,
            flexBasis: 0,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column"
        },
        cardMainArea: {
            flexGrow: 1,
            display: "inline-flex",
            alignItems: "flex-start",
            justifyContent: "flex-start"
        },
        qty:{
            marginLeft:20
        },
        logo: {
            marginRight: theme.spacing(1)
        },
        ...avatar(theme)
    }
}

export function statusStyles(theme) {
    return {
        status: {
            textTransform: "uppercase"
        },
        statusActive: {
            color: theme.palette.success.main
        },
        statusDeclined: {
            color: theme.palette.error.main,
        },
    }
}

export function securityBanner(theme) {
    return {
        securityBanner: {
            padding: theme.spacing(2),
            margin: theme.spacing(2)
        }
    }
}


export function greenColor(theme, propNam="color") {
    return {
        [propNam]: "#51c07a"
    }
}
export function starFishColor(theme, propNam="color") {
    return {
        [propNam]: "#007BFF"
    }
}

export function redColor(theme, propNam="color") {
    return {
        [propNam]: "red"
    }
}