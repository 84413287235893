import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Merchants from "./Merchants";
import Review from "./Review";
import Checkout from "./Checkout";
import Complete from "./Complete";
import { joinPath } from "../../utils/helpers";
import { useStores } from "../../hooks/use-stores";
import BalanceNotification from "../Starfish/BalanceNotification";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: "100%",
    minHeight: "100vh",
    background: "black",
    color: "white",
    //TODO: Remove later
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ShoppingBossRoutes = observer(({ fromModule = null }) => {
  const history = useHistory();
  const shoppingBossMatch = useRouteMatch();
  const classes = useStyles();
  const { commonStore, shoppingBossStore } = useStores();

  const goToWallet = () => {
    history.push('/wallet/ewallet/add_more_money');
  }
  return (
    <Container className={classes.wrapper} maxWidth="md">
      {/* Show the Balance notification on home and review pages only - starfish uses a different location for these - could maybe
      move them here?*/}
      {!commonStore.onStarfishOrRoyalStarfish &&
        (shoppingBossStore.currentPage == 'merchants' || shoppingBossStore.currentPage == 'review') &&
        <BalanceNotification balance={shoppingBossStore.balance} onAddMoney={goToWallet} />
      }
      <Switch>
        <Route
          path={joinPath(shoppingBossMatch.url, `checkout`)}
          render={() => {
            return <Checkout shoppingBossMatch={shoppingBossMatch} />;
          }}
        />
        <Route
          path={joinPath(shoppingBossMatch.url, `complete`)}
          render={() => {
            return <Complete shoppingBossMatch={shoppingBossMatch} fromModule={fromModule} />;
          }}
        />
        <Route
          path={joinPath(shoppingBossMatch.url, `review`)}
          render={() => {
            return <Review shoppingBossMatch={shoppingBossMatch} />;
          }}
        />
        <Route
          path={"*"}
          render={() => {
            return <Merchants shoppingBossMatch={shoppingBossMatch} />;
          }}
        />
      </Switch>
    </Container>
  );
});

export default ShoppingBossRoutes;