import React from 'react'
import {Link} from 'react-router-dom'
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    footer:{
        height:200,
    },
    logo:{
        height:30
    }
}))


export default function Footer() {
    const classes = useStyles();
  return (
    <Grid container direction={"column"} alignItems={"center"} justify={"center"} className={classes.footer}>
        <Grid container item alignItems={"center"} justify={"center"} direction={"column"}>
            <Link to={'/'}>
            <img
              className={classes.logo}
              src="https://main.secure.footprint.net/public/icon/life.png"
              alt=""
            />
            </Link>
            <p>Copyright 2020</p>
      </Grid>

      <Grid container className="footer__container" alignItems={"center"} justify={"center"} spacing={2}>
        <Grid item>Terms of Use</Grid>
        <Grid item>US</Grid>
        <Grid item>Canada</Grid>
        <Grid item>Europe</Grid>
      </Grid>
    </Grid>
  )
}
