import {Box, CircularProgress, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import Vimeo from "@u-wave/react-vimeo";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    videoModalTitle: {
        maxWidth: '95%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    relatedVideoList: {
        width: '100%',
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4)
        }
    },
    videoImage: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '150px',
        paddingRight: theme.spacing(1)
    },
    playIcon: {
        verticalAlign: 'middle',
    },
    videoLink: {
        display: 'flex',
        maxHeight: 150,
        margin: theme.spacing(1),
        "&:hover": {
            cursor: 'pointer'
        },
        maxWidth: '400px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%'
        }
    },
    modalVideoTitle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '90%',
        overflow: 'hidden'
    },

    modalVideoDescription: {
        maxHeight: '130px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '90%',
        '-webkit-line-clamp': 3,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical'
    },
    videoItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',

            maxHeight: 360,
            overflowY: 'scroll',


    },
    videoImageContainer: {
        display: 'flex',
        width: '25%',
    },
    videoInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        padding: '15px',
        paddingLeft: 0
    },
    videoProgressContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000
    },
    videoPlayer: {
        padding: theme.spacing(.5)
    },
    alertBox: {
        maxWidth: 300,
        margin: '0 auto'
    },
    currentlyPlayingVideo: {
        border: '1px solid gold'
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        borderRadius: ".4rem",
        maxHeight: "70vh",
        width: "100%"
    },
}));


function VimeoAlbum({currentVideoInfo,setCurrentVideoInfo, relatedVideos, hasFutureTicket, hasVIPTicket, customStringMap, videoModalTitle}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
    },[currentVideoInfo])

    const doesntHaveFutureVideoPermissions = !!currentVideoInfo.futureTicketRequired && !hasFutureTicket;
    const doesntHaveVIPTicketPermissions = !!currentVideoInfo.vipTicketRequired && !hasVIPTicket;
    const doesntHaveVideoPermissions = doesntHaveFutureVideoPermissions || doesntHaveVIPTicketPermissions;
    const onPlayerLoaded = () => setLoading(false);


    const hasOtherVideos = (videoId) => {
        return !!(relatedVideos && relatedVideos.length && relatedVideos.filter(vids => (vids.vimeoID || vids.id )!= videoId).length);
    };

  return (
      <Grid container spacing={2}>
          {currentVideoInfo &&
              <Grid item md={hasOtherVideos(currentVideoInfo.vimeoID||currentVideoInfo.id ) ? 6 : 12} xs={12} alignItems={"center"} style={{display:"flex"}} >
                  {
                      (doesntHaveVideoPermissions) ?
                          <Box className={classes.alertBox}>
                              <Alert severity="error" variant="standard">{t(`conventionProgram:content.videoPermissions${doesntHaveVIPTicketPermissions ? 'VIP' : 'Future'}`)}</Alert>
                          </Box> :
                          <div className={clsx(classes.modalContent, "embed-responsive")}>
                              <Vimeo responsive
                                     id="vimeoPlayer"
                                     onLoaded={onPlayerLoaded}
                                     onError={onPlayerLoaded}
                                     className={clsx("embed-responsive-item", classes.videoPlayer)}
                                     allow="fullscreen"
                                     video={currentVideoInfo.vimeoID||currentVideoInfo.id } />
                              {loading && <Box className={classes.videoProgressContainer}>
                                  <CircularProgress />
                              </Box>
                              }
                          </div>
                  }
              </Grid>
          }
          {hasOtherVideos(currentVideoInfo.vimeoID) && <Grid item md={6} xs={12} className={classes.relatedVideoList}>
              <Typography variant="h5" className={classes.videoModalTitle}>{t('conventionProgram:content.videosOfType', { type: videoModalTitle })}</Typography>
              <Divider />
              <Box className={classes.videoItemContainer}>
                  {relatedVideos
                  .map(video =>
                      <>
                          <Box key={video.vimeoID} className={clsx(classes.videoLink, (video.vimeoID === currentVideoInfo.vimeoID) && classes.currentlyPlayingVideo)} onClick={() => setCurrentVideoInfo(video)}>
                              <Box className={classes.videoImageContainer}>
                                  <img className={classes.videoImage} src={video.thumbnailURL} />
                              </Box>
                              <Box className={classes.videoInfoContainer}>
                                  <Typography variant="subtitle1" className={classes.modalVideoTitle}>{video.title}</Typography>
                                  {(!!video.vipTicketRequired || !!video.futureTicketRequired) && <Typography className={classes.modalVideoDescription} variant="body2">
                                      {!!video.vipTicketRequired ? customStringMap.needsVipTicket : customStringMap.needsFutureTicket}
                                  </Typography>
                                  }
                              </Box>
                          </Box>
                      </>
                  )}
              </Box>
          </Grid>
          }
      </Grid>
  );
}

export default VimeoAlbum;