import React from "react";
import {observer} from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import DialogContent from "@material-ui/core/DialogContent";

const PreviewShareProductModal = ({ t, product, shareProductRecipientName
                                      , shareProductMessage
                                      , previewShareProductEmail
                                      , togglePreviewShareProductEmail }) => {
    if (!previewShareProductEmail) {
        return null;
    }

    return (
        <Dialog open={true} onClose={togglePreviewShareProductEmail} maxWidth={"sm"} fullWidth>
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Typography>Here is email preview</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => togglePreviewShareProductEmail()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <table style={{width:'100%', textAlign: 'center'}}>
                    <tbody>
                    <tr>
                        <td><h2 style={{fontSize: 'x-large', paddingBottom: '15px'}}>{shareProductRecipientName},</h2></td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 'medium'}}>{shareProductMessage}<br /><br /></td>
                    </tr>
                    </tbody>
                </table>
                <table style={{width:'100%', textAlign: 'center'}}>
                    <tbody>
                    <tr>
                        <td><img src={product.productMedia[0].imageURL} alt="Promotional Product" height="250"
                                 style={{height: '250', width: '-webkit-fill-available', objectFit: 'contain', paddingBottom: '15px'}}/></td>
                    </tr>
                    <tr>
                        <td><h2 style={{fontSize: 'large', paddingBottom: '15px'}}>{product.productName}</h2></td>
                    </tr>
                    <tr>
                        <td><h2 style={{fontSize: 'medium'}} dangerouslySetInnerHTML={{
                            __html: product.productSummary
                        }}/></td>
                    </tr>
                    <tr>
                        <td>
                            <br /><br /><a className="button"><h2 style={{fontSize: 'medium', color: 'blue'}}>BUY NOW!</h2></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </DialogContent>
        </Dialog>
    )
}
export default  observer(PreviewShareProductModal)