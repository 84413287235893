import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {useLocalStore, observer} from "mobx-react-lite";
import BillingFormDef from "../forms/enrollBillingInfo";
import {useStores} from "../hooks/use-stores";
import BillingInfoForm from "./enrollment/billingInfoForm";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import fromPairs from "lodash/fromPairs";
import toPairs from "lodash/toPairs";
import Alert from "@material-ui/lab/Alert";



const useStyles = makeStyles((theme) => ({
     root:{
         color: "white",
         padding:10
     },
    appbarToolbar: {
        height: 58
    },
    appLogo: {
        paddingLeft: theme.spacing(1),
        whiteSpace: "nowrap",

    },
    appLogoImg: {
        height: 27,
        width: 27,
    },
    appLogoText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "35px",
        [theme.breakpoints.down('xs')]: {
            fontSize:  19,
        },
    },
}))


function ShoppingBossOnboarding()  {
    const classes = useStyles()
    const {onboardStore, userProfileStore, authStore} = useStores()
    const {t} = useTranslation("onboard")
    const [showForm, setShowForm] = useState(false)
    const { allCountries, regionsByCountry } = userProfileStore;
    const { country, sbSetupCompleted } = authStore;
    const {  savingSBDataError } = onboardStore;
    const localStore = useLocalStore(() => ({
        _form: new BillingFormDef({}, {
                onSuccess: async (form) => {
                    let values = form.values()
                    const  mapping = { "billFirstName": "firstName"
                        , "billLastName": "lastName"
                        , "billCountry": "country"
                        , "billAddress": "address1"
                        , "billCity": "city"
                        , "billPostalCode": "zip"
                        , "billRegion": "region"
                        , "cardExpMonth": "cardMonth"
                        , "cardExpYear": "cardYear"
                        , "cardCode": "cvc"
                        ,  }

                    let translatedValues = fromPairs( toPairs ( values).map( pair=>{
                        return mapping[pair[0]]? [mapping[pair[0]], pair[1]]: pair
                    } ))


                    await onboardStore.saveSBData( translatedValues )
                    localStore.formSubmitting = false;
                },
                onError: async ()=>{
                    this.formSubmitting = false;
            },
            formSubmitting:false
        }),
        submit(){
            this.formSubmitting = true;
            this._form.submit()
        },
        get error(){
            return this._form.error
        },
        update(values) {
            this._form.update(values)
        }
    }))

    useEffect(() => {
        localStore.update({billCountry:country})
    }, [country]);

    const handleAccountCreate = () => {
        setShowForm(true)
    }

    useEffect(() => {
        userProfileStore.loadAllCountries();
        userProfileStore.loadRegionsByCountry(country);
    }, []);

    const handleBillingCountryChanged = (newVal) => {
        if (newVal)
            userProfileStore.loadRegionsByCountry(newVal);
    };


    if ( sbSetupCompleted )
        return  <Container maxWidth={"md"} className={classes.root}>
            <Grid container justify={"center"} alignItems={"center"}>
                <Grid container item xs={12}>
                    <Typography variant={"subtitle2"}>{t("already_have")}</Typography>
                </Grid>
                <Grid container item xs={12} justify={"center"} alignItems={"center"}>
                    <Button color={"secondary"} variant={"contained"} href={"/"}>{t("home")}</Button>
                </Grid>
            </Grid>

        </Container>



    return  <Container maxWidth={"md"} className={classes.root}>
             <Grid container justify={"center"} alignItems={"center"}>
                 <Grid container item xs={12}>
                     <Toolbar disableGutters variant="dense" className={classes.appbarToolbar}>
                         <span className={classes.appLogo}>
                        <img src={"/logo.png"} className={classes.appLogoImg} />
                        <span className={classes.appLogoText}>Life - National Brand Stores Setup</span>
                    </span>
                     </Toolbar>
                 </Grid>
                 {!showForm
                 &&  <Grid container item spacing={4}>
                     <Grid container item xs={12}>
                         <Typography variant={"h6"}>{t("welcome")}</Typography>
                     </Grid>
                     <Grid container item xs={12}>
                         <Typography variant={"subtitle2"}>{t("first_time")}</Typography>
                     </Grid>
                     <Grid  item xs={12}>
                         <Divider/>
                     </Grid>
                     <Grid container item xs={12} justify={"center"} alignItems={"center"}>
                         <Button color={"secondary"} variant={"contained"} onClick={handleAccountCreate}>{t("create_new_account")}</Button>
                    </Grid>
                     <Grid  item xs={12}>
                         <Divider/>
                     </Grid>
                     <Grid container item xs={12}>
                         <Typography variant={"subtitle2"}>{t("already_have_support")}</Typography>
                     </Grid>
                     </Grid>}
                     {showForm
                 &&  <>
                        {(savingSBDataError || localStore.error) && <Alert severity="error">{savingSBDataError || localStore.error}</Alert>}
                        <BillingInfoForm form={localStore._form}
                                         buttonText={t("create_account")}
                                         cardFirst={false}
                                         allCountries={allCountries}
                                         regionsByCountry={regionsByCountry}
                                         handleCountryChanged={handleBillingCountryChanged}
                                         onSubmit={() => {
                                             localStore.submit();
                                         }}
                                         submitting={localStore.formSubmitting}
                        />
                     </>}
             </Grid>
        </Container>

}

export default observer( ShoppingBossOnboarding )