import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";

import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {starFishColor} from "../../components/styles";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    circle:{
        width: 120,
        height: 120,
        borderRadius: "50%",
        ...starFishColor(theme, "borderColor"),
        borderStyle: "solid",
        borderWidth: 2,
        padding: 10,
        [theme.breakpoints.down("xs")]: {
            width: 70,
            height: 70,
            padding: 5,

        }
    },
    addIcon:{
      fontWeight: "bold",
      paddingTop:42,
        [theme.breakpoints.down("xs")]: {
            paddingTop:22,

        }
    }

}));


function StarGetter(){

    const classes = useStyles();
    const {authStore} = useStores();
    const {t} = useTranslation("starfish");
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down(700));

    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/stargetter-image1.jpg`}
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("stargetter.action_breeds")}<span className={classes.linkColor}>{t("stargetter.results")}</span></Typography>
                <Typography align="center" className={classes.mainText}  variant="body1">{t("stargetter.benchmark_for_generating")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section, classes.noBottomMargin)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/stargetter-image2.jpg`}
                className={clsx( classes.middleImage, classes.noBottomMargin)} role="img"/>
        </section>

        <section className={clsx( classes.section, classes.inverse)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("stargetter.why_star_getter")}</Typography>
                <Typography align="center" className={classes.mainText}  variant="body1">{t("stargetter.why_star_getter_description")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <Grid container spacing={2}>
                <Grid item sm={2} xs ={0} container alignItems="center" ><Divider className={classes.divider} /></Grid>
                <Grid item sm={8}>
                    <Typography align="center" className={classes.headText} variant="h2">
                        {t("stargetter.earn1")}
                        <span className={classes.linkColor}>{t("stargetter.200pv")}</span>
                        {t("stargetter.earn3")}
                    </Typography>
                </Grid>
                <Grid item sm={2} xs ={0} container alignItems="center" ><Divider className={classes.divider}/></Grid>
            </Grid>
        </section>
        <section className={clsx( classes.section, classes.noBottomMargin)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h2">{t("stargetter.200pv")}</Typography>
            </div>

        </section>
        <section className={clsx( classes.section, classes.noTopMargin)}>
            <img
                src={ authStore.country === "fr"
                    ? `${process.env.PUBLIC_URL}/img/stargetter-image4-fr.png`
                    : `${process.env.PUBLIC_URL}/img/stargetter-image4-en.png` }
                className={clsx( classes.middleImage, classes.noTopMargin)}
                role="img"/>
        </section>

        {/*<section className={clsx( classes.section)}>*/}
        {/*    <Box display="flex" alignItems="flex-start" justifyContent="center">*/}
        {/*        <Box  display="flex" flexDirection="column" alignItems="center">*/}
        {/*            <Box className={classes.circle}>*/}
        {/*                <Typography align="center" className={classes.headText} variant={isPhone?"h5":"h3"}>50PV</Typography>*/}
        {/*            </Box>*/}
        {/*            <Typography align="center" className={classes.headText} variant="h6">SUBSCRIPTION</Typography>*/}
        {/*            <Typography align="center" className={classes.mainText}  variant="subtitle1">Royal Starfish</Typography>*/}
        {/*        </Box>*/}
        {/*        <Box>*/}
        {/*            <Typography align="center" className={classes.addIcon} variant={isPhone?"h5":"h3"}>+</Typography>*/}
        {/*        </Box>*/}
        {/*        <Box  display="flex" flexDirection="column" alignItems="center">*/}
        {/*            <Box className={classes.circle}>*/}
        {/*                <Typography align="center" className={classes.headText} variant={isPhone?"h5":"h3"}>68PV</Typography>*/}
        {/*            </Box>*/}
        {/*            <Typography align="center" className={classes.headText} variant="h6">STAR-GETTER</Typography>*/}
        {/*            <Typography align="center" className={classes.mainText}  variant="subtitle1">Average PV from Merchant shopping</Typography>*/}
        {/*        </Box>*/}
        {/*        <Box>*/}
        {/*            <Typography align="center" className={classes.addIcon} variant={isPhone?"h5":"h3"}>+</Typography>*/}
        {/*        </Box>*/}
        {/*        <Box  display="flex" flexDirection="column" alignItems="center">*/}
        {/*            <Box className={classes.circle}>*/}
        {/*                <Typography align="center" className={classes.headText} variant={isPhone?"h5":"h3"}>50PV</Typography>*/}
        {/*            </Box>*/}
        {/*            <Typography align="center" className={classes.headText} variant="h6">MARKETPLACE</Typography>*/}
        {/*            <Typography align="center" className={classes.mainText}  variant="subtitle1">Additional Marketplace PV</Typography>*/}
        {/*        </Box>*/}


        {/*    </Box>*/}
        {/*</section>*/}

        <section className={clsx( classes.section)}>
            <Divider className={classes.divider}/>
        </section>

        <section className={clsx( classes.section)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/stargetter-image3.png`}
                className={classes.middleImage} role="img"/>
        </section>

        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3"><span className={classes.linkColor}>{t("stargetter.track_your_progress1")}</span>{t("stargetter.track_your_progress2")}</Typography>
                <Typography align="center" className={classes.mainText}  variant="body1">{t("stargetter.track_your_progress_description")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default StarGetter