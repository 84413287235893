
import IdLessRestService from "./IdLessRestService";


export default class SendoutService extends IdLessRestService {
    constructor() {
        super('/api/share');
    }

    async getShareDetails(code){
        return this.post( `${this.baseUrl}/buildpage`, {msgID:code})
        //

        //STUB:
        await new Promise((resolve, reject)=>{
            setTimeout( resolve, 1000 )
        })

        if( code === "11111111"){
            return {messageType:7, mediaURL: "528290854", shopURL:"shopURL", senderInfo:{ senderEmail:"test@test.com", senderDisplayName: "Test ", senderPhone: "242423123142"}}
        }
        else{
            throw new Error("Code not valid")
        }
    }

}
