import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Box, Button, Container, Divider, Grid, Link, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Keypad from "./components/Keypad";
import ValueWithCurrency from "./components/StyledValueWithCurrency";
import Header from "./components/Header";
import Progress from "./components/Progress";
import InfoModal from "./components/InfoModal";
import MerchantInfo from "./components/MerchantInfo";
import { joinPath } from "../../utils/helpers";
import { useStores } from "../../hooks/use-stores";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Html from "../../components/Html";

const useStyles = makeStyles((theme) => ({
  subheader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modalContainer: {
    padding: 20,
    borderRadius: 5,
    outline: "none",
    textAlign: 'center'
  },
  rangeWarning: {
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    borderRadius: 20,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'max-content',
    fontSize: 14
  },
  textField: {
    width: '100%',
    textAlign: 'center',
    fontSize: 40,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  currency: {
    fontSize: 14,
    verticalAlign: 'super',
    color: 'gray'
  },
  staticPriceList: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  singleValue: {
    justifyContent: 'center'
  },
  staticPrice: {
    display: 'flex',
    flex: '0 0 25%',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    border: 'solid #007BFF 1px',
    margin: theme.spacing(1),
    height: 'auto',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    }
  },
  inputContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    margin: '0 auto',
    width: '100%'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  checkoutButton: {
    backgroundColor: "#007BFF",
    color: "white",
    maxWidth: 'sm',
    margin: '0 auto',
  },
  termsContainer: {
    textAlign: 'center',
  },
  termsLink: {
    fontSize: 14,
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  connectionSpeedWarning: {
    color: 'white',
    lineHeight: 1.2,
    fontSize: '.8rem',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const InformationLink = ({ onClick, description, hideBorder }) =>
  <Box
    onClick={onClick}
    style={{
      cursor: 'pointer',
      padding: '8px 0',
      alignItems: 'center',
      borderBottom: hideBorder ? 0 : '1px solid #555555',
    }}
    display={'flex'}
    justifyContent={'space-between'}
  >
    <Box
      style={{
        fontSize: 15,
        color: '#007BFF',
        textAlign: 'left'
      }}
    >
      {description}
    </Box>
    <div><FontAwesomeIcon icon={faChevronRight} size="14px" fontWeight={'bold'} /></div>
  </Box>;


const Review = ({ shoppingBossMatch }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { shoppingBossStore } = useStores();
  const history = useHistory();

  const params = useParams();
  const { error, checkoutInfoLoading, merchantInfo, eCodeInfo } = shoppingBossStore;

  const [isShoppingBoss, setIsShoppingBoss] = useState(true);
  const [merchantInfoLoading, setMerchantInfoLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [valueInCents, setValueInCents] = useState(0);
  const [showConnectionSpeedWarning, setShowConnectionSpeedWarning] = useState(true);

  const returnToMerchantsPage = () => history.push(shoppingBossMatch.url);
  const hideModal = () => {
    setModalOpen(false);
    setModalTitle('');
    setModalMessage('');
  };

  const showMerchantCurrency = (country) => {
    if (country == "CA") {
      return "CAD";
    }
    return "USD";
  };

  const isInRange = () => {
    const { hasRange } = eCodeInfo;
    const { minRange, maxRange } = getRanges();
    const valueInDollars = valueInCents / 100;

    return hasRange && (valueInDollars >= minRange && valueInDollars <= maxRange);
  };

  const isValidStaticPrice = () => {
    const { staticPrices, hasRange } = eCodeInfo;
    const valueInDollars = valueInCents / 100;

    return !hasRange && staticPrices.length && staticPrices.includes(valueInDollars);
  };

  const startCheckoutProcess = async () => {
    const valueInDollars = valueInCents / 100;

    if (!isShoppingBoss && !shoppingBossStore.isValidBitrefillAmount(valueInDollars)) {
      setModalTitle(t('shoppingBoss:error.title'));
      setModalMessage(t('shoppingBoss:error.notSelected'));
      setModalOpen(true);
      return;
    } else {
      if ((!(isInRange() || isValidStaticPrice()))) {
        setModalTitle(t('shoppingBoss:error.title'));
        setModalMessage(rangeMessage());
        setModalOpen(true);
        return;
      }
    }

    // The inputted value must be between the ranges provided by the API
    // If the user's balance doesn't cover the total, show an error
    try {
      if (shoppingBossStore.userBalance < valueInDollars) {
        setModalTitle(t('shoppingBoss:error.title'));
        setModalMessage(t('shoppingBoss:error.insufficentBalance'));
        setModalOpen(true);
        return;
      }

      await shoppingBossStore.startCheckout({
        merchantID: merchantInfo.id,
        eCodeID: eCodeInfo.id,
        denomination: valueInDollars,
        merchantCountry: merchantInfo.merchantCountry,
        merchantTerms: eCodeInfo.description
      });
      history.push(joinPath(shoppingBossMatch.url, 'checkout'));
    } catch (e) {
      setModalTitle(t('shoppingBoss:error.title'));
      setModalMessage(t('shoppingBoss:error.tokenFail'));
      setModalOpen(true);
    }
  };

  const inputSelector = () => {
    const { hasRange } = eCodeInfo;
    if (hasRange && isShoppingBoss) {
      return <Keypad onKeyPressed={setValueInCents} value={valueInCents} />;
    } else {
      let listOfValues = eCodeInfo.staticPrices;
      const singleValue = listOfValues.length == 1;

      return (
        <List className={clsx(classes.staticPriceList, singleValue && classes.singleValue)}>
          {
            listOfValues.map(
              price =>
                <ListItem key={price} component={Button} className={classes.staticPrice} onClick={() => setValueInCents(price * 100)}>
                  ${price}
                </ListItem>
            )
          }
        </List>
      );
    }
  };

  const getRanges = () => {
    const { staticPrices, hasRange, } = eCodeInfo;

    let minRange = eCodeInfo.minRange;
    let maxRange = eCodeInfo.maxRange;

    if (!hasRange) {
      minRange = staticPrices[0];
      maxRange = staticPrices[staticPrices.length - 1];
    }

    // adjust minRange so the minimum is always 1 dollar
    minRange = Math.max(minRange, 1);

    return { minRange, maxRange };
  };

  const rangeMessage = () => {
    const { minRange, maxRange } = getRanges();
    return t("shoppingBoss:review.rangeMessage", { minRange: `$${minRange}`, maxRange: `$${maxRange}` });
  };

  const showMerchantTerms = (termsAndConditionsHtml) => {
    setModalTitle(t('shoppingBoss:review.termsAndConditions'));
    setModalMessage(() => <Html className={classes.body} __html={termsAndConditionsHtml} /> );
    setModalOpen(true);
  };

  const showRedemptionInstructions = (redeemInstructionsHtml) => {
    setModalTitle(t('shoppingBoss:review.redemptionInstructions'));
    setModalMessage(() => <Html className={classes.body} __html={redeemInstructionsHtml} /> );
    setModalOpen(true);
  };

  useEffect(() => {
    shoppingBossStore.currentPage = 'review';

    /*
      We need some baseline information about the merchant to know how to handle
      its flow. If the merchant wasn't selected from the homepage (ie the user goes directly
      to the merchant's page via their id) we don't have this information
    */
    if (shoppingBossStore.selectedMerchant) {
      (async () => {
        try {
          await shoppingBossStore.fetchMerchantInfo();

          if (shoppingBossStore.userBalance == null) {
            await shoppingBossStore.fetchUserBalance();
          }

        } catch (e) {
          // handled in the render
        } finally {
          setMerchantInfoLoading(false);
        }
      })();
    } else {
      history.push(shoppingBossMatch.url); // if no merchant was in the params, the user didn't get here; take them back to select a merchant
    }
  }, []);

  useEffect(() => {
    if (merchantInfo) {
      const isSb = merchantInfo?.provider === 'ShoppingBoss' || merchantInfo?.provider === 'sparkwallet';
      setIsShoppingBoss(isSb);

      if (merchantInfo.buyMessage) {
        setModalMessage(merchantInfo.buyMessage);
        setModalOpen(true);
      }

      if (eCodeInfo) {
        // Finally, once we have the merchant info, preselect the value if it's there's only one static price
        const { staticPrices } = eCodeInfo;
        if ((staticPrices && staticPrices.length > 0) || !isSb) {
          let listOfValues = staticPrices;

          if (listOfValues.length == 1) {
            setValueInCents(listOfValues[0] * 100);
          }
        }
      }
    }
  }, [merchantInfo]);

  return (
    <Container maxWidth="sm">
      {merchantInfoLoading ? <Progress /> :
        !merchantInfo ? <Alert severity="error">
          <AlertTitle>{t('shoppingBoss:error.title')}</AlertTitle>
          {error || t('shoppingBoss:error.generic')}
        </Alert>
          :
          <>
            <InfoModal open={modalOpen} onClose={hideModal} title={modalTitle} message={modalMessage} confirmButtonText={t('shoppingBoss:ok')} />
            <Grid xs={12} height="100vh" container flexDirection="column" justifyContent="center" spacing={1}>
              <Grid xs={12} container style={{ position: 'relative' }}>
                <Grid container item xs={12}>
                  <Header buttonText={t('shoppingBoss:review.back')} onBackButtonPressed={returnToMerchantsPage} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <MerchantInfo
                    logo={merchantInfo.logo}
                    name={merchantInfo.name}
                    cbPercent={eCodeInfo.percent}
                    /* Making this area of the component generic */
                    rightSide={
                      () => <Grid container className={classes.termsContainer}>
                        {/* If the redemption instructions exist, show the button to display the modal */}
                        {!!eCodeInfo.redeemInstructionsHtml && <Grid item xs={12}>
                          <InformationLink onClick={() => showRedemptionInstructions(eCodeInfo.redeemInstructionsHtml)} description={t("shoppingBoss:review.redemptionInstructions")} />
                        </Grid>
                        }
                        {/* If the terms And Conditions exist, show the button to display the modal */}
                        {!!eCodeInfo.termsAndConditionsHtml && <Grid item xs={12}>
                          <InformationLink hideBorder onClick={() => showMerchantTerms(eCodeInfo.termsAndConditionsHtml)} description={t("shoppingBoss:review.termsAndConditions")} />
                        </Grid>
                        }
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <ValueWithCurrency
                  color={!(isInRange() || isValidStaticPrice()) ? '#007BFF' : 'white'}
                  disabled={true}
                  value={valueInCents}
                  onChange={(e) => setValueInCents(e.target.value)}
                  currency={showMerchantCurrency(merchantInfo.merchantCountry)}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              {eCodeInfo.hasRange && isShoppingBoss &&
                <Grid container item xs={12}>
                  <Box style={{ backgroundColor: !(isInRange() || isValidStaticPrice()) ? '#007BFF' : 'rgb(55,53,56)' }} className={classes.rangeWarning}>{rangeMessage()}</Box>
                </Grid>
              }
              <Grid item container xs={12}>
                <Box className={classes.inputContainer}>{inputSelector()}</Box>
              </Grid>

              {showConnectionSpeedWarning && <Typography className={classes.connectionSpeedWarning}>{t('shoppingBoss:review.connectionSpeed')}</Typography>}

              <Grid item container xs={12} direction="column">
                <Button
                  className={classes.checkoutButton}
                  fullWidth
                  size={"large"}
                  variant={"contained"}
                  onClick={startCheckoutProcess}
                  disabled={checkoutInfoLoading}
                >{checkoutInfoLoading ? t("shoppingBoss:review.loading") : t("shoppingBoss:review.review")}</Button>
              </Grid>
            </Grid>
          </>
      }
    </Container>
  );
};

export default observer(Review);