import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons';
import {makeStyles} from "@material-ui/core/styles";
import {observer} from "mobx-react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from "@material-ui/core/Link";
import {joinPath} from "../../../../utils/helpers";

const useStyles = makeStyles((theme) => ({
    root:{
        backgroundColor: "#111",
        borderLeft: "1px solid #000000",
        paddingLeft: 22,
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 20
    }
}))


function BreadCrumb({shoppingMatch, parentCategory, childCategory }){

    const classes = useStyles()
    const { t } =useTranslation();
    return (
        <div className={classes.root}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link component={RouterLink} color="inherit" to={shoppingMatch.url}>
                    <FontAwesomeIcon data-dismiss="modal" icon={faShoppingBag} size="1x" />
                </Link>
                {parentCategory && <Link component={RouterLink} color="inherit"
                                         to={joinPath( shoppingMatch.url, `category/${parentCategory.parentCategoryID}`)}>
                    {t(`shopping:categories.${parentCategory.parentCategoryName}`)}
                </Link>}
                {childCategory && <Link component={RouterLink} color="inherit"
                                        to={joinPath( shoppingMatch.url, `category/${parentCategory.parentCategoryID}/sub-category/${childCategory.childCategoryID}`)}>
                    {t(`shopping:subcategories.${childCategory.childCategoryName}`)}
                </Link>}
            </Breadcrumbs>
        </div>
      
    )

}

export default observer(BreadCrumb)



