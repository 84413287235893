import React, { useState} from 'react';
import SpeakerModal from './SpeakerModal';
import {useTranslation} from 'react-i18next';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {useHistory} from "react-router";
import {joinPath} from "../../../utils/helpers";
import {observer} from "mobx-react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import MediaList from "./MediaList";
import flatten from "lodash/flatten";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-light-svg-icons";

const useStyles = makeStyles((theme) => ({
  title:{
    display: 'flex',
    justifyContent:"space-between"
  }
}))


function Speaker({ shoppingMatch, speakerName, onAdditionalProductHandler, speakerAudio, onClose }) {

  const history = useHistory();
  const classes = useStyles();
  const {t} = useTranslation();
  const [currentIdx, setCurrentIdx] = useState(null)
  const [currentSongURL, setCurrentSongURL] = useState(null)

  const pauseTrack = (idx) => {
    if (currentSongURL) {
      setCurrentIdx( idx )
      currentSongURL.pause();
      setCurrentSongURL(null)
    }
  }

  console.log("show modal")


  const playTrackAudio = (audioURL, idx) => {
    setCurrentIdx( idx )

    let audio = new Audio(audioURL)

    if (!currentSongURL) {
      setCurrentSongURL(audio)
      audio.play();
      return
    }

    if (currentSongURL.src !== audioURL) {
      currentSongURL.pause();

      setCurrentSongURL(audio)
      audio.play();
      return;
    }

    audio.play();
  }

  const onShowAllClick = (speaker) => {
    history.push(joinPath( shoppingMatch.url, '/search' ), { speaker: speaker})
  }

  const renderMediaListItems = () => {
    if (speakerAudio) {
      return speakerAudio.map((item, index) => {
        return <SpeakerModal
          onAdditionalProductHandler={onAdditionalProductHandler}
          currentIdx={currentIdx}
          idx={index}
          key={index}
          pauseTrack={(index) => { pauseTrack(index) }}
          playTrackAudio={(songURL, index) => { playTrackAudio(songURL, index)}}
          audioItem={item} />
      })
    }
  }

    return (
      <Dialog
        open={true}
        onClose={onClose}
      >

        <DialogTitle disableTypography  classes={{root:classes.title}} >
          <Grid container alignItems={"center"} justify={"space-between"}>
            <Typography>{t('shopping:audiomodal.audiosby')} {speakerName}</Typography>
            <div style={{ cursor: "pointer" }} onClick={() => onClose()}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </div>
          </Grid>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <div className="modal-content modal-speaker__wrapper">
              <Button  variant={"text"} color={ "primary"} onClick={() => onShowAllClick(speakerName)}>
                {t('shopping:audiomodal.showall')}
              </Button>
          <MediaList shoppingMatch={shoppingMatch} showType={true} productMedia={ flatten( speakerAudio.map(x=> x.productDetails.map( y=>{  y.parentItem = x; return y} ) )) } />
          </div>
        </DialogContent>
      </Dialog>
    )

}

export default observer(Speaker);
