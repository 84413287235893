import React, {useMemo,} from "react";
import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core';
import menu from './menu.json';
import {useRouteMatch} from "react-router-dom";
import LinksMenu from "./LinksMenu";

function Share ( {} ){
    const match = useRouteMatch();
    const MainLinks = useMemo(()=>{
         const items = menu.find(x=>x.tag==="sharestarfish").items
        return items
    },[menu])



    return (
        <Container>
            <>
                <LinksMenu items={MainLinks}
                           showBack={false}
                           parentMatch = { {path: "/royalstarfish"}}
                           collapsePath  />
            </>
        </Container>
    );
}

export default observer(Share);