import React from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {getUniqKey, joinPath} from "../../utils/helpers";
import Avatar from "@material-ui/core/Avatar";
import MoreHoriz from "@material-ui/icons/MoreHoriz";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ItemWrapper from "../../containers/ItemWrapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {getItemUrl} from "../../utils/menuUtils";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position:"relative",
        color: "white",
        flexDirection:"column",
        marginBottom:20,
        paddingRight:20

    },
    cardBody:{
        padding: 20,
        paddingBottom: 0,
        border:"1px solid #f50057",
        borderRadius: 20,
        height: 250,
        display:"flex",
        flexDirection: "column"
    },
    cardTitle:{
        marginBottom: 20,
    },
    link:{
        textDecoration:"none",
        color: "white",
        display:"inline-flex",
        alignItems:"center",
        flexDirection:"column",
        justifyContent:"center",
        width:100,
        marginBottom: 20,
        "&:hover":{
            color:"white",
            textDecoration:"none",
        }
    },
    subitems:{
        display: "flex",
    },
    icon:{
        width:75,
        height:75,
        [theme.breakpoints.down('sm')]: {
            width:50,
            height:50
        },
    },
    text:{
        display:"inline-block",
        width:"100%",
        textAlign:"center",
        [theme.breakpoints.down('sm')]: {
          fontSize:"small"
        },
    },
    thumbContainer:{
        position: "relative"
    },

    loadOverlay:{
        position: "absolute",
        top:0,
        bottom:0,
        left:0,
        right:0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }
}));

export default observer(({def, parentMatch, onPlay, detailsLoadingFor}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return def   && <Grid  item xs={12} sm={6} className={classes.root} key={def.tag}>
        <div className={classes.cardBody}>
        <Typography variant="subtitle1" align={"center"} className={classes.cardTitle}>{def.title}</Typography>
        <Grid  item container alignItems="flex-start" >
            {(def.subsection.slice(0, 3)).map(x=>{
               let itemUrl = getItemUrl(x);
               const mediaID =   itemUrl &&  itemUrl.startsWith("playmedia://") && itemUrl.substr("playmedia://".length )
               return <Grid item xs={3}   key={getUniqKey(x)} className={classes.link}>
                   <ItemWrapper def={def} item={x} parentMatch={parentMatch} onPlay={onPlay}>
                       <ListItemAvatar className={classes.thumbContainer}>
                           <>
                               <Avatar src={x.thumbnail} variant={"rounded"} className={classes.icon}/>
                               {detailsLoadingFor && detailsLoadingFor.includes(mediaID) &&
                               <div className={classes.loadOverlay }>
                                   <CircularProgress color={"secondary"} thickness={3}/>
                               </div>}
                           </>
                       </ListItemAvatar>
                        <Typography variant="subtitle1"  className={classes.text}> {x.title}</Typography>

                   </ItemWrapper>
                </Grid>
            })}
            <Grid item xs={3}
                  component={Link} to={joinPath(parentMatch.url, getUniqKey(def) )}
                  className={classes.link}>
                <MoreHoriz className={classes.icon}/>
                <Typography variant="body1"  className={classes.text}>{t("more")}</Typography>
            </Grid>

        </Grid>
        </div>
    </Grid>
})


