import React from 'react';

import MainCategoryListContainerItem from './MainCategoryListContainerItem';
import Grid from "@material-ui/core/Grid";
import {observer} from "mobx-react";

function HomeListContainer({categoryItems, onAutoShipClick}) {

  const renderHomeListItems = () => {
    if (categoryItems) {
      return categoryItems.map((category, i) => {

        return <MainCategoryListContainerItem
          key={i}
          childCategories={category.childCategories}
          category={category}
          onAutoShipClick={onAutoShipClick}
        />
      })
    }
  }

    return (
      <Grid container spacing={3}>
        {renderHomeListItems()}
      </Grid>
    )
}

export default observer(HomeListContainer);
