import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import MaterialTextField from "../inputs/MaterialTextField";
import MaterialSelect from "../inputs/MaterialSelect";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import MaskedMaterialTextField from "../inputs/MaskedMaterialTextField";
import MaterialSelectWithSuggetion from "../inputs/MaterialSelectWithSuggestion";
import Paper from "@material-ui/core/Paper";
import MaterialTextFieldWithSuggetion from "../inputs/MaterialTextFieldWithSuggestion";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: theme.spacing(1),
        position: "relative",
        marginBottom: theme.spacing(3),

    }
}));


function ShippingAddressForm({ form, showCountry = true, country, regionsByCountry, suggestions, showButtons, showDefault, error, parentMatch, allCountries, handleCountryChanged, onCancel }) {
    const { t } = useTranslation();
    const classes = useStyles();
    return form ? <Grid className={classes.root} container>
        <Paper className={classes.section}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <MaterialTextField
                        fullWidth
                        margin="normal"
                        field={form.$("addressName")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <MaterialTextFieldWithSuggetion
                        fullWidth
                        margin="normal"
                        suggestion={(suggestions && suggestions.street1 && suggestions.street1 !== form.$("address1").value)
                            ? suggestions.street1 : null}
                        suggestionPrefix={"USPS Suggestion: "}
                        field={form.$("address1")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <MaterialTextField
                        fullWidth
                        margin="normal"
                        field={form.$("address2")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <MaterialTextFieldWithSuggetion
                        fullWidth
                        margin="normal"
                        suggestion={(suggestions && suggestions.city && suggestions.city !== form.$("city").value)
                            ? suggestions.city : null}
                        suggestionPrefix={"USPS Suggestion: "}
                        field={form.$("city")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                { showCountry && <Grid item xs={12} lg={12}>
                    <MaterialSelect
                        fullWidth
                        label="Language"
                        margin="normal"
                        labelAccessor={(x => x.countryName)}
                        valueAccessor={(x => x.countryShortCode)}
                        className={classes.textField}
                        field={form.$("country")}
                        options={allCountries}
                        onChange={handleCountryChanged}
                    />
                </Grid> }
                <Grid item xs={12} md={12}>
                    <MaterialSelectWithSuggetion
                        fullWidth
                        label="Language"
                        margin="normal"
                        suggestion={(suggestions && suggestions.state && suggestions.state !== form.$("region").value)
                            ? suggestions.state : null}
                        suggestionPrefix={"USPS Suggestion: "}
                        className={classes.textField}
                        field={form.$("region")}
                        valueAccessor={(x => x.shortCode)}
                        labelAccessor={(x => x.name)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        options={regionsByCountry[form.$("country").value] || []} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <MaterialTextFieldWithSuggetion
                        fullWidth
                        margin="normal"
                        suggestion={(suggestions && suggestions.Zip5 && suggestions.Zip5 !== form.$("zip").value)
                            ? suggestions.Zip5 : null}
                        suggestionPrefix={"USPS Suggestion: "}
                        field={form.$("zip")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </Paper>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {showButtons && <div className={classes.buttons}>
            <span className={classes.defaultMark}>
                {showDefault &&
                    <FormControlLabel control={
                        <Checkbox
                            checked={!!form.$("primaryAddress").value}
                            onChange={e => { form.$("primaryAddress").onChange(e.target.checked); }}
                            color="primary"
                        />
                    }
                        label="Make this my default shipping address"
                    />}

            </span>
            { parentMatch
                ?<Button component={Link} to={parentMatch.url}
                color="primary" className={classes.textField} >Cancel</Button>
                :<Button onClick={onCancel}
                color="primary" className={classes.textField} >Cancel</Button>}

            <Button variant="contained"
                onClick={form.onSubmit}
                color="primary" className={classes.textField} >Save</Button>
        </div>}
    </Grid>
        : "noform";
}

export default observer(ShippingAddressForm);