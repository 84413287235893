import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useStores } from "../../../hooks/use-stores";
import { Box, CircularProgress, Collapse, ListItemText, MenuItem, Select } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { greenColor, redColor } from "../../../components/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from "@material-ui/core/IconButton";
import { useLocalObservable } from "mobx-react-lite";
import PageTitle from "../../../components/PageTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import services from "../../../services";

const formatterUSD = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

function transactionName(id) {
    switch (id) {
        case 1:
            return "Shopping Cart";
        case 22:
            return "Marketing System Subscription";
        case 24:
            return "Leadership Convention Tickets";
        case 39:
            return "Total Personal Development";
        case 45:
            return "Volume From Partner Apps - Merchants";
        case 50:
            return "Gift Cards";
        case 51:
            return "Life App";
        case 52:
            return "Energy Drinks";
        case 58:
            return "Autoship";
        case 62:
            return "Thing or 2 Travel - Resorts";
    }
}

const useStyles = makeStyles((theme) => ({
    amountCell: {
        gridTemplateColumns: "120px [col-start] 50px [col-start]"
    },
    greenLabel: {
        ...greenColor(theme),
        gridColumn: 1
    },
    total: {
        gridColumnStart: "col-start 1"

    },
    amountAdditionalValue: {
        gridColumn: 2
    },
    deposit: { ...greenColor(theme) },
    withdrawal: { ...redColor(theme) },
    pendingEWallet: {
        marginBottom: theme.spacing(5),
    },
    filter: {
        gap: theme.spacing(5),
    },
    inputLabel: {
        fontSize: '1.2rem'
    }
}));


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '& td': {
            borderBottom: "none",
        },
    },
}))(TableRow);

export default observer(function TransactionsList() {
    const { transactionType } = useParams();
    const { t } = useTranslation("transactions");
    const { authStore, commonStore, transactionsStore } = useStores();
    const { currentUser } = authStore;
    const [open, setOpen] = React.useState(-1);
    const classes = useStyles();
    const { transactionsLoading, transactions, transactionsTypes
        , transactionsDetailsLoading, transactionsDetailsIndex
        , transactionsTrackingLoading, transactionsTrackingIndex
        , pendingeWallet

    } = transactionsStore;
    const [selectedYear, setSelectedYear] = useState(2023);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);


    const localStore = useLocalObservable(() => ({
        transactionsTypeFilter: -1,
        setTransactionsTypeFilter(value) {
            this.transactionsTypeFilter = value;
        },
        get effectiveItems() {
            /*
                It's possible that the current filter doesn't exist for the new result set.
               Attempting to filter on a non-existant type would return nothing and cause a blank to show
               in the type dropdown. If the type doesn't exist in the new set, fall back to showing 'all'
            */
            if (!transactions.some(t => t.transactionTypeID == this.transactionsTypeFilter)) {
                this.transactionsTypeFilter = -1;
            }
            if (this.transactionsTypeFilter === -1) {
                return transactions;
            }
            else
                return transactions.filter(item => item.transactionTypeID === this.transactionsTypeFilter);
        }
    }));

    useEffect(() => {
        transactionsStore.loadTransactions({ transactionType, year: selectedYear, month: selectedMonth });
        transactionsStore.loadTransactionsDetails({ transactionType, year: selectedYear, month: selectedMonth });
        transactionsStore.loadTransactionsTracking({ transactionType, year: selectedYear, month: selectedMonth });
        transactionsStore.loadPendingeWalletTransactions();
    }, [selectedYear, selectedMonth]);

    const downloadCSV = async () => {
        try {
            const csvResponse = await services.UserProfile.Transactions().export(selectedYear, selectedMonth, transactionType);

            let hiddenDownloadLink = document.createElement('a');
            const blob = new Blob([csvResponse], { type: 'text/csv' });
            hiddenDownloadLink.href = window.URL.createObjectURL(blob);
            hiddenDownloadLink.download = 'transactions.csv';
            hiddenDownloadLink.click();
        } catch (e) {
            commonStore.warn("There was an error downloading your file. Please try again.");
        }
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleTypeFilterChange = (e) => {
        localStore.setTransactionsTypeFilter(e.target.value);
    };

    const handleExport = (e) => {
        transactionsStore.exportData({ transactionType, year: selectedYear, month: selectedMonth });
    };

    const toggleOpenDetails = (groupID) => {
        if (open === groupID) {
            setOpen(-1);
        } else {
            setOpen(groupID);
        }
    };

    const detailsRows = useMemo(() => {
        return transactionsDetailsIndex[open] || [];
    }, [open, transactionsDetailsIndex]);

    const trackingInfo = useMemo(() => {
        return transactionsTrackingIndex[open];
    }, [open, transactionsTrackingIndex]);

    // We don't want to show dibsEarned after March of 2022
    // but we do want to show if All selected (ie when-1 is the month,
    // so this should catch that case too)
    const hideDibs = useMemo(() => {
        const hideDibsAfterDate = "03/14/2022";
        const isSelectedDateAfterMarch2022 = selectedYear >= 2022 && selectedMonth > 3;
        const isUserSignupDataAfterMarch2022 = moment(currentUser.signupDate).isAfter(hideDibsAfterDate);
        return isSelectedDateAfterMarch2022 || isUserSignupDataAfterMarch2022;
    }, [selectedYear && selectedMonth]);

    const showSatsColumn = useMemo(() => {
        if (selectedMonth && selectedYear && selectedMonth < 12 && selectedYear <= 2022) {
            return true;
        } else {
            return false;
        }
    }, [selectedYear, selectedMonth]);

    return <>
        {pendingeWallet.length > 0 && <>
            <PageTitle>{t("pendingeWalletTransactions")}</PageTitle>
            <TableContainer className={classes.pendingEWallet}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell>{t("type")}</TableCell>
                            <TableCell align="right">{t("amount")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pendingeWallet.map(p => {
                            return <TableRow key={p.TransactionID}>
                                <TableCell>{moment(p.CreatedDate).format("LL")}</TableCell>
                                <TableCell>{p.Amount > 0 ? "Deposit" : "Withdrawal"}</TableCell>
                                <TableCell align="right" className={clsx({
                                    [classes.deposit]: p.Amount > 0,
                                    [classes.withdrawal]: p.Amount < 0
                                })}>
                                    {p.Amount > 0 ? "+$" : "-$"}{p.Amount}</TableCell>
                            </TableRow>;
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>}
        {(transactionsLoading || transactionsLoading == null)
            ? <CircularProgress />
            : <>
                <PageTitle>{t("transactionsHistory")}</PageTitle>

                <Box display="flex" justifyContent="flex-end" className={classes.filter}>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("month")}</InputLabel>
                        <Select value={selectedMonth} onChange={handleMonthChange} label={t("month")} >
                            <MenuItem value={-1}>{t("all")}</MenuItem>
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>February</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("year")}</InputLabel>
                        <Select value={selectedYear} onChange={handleYearChange} label={t("year")}>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("type")}</InputLabel>
                        <Select value={localStore.transactionsTypeFilter} onChange={handleTypeFilterChange} label={t("type")}>
                            <MenuItem value={-1}>{t("all")}</MenuItem>
                            {transactionsTypes.map((type) => <MenuItem key={type} value={type}>{transactionName(type)}</MenuItem>)}

                        </Select>
                    </FormControl>
                    {!commonStore.isNativeApp && <Button onClick={downloadCSV} startIcon={<CloudDownloadIcon />}>Export</Button>}
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("orderNo")}</TableCell>
                                <TableCell>{t("name")}</TableCell>
                                {!!showSatsColumn && <TableCell>{t("satsEarned")}</TableCell>}
                                {!hideDibs && <TableCell>{t("dibsEarned")}</TableCell>}
                                <TableCell>{t("cashEarned")}</TableCell>
                                {currentUser.isMember && <TableCell>{t("totalPV")}</TableCell>}
                                <TableCell>{t("tax")}</TableCell>
                                <TableCell>{t("shipping")}</TableCell>
                                <TableCell align="right">{t("amount")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {localStore.effectiveItems.map((row) => (
                                <>
                                    <TableRow key={row.transactionID} onClick={() => toggleOpenDetails(row.orderID)}>
                                        <TableCell>
                                            <IconButton aria-label="expand row" size="small"
                                                onClick={() => toggleOpenDetails(row.orderID)}>
                                                {open === row.groupID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {moment(row.orderDate).format("LL")}
                                        </TableCell>
                                        <TableCell>
                                            {row.orderID}
                                        </TableCell>
                                        <TableCell>{row.transactionName}</TableCell>
                                        {!!showSatsColumn && <TableCell align="right">{formatterUSD.format(row.satsEarned)}</TableCell>}
                                        {!hideDibs && <TableCell align="right">{formatterUSD.format(row.dibsEarned)}</TableCell>}
                                        <TableCell align="right">{formatterUSD.format(row.cashEarned)}</TableCell>
                                        {currentUser.isMember && <TableCell>{formatterUSD.format(row.totalPV)}</TableCell>}
                                        <TableCell>{formatterUSD.format(row.tax)}</TableCell>
                                        <TableCell>{formatterUSD.format(row.shipping)}</TableCell>
                                        <TableCell align="right">{formatterUSD.format(row.total)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={"100%"}>
                                            <Collapse in={open === row.groupID} timeout="auto" unmountOnExit>
                                                {transactionsDetailsLoading
                                                    ? <CircularProgress />
                                                    : <Box margin={1}>
                                                        <Grid container>
                                                            <Grid container item sm={6} direction="column">
                                                                <Typography variant={"h6"}>{t("global:shippingInfo")}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddressName}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddress1}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddress2}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingCity} {row.shippingState} {row.shippingZip} {row.shippingCountry}</Typography>
                                                            </Grid>
                                                            <Grid container item sm={6} direction="column">
                                                                <Typography variant={"h6"}>{t("global:billingInfo")}</Typography>
                                                                <Grid container item direction="column">
                                                                    <Typography variant={"body1"}>{t("dibsRedeemed")}: {formatterUSD.format(row.dibsRedeemed)}</Typography>
                                                                    <Typography variant={"body1"}>{t("cashRedeemed")}: {formatterUSD.format((row.cashReeemed || 0) + (row.walletRedeemed || 0))}</Typography>
                                                                    <Typography variant={"body1"}>{t("last4")}: {row.last4}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {trackingInfo ? <Grid container>
                                                            <Grid container item sm={6}>
                                                                Tracking# <Link href={`https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingInfo.trackingNumber}`} target={"_blank"}> {trackingInfo.trackingNumber}</Link>
                                                            </Grid>
                                                        </Grid> : null}
                                                        <Table size={"small"}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell>{t("qty")}</TableCell>
                                                                    <TableCell>{t("cost")}</TableCell>
                                                                    <TableCell align="right">{t("amount")}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {detailsRows.map((d, i) => {
                                                                    return <StyledTableRow key={i}>
                                                                        <TableCell>
                                                                            {d.imageURL && <Avatar variant={"rounded"} src={d.imageURL} alt={d.itemDesc}
                                                                                style={{ width: "75px", height: "75px" }} />}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText primary={d.itemDesc}
                                                                                secondary={
                                                                                    `${d.sKU ? `SKU: ${d.sKU}` : ""}
                                                                                              | Format: ${d.isDigital ? "Digital" : "Physical"} 
                                                                                              | Price: $${d.unitCost.toFixed(2)}
                                                                                                ${currentUser.isMember ? `PV: ${d.pointValue.toFixed(2)}` : ''}`


                                                                                } />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.quantity}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {d.unitCost.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(d.quantity * d.unitCost).toFixed(2)}
                                                                        </TableCell>
                                                                    </StyledTableRow>;
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                }
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>}

    </>;
});