import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        position: "relative"
    },
    xlarge: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    libraryTitle: {
        fontSize: '.9rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem',
        }
    }
}));

export default observer(({ title, imageURL, speakers }) => {
    const classes = useStyles();

    return <Grid container alignItems={"center"} spacing={3} className={classes.root}>
        <Grid item sm={4} md={2}>
            <ListItemAvatar>
                <Avatar variant="rounded" className={classes.xlarge}
                    alt={` `}
                    src={`${imageURL}`}
                />
            </ListItemAvatar>
        </Grid>
        <Grid item sm={8} md={6}>
            <Grid item sm={12} container alignItems={"flex-end"} >
                <Typography primary={title} variant={"subtitle2"} className={classes.libraryTitle}>{title}</Typography>
            </Grid>
            <Grid item sm={12} container alignItems={"flex-end"} >
                <Typography primary={speakers} variant={"body2"} >{speakers}</Typography>
            </Grid>
        </Grid>
    </Grid>;
});


