import IdLessRestService from "./IdLessRestService";


export default class ShoppingCartService extends IdLessRestService {
    constructor() {
        super('/api/shoppingcart');
    }


    addItemToCart2(productID, qty, lang = 'en', cartID = '',) {
        return this.put(`${this.baseUrl}/cart/items`, { productID, qty, lang, cartID });
    }


    addItemToCart(options){
        return this.put(`${this.baseUrl}/cart/items`, options);
    }

    deleteCartItem(options){
        return this.del(`${this.baseUrl}/cart/items`, options);
    }

    updateCartItem(options){
        return this.patch(`${this.baseUrl}/cart/items`, options);
    }

    setup(guestCountry){
        return this.get(guestCountry?`${this.baseUrl}/setup/${guestCountry}`:`${this.baseUrl}/setup`);
    }

    userDetails(){
        return this.get(`${this.baseUrl}/userdetails`);
    }

    promotionalProducts({customerLevelID, marketID, language}){
        let url =  customerLevelID
            ? `${this.baseUrl}/parentcategory/792/${language}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}`
            : `${this.baseUrl}/parentcategory/792/${language}`;

        return this.get(url);
    }

    featuredProducts({customerLevelID, marketID, language}){
        let url =  customerLevelID
            ? `${this.baseUrl}/parentcategory/793/${language}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}`
            : `${this.baseUrl}/parentcategory/793/${language}`;

        return this.get(url);
    }

    getProductsByChildCategoryId({childCategoryId, pageIndex, customerLevelID, marketID, language}){
        let url =  customerLevelID
            ? `${this.baseUrl}/category/${childCategoryId}/filter?pageindex=${pageIndex}&pagesize=30&showAll=0&lang=${language}&guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}`
            : `${this.baseUrl}/category/${childCategoryId}/filter?pageindex=${pageIndex}&pagesize=30&showAll=0&lang=${language}`;

        return this.get(url );
    }


    getProductsByCategoryId({categoryId, customerLevelID, marketID, language}){
        let url =  customerLevelID
            ? `${this.baseUrl}/parentcategory/${categoryId}/${language}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}`
            : `${this.baseUrl}/parentcategory/${categoryId}/${language}`;

        return this.get(url );
    }

    getAutoshipProducts({language}){
        return this.get( "/api/autoship" );
    }

    getProductDetail(productCategoryID, sku, customerLevelID, marketID, language, autoship){
        let url =  customerLevelID
            ? `${this.baseUrl}/${sku}/${productCategoryID}/${language}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}`
            : `${this.baseUrl}/${sku}/${productCategoryID}/${language}${autoship?"/true":""}`;

        return this.get(url );
    }

    createUserCart(){
        return this.get(`${this.baseUrl}/cart`);
    }


    getUserCart({cartGuid, customerLevelID}) {
        return this.post(`/api/v2/shoppingcart/cart/items`, {
            cartID: cartGuid,
            customerLevelID: customerLevelID,
            lang: "en"
        });
    }

    getCartTotals({  cartGuid,
                      customerLevelID,
                      country,
                      region}){
        return this.post(`/api/v2/shoppingcart/cart/total`, {
            cartID: cartGuid,
            customerLevelID,
            country,
            region
        });
    }

    //move follow to propper services

    getCustomerEnrollWithProductLink(options){
        return this.post(`/api/enroll/guest`, options);
    }

    sendProductEmail(options){
        return this.post(`api/enroll/guest/email`, options);
    }

    getSpeakerAudio({speaker, customerLevelID, marketID, language}){
        let url =customerLevelID
            ? `${this.baseUrl}/search/speaker/${speaker}/${language}/?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}`
            : `${this.baseUrl}/search/speaker/${speaker}/${language}`;

        return this.get(url );
    }

    searchProducts(value, language, postBody){
        return this.post( `${this.baseUrl}/search/${encodeURIComponent(value)}/${language}`, postBody)
    }


    verifyEAccelerator(){
        return this.get( `${this.baseUrl}/verify/eaccelerator`)
    }
}
