import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import MaterialTextField from "../inputs/MaterialTextField";
import MaterialSelect from "../inputs/MaterialSelect";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialPhoneField from "../inputs/MaterialPhoneField";
import MaskedMaterialTextField from "../inputs/MaskedMaterialTextField";


function PersonalForm({form, onSubmit, submitting}) {
    const {t} = useTranslation();
    return form ? <Grid container spacing={3} justify={"center"} alignItems={"center"}>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                field={form.$("firstName")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                field={form.$("lastName")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                field={form.$("email")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                translationPrefix="enrollment"
                field={form.$("password")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                translationPrefix="enrollment"
                field={form.$("confirmPassword")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        {["US", "CA"].includes(form.$("country").value)
            && <Grid item xs={12} lg={7}>
            <MaskedMaterialTextField
                fullWidth
                margin="normal"
                field={form.$("phone")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>}
        <Grid item xs={12} lg={7}>
            <MaterialSelect
                fullWidth
                field={form.$("preferredLanguage")}
                options={[
                    {value:"en",label:"English"},
                    {value:"es",label:"Español"},
                    {value:"fr",label:"Français"} ]}
            />
        </Grid>
        {( onSubmit && ( submitting === undefined || !submitting  )) && <Grid item xs={12} lg={7}>
            <Button  onClick={onSubmit} fullWidth variant={"contained"}
                     color="primary">{t("enrollment:continue")}</Button>

        </Grid>}
        { onSubmit && ( submitting ) && <Grid item xs={12} lg={7}>
            <CircularProgress/>
        </Grid>}
    </Grid>:null
}

export default observer(PersonalForm)