import React from 'react';
import { useHistory } from "react-router"
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useStores } from "../../../hooks/use-stores";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        color: 'black',
        height: '100%',
        minHeight: '100%'
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        textAlign: 'center',
        marginTop: 0,
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    createNewButton: {
        backgroundColor: '#0095f6',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(0, 164, 227, .7)'
        }
    },
    divider: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    select: {
        fontSize: '1rem',
        marginBottom: theme.spacing(2)
    },
    chooseFile: {
    },
    lengthWarning: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: 'orange'
    }
}));

const ChoosePath = ({ lifelineMatch }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { authStore, commonStore, lifelineStore } = useStores();

    const videoDurationLimit = lifelineStore.videoDuration(authStore.currentUser.userID);

    const onFileSelected = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        if (files.length !== 1) {
            commonStore.warn('Please select a single file');
        } else {
            validateVideo(e.target.files[0]);
        }
    }

    const videoIsValid = (file) => {
        var blob = new Blob(
            [file],
            { "type": `${file.type}`}
        );

        lifelineStore.videoFile = blob;
        history.push(`${lifelineMatch.url}/reviewVideo`);
    }

    const validateVideo = (file) => {
        let video = document.createElement('video');

        video.onloadedmetadata = () => {
            if (video.duration > videoDurationLimit) {
                commonStore.warn('Video duration is too long. Please choose a shorter video');
            } else {
                videoIsValid(file);
            }
            window.URL.revokeObjectURL(video.src);
        }
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);
    }

    // iOS compresses videos by default.
    // Use multiple in the input disables compression for ios - a hack to avoid double compressing
    // See https://stackoverflow.com/questions/63631803/mobile-safari-ios-input-type-file-does-no-longer-convert-downscale-vide
    // We enforce a single file limit so only one file will can be selected. Thanks Apple.
    const isIos = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    return (
        <Container className={classes.wrapper}>
            <div className={classes.content}>
                <Grid container xs={12} alignItems="center" justifyContent="center" direction="column">
                    <Button className={classes.createNewButton} onClick={() => history.push(`${lifelineMatch.url}/createVideo`)}>{t('lifeline:createVideo.choosePath.createNew')}</Button>
                    <div className={classes.divider}>
                        {t('lifeline:createVideo.choosePath.or')}
                    </div>
                    <div>
                        <Typography className={classes.select} variant="body1">{t('lifeline:createVideo.choosePath.selectExisting')}</Typography>
                        <form className={classes.chooseFile}>
                            <input type="file" accept="video/mp4,video/*" onChange={onFileSelected} />
                        </form>
                        <Typography className={classes.lengthWarning} variant="body1">{t(`lifeline:createVideo.choosePath.maxLength${lifelineStore.isSpecialUser(authStore.currentUser.userID) ? 'Special' : ''}`)}</Typography>
                    </div>
                </Grid>
            </div>
        </Container>
    )
}

export default ChoosePath;

