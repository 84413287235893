import IdLessRestService from "./IdLessRestService";


class ShoppingBossUserService extends IdLessRestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/user`);
        }
        else
            throw new Error("Can be only nested service");
    }

}
export default class ShoppingBossService extends IdLessRestService {
    constructor() {
        super('/api/v5/shoppingboss');
    }

    User() {
        return new ShoppingBossUserService(this);
    }

    merchants(campaign = '') {
        return this.get(`${this.baseUrl}/merchants/${campaign}`);
    }

    merchantInfo(merchantId) {
        return this.get(`${this.baseUrl}/merchant/${merchantId}`);
    }

    favorites() {
        return this.get(`${this.baseUrl}/user/merchants/favorite`);
    }

    addToFavorites(merchantId) {
        return this.post(`${this.baseUrl}/merchant/${merchantId}/favorite/`);
    }

    removeFromFavorites(merchantId) {
        return this.del(`${this.baseUrl}/merchant/${merchantId}/favorite/`);
    }

    user() {
        return this.get(`${this.baseUrl}/user`);
    }

    checkoutToken(params) {
        return this.post(`${this.baseUrl}/checkout/token`, params);
    }

    checkout(params) {
        return this.post(`${this.baseUrl}/checkout`, params);
    }

    userBalance() {
        return this.get(`${this.baseUrl}/user/balance`);
    }

    starfishBanners() {
        return this.get(`${this.baseUrl}/banners/starfish`);
    }

    albums() {
        return this.get(`${this.baseUrl}/life/albums`);
    }
}
