import React from 'react';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import { joinPath } from "../../../utils/helpers";


const useStyles = makeStyles((theme) => ({

  root:{
    justifyContent:"center"
  },
  productListItem: {
    height: 200,
    textAlign: "center",
    marginBottom: 70,
    cursor: "pointer",
    objectFit: "contain",
    borderRadius: "5%",
  },
  productListItemImg: {
    height: "100%",
    maxWidth: 240,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
      width: 175
    },
    borderRadius: "5%",
    transition: "all .3s"
  },
  productListTitle: {
    paddingTop: 10,
    fontSize: 16,
    lineHeight: 1,
    color: "white",
    textDecoration: "none"
  }
}));


const ProductListItem = ({ item, parentMatch, category, sizeLG=3, sizeSM=4, sizeXS=6 }) => {
  const classes = useStyles();
  return (
    (
      <Grid container item
        lg={sizeLG}
        sm={sizeSM}
        xs={sizeXS} justifyContent={"center"} className={classes.root}>
        <Link component={RouterLink} to={joinPath(parentMatch.url, `/product-detail/${item.productSKU}`)}
          className={clsx(classes.productListItem, ` ${category} animated fadeIn delay-.5s`)}>
          <img src={item.productImage || item.bannerImage || item.imageURL} alt="product list" className={classes.productListItemImg} />
          <Typography variant={"subtitle1"} className={classes.productListTitle}>{item.productName|| item.productTitle }</Typography>
        </Link>
      </Grid>
    )
  );
};

export default ProductListItem;
