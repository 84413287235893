import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/use-stores';

const LearnAboutWallet = ({ notOnStarfish }) => {
    const { t } = useTranslation();
    const { authStore } = useStores();
    return (
        <Container>
            <h1>{notOnStarfish ? t('starfish:learnAboutWallet.nonStarfishWallet') : t('starfish:learnAboutWallet.wallet')}</h1>
            <div dangerouslySetInnerHTML={{ __html: authStore?.currentUser?.country == 'CA' ? t('starfish:learnAboutWallet.CA') : t('starfish:learnAboutWallet.US') }} />
            {notOnStarfish ? <div dangerouslySetInnerHTML={{ __html: t('starfish:learnAboutWallet.nonStarfishExtra') }} /> : null}
        </Container >
    )
}

export default LearnAboutWallet;