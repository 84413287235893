import React, { useState } from 'react';
import { Avatar, Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginBottom: '12px'
    },
    merchantInfo: {
        textAlign: '100%'
    },
    merchantInfoName: {
        fontWeight: 'bold',
        textAlign: 'left',
        margin: 0,
        fontSize: '.9rem',
    },
    avatar: {
        width: theme.spacing(11),
        height: theme.spacing(11),
        marginRight: theme.spacing(2),
        backgroundColor: "#fff",
        '& > img': {
            objectFit: 'contain'
        }
    },
    cashbackContainer: {
        marginTop: theme.spacing(4)
    },
    cashBack: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
    },
    cashBackTitle: {
        fontSize: 12
    },
    cashBackValue: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: '#007BFF',
        marginTop: '-2px'
    },
    readMore: {
        color: 'rgb(192,192,192)',
        cursor: 'pointer',
        marginLeft: '2px'
    }
}));

const DESCRIPTION_LENGTH = 100;

const MerchantInfo = ({ logo, name, cbPercent, rightSide, hideCbPercent }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Box display="flex" alignItems="center">
                <Avatar
                    className={classes.avatar}
                    variant="rounded"
                    alt={name}
                    src={`${logo}`}
                />
                <Box display="flex" flexDirection="column" style={{width: '100%'}}>
                    <Typography variant="h6" noWrap className={classes.merchantInfoName}>{name}</Typography>
                    {rightSide ? rightSide() : null}

                </Box>
            </Box>
            {
                !hideCbPercent &&
                <Grid xs={12} container className={classes.cashbackContainer}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.cashBack}>
                            <Typography className={classes.cashBackValue}>{cbPercent}</Typography>
                            <Typography className={classes.cashBackTitle}>{t("shoppingBoss:review.cashback")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Box >
    );
};

export default MerchantInfo;
