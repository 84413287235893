import React, {useEffect, useMemo, useState} from 'react'
import ProductList from '../ProductList/ProductList';
import Pagination from "react-js-pagination";
import LoadingIndicator from '../../../components/shop/LoadingIndicator'
//import { getCookie, deleteCookie } from '../../utils/cookieUtil';
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {useStores} from "../../../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({

  paginationContainer: {

  }
}))


function SubCategoryList({categoryId, subCategoryId}) {
  // state = {
  //   childItems: [],
  //   activePage: 1,
  //   pageNumbers: [],
  //   numberOfPages: 0,
  //   parentCategory: '',
  //   childCategory: '',
  //   showFooter: false
  // }

  const classes = useStyles()

  const [activePage, setActivePage] = useState(1)
  const {commonStore, shopStore} = useStores()
  const {parentCategoryIds, numberOfPages, childItems , productsByChildCategoryIdLoading, customerLevelID, market} = shopStore


  console.log('subCategoryID', subCategoryId)
  console.log('categoryId', categoryId)

  useEffect(()=>{
    (async ()=>{
      if ( categoryId &&  subCategoryId )
      await shopStore.getProductsByChildCategoryId({ childCategoryId:subCategoryId
        , pageIndex:activePage
        , customerLevelID, marketID:market
        , language:commonStore.language})
      window.scrollTo(0, 0)
    })()
  },[categoryId, subCategoryId, activePage ])


  // async componentDidMount() {
  //   if (this.props.baselineLoad) {
  //     this.setUpProductListItems();
  //   }
  // }
  //
  // componentDidUpdate = async (prevProps) => {
  //   if (prevProps.baselineLoad !== this.props.baselineLoad) {
  //     this.setUpProductListItems();
  //   }
  //   if (prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID ||
  //     prevProps.langApi !== this.props.langApi) {
  //       this.setUpProductListItems();
  //   }
  // }
  //const setUpProductListItems = () => {
    //
    //
    // let customerLevelID = null;
    // let marketID = null;
    // if (this.props.isGuest) {
    //   const country = JSON.parse(getCookie('guest_country'));
    //
    //   if (!country.customerLevelID || !country.marketID) {
    //     deleteCookie("access_token");
    //     deleteCookie("cart_guid");
    //     deleteCookie("selected_lang");
    //     deleteCookie("guest_country");
    //     deleteCookie("guest_region");
    //     deleteCookie("is_guest");
    //     deleteCookie("is_quick_buy");
    //     this.props.history.push('/login');
    //   }
    //
    //   customerLevelID = country.customerLevelID;
    //   marketID = country.marketID;
    // // }
    //
    // this.props.getProductsByChildCategoryId(subCategoryID, this.props.accessToken, this.props.langApi, 1, customerLevelID, marketID, ((response) => {
    //   this.setState({ childItems: response[0],
    //     childCategory: childCategory[0].childCategoryName,
    //     parentCategory: parentCategory[0].parentCategoryName,
    //     numberOfPages: response[1][0].numberOfPages,
    //     showFooter: true });
    //   this.generatePageNumber();
    // }).bind(this));
 //}

  const pageNumbers = useMemo( () => {
    const pageNumArray = []
    for (let i = 0; i < numberOfPages; i++) {
      pageNumArray.push(i + 1)
    }
    return pageNumArray
  },[numberOfPages])

  const handlePageChange = (pageNumber) =>{
    setActivePage(pageNumber)
    // const childCategoryId = subCategoryID;
    //
    // let customerLevelID = null;
    // let marketID = null;

    // if (this.props.isGuest) {
    //   const country = JSON.parse(getCookie('guest_country'));
    //
    //   if (!country.customerLevelID || !country.marketID) {
    //     deleteCookie("access_token");
    //     deleteCookie("cart_guid");
    //     deleteCookie("selected_lang");
    //     deleteCookie("guest_country");
    //     deleteCookie("guest_region");
    //     deleteCookie("is_guest");
    //     deleteCookie("is_quick_buy");
    //     this.props.history.push('/login');
    //   }
    //
    //   customerLevelID = country.customerLevelID;
    //   marketID = country.marketID;
    // }
    //
    // this.props.getProductsByChildCategoryId(childCategoryId, this.props.accessToken, this.props.langApi, pageNumber, customerLevelID, marketID, ((response) => {
    //   this.setState({ childItems: response[0] });
    //   this.setState({ activePage: pageNumber });
    //
    // }).bind(this));
  }

    return (
    <>
      {
        productsByChildCategoryIdLoading ?
        (<LoadingIndicator />) : (
        <Container maxWidth={"md"}>
            <Grid container>
              <ProductList
                category={'category-view-img'}
                products={childItems}
              />
            </Grid>
          {numberOfPages>0 && <Grid container className={classes.paginationContainer}>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={4}
                totalItemsCount={pageNumbers.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </Grid>}
          </Container>)
      }
    </>
    )

}

export default observer(SubCategoryList);
