import React, {useCallback, useEffect,} from "react";
import {Link, useRouteMatch,} from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/use-stores";
import {Route, Switch, useHistory} from 'react-router';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import UserProfile from '../Profile/UserProfile';
import BillingInfo from '../Profile/BillingInfo';
import Security from '../Profile/Security';
import Help from '../Profile/Help';
import IFrame from '../../components/IFrame';
import MemberEnrollment from "../MemberEnrollment";
import Policies from "./Policies";
import {joinPath} from "../../utils/helpers";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer'
    },
    redBorder: {
        border: '1px solid #007BFF',
        borderRadius: '20px'
    },
    menuTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    }
}));


const MenuItem = ({ parentMatch, title, subtitle, path, isVerified,  handleUnverified  }) => {
    const classes = useStyles();
    const props  = {};
    if( path !== '/member/enroll' ){
        props.component=Link;
        props.to = joinPath( parentMatch.path, `${path}` )
    }
    else{
        if ( !isVerified ) {
            props.onClick= () => handleUnverified()
        }
        else {
            props.component=Link
            props.to=path
            props.target='_blank'
        }
    }

    return (
        <Box  {...props} className={clsx(classes.menuItem, classes.redBorder)}>
            <Box>
                <Typography className={classes.menuTitle} variant="h4">{title}</Typography>
            </Box>
            {subtitle && <Box>
                <Typography variant="body1">{subtitle}</Typography>
            </Box>
            }
        </Box>
    );
};

const MemberProfileLink = { id: 'memberEnroll', path: '/member/enroll', title: 'Become A Member', component: () => <MemberEnrollment /> };
const ProfileLinks = [
    { id: 'personalInfo', path: '/personalInfo', title: 'Personal Info', component: () => <UserProfile /> },
    { id: 'billingInfo', path: '/billingInfo', title: 'Billing Info', component: () => <BillingInfo /> },
    { id: 'security', path: '/security', title: 'Security', component: () => <Security /> },
    { id: 'subscriptions', path: '/subscriptions', title: 'Manage Subscriptions', component: () => <IFrame tag={"profile/subscriptions"} /> },
    { id: 'privacy', path: '/privacy', title: 'Terms and Privacy', component: () => <Policies /> },
    { id: 'help', path: '/help', title: 'Help', component: () => <Help /> },
    { ...MemberProfileLink }
];

const Wallet = observer(({identityVerificationStatus, isRoyal=false }) => {
    const classes = useStyles();
    const { supportStore, authStore, commonStore } = useStores();
    const history = useHistory();
    const match = useRouteMatch();
    const { currentUser, } = authStore;
    const { supportInfo, supportInfoLoading } = supportStore;
    const { t } = useTranslation();

    useEffect(() => {
        void supportStore.loadSupportInfo();
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isVerified = React.useMemo(() => {
        return ["APPROVED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const handleUnverified = () => {
        if (isUnverified) {
            commonStore.showConfirm(t("starfish:home.account_not_verified")
                , t("starfish:home.verify_account")
                , "Identify Verification",
                () => {
                    history.push(`/${isRoyal?"royalstarfish":"starfish"}/add_more_money/verify_identity`)
                }
            )
        }
        else
            commonStore.showConfirm(t("starfish:home.account_verification_failed")
                , t("starfish:home.contact_support")
                , t("starfish:home.identity_verification")
                , () => {
                    history.push(joinPath(match.path, 'help'))
                }
            )

    }

    const handleLogout = useCallback(() => {
        authStore.logout();
        history.push('/');
    }, []);



    return (
        <Container>
            <>
                <Switch>
                    {
                        ProfileLinks.map(link =>
                            <Route path={link.path !== '/member/enroll' ? joinPath(match.path, link.path) : link.path}
                                render={() =>
                                    <Box style={{ marginBottom: '60px' }}>
                                        {link.component()}
                                    </Box>
                                }
                            />
                        )
                    }

                    <Route path="*"
                        render={() => {

                            return <>
                                {ProfileLinks.map((link) => {
                                    if (link.id === 'memberEnroll') {
                                        if (currentUser.isMember === true) return '';
                                        else if (supportInfoLoading === null || supportInfoLoading === true) return true;
                                        else if (supportInfoLoading === false && supportInfo && supportInfo.isMemberEnrollmentAvailable === true ) {
                                            return <MenuItem
                                                parentMatch={match}
                                                key={MemberProfileLink.id}
                                                title={t(`starfish:profile.${MemberProfileLink.id}`)}
                                                path={MemberProfileLink.path}
                                                isVerified={isVerified}
                                                handleUnverified={handleUnverified}
                                            />;
                                        } else return '';
                                    } else {
                                        return <MenuItem
                                            parentMatch={match}
                                            key={link.id}
                                            title={t(`starfish:profile.${link.id}`)}
                                            path={link.path}
                                        />;
                                    }
                                })}
                                <Box className={clsx(classes.menuItem, classes.redBorder)} onClick={handleLogout}>
                                    <Box>
                                        <Typography className={classes.menuTitle} variant="h4">{t("global:logout")}</Typography>
                                    </Box>
                                </Box>
                            </>



                        }}
                    />
                </Switch>
            </>
        </Container>
    );
});

export default Wallet;