import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import debounce from "lodash/debounce";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { useStores } from "../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import services from "../../services";
import MaterialTextField from "../../components/inputs/MaterialTextField";
import PageTitle from "../../components/PageTitle";
import Validator from "validatorjs";

const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: theme.spacing(4),
        position: "relative",
        marginBottom: theme.spacing(3),

    },
    spouseEnableSection: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    infoIcon: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    popoverBody: {
        padding: theme.spacing(1)
    },
    textField: {

    },

    upperCase: {
        textTransform: "uppercase"
    }
}));

export default observer(function UserProfile() {
    const classes = useStyles();
    const { userProfileStore, spouseProfileStore, commonStore, authStore, updatingUser } = useStores();
    const [changingUsername, setChangingUsername] = useState(false);
    const [changingPassword, setChangingPassword] = useState(false);
    const [creatingSpouse, setCreatingSpouse] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [email, setEmail] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [newPasswordConfirmError, setNewPasswordConfirmError] = useState("");

    const { error } = userProfileStore
    const { spouseUsername } = authStore
    const { savingSpouseProfile, spouseEditForm, spouseProfileLoading } = spouseProfileStore



    const closePersonalSections = () => {
        setChangingUsername(false)
        setChangingPassword(false)
        setEmail("")
        setCurrentPassword("")
        setNewPassword("")
        setNewPasswordConfirm("")
    }

    const [loading, setLoading] = React.useState(false)
    const fetchIdRef = React.useRef(0)

    const fetchData = React.useCallback(debounce(async (email) => {
        // Ideally this would all be in a form, but that would require changing this whole container
        const isEmail = new Validator({email}, {'email': 'string|email|required|between:4,150'});
        if (isEmail.fails()) {
            setEmailValid(false);
            setEmailError('Username must be an email');
            return;
        }


        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current
        setLoading(true)
        setEmailValid(true)
        setEmailError("")

        try {
            await services.UserProfile.UsernameService().validateUserName(email)

            if (fetchId === fetchIdRef.current) {
                setEmailValid(true)
                setEmailError("")
            }
        }
        catch (e) {
            if (fetchId === fetchIdRef.current) {
                setEmailValid(false)
                setEmailError(e.response.data.message)
            }
        }
        finally {
            setLoading(false)
        }
    }, 500), [])

    useEffect(() => {
        if (email)
            fetchData(email)
    }, [fetchData, email])



    const updateEmail = async () => {
        if (emailError) {
            return;
        }

        let done = await authStore.updateUsername(email, currentPassword)
        if (done) {
            closePersonalSections()
            await authStore.refreshUser()
        }

    }

    const updatePassword = async () => {
        let isValid = true;
        if (newPassword.length < 7) {
            setNewPasswordError("Password must be at least 7 characters.")
            isValid = false
        }
        else
            setNewPasswordError("")

        if (newPassword !== newPasswordConfirm) {
            setNewPasswordConfirmError("Passwords do not match.")
            isValid = false
        }
        else {
            setNewPasswordConfirmError("")
        }

        if (isValid) {
            let done = await authStore.updatePassword(currentPassword, newPassword)
            if (done) {
                closePersonalSections()
            }
        }

    }


    const saveSpouse = async () => {
        spouseEditForm.submit()
    }

    const deleteSpouse = async () => {
        commonStore.showConfirm("Are you sure you want to remove the spouse account? All third party services associated with this account will be removed.", "Delete"
            , "Delete spouse account?"
            , async () => {
                let result = await spouseProfileStore.deleteSpouse()
                commonStore.success(result.message)
                setCreatingSpouse(false)
                await authStore.refreshUser()
            })
    }



    const onChangeSpouseAccount = async () => {
        setCreatingSpouse(true)
        spouseProfileStore.createEditForm(() => {
            setCreatingSpouse(false)

            authStore.refreshUser()
        })
        await spouseProfileStore.loadSpouseProfile()


    }



    useEffect(() => {
        (async () => {

        })()

    }, []);


    return <>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {(false)
            ? <CircularProgress />
            : <>
                <PageTitle>Password & Security</PageTitle>
                <Typography variant="subtitle1" className={classes.upperCase}>Your Credentials</Typography>
                <Paper className={classes.section}>

                    {(!changingUsername && !changingPassword) && <>
                        <Typography gutterBottom variant="caption"><b>Username:</b></Typography>
                        <Typography gutterBottom variant="subtitle1">{authStore.currentUser.username || authStore.currentUser.email}</Typography>
                        <Grid container spacing={3}>
                            <Grid item container sm={12} md={6} justify="center">
                                <Button color="primary" variant="contained" onClick={e => { setChangingUsername(true) }}>Change username</Button>
                            </Grid>
                            <Grid item container sm={12} md={6} justify="center">
                                <Button color="primary" variant="contained" onClick={e => { setChangingPassword(true) }}>Change password</Button>
                            </Grid>
                        </Grid>
                    </>}
                    {(changingUsername) && <>
                        <Grid container spacing={3}>
                            <Grid item container xs={12}>
                            {commonStore.onStarfishOrRoyalStarfish && <Typography variant="body1" style={{color: '#3579f6'}}>Your username has to be an email address and to change it please enter the new email address and your current password.</Typography>}
                                <TextField
                                    fullWidth
                                    label="Email"
                                    margin="normal"
                                    error={!emailValid}
                                    helperText={emailError}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={email}
                                    onChange={e => { setEmail(e.target.value) }}
                                />
                            </Grid>
                            <Grid item container xs={12}>
                                <TextField
                                    fullWidth
                                    label="Password"
                                    margin="normal"
                                    type="password"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={currentPassword}
                                    onChange={e => { setCurrentPassword(e.target.value) }}
                                />
                            </Grid>
                            <Grid item container sm={12} md={6} justify="center">
                                <Button fullWidth variant="contained" onClick={closePersonalSections}>Cancel</Button>
                            </Grid>
                            <Grid item container sm={12} md={6} justify="center">
                                <Button disabled={loading || updatingUser} fullWidth color="primary" variant="contained" onClick={updateEmail}>Update Username</Button>
                            </Grid>
                        </Grid>
                    </>}
                    {(changingPassword) && <>
                        <Grid container spacing={3}>
                            <Grid item container xs={12}>
                            <Typography variant="body2">Your new password must be at least 7 characters and for security reasons should contain at least 1 special character.</Typography>
                                <TextField
                                    fullWidth
                                    label="Current Password"
                                    margin="normal"
                                    type="password"
                                    className={classes.textField}
                                    value={currentPassword}
                                    onChange={e => { setCurrentPassword(e.target.value) }}
                                />
                            </Grid>
                            <Grid item container xs={12}>
                                <TextField
                                    fullWidth
                                    label="New Password"
                                    margin="normal"
                                    type="password"
                                    error={!!newPasswordError}
                                    helperText={newPasswordError}
                                    className={classes.textField}
                                    value={newPassword}
                                    onChange={e => { setNewPassword(e.target.value) }}
                                />
                            </Grid>
                            <Grid item container xs={12}>
                                <TextField
                                    fullWidth
                                    label="New Password Confirm"
                                    margin="normal"
                                    type="password"
                                    className={classes.textField}
                                    error={!!newPasswordConfirmError}
                                    helperText={newPasswordConfirmError}
                                    value={newPasswordConfirm}
                                    onChange={e => { setNewPasswordConfirm(e.target.value) }}
                                />
                            </Grid>
                            <Grid item container sm={12} md={6} justify="center">
                                <Button fullWidth variant="contained" onClick={closePersonalSections}>Cancel</Button>
                            </Grid>
                            <Grid item container sm={12} md={6} justify="center">
                                <Button disabled={updatingUser} fullWidth color="primary" variant="contained" onClick={updatePassword}>Update password</Button>
                            </Grid>
                        </Grid>
                    </>}
                </Paper>

                {/* Spouse Username is a way to check if spouse credentials exist without making an extra API call */}
                {!commonStore.onStarfishOrRoyalStarfish &&
                    spouseUsername && <>
                        <Typography variant="subtitle1" className={classes.upperCase}>Spouse Credentials</Typography>
                        <Paper className={classes.section}>
                            {!creatingSpouse &&
                                <Grid container spacing={3}>
                                    {spouseUsername &&
                                        <Grid item container xs={12}>
                                            <Typography variant="subtitle1">{spouseUsername}</Typography>
                                        </Grid>
                                    }
                                    <Grid item container xs={12} justify="center">
                                        <Button fullWidth color="primary" variant="contained" onClick={onChangeSpouseAccount}> Change Spouse Credentials</Button>
                                    </Grid>
                                </Grid>}
                            {creatingSpouse &&
                                <>
                                    {(spouseProfileLoading || !spouseEditForm)
                                        ? <CircularProgress />
                                        : <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <MaterialTextField
                                                    fullWidth
                                                    margin="normal"
                                                    field={spouseEditForm.$("firstName")}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MaterialTextField
                                                    fullWidth
                                                    margin="normal"
                                                    field={spouseEditForm.$("lastName")}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MaterialTextField
                                                    fullWidth
                                                    margin="normal"
                                                    field={spouseEditForm.$("email")}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MaterialTextField
                                                    fullWidth
                                                    margin="normal"
                                                    field={spouseEditForm.$("phone")}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MaterialTextField
                                                    fullWidth
                                                    margin="normal"
                                                    field={spouseEditForm.$("username")}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MaterialTextField
                                                    fullWidth
                                                    margin="normal"
                                                    field={spouseEditForm.$("password")}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item container sm={12} md={6} justify="center">
                                                <Button fullWidth variant="contained" onClick={closePersonalSections}>Cancel</Button>
                                            </Grid>
                                            <Grid item container sm={12} md={6} justify="center">
                                                <Button disabled={savingSpouseProfile} fullWidth color="primary" variant="contained" onClick={saveSpouse}>Save</Button>
                                            </Grid>
                                            <Grid item container sm={12} justify="center">
                                                <Button disabled={savingSpouseProfile} fullWidth color="secondary" variant="contained" onClick={deleteSpouse}>Delete Spouse Account</Button>
                                            </Grid>
                                        </Grid>}

                                </>
                            }
                        </Paper>
                    </>
                }

            </>
        }
    </>
})