import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import QRCode from "react-qr-code";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import ListIcon from "@material-ui/icons/List";
import 'react-circular-progressbar/dist/styles.css';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles } from "@material-ui/styles";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useStores } from "../../hooks/use-stores";
import ThumbMediaList from "../../components/ThumbMediaList";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";
import services from "../../services";
import Alert from "@material-ui/lab/Alert";
import MediaList from "../../components/MediaList";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTextField from "../../components/inputs/MaterialTextField";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { extractErrorMessage } from "../../utils/helpers";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { green } from "@material-ui/core/colors";
import { joinPath } from "../../utils/helpers";
import ErrorModal from '../../components/ErrorModal';
import { FormHelperText } from "@material-ui/core";
import { isAudio, isPack, isBook } from '../../utils/mediaUtils';

const useStyles = makeStyles((theme) => ({
    root: {

        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
    info: {
        padding: theme.spacing(2),
    },
    divider: {
        margin: "20px 0"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2)
    },
    stepper: {
        display: 'none',
        background: "transparent",
        paddingTop: 0,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    title: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    searchContainer: {
        display: "flex"
    },
    alertTitle: {
        paddingBottom: `${theme.spacing(2)}px`,
    },
    alert: {
        minHeight: `${theme.spacing(15)}px`,
        minWidth: `${theme.spacing(44)}px`,
        width: `${theme.spacing(44)}px`,
    },
    alertCloseButton: {
        position: 'absolute',
        top: `-${theme.spacing(1)}px`,
        right: `-${theme.spacing(0)}px`,
    },
    acceptBtn: {
        backgroundColor: green[500],
        color: "white",
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: green[700],
            color: "white",
        },
        '&:active': {
            backgroundColor: green[700],
            color: "white"
        }
    },
    qrCodeSpacing: {
        marginBottom: `${theme.spacing(2)}px`
    }
}));

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 35,
        height: 35,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: 'rgb(32,150,243)',
        color: 'white',
    },
    completed: {
        backgroundColor: 'rgb(30,99,168)',
        color: 'white',
    },
});

function ColorStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <EditIcon />,
        2: <PersonIcon />,
        3: <ListIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const ColorConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: 'rgb(32,150,243)',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: 'rgb(32,150,243)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

function getSteps() {
    return ['Content', 'Recipient', 'Preview', 'Finished'];
}
const titles = ['Sendout', 'Recipient', 'Preview', 'Sendout created'];



export default observer(function Members({ parentMatch }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const [language, setLanguage] = React.useState('en');
    const steps = getSteps();
    const { authStore, commonStore, sendOutStore, audioStore, videoStore } = useStores();
    const { isNativeApp } = commonStore;
    const { currentUser } = authStore;
    const { recipientForm, baselineLoad, rascalPodcast, salesAchiever, sendoutVideos, sendoutAudios, } = sendOutStore;

    const [loading, setLoading] = React.useState(false);
    const [showSendoutModal, setShowSendoutModal] = React.useState(false);
    const [qtySendOutToPurchase, setQtySendOutToPurchase] = React.useState(0);
    const [searchResults, setSearchResults] = React.useState(null);
    const [searchString, setSearchString] = React.useState("");
    const [searchError, setSearchError] = React.useState(null);
    const [searchMode, setSearchMode] = React.useState(false);
    const [showValidationError, setShowValidationError] = React.useState(false);
    const [selectedMedia, setSelectedMedia] = React.useState(false);
    const [sendOutLink, setSendOutLink] = React.useState(false);
    const [QRCodeShown, SetQRCodeShown] = React.useState(false);
    const [sendEmailDialogShown, setSendEmailDialogShown] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState(false);
    const [allowErrorModalToClose, setAllowErrorModalToClose] = React.useState(true);
    const [email, setEmail] = React.useState("");
    const [buttonTitle, setButtonTitle] = React.useState('Next');
    const [errorMessage, setErrorMessage] = React.useState("");
    const fetchIdRef = React.useRef(0);

    const handleMediaPlay = (media) => {
        if (!!isPack(media)) {
            showErrorModal("Audio preview for Packs is not available");
            return;
        }
        if (!!isBook(media)) {
            showErrorModal("Preview for eBooks is not available");
            return;
        }

        if (!!isAudio(media)) {
            audioStore.setMedia(media);
        } else {
            //set hasPurchased to true to hide "Buy Now" button in video modal 
            videoStore.setMedia(media, true);
        }
    };

    const hideErrorModal = () => {
        setErrorMessage("");
        setErrorModal(!errorModal);
    };

    const showErrorModal = React.useCallback((errorMessage, bAllowErrorModalToClose = true) => {
        setErrorMessage(errorMessage);
        setErrorModal(!errorModal);
        setAllowErrorModalToClose(bAllowErrorModalToClose);
    }, []);

    const fetchData = React.useCallback(debounce(async (searchString) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;
        setSearchError(null);
        if (!searchString || searchString.length < 1) {
            setLoading(false);
            setSearchResults(null);
            return;
        }
        setLoading(true);
        try {
            let result = await services.Sendout.search({ term: searchString, productLang: language });


            if (fetchId === fetchIdRef.current) {
                if (result && result) {
                    setSearchResults(result);
                }
                else
                    setSearchResults([]);

            }
        }
        catch (e) {
            if (fetchId === fetchIdRef.current) {
                setSearchError(e.message);
            }
        }
        finally {
            setLoading(false);
        }
    }, 200), [language]);


    const createSendout = React.useCallback(async () => {

        try {
            if (recipientForm && selectedMedia) {
                let result = await services.Sendout.create({ ...recipientForm.values(), mediaID: selectedMedia.mediaID });
                setSendOutLink(result.link);
                setActiveStep(3);
            }
            else
                setActiveStep(0);
        }
        catch (e) {
            console.error(e);
        }
        finally {
        }
    }, [recipientForm, selectedMedia]);

    useEffect(() => {
        if (baselineLoad.available === 0) {
            //if on mobile device then show error modal, otherwise, show purchase modal;
            if (!!isNativeApp)
                showErrorModal("Sorry, you have run out of your monthly Sendouts.  To purchase more, please go to the website Create Sendout page.", false);
            else
                setShowSendoutModal(true);
        }
    }, [isNativeApp, baselineLoad.available, showErrorModal]);

    const onDecline = (() => {
        setShowSendoutModal(!showSendoutModal);
        history.push(joinPath(parentMatch.url));
    });

    const getSendoutCost = ((value) => {
        return baselineLoad.costSendout * value;
    });

    const updateQtyToPurchase = (e) => {
        setShowValidationError(false);
        setQtySendOutToPurchase(e.target.value);
    };

    const validatePurchaseSendout = (errorMessage) => {
        setErrorMessage(errorMessage);
        setShowValidationError(true);
    };

    const purchaseSendouts = (async () => {
        if (!qtySendOutToPurchase)
            validatePurchaseSendout("Please select an Option.");
        else {
            try {
                setShowValidationError(false);
                let { cart_guid = '' } = await sendOutStore.purchaseSendouts(qtySendOutToPurchase);
                if (!!cart_guid) {
                    setShowSendoutModal(!showSendoutModal);
                    history.push(joinPath(parentMatch.url, `/marketingsystem/purchase_sendout/${cart_guid}`));
                } else {
                    validatePurchaseSendout("Error purchasing sendouts; please try again.");
                }
            } catch (e) {
                setSearchError(extractErrorMessage(e));
            }
        }
    });

    useEffect(() => {
        sendOutStore.createRecipientForm(() => {
            setActiveStep(2);
        });
    }, []);


    useEffect(() => {
        if (activeStep === 0) {
            setSelectedMedia(null);
        }
    }, [activeStep]);

    useEffect(() => {
        fetchData(searchString);
    }, [fetchData, searchString]);

    const resetToFirstStep = () => {
        setActiveStep(0);
        sendOutStore.loadSendout({ lang: language });
        recipientForm.reset();
    };

    useEffect(() => {
        sendOutStore.loadSendout({ lang: language });

        return (() => {
            sendOutStore.clearSendoutBaseline();
        });
    }, [language]);

    const handleNext = () => {
        //showErrorModal
        if (activeStep === 0 && !selectedMedia) {
            showErrorModal("Please select a media sendout");
            return;
        }
        if (activeStep === 1) {
            recipientForm.submit();
            return;
        }
        if (activeStep === 2) {
            return createSendout();

        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    useEffect(() => {
        if (activeStep === steps.length - 1)
            setButtonTitle('Finish');
        else if (activeStep === 2)
            setButtonTitle('Generate Sendout');
        else
            setButtonTitle('Next');
    }, [activeStep, steps.length]);


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const closeSearch = () => {
        setSearchMode(false);
        setSearchString("");
        setSearchResults(null);
    };

    const handleMediaSelect = (media) => {
        setSelectedMedia(media);
        setActiveStep(1);
    };


    const handleLanguageChange = (event, newValue) => {
        setLanguage(event.target.value);
    };

    const showQRCode = () => {
        SetQRCodeShown(!QRCodeShown);
    };

    const openSendEmailDialog = () => {
        setSendEmailDialogShown(true);
    };

    const sendEmail = async () => {
        try {
            await services.Sendout.email(email, sendOutLink);
            commonStore.success("Email sent");
            setSendEmailDialogShown(false);
        }
        catch (e) {
            setSearchError(extractErrorMessage(e));
        }
    };

    const title = titles[activeStep];

    return (<div className={classes.root}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorConnector />} className={classes.stepper}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel StepIconComponent={ColorStepIcon}>{label}</StepLabel>
                </Step>
            ))}

        </Stepper>
        <Typography variant={"subtitle1"} align={"center"} className={classes.title}>{title}</Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} >
                {(activeStep < steps.length) &&
                    <>
                        {(activeStep === 0) && <>
                            <Paper className={classes.info}>
                                {t("sendout:mainheading")}
                            </Paper>

                            <FormControl className={classes.margin} >
                                <InputLabel htmlFor="age-native-simple">Language</InputLabel>
                                <Select value={language} onChange={handleLanguageChange}>
                                    <MenuItem value={"en"}>English</MenuItem>
                                    <MenuItem value={"fr"}>French</MenuItem>
                                    <MenuItem value={"es"}>Spanish</MenuItem>
                                </Select>
                            </FormControl>
                            {(!loading && (!searchResults || searchResults.length === 0) && !searchMode) && <>

                                {(salesAchiever && salesAchiever.length > 0) &&
                                    <>
                                        <Typography variant="h6" className={classes.sectionHeader}>{t("sendout:salesachieveraudios")}
                                        </Typography>
                                        <ThumbMediaList onSelect={handleMediaSelect} items={salesAchiever}
                                            forceType={"audio"}
                                        />

                                    </>}
                                {(rascalPodcast && rascalPodcast.length > 0) &&
                                    <>
                                        <Typography variant="h6" className={classes.sectionHeader}>{t("sendout:rascalpodcast")}</Typography>
                                        <ThumbMediaList onSelect={handleMediaSelect} items={rascalPodcast}
                                            forceType={"audio"}
                                        />

                                    </>}
                                {(sendoutAudios && sendoutAudios.length > 0) &&
                                    <>
                                        <Typography variant="h6" className={classes.sectionHeader}>{t("sendout:audios")}</Typography>
                                        <ThumbMediaList onSelect={handleMediaSelect} items={sendoutAudios}
                                            forceType={"audio"}
                                        />

                                    </>}
                                {(sendoutVideos && sendoutVideos.length > 0) &&
                                    <>
                                        <Typography variant="h6" className={classes.sectionHeader}>{t("sendout:videos")}</Typography>
                                        <ThumbMediaList onSelect={handleMediaSelect} items={sendoutVideos}
                                            forceType={"video"}
                                        />

                                    </>}
                            </>}

                            <Typography variant={"subtitle1"}>Sendout</Typography>
                            {searchError && <Alert severity={"error"}>{searchError}</Alert>}
                            <div className={classes.searchContainer}>
                                <FormControl fullWidth className={classes.margin} variant="outlined">
                                    <OutlinedInput classes={{ notchedOutline: classes.notchedOutline, input: classes.colorWhite }}
                                        id="outlined-adornment-amount"
                                        placeholder={"Search..."}
                                        value={searchString}
                                        onFocus={e => { setSearchMode(true); }}
                                        autoComplete={"off"}
                                        onChange={e => { setSearchString(e.target.value); }}
                                        endAdornment={(
                                            searchString && <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={e => { setSearchString(""); }}
                                                ><CancelIcon /></IconButton>
                                            </InputAdornment>)}
                                        startAdornment={<InputAdornment className={classes.colorWhite} position="start"><SearchIcon /></InputAdornment>}

                                    />

                                </FormControl>
                                {searchMode && <Button onClick={closeSearch}>Cancel</Button>}
                            </div>
                            {searchResults &&
                                <>
                                    {(searchResults && searchResults.length > 0)
                                        ? <MediaList onSelect={handleMediaSelect} items={searchResults} hidePlay={true} />
                                        : <Alert variant={"filled"} severity={"warning"}>Nothing Found</Alert>
                                    }
                                </>}


                        </>}
                        {(activeStep === 1) && <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <MaterialTextField
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        field={recipientForm.$("recipientName")}
                                        className={classes.textField}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MaterialTextField
                                        fullWidth
                                        multiline
                                        inputProps={{ rows: 5 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        field={recipientForm.$("notes")}
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                        </>}
                        {(activeStep === 2) && <>
                            <Paper className={classes.info}>
                                <Typography variant={"body"}>Hello <strong>{recipientForm.$("recipientName").value}</strong>,</Typography>
                                <br />

                                <Typography variant={"body"}>{currentUser.firstName} {currentUser.lastName} {t("sendout:sharethefollowinginfo")}</Typography>
                                <br />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.large}
                                            alt={` `}
                                            src={selectedMedia.imageURL}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedMedia.title}
                                        secondary={<Button color={"secondary"} variant={"contained"} size={"small"} onClick={() => handleMediaPlay(selectedMedia)} >Play</Button>} />
                                </ListItem>
                                <pre style={{ color: "white" }}>
                                    {recipientForm.$("notes").value}
                                </pre>

                            </Paper>
                            <Divider className={classes.divider} />
                            <Paper className={classes.info}>
                                <Typography variant={"subtitle1"}>{t("sendout:sendoutsummary")}</Typography>
                                <Grid container>
                                    <Grid item xs={9} md={4}>{t("sendout:beginbalance")}</Grid>
                                    <Grid item xs={3} md={8}>{baselineLoad.available}</Grid>
                                    <Grid item xs={9} md={4}>{t("sendout:endbalance")}</Grid>
                                    <Grid item xs={3} md={8}>{Math.max(0, baselineLoad.available - 1)}</Grid>
                                </Grid>


                            </Paper>
                        </>}
                        {(activeStep === 3) && <>
                            <Typography variant={"body1"}>{t("sendout:successmessage")}</Typography>
                            <TextField disabled={true}
                                fullWidth
                                variant={"filled"}
                                label={"Share your following link:"}
                                value={sendOutLink} />

                            <br /> <br /> <br />
                            <Typography variant={"body1"}>{t("sendout:howtoshare")}</Typography>
                            <br />
                            <Grid container spacing={3} justify={"center"}>
                                {isNativeApp && <Grid item xs={10} md={7}>
                                    <Button component={"a"}
                                        href={`sms://${sendOutLink}`}
                                        fullWidth color={"secondary"} variant={"contained"}>{t("sendout:textmessage")}</Button>
                                </Grid>}
                                <Grid item xs={10} md={7} >
                                    <Button fullWidth
                                        color={"secondary"}
                                        variant={"contained"}
                                        onClick={openSendEmailDialog}>{t("sendout:email")}</Button>
                                </Grid>
                                <Grid item xs={10} md={7} >
                                    <CopyToClipboard text={sendOutLink}
                                        onCopy={() => commonStore.success("Link copied")}>
                                        <Button fullWidth color={"secondary"} variant={"contained"}>{t("sendout:copylink")}</Button>
                                    </CopyToClipboard>
                                </Grid>
                                <Grid item xs={10} md={7} container justify={"center"}>
                                    <Button fullWidth
                                        className={classes.qrCodeSpacing}
                                        color={"secondary"}
                                        variant={"contained"}
                                        onClick={showQRCode}>{t("sendout:qrcode")}</Button>
                                    {QRCodeShown
                                        ? <QRCode value={sendOutLink} />
                                        : null}
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid item xs={10} md={7} container justify={"center"}>
                                    <Button fullWidth color={"primary"} variant={"contained"} onClick={() => resetToFirstStep()}>
                                        Create Another Sendout
                                    </Button>
                                    <Typography variant={"body1"}>{t("sendout:dontforgetshare")}</Typography>
                                </Grid>
                            </Grid>

                        </>}
                    </>}

            </Grid>


        </Grid>


        <DialogActions >
            {activeStep === 3 ? (
                <>

                </>
            ) : (
                <>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                        Back
                                </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                    >
                        {buttonTitle}
                    </Button>
                </>
            )}
        </DialogActions>

        {sendEmailDialogShown && <Dialog maxWidth={"md"} open={sendEmailDialogShown} onClose={e => { setSendEmailDialogShown(false); }}>

            <DialogTitle>Enter Email</DialogTitle>
            <DialogContent>
                <TextField style={{ minWidth: 300 }} size={"medium"} type={"email"} value={email} onChange={e => { setEmail(e.target.value); }} required />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setSendEmailDialogShown(false); }}>Cancel</Button>
                <Button type={"submit"} disabled={!email}
                    variant={"contained"} color={"primary"} onClick={sendEmail}>Send</Button>
            </DialogActions>
        </Dialog>}
        { !!errorModal
            ? (<ErrorModal allowUserToClose={allowErrorModalToClose} errorTitle={!!isNativeApp ? 'Error' : 'Error'} handleClose={hideErrorModal} open={errorModal} error={errorMessage} />)
            : null
        }
        { !!showSendoutModal
            ? (<Dialog onClose={null} aria-labelledby="alert-dialog" open={showSendoutModal}>
                <Alert variant="outlined" severity="info" className={classes.alert}>
                    <AlertTitle className={classes.alertTitle}>Purchase Sendouts to Continue</AlertTitle>
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                Quantity of Sendouts to Purchase
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Select
                                    required
                                    fullWidth
                                    onChange={updateQtyToPurchase}
                                    label="qtySendout"
                                    className={classes.textField}
                                    placeholder="Please Select">
                                    <MenuItem value={5}>5 {`($${getSendoutCost(5)})`}</MenuItem>
                                    <MenuItem value={10}>10 {`($${getSendoutCost(10)})`}</MenuItem>
                                    <MenuItem value={15}>15 {`($${getSendoutCost(15)})`}</MenuItem>
                                    <MenuItem value={20}>20 {`($${getSendoutCost(20)})`}</MenuItem>
                                </Select>
                                {!!showValidationError
                                    ? <FormHelperText error>{errorMessage}</FormHelperText>
                                    : null
                                }
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button
                                onClick={onDecline}
                                color="secondary" size="small" variant="contained">
                                Decline
                        </Button>
                            <Button size="small"
                                onClick={purchaseSendouts}
                                variant="contained"
                                className={classes.acceptBtn}>
                                Purchase Sendouts
                            </Button>
                        </DialogActions>
                    </>
                </Alert>
            </Dialog>)
            : null
        }

    </div >);

});