import { makeAutoObservable, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";

class VideoStore {
    constructor(libraryStore) {
        makeAutoObservable(this);
        this.libraryStore = libraryStore;
    }

    element = null;
    media = null;
    vimeoAlbum = null;
    loadingAlbumData = false;

    setError(error, type = "videoPlayer") {
        error = extractErrorMessage(error);
        this.error = error;
    }


    async closeMedia() {
        this.media = null;
        this.vimeoAlbum = null;
    }

    async setVimeoAlbum(album) {
        if ( typeof album === "number"){
            try {
                this.loadingAlbumData = true;
                album = await services.Library.album(album)
                runInAction(() => {
                    this.vimeoAlbum = {...album, title: album.name
                        , videos: album.videos.map(v => ({...v, title: v.name, thumbnailURL: v.thumbnail, vimeoID: v.id}))}
                })
            }
            catch (e) {
                runInAction(() => {
                    this.setError(e)
                })
            }
            finally {
                runInAction(() => {
                    this.loadingAlbumData = false;
                })
            }
        }
        else
            this.vimeoAlbum = album;
    }


    async setMedia(media, hasPurchased = null) {
        if ( media  && media.vimeo ){
            this.media  = {mediaURL: media.url, mediaID: media.url, title: media.title }
            return
        }
        //if ( media.mediaType !== "video") return
        this.libraryStore.detailsLoadingFor.push(media.mediaID);
        try {
            let details = await services.Library.details(media.mediaID);
            if (details) {

                runInAction(() => {
                    this.media = details[0];
                    if (!!hasPurchased)
                        this.media.hasPurchased = hasPurchased;
                });
            }
        }
        finally {
            runInAction(() => {
                this.libraryStore.detailsLoadingFor.remove(media.mediaID);
            });
        }
        //console.log( details )
    }
    get mediaURL() {
        return this.media && this.media.mediaURL;
    }
    get mediaID() {
        return this.media && this.media.mediaID;
    }
    get canBuy() {
        return this.media && !this.media.hasPurchased;
    }
    get mediaTitle() {
        return (this.media && this.media.title );
    }
    get mediaImageURL() {
        return this.media && this.media.imageURL;
    }
    get mediaSpeakers() {
        return this.media && (this.media.speakers || []).map(sp => sp.speakerName).join(",");
    }


}

export default VideoStore;