import { makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import some from "lodash/some"
import pick from "lodash/pick"
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import createUserForm from "../forms/user";
import createSpouseForm from "../forms/spouse";

class StatStore {
    constructor(commonStore) {
        makeAutoObservable(this)
        this.commonStore =commonStore
    }

    userProfile = null
    userPid = null
    allCountries = []
    regionsByCountry = {}
    spouseEnabled = false
    deletedSpouse = false

    userProfileLoading = false
    allCountriesLoading = false
    userPidLoading = false
    regionsByCountryLoading = false
    savingProfile = false
    userEditForm = null
    spouseEditForm = null
    error = null

    setError( error, type = "userProfile") {
        error = extractErrorMessage(error)
        this.error = error
     }

    createEditForm ( onSaved ){
        this.spouseEditForm = createSpouseForm()
        this.userEditForm = createUserForm({
            onSuccess: async (form)=>{
                let dto = this.dto;
                let result
                if ( !this.spouseEnabled
                    && some( Object.values ( pick( this.userProfile.spouse, "firstName", "lastName", "email", "phone" )) )){
                    this.commonStore.showConfirm( "Are you sure you want to remove the spouse account? All third party services associated with this account will be removed."
                        , "Yes", "Delete spouse account?", async ()=>{
                            result = await this.update( dto, true  )
                        },  )
                }
                else{
                    result = await this.update( dto, false  )
                }

                if (onSaved)
                    onSaved(result)
            },
            onError:(form) =>{
                this.setError( form.errors() );
            }
        } )
    }

    setSpouseEnabled(value){
        this.spouseEnabled = value
    }

    get userCountry (){
        if (!this.userProfile) return null;
        return this.allCountries.find(x=> x.countryShortCode === this.userProfile.address.country )
    }

    get userRegion (){
        return  this.userAvailableRegions.find(x=> x.shortCode === this.userProfile.address.region )
    }

    setRegion(value){
        this.userProfile.address.region = value
    }

    get userAvailableRegions (){
        if (!this.userProfile) return [];
        return this.regionsByCountry[this.userProfile.address.country] || []
    }

    loadUserProfile = generateLoadEntity( "userProfile", this, "userProfileLoading"
        , async ()=>{
            let result =  await services.UserProfile.list()

            runInAction(() => {
                this.userEditForm.update({ ...result, ...result.address })
                this.spouseEditForm.update( result.spouse )
                this.spouseEnabled =   some( Object.values ( pick( result.spouse, "firstName", "lastName", "email", "phone" )) )
            })
            return result
        }, "userProfile" )

    loadUserPid = generateLoadEntity( "userPid", this, "userPidLoading"
        , ()=>{ return services.Members.pid() }, "userPid", "pid" )


    async update (values, deleteSpouse ){
        if ( this.savingProfile ) return
        this.savingProfile = true
        try {
            if ( deleteSpouse ) {
                await services.UserProfile.deleteSpouse()
                this.deletedSpouse = true;
            }
            const result = await services.UserProfile.update(values)

            return result
        }
        catch (e) {
            this.setError( extractErrorMessage(e),"profile" )
        }
        finally {
            this.savingProfile =false
        }

    }


    loadAllCountries = generateLoadList( "allCountries", this, "allCountriesLoading"
        , ()=>{ return services.Utility.getAllCountries() }, "allCountries", "countries" )

    loadRegionsByCountry = generateLoadList( "regionsByCountry", this, "regionsByCountryLoading"
        , async(country)=>{
                const [{regions}] =  await services.Utility.getRegionByCountry(country)
                runInAction(() => {
                    this.regionsByCountry[country] = regions
                })
        })


    get dto() {
        let values = this.userEditForm.values()
        let spouse = this.spouseEnabled?  this.spouseEditForm.values(): {firstName:"",lastName:"",email:"",phone:""}
        return {
            ...values,
            firstName: this.userProfile.firstName,
            lastName: this.userProfile.lastName,
            country: this.userProfile.address.country,
            region: this.userProfile.address.region,
            spouse
        }
    }

    updateZipRules() {
        if (!this.userEditForm) return;
        this.userEditForm.updateZipRules(this.userCountry?.countryShortCode);
    }
}

export default StatStore