import React, {useMemo} from "react";
import { observer} from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import OpenIcon from "@material-ui/icons/OpenInNew";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/use-stores";


const useStyles = makeStyles((theme) => ({
    whiteColor: {
        color: "white"
    },

    root:{
        border: "1px solid #ddd",
        borderRadius: 4,
        width:"100%"
    },
    block:{
        padding: 25,
        borderTop: "solid #ffffff73 1px",
        display:"flex",
        flexDirection:"column",
        rowGap:20
    }


}));



export default observer(function Help() {
    const classes = useStyles();
    const { t } = useTranslation("starfish");
    const { authStore } = useStores();
    const { currentUser } = authStore;
    const { address } = currentUser;
    const { country } = address;


    const termsPDF= useMemo(()=>{
        if ( country === "CA" ) {
            return "https://main.secure.footprint.net/forms/CA_LifeStarfishTerms_EN.pdf";
        }
        else {
            return "https://main.secure.footprint.net/forms/US_LifeStarfishTerms_EN.pdf";
        }
    },[country]);

    return <div className={classes.whiteColor}>
        <Typography variant="h5">{t("policies.title")}</Typography>
        <div className={classes.root}>
           <div className={classes.block}>
               <Typography variant="h6">{t("policies.privacyPolicy")}</Typography>
               <Typography variant="body1" hidden>{t("policies.privacyPolicyDescription")}</Typography>
               <div>
                   <Button href={"https://main.secure.footprint.net/forms/LifeStarfishPerksPrivacyPolicy_EN.pdf"}
                       startIcon={<OpenIcon />}
                       target={"_blank"}
                       variant={"contained"}
                       size={"small"}
                       color={"primary"}>
                   {t("policies.openPDF")}
                </Button>
               </div>
           </div>
            <div className={classes.block}>
                <Typography variant="h6">{t("policies.termsAndConditions")}</Typography>
                <Typography variant="body1"  hidden>{t("policies.termsAndConditionsDescription")}</Typography>
                <div>
                    <Button href={termsPDF}
                        startIcon={<OpenIcon />}
                        target={"_blank"}
                        variant={"contained"}
                        size={"small"}
                        color={"primary"}>
                    {t("policies.openPDF")}
                    </Button>
                </div>
            </div>

        </div>
    </div>;
});

