import { getUniqKey, joinPath, parseJwt } from "./helpers";
import { getCookie } from "./cookieUtil";
import SectionWithSmallIcons from "../components/Members/SectionWithSmallIcons";
import SectionWithMoreButton from "../components/Members/SectionWithMoreButton";
import SectionWithMediumIcons from "../components/Members/SectionWithMediumIcons";
import stores from '../stores';

export const getVerticalThumbnail = (item) => {

    switch (getUniqKey(item)) {
        case "shop":
            return "https://main.secure.footprint.net/public/icon/v2/banner_v_shop.jpg";
        case "save":
            return "https://main.secure.footprint.net/public/icon/v2/banner_v_save.jpg";
        case "succeed":
            return "https://main.secure.footprint.net/public/icon/v2/banner_v_succeed.jpg";
    }
};

export const isCurrentItemExternal = (currentItem) => {
    if (!currentItem) return false;
    if ( currentItem.shopping ) return  false;
    let subItmes = (currentItem.section || currentItem.subsection);
    if ((!subItmes || subItmes.length === 0) && currentItem.url) {
        return true;
    }

    return false;
};

const {REACT_APP_DONT_PATCH_DEV} = process.env

export const getLegacyUrlBase = () => {
    return window.location.hostname.includes("dev") && REACT_APP_DONT_PATCH_DEV!=="1"
        ? "legacydev.lifeinfoapp.com"
        : "legacy.lifeinfoapp.com";
};
export const fixDomain = (url) => {
    if (!url) return url;
    url = url.replace("appview.mainhomepage.com", window.location.hostname.includes("dev") && REACT_APP_DONT_PATCH_DEV!=="1"
        ? "legacydev.lifeinfoapp.com"
        : "legacy.lifeinfoapp.com");


    if (window.location.hostname.includes("dev") && REACT_APP_DONT_PATCH_DEV!=="1") {
        url = url.replace("portal.lifeinfoapp.com", "portaldev.lifeinfoapp.com");
        url = url.replace("legacy.lifeinfoapp.com", "legacydev.lifeinfoapp.com");
    }

    if (window.location.protocol === "https:" && url.startsWith("http:"))
        url = url.replace("http:", "https:");

    if (url.includes("appview.mainhomepage.com" || "legacy.lifeinfoapp.com")) {
        if (!url.includes("&SkinSrc=[G]Skins/LifeApp/App"))
            url += (url.includes("?")) ? "?SkinSrc=[G]Skins/LifeApp/App" : "&SkinSrc=[G]Skins/LifeApp/App";

        if (window.location.protocol === "https:" && !url.includes("ssl=1"))
            url += (url.includes("?")) ? "?ssl=1" : "&ssl=1";
    }

    return url;
};

export const isCustomHandler = (currentItem, currentUser) => {
    if (!currentItem || !currentItem.url) return null;
    const tag = getUniqKey(currentItem);
    if (tag === "customeronboardingbanner" && process.env.REACT_APP_CUSTOMER_ONBOARD)
        return true;
    if (tag === "shoppingboss1" || tag === 'shoppingboss3') {
        return !currentUser.hasSBAccess;
    }
};

export const getItemPath = (currentItem, currentUser) => {
    if (!currentItem || !currentItem.url) return null;
    const tag = getUniqKey(currentItem);

    if (tag === "lifetraining" || tag === "life_training") {
        return `/library/series/LT`;
    }
    if (tag === "shoppingboss1" || tag === 'shoppingboss3') {
        if (currentUser.hasSBAccess && !currentUser.isShoppingBossSetup)
            return `/onboarding/shopping_boss`;
    }
    if (currentItem.url && currentItem.url.startsWith("library://")) {
        return `/library/series/${currentItem.url.replace("library://", "")}`;;
    }
};
export const showItemNativeAppModal = (currentItem, forceNativeAppModal) => {
    if (forceNativeAppModal) return true;
    if (!currentItem || currentItem.tag !== "thingor2travel") return false;
    if (getCookie("nativeapp") !== "true") return true;
    return false;
};

export const getItemUrl = (currentItem) => {
    let access_token = parseJwt(stores.authStore.token);
    if (!currentItem || !currentItem.url) return null;
    const tag = getUniqKey(currentItem);

    if (tag === "thingor2travel" && getCookie("nativeapp") === "true") {
        let access_token = parseJwt(stores.authStore.token);
        return `popupbrowser://Travel|false|https//appview.mainhomepage.com/loginuser.aspx?pageid=1718&booknow=true&loginguid=${access_token.loginGuid}&language=&ssl=1&versionName=&versionCode=&appId=&SkinSrc=%5BG%5D/Skins/LifeApp/App`;
    } else if (tag === "orrinnewbook") {
        if (getCookie("nativeapp") === "true")
            return "playmedia://eBK1136";
    } else if (tag === "thingor2resorts") {
        if (getCookie("nativeapp") === "true") {
            return `popupbrowser://Resorts|false|https//appview.mainhomepage.com/loginuser.aspx?pageid=1748&loginguid=${access_token.loginGuid}&language=&ssl=1&versionName=&versionCode=&appId=&SkinSrc=%5BG%5D/Skins/LifeApp/App`;
        }
    } else if (tag === "rascalradio") {
        if (getCookie("nativeapp") === "true")
            return 'native://home/home';
        else
            return `https://my.rascal-radio.com/index.aspx?loginguid=${access_token.loginGuid}`;
    } else if (tag === "coupons" && getCookie("nativeapp") === "true") {
        //let access_token = parseJwt(stores.authStore.token);
        return `popupbrowser://Coupons|false|https://my.financialfitnessinfo.com/index.aspx?redirect=true&page=ffsSSO.aspx?action=saver&iframe=false&loginguid=${access_token.loginGuid}`;
    } else if (tag === "shareproduct") {
        //let access_token = parseJwt(stores.authStore.token);
        return `https://shop.lifeinfoapp.com/search?hidenav=true`;
    }
    else if (tag === "life_training") {
        return `/library/series/LT`;
    }
    else if (tag === "dailydozen") {
        if (getCookie("nativeapp") === "true")
            return `popupbrowser://DailyDozen|false|https://tpd.mainhomepage.com/index.aspx?redirect=true&page=tpdSSO.aspx?action=dailydozen&iframe=false&nativeapp=true&forspouse=false&loginguid=${access_token.loginGuid}`;
        else
            return `https://tpd.mainhomepage.com/index.aspx?redirect=true&page=tpdSSO.aspx?action=dailydozen&iframe=false&nativeapp=true&forspouse=false&loginguid=${access_token.loginGuid}`;
    }
    else if (tag === "calculator") {
        if (getCookie("nativeapp") === "true")
            return `popupbrowser://Calculator|false|https://my.financialfitnessinfo.com/index.aspx?redirect=true&page=ffsSSO.aspx?action=calculator&iframe=false&loginguid=${access_token.loginGuid}`;
        else
            return `https://my.financialfitnessinfo.com/index.aspx?redirect=true&page=ffsSSO.aspx?action=calculator&iframe=false&loginguid=${access_token.loginGuid}`;
    } else if (tag === "realhomerewards" && getCookie("nativeapp") === "true") {
        //let access_token = parseJwt(stores.authStore.token);
        return `popupbrowser://RealHomeRewards|false|${currentItem.url}`;
    } else if (tag === "lifeline" && getCookie("nativeapp") === "true") {
        return 'native://lifeline/lifeline';
    }
    //use local var to make more clean logic  in case if more replacements will require
    let url = currentItem.url;
    if (url.startsWith("systembrowser://") && (getCookie("nativeapp") !== "true")) {
        url = url.replace("systembrowser://", "");
    }
    if (url && url.startsWith("playmedia://"))
        return url;
    if (url && url.startsWith("library://"))
        return `/library/series/${url.replace("library://", "")}`;

    return fixDomain(url);
};

export const calculateItemPath = (currentLevel, item, prefix = "", level = 0) => {

    if (!currentLevel) return null;
    let subItmes = (currentLevel.section || currentLevel.subsection);
    if (subItmes) {
        let key = getUniqKey(item);

        let match = subItmes.find(x => getUniqKey(x) === key);
        if (match) {
            return joinPath(prefix, getUniqKey(match));
        }

        for (let i = 0; i < subItmes.length; i++) {
            let x = subItmes[i];
            if (x.tag === "myfavorites") continue;
            let subMatch = calculateItemPath(x, item, joinPath(prefix, getUniqKey(x)), level + 1);
            if (subMatch) {
                return subMatch;
            }
        }
        return null;
    }

};


export const getItemBanner = (item) => {

    if (!item) return null;
    if (item.banner) return item.banner;
    if (item.sheet || !(item.section || item.subsection)) return null;
    let bannerSection = (item.section || item.subsection).find(x => getUniqKey(x) === "promobanner");
    if (bannerSection) return bannerSection.banner;
};

const newWindowTags = [
    "lifeclothing"
    , "rascalradio"
    , "realhomerewards"
    , "thingor2resorts"
    , "coupons"
    , "dailydozen"
    , "calculator"
    , "businesscards"
    , "watertree"
    , "lifeonlife"
    , "autoship"
    , "autoshippro"
];

export const isNewWindow = (item) => {
    return newWindowTags.includes(getUniqKey(item));
};


export const patchShoppingItems = ( items )=>{
    if(!items) return;
    for ( let i=0; i<items.length; i++ ){
        patchShoppingItem(items[i])
        patchShoppingItems( items[i].section || items[i].subsection )
    }

}

export const patchShoppingItem = ( item )=>{
    if ( item && item.url && !item.shopping ) {

        try{
            const rawUrl = getItemUrl(item)
            const url = new URL(rawUrl)
            if (url.hostname && url.hostname === "shop.lifeinfoapp.com") {
                item.url = `/shopping${url.pathname}`
                item.shopping = true
                item.shoppingPath = url.pathname==="/search"?"/": url.pathname
            }
        }
        catch (e){

        }
    }
}

export const getSubItems = (item) => {
    if (!item) return [];
    if (!(item.section || item.subsection)) return [];
    return (item.section || item.subsection)
        .filter(x => x.tag !== "promobanner" && x.tag !== "myfavorites")
        .map(x => {
            return {
                ...x, isExternal: !(x.section || x.subsection) && x.url
                , inNewWindow: isNewWindow(x)
                    && getCookie("nativeapp") !== "true"
            };
        });
};

export const getMembersViewByTag = (item) => {
    switch (getUniqKey(item)) {
        case "explorelife":
            return SectionWithSmallIcons;
        case "quicklinks":
            return SectionWithMediumIcons;
        default:
            return SectionWithMoreButton;

    }
};

