import React from "react";
import {  Redirect } from "react-router-dom";
import {  observer } from "mobx-react-lite";
import {useStores} from "../hooks/use-stores";
import {useLocation} from "react-router";


export default (observer(({ component: C, ...rest }) => {
    const location = useLocation()
    let redirect = new URLSearchParams(location.search).get("redirect")
    const {authStore} = useStores()
    const {currentUser} = authStore;
    if ( currentUser ) {
        return <Redirect
            to={redirect === "" || redirect === null ? "/" : redirect}
        />
    }
    else
        return  <C {...rest} />


}))