import { makeAutoObservable, reaction, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import shippingAddressForm from "../forms/shippingAddress";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList, generateLoadList2,
    generateUpdateEntity
} from "../utils/mobx";
import { getCookie, deleteCookie, setCookie } from "../utils/cookieUtil";

class ShopStore {
    constructor(commonStore, authStore) {
        try {
            this.overrideGuestCountry = JSON.parse(getCookie("guest_country"));
        } catch (e) {

        }
        makeAutoObservable(this);
        this.authStore = authStore;
        this.commonStore = commonStore;

        reaction(
            () => this.authStore.currentUser,
            user => {
                if (user) {
                    this.permanentGuestCountry = null;
                    deleteCookie("guest_country");

                } else {
                    deleteCookie("cart_guid");
                    deleteCookie("autoship_cart_guid");
                    deleteCookie("subscription_cart_guid");
                }
            }
        );

        reaction(
            () => this.autoShipUserCartGuid,
            autoShipUserCartGuid => {
                if (autoShipUserCartGuid) {
                    setCookie("autoship_cart_guid", autoShipUserCartGuid);
                } else {
                    deleteCookie("autoship_cart_guid");
                }
            }
        );

        reaction(
            () => this.userCartGuid,
            userCartGuid => {
                if (userCartGuid) {
                    setCookie("cart_guid", userCartGuid);
                } else {
                    deleteCookie("cart_guid");
                }
            }
        );

        reaction(
            () => this.subscriptionUserCartGuid,
            subscriptionUserCartGuid => {
                if (subscriptionUserCartGuid) {
                    setCookie("subscription_cart_guid", subscriptionUserCartGuid);
                } else {
                    deleteCookie("subscription_cart_guid");
                }
            }
        );

        reaction(
            () => this.autoshipShippingInfo,
            autoshipShippingInfo => {
                if (autoshipShippingInfo && this.addressForm) {
                    this.addressForm.update({ ...autoshipShippingInfo, region: autoshipShippingInfo.state });
                }
            }
        );


    }

    baselineLoad = null;
    productDetail = null;
    userCartGuid = getCookie('cart_guid') || null;
    autoShipUserCartGuid = getCookie('autoship_cart_guid') || null;
    subscriptionUserCartGuid = getCookie('subscription_cart_guid') || null;
    promotionalProducts = [];
    featuredProducts = [];
    userCart = [];
    currentAutoShipItems = [];
    currentAutoShipInfo = null;
    cartTotals = {};
    autoshipUserCart = [];
    autoshipCartTotals = {};
    autoshipUserInfo = {};
    loadCurrentAutoShipError = null;
    subscriptionUserCart = [];
    subscriptionCartTotals = {};
    productsByChildCategory = [];
    productsByCategory = [];
    speakerAudio = [];
    baselineLoading = false;
    promotionalProductsLoading = false;
    featuredProductsLoading = false;
    productsByChildCategoryIdLoading = false;
    productsByCategoryIdLoading = false;
    customerEnrollWithProductLinkLoading = false;
    productDetailLoading = false;
    deletingCartItems = [];
    deletingAutoShipCartItems = [];
    deletingSubscriptionCartItems = [];
    updatingCartItems = [];
    updatingAutoShipCartItems = [];
    updatingSubscriptionCartItems = [];
    updatingAutoShip = false;
    updatinPaymentID = false;
    purchasingAutoshopPro = false;
    speakerAudioLoading = [];
    sendingProductEmail = false;
    userCartLoading = false;
    userCartItemsLoading = false;
    autoShipUserCartLoading = false;
    cartTotalsLoading = false;
    autoShipCartTotalsLoading = false;
    subscriptionCartTotalsLoading = false;
    currentAutoShipItemsLoading = false;
    currentAutoShipInfoLoading = false;
    addingItemToCart = false;
    addingItemToAutoShipCart = false;
    addingItemToSubscriptionCart = false;
    error = null;
    productDetailError = null;
    sendProductEmailError = null;
    getUserCartError = null;
    overrideGuestCountry = null;
    permanentGuestCountry = null;
    showAutoshipBanner = false;
    showAutoshipModal = false;
    newRunDay = null;
    addressForm = null;
    addressFormSubmitting = false;
    suggestions = null;
    shippingError = null;

    subscriptionInfo=[];
    subscriptionInfoLoading=null;
    subscriptionCartLoading=false;


    setError(error, type = "") {
        error = extractErrorMessage(error);
        if (type === "shipping") {
            this.shippingError = error;
        }
        else if (type === "getProductDetail")
            this.productDetailError = error;

        else if (type === "getUserCart")
            this.getUserCartError = error;
        else if (type === "sendProductEmail")
            this.sendProductEmailError = error;
        else if (type === "loadCurrentAutoShip")
            this.loadCurrentAutoShipError = error;
        else
            this.error = error;
    }

    submitAddressForm() {
        this.addressFormSubmitting = true;
        this.addressForm.submit();
    }

    async validateAddressForm() {
        let values = this.addressForm.values();
        if (values.country === "US") {
            try {
                await services.Utility.verifyAddess({
                    street1: values.address1,
                    street2: values.address2,
                    city: values.city,
                    state: values.region,
                    zip: values.zip
                });
            } catch (e) {
                if (e.response.status === 404)
                    this.setError("USPS could not validate your address. Please enter a valid address. ", "shipping");
                else if (e.response.status === 409) {
                    this.suggestions = e.response.data;
                }
                return this.effectiveSuggestions;
            }
        }
    }

    setEditForm(onSaved) {
        this.suggestions = null;
        let initials;
        if (this.autoshipShippingInfo)
            initials = { ...this.autoshipShippingInfo, country: this.autoshipShippingInfo.country, region: this.autoshipShippingInfo.state };
        this.addressForm = new shippingAddressForm({
            onSuccess: async (form) => {
                let result;
                let values = form.values();
                try {
                    result = await services.AutoShip.updateShipping({
                        shippingAddressName: values.addressName,
                        shippingAddress1: values.address1,
                        shippingAddress2: values.address2,
                        shippingCity: values.city,
                        shippingState: values.region,
                        shippingZip: values.zip,
                        shippingCountry: values.country,
                        shippingCompany: this.autoshipShippingInfo.company,
                        shippingPhone: this.autoshipShippingInfo.phone

                    });
                    await this.loadCurrentAutoShipInfo();
                    if (onSaved)
                        onSaved(result);
                }
                catch (e) {
                    this.setError(extractErrorMessage(e), "shipping");
                }
                finally {
                    this.addressFormSubmitting = false;
                }


            },
            onError: (form) => {
                this.addressFormSubmitting = false;
                this.setError(form.errors(), "shipping");
            }
        }, initials);

    }


    get effectiveSuggestions(){
        if ( !this.suggestions || !this.addressForm  ) return [];
        let result = [];
        if    (this.suggestions.street1 && this.suggestions.street1 !== this.addressForm.$("address1").value){
            result.push({ field: this.addressForm.$("address1"), suggestedValue: this.suggestions.street1 });
        }
        if    (this.suggestions.city && this.suggestions.city !== this.addressForm.$("city").value){
            result.push({ field: this.addressForm.$("city"), suggestedValue: this.suggestions.city });
        }
        if    (this.suggestions.state && this.suggestions.state !== this.addressForm.$("region").value){
            result.push({ field: this.addressForm.$("region"), suggestedValue: this.suggestions.state });
        }
        if    (this.suggestions.Zip5 && this.suggestions.Zip5 !== this.addressForm.$("zip").value){
            result.push({ field: this.addressForm.$("zip"), suggestedValue: this.suggestions.Zip5 });
        }

        return result;
    }



    get publicCategories() {
        if (!this.baselineLoad) return [];
        const publicOnlyItems = this.baselineLoad[1].parentCategoryIds.filter((categoryItem) => {
            return !categoryItem.memberOnly;
        });
        publicOnlyItems.push({
            parentCategoryID: null,
            parentCategoryName: "merchandise",
            memberOnly: false,
            parentCategoryImage: null,
            childCategories: []
        });

        return publicOnlyItems;

    }

    get parentCategoryIds() {
        if (!this.baselineLoad) return [];
        return this.baselineLoad[1].parentCategoryIds;
    }

    get marketIds() {
        if (!this.baselineLoad) return [];

        return this.baselineLoad[0].marketIds;
    }

    get userMarketId() {

        if (!this.authStore.currentUser || !this.authStore.currentUser.shoppingUserDetails) return null;

        return this.marketIds.find(x => x.countryValue === this.authStore.currentUser.shoppingUserDetails.Country);
    }

    get currency() {
        if (!this.userMarketId) return null;
        return this.userMarketId.currency;
    }

    get memberCategories() {
        if (!this.baselineLoad) return [];
        const memberOnlyItems = this.baselineLoad[1].parentCategoryIds.filter((categoryItem) => {
            return categoryItem.memberOnly;
        });
        // if (this.authStore.isAuthenticated && this.authStore.currentUser.isMember) {
        //     memberOnlyItems.push({ parentCategoryName: "autoshippro", tag: "autoshippro" });
        // }

        return memberOnlyItems;
    }

    get childItems() {
        if (this.productsByChildCategory.length > 0) return this.productsByChildCategory[0];
        return [];
    }

    get numberOfPages() {
        if (this.productsByChildCategory.length !== 2) return 0;

        return this.productsByChildCategory[1][0].numberOfPages || 0;
    }

    get cartItems() {
        if (!this.userCart || this.userCart.length === 0) return 0;
        if (this.userCart.length > 1) {
            return this.userCart.reduce((sum, number) => {
                return sum + parseInt(number.quantity, 10);
            }, 0);
        } else return parseInt(this.userCart[0].quantity);

    }

    get autoshipCartItems() {
        if (!this.autoshipUserCart || this.autoshipUserCart.length === 0) return 0;
        if (this.autoshipUserCart.length > 1) {
            return this.autoshipUserCart.reduce((sum, number) => {
                return sum + parseInt(number.quantity, 10);
            }, 0);
        } else return parseInt(this.autoshipUserCart[0].quantity);

    }

    get subscriptionCartItems() {
        if (!this.subscriptionUserCart || this.subscriptionUserCart.length === 0) return 0;
        if (this.subscriptionUserCart.length > 1) {
            return this.subscriptionUserCart.reduce((sum, number) => {
                return sum + parseInt(number.quantity, 10);
            }, 0);
        } else return parseInt(this.subscriptionUserCart[0].quantity);

    }

    get subTotal() {
        return this.cartTotals && this.cartTotals.subTotal;

    }


    get guestCountry() {
        if (this.authStore.isGuest)
            return this.marketIds.find(x => x.countryValue === ((this.overrideGuestCountry && this.overrideGuestCountry.countryValue) || this.authStore.country));
    }

    get guestCountryIndex() {
        if (this.authStore.isGuest)
            return this.marketIds.findIndex(x => x.countryValue === ((this.overrideGuestCountry && this.overrideGuestCountry.countryValue) || this.authStore.country));
    }


    get mySubscriptions(){
        return this.subscriptionInfo.filter(x=>x.alreadySubscribed)
    }

    get availableSubscriptions(){
        return this.subscriptionInfo.filter(x=>!x.alreadySubscribed)
    }

    setGuestCountry(value) {
        if (this.authStore.isGuest) {
            this.overrideGuestCountry = value;
            setCookie("guest_country", JSON.stringify(value));
        }
    }

    toggleAutoshipBanner() {
        this.showAutoshipBanner = true;
    }

    toggleAutoshipModal() {
        this.showAutoshipModal = !this.showAutoshipModal;
    }


    async purchaseAutoshopPro() {
        if (this.purchasingAutoshopPro) return;
        this.purchasingAutoshopPro = true;
        try {
            const { cartID: cart_guid = '' } = await services.ShoppingCart.addItemToCart2(18341, 1, 'en');
            return cart_guid;
        } catch (e) {
            this.setError(extractErrorMessage(e), "sendout");
        } finally {
            this.purchasingAutoshopPro = false;
        }
    }

    async updatePaymentMethod(paymentID) {
        if (this.updatinPaymentID) return;
        this.updatinPaymentID = true;
        try {
            await services.AutoShip.updatePaymentID(paymentID, this.autoshipPaymentInfo.last4);
            return true;
        } catch (e) {
            this.setError(extractErrorMessage(e), "autoship");
        } finally {
            this.updatinPaymentID = false;
        }
    }

    async udateAutoShip() {
        if (this.updatingAutoShip) return;
        this.updatingAutoShip = true;
        try {
            await services.AutoShip.updateAutoship(this.autoShipUserCartGuid, this.runDay);
            await this.loadCurrentAutoShipItems()
            return true;
        } catch (e) {
            this.setError(extractErrorMessage(e), "autoship");
        } finally {
            await this.loadCurrentAutoShipInfo();
            this.updatingAutoShip = false;
        }
    }

    async cancelAutoShip() {
        if (this.updatingAutoShip) return;
        this.updatingAutoShip = true;
        try {
            await services.AutoShip.updateAutoship(this.autoShipUserCartGuid, -1);
            return true;
        } catch (e) {
            this.setError(extractErrorMessage(e), "autoship");
        } finally {
            await this.loadCurrentAutoShipInfo();
            this.updatingAutoShip = false;
        }
    }

    async reactivateAutoship() {
        if (this.updatingAutoShip) return;
        this.updatingAutoShip = true;
        try {
            await services.AutoShip.updateAutoship(this.autoShipUserCartGuid, 0);
            return true;
        } catch (e) {
            this.setError(extractErrorMessage(e), "autoship");
        } finally {
            await this.loadCurrentAutoShipInfo();
            this.updatingAutoShip = false;
        }
    }


    get market() {
        // if ( !this.authStore.isGuest && this.authStore.isAuthenticated && this.authStore.currentUser.shoppingUserDetails ){
        //     return this.authStore.currentUser.shoppingUserDetails.MarketID
        // }

        if (!this.guestCountry) return null;
        return this.guestCountry.marketID;
    }

    get userCustomerLevelID() {
        if (!this.authStore.isGuest && this.authStore.isAuthenticated && this.authStore.currentUser.shoppingUserDetails) {
            return this.authStore.currentUser.shoppingUserDetails.CustomerLevelID;
        }
    }

    get nextProcessingDate() {
        return this.currentAutoShipInfo && this.currentAutoShipInfo.billing
            && this.currentAutoShipInfo.billing.nextProcessingDate
            && new Date(this.currentAutoShipInfo.billing.nextProcessingDate);
    }

    get autoshipOrderStatus() {
        return this.currentAutoShipInfo && this.currentAutoShipInfo.order;
    }

    get autoshipIsCancelled() {
        if (!this.currentAutoShipInfo) return false;
        if (!this.currentAutoShipInfo.order) return false;
        return this.currentAutoShipInfo.order.orderStatus === "cancelled";
    }
    get autoshipIsNew() {
        if (!this.currentAutoShipInfo) return true;

        return false;
    }

    get autoshipIsExisting() {
        if (!this.currentAutoShipInfo) return false;
        if (!this.currentAutoShipInfo.order) return false;
        return this.currentAutoShipInfo.order.orderStatus !== "cancelled";
    }

    get autoshipPaymentInfo() {
        return this.currentAutoShipInfo && this.currentAutoShipInfo.paymentInfo;
    }

    get autoshipShippingInfo() {
        return this.currentAutoShipInfo && this.currentAutoShipInfo.shipping;
    }

    get autoshipEffectiveCart(){
        if ( this.autoshipUserCart.length>0)
            return this.autoshipUserCart

        return this.currentAutoShipItems

    }

    get runDay() {
        if (this.newRunDay) return this.newRunDay;
        if (this.nextProcessingDate)
            return this.nextProcessingDate.getDate();
    }

    setRunDay(value) {
        this.newRunDay = value;
    }

    setSpeakerAudio() {
        this.speakerAudio.replace([]);
    }

    get customerLevelID() {


        if (!this.guestCountry) return null;
        return this.guestCountry.customerLevelID;
    }

    loadBaseline = generateLoadEntity("baseline", this, "baselineLoading"
        , () => {
            return services.ShoppingCart.setup(this.authStore.guestCountry);
        }, "baselineLoad");


    loadPromotionalProducts = generateLoadList("promotionalProducts", this, "promotionalProductsLoading"
        , (options) => {
            return services.ShoppingCart.promotionalProducts(options);
        }, "promotionalProducts");


    loadFeaturedProducts = generateLoadList("featuredProducts", this, "featuredProductsLoading"
        , (options) => {
            return services.ShoppingCart.featuredProducts(options);
        }, "featuredProducts");

    getUserCart = generateLoadList("getUserCart", this, "userCartItemsLoading"
        , async (options) => {
            let items = await services.ShoppingCart.getUserCart(options);
            return Array.isArray(items) ? items : [];
        }, "userCart");


    getCartTotals = generateLoadEntity("getCartTotals", this, "cartTotalsLoading"
        , (options) => {
            return services.ShoppingCart.getCartTotals(options);
        }, "cartTotals", "[0].cartTotal");

    refreshCartTotals = generateLoadEntity("getCartTotals", this, "cartTotalsRefreshing"
        , (options) => {
            return services.ShoppingCart.getCartTotals(options);
        }, "cartTotals", "[0].cartTotal");

    getAutoShipCartTotals = generateLoadEntity("getAutoShipCartTotals", this, "autoShipCartTotalsLoading"
        , (autoShipUserCartGuid) => {
            return services.AutoShip.getCartTotals(autoShipUserCartGuid);
        }, "autoshipCartTotals", "[0].cartTotal");

    refreshAutoShipCartTotals = generateLoadEntity("getAutoShipCartTotals", this, "autoShipCartTotalsRefreshing"
        , (autoShipUserCartGuid) => {
            return services.AutoShip.getCartTotals(autoShipUserCartGuid);
        }, "autoshipCartTotals", "[0].cartTotal");

    getSubscriptionCartTotals = generateLoadEntity("getSubscriptionCartTotals", this, "subscriptionCartTotalsLoading"
        , (cartGuid) => {
            return services.Subscription.getCartTotals(cartGuid);
        }, "subscriptionCartTotals", "[0].cartTotal");

    refreshSubscriptionCartTotals = generateLoadEntity("getSubscriptionCartTotals", this, "subscriptionCartTotalsRefreshing"
        , (cartGuid) => {
            return services.Subscription.getCartTotals(cartGuid);
        }, "subscriptionCartTotals", "[0].cartTotal");


    createCartGuid = generateLoadEntity("createCartGuid", this, "userCartLoading"
        , () => {
            if (this.authStore.guestCartId)
                return { cartID: this.authStore.guestCartId };
           // if ( this.authStore.userCartGuid)
           //     return { cartID: this.authStore.userCartGuid };

            return services.ShoppingCart.createUserCart();

        }, "userCartGuid", "cartID");


    createAutoShipCartGuid = generateLoadEntity("createCartGuid", this, "userCartLoading"
        , () => {
            return services.AutoShip.createAutoShipUserCart();

        }, "autoShipUserCartGuid", "cartID");




    loadCurrentAutoShipItems =   generateLoadList("loadCurrentAutoShip", this, "currentAutoShipItemsLoading"
        , () => {
            return services.AutoShip.loadCurrentAutoShip();

        }, "currentAutoShipItems" , undefined, {retries:3, counter:0});


    get isAutoshipChanged() {
        let currentItems = this.currentAutoShipItems.map(x => `${x.productSKU}:${x.quantity}`).sort();
        let cartItems = this.autoshipUserCart.map(x => `${x.productSKU}:${x.quantity}`).sort();
        return currentItems.join(";") !== cartItems.join(";");

    }

    loadCurrentAutoShipInfo = generateLoadEntity("loadCurrentAutoShipInfo", this, "currentAutoShipInfoLoading"
        , () => {
            return services.AutoShip.loadCurrentAutoShipInfo();

        }, "currentAutoShipInfo", "[0]", null, {retries:3, counter:0} );


    loadAutoShipCartItems = generateLoadList("autoShipCartItems", this, "autoShipCartItemsLoading"
        , async () => {
            if (!this.autoShipUserCartGuid)
                await this.createAutoShipCartGuid();
            let result;
            try {
                result = await services.AutoShip.getUserCart(this.autoShipUserCartGuid);
            } catch (e) {
                await this.createAutoShipCartGuid();
                result = await services.AutoShip.getUserCart(this.autoShipUserCartGuid);
            }
            return Array.isArray(result.cartItems) ? result.cartItems : [];

        }, "autoshipUserCart");


    createSubscriptionCartGuid = generateLoadEntity("createCartGuid", this, "userCartLoading"
        , () => {
            return services.Subscription.createUserCart();

        }, "subscriptionUserCartGuid", "cartID");

    loadSubscriptionInfo = generateLoadEntity("loadSubscriptionInfo", this, "subscriptionInfoLoading"
        , () => {
            return services.Subscription.loadSubscriptionInfo();

        }, "subscriptionInfo");



    loadSubscriptionsCartItems = generateLoadList("subscriptionCartItems", this, "subscriptionCartLoading"
        , async () => {
            if (!this.subscriptionUserCartGuid)
                await this.createSubscriptionCartGuid();
            let result;
            try {
                result = await services.Subscription.getUserCart(this.subscriptionUserCartGuid);
            } catch (e) {
                console.error("regenerate shop guid");
                console.error(e);
                await this.createSubscriptionCartGuid();
                result = await services.Subscription.getUserCart(this.subscriptionUserCartGuid);
            }
            return Array.isArray(result.cartItems) ? result.cartItems : [];

        }, "subscriptionUserCart");


    getProductsByChildCategoryId = generateLoadList("getProductsByChildCategoryId", this, "productsByChildCategoryIdLoading"
        , (options) => {
            return services.ShoppingCart.getProductsByChildCategoryId(options);
        }, "productsByChildCategory");

    getProductsByCategoryId = generateLoadList("getProductsByCategoryId", this, "productsByCategoryIdLoading"
        , (options) => {
            return services.ShoppingCart.getProductsByCategoryId(options);
        }, "productsByCategory");

    getAutoshipProducts = generateLoadList("getAutoshipProducts", this, "productsByCategoryIdLoading"
        , (options) => {
            return services.AutoShip.getAutoshipProducts(options);
        }, "productsByCategory");

    loadProductDetail = generateLoadEntity("getProductDetail", this, "productDetailLoading"
        , (options) => {
            try {
                return services.ShoppingCart.getProductDetail(options.subCategoryId || options.categoryId, options.productSKU, options.customerLevelID, options.marketID, options.language, options.shopType === "autoship");
            } catch (e) {
                return services.ShoppingCart.getProductDetail(options.categoryId, options.productSKU, options.customerLevelID, options.marketID, options.language, options.shopType === "autoship");
            }
        }, "productDetail", "productDetails[0]");

    addItemToCart = generateCreteEntity("addItemToCart", this, "addingItemToCart"
        , (options) => {
            return services.ShoppingCart.addItemToCart(options);
        });

    addItemToAutoShipCart = generateCreteEntity("addItemToAutoShipCart", this, "addingItemToAutoShipCart"
        , async (options) => {
            let response = await services.AutoShip.addItemToCart(options);
            runInAction(x => {
                this.autoShipUserCartGuid = response.cartID;
            });
            return response;
        });

    addItemToSubscriptionCart = generateCreteEntity("addItemToSubscriptionCart", this, "addingItemToSubscriptionCart"
        , async (options) => {
            let response = await services.Subscription.addItemToCart(options);
            runInAction(x => {
                this.subscriptionUserCartGuid = response.cartID;
            });
            return response;
        });

    deleteCartItem = generateDeleteEntity("deleteCartItem", this, "deletingCartItems"
        , (options) => {
            return services.ShoppingCart.deleteCartItem(options);
        });

    deleteAutoShipCartItem = generateDeleteEntity("deleteAutoShipCartItem", this, "deletingAutoShipCartItems"
        , (options) => {
            return services.AutoShip.deleteCartItem(options);
        });

    deleteSubscriptionCartItem = generateDeleteEntity("deleteSubscriptionCartItem", this, "deletingSubscriptionCartItems"
        , (options) => {
            return services.Subscription.deleteCartItem(options);
        });

    updateCartItem = generateUpdateEntity("updateCartItem", this, "updatingCartItems"
        , async (key, options) => {
            const result = await services.ShoppingCart.updateCartItem(options);
            let existing = this.userCart.find(x => x.productID === key)
            if (existing) {
                existing.quantity = options.qty
            }
            await this.refreshCartTotals(this.userCartGuid);
            return result
        });

    updateItemQty(item, quantity){
        item.quantity = quantity
    }

    updateAutoShipCartItem = generateUpdateEntity("updateAutoShipCartItem", this, "updatingAutoShipCartItems"
        , async (key, options) => {
            const result = await services.AutoShip.updateCartItem(options);
             let existing = this.autoshipUserCart.find(x => x.productID === key)
            if (existing) {
                existing.quantity = options.qty
            }
            await this.refreshAutoShipCartTotals(this.autoShipUserCartGuid);
            return result
        });

    updateSubscriptionCartItem = generateUpdateEntity("updateSubscriptionCartItem", this, "updatingSubscriptionCartItems"
        , async (key, options) => {
            const result = await services.Subscription.updateCartItem(options);
            let existing = this.subscriptionUserCart.find(x => x.productID === key)
            if (existing) {
                existing.quantity = options.qty
            }
            await this.refreshSubscriptionCartTotals(this.subscriptionUserCartGuid);
            return result
        });


    getCustomerEnrollWithProductLink = generateCreteEntity("getCustomerEnrollWithProductLink"
        , this, "customerEnrollWithProductLinkLoading"
        , (options) => {
            return services.ShoppingCart.getCustomerEnrollWithProductLink(options);
        });


    sendProductEmail = generateCreteEntity("sendProductEmail"
        , this, "sendingProductEmail"
        , (options) => {
            return services.ShoppingCart.sendProductEmail(options);
        });


    getSpeakerAudio = generateLoadList2("getSpeakerAudio"
        , this, "speakerAudioLoading"
        , (options) => {
            return services.ShoppingCart.getSpeakerAudio(options);
        }, "speakerAudio");

}

export default ShopStore;