import { makeAutoObservable, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateCreteEntity, generateDeleteEntity, generateLoadEntity } from "../utils/mobx";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import createEnrollMember from "../forms/enrollMember";
import createBillingInfoForm from "../forms/enrollBillingInfo";
import createBillingAddressForm from "../forms/billingAddress";
import { common } from "@material-ui/core/colors";

const permanentItems = ["enroll01", "eLifeAppSuperPlusD"];
function canDeleteAdapter(cart) {
    if (!cart) return;
    let items = Array.isArray(cart) ? cart : cart.cartItems;
    if (items)
        items.forEach(x => {
            x.canDelete = !permanentItems.includes(x.productSKU);
        });

    return cart;

}

function combineItemsWithRecomendations(cart, additionalItems, occurrence) {
    let items = cart ? cart.cartItems.filter(x => x.productSKU !== "eRenew01" && x.productSKU !== "eRenew02" && x.occurrence === occurrence) : [];
    let recommended = additionalItems ? additionalItems.filter(x => x.occurrence === occurrence && x.recommended) : [];

    recommended.forEach(x => {
        let existing = items.find(i => x.productSKU === i.productSKU);
        if (!existing) {
            x.removed = x.productSKU !== "enroll01";
            x.canDelete = x.productSKU !== "enroll01";
            x.totalTCs = x.productTC;
            items.unshift(x);
        }
    });
    return items;
}

class MemberEnrollmentStore {
    constructor(userBillingInfoStore, authStore, commonStore) {
        makeAutoObservable(this);
        this.userBillingInfoStore = userBillingInfoStore;
        this.authStore = authStore;
        this.commonStore = commonStore;
    }

    error = null;
    cartError = null;
    additionalItems = null;
    loadingReferralInfo = false;
    loadingAdditionalItems = false;
    loadingCartInfo = false;
    loadingCartTotals = false;
    loadingEnrollmentWaiverQual = false;
    addingItemToCart = false;
    creating = false;
    cart = null;
    totals = null;
    enrollmentWaiverQual = null;
    expired = false;
    activeStep = "1";
    paymentID = 0;
    signature = [];

    billingInfoForm = null;
    billingInfoFormSubmitting = false;

    personalForm = null;
    personalFormSubmitting = false;

    billingInfoFormEdit = null;
    billingInfoFormEditSubmitting = false;

    billingAddressForm = null;
    billingAddressFormSubmitting = false;

    billingAddressFormEdit = null;
    billingAddressFormEditSubmitting = false;

    async initPersonalInfoForm(country) {
        this.personalForm = createEnrollMember(country
            , {
                onSuccess: () => { runInAction(() => { this.personalFormSubmitting = false; this.handleNextStep(); }); },
                onError: () => { runInAction(() => { this.personalFormSubmitting = false; }); }
            });
    }

    initBillingInfoForm() {

        let country = this.authStore.currentUser.address && this.authStore.currentUser.address.country;
        this.billingInfoForm = createBillingInfoForm({
            paymentID: 0,
            billCountry: country,
            billFirstName: this.authStore.currentUser.firstName,
            billLastName: this.authStore.currentUser.lastName
        }, {
            onSuccess: () => { runInAction(() => { this.billingInfoFormSubmitting = false; this.handleNextStep(); }); },
            onError: () => { runInAction(() => { this.billingInfoFormSubmitting = false; }); }
        });
    }

    async initBillingInfoFormEdit(onSuccess) {
        this.billingInfoFormEdit = createBillingInfoForm({}
            , {
                onSuccess: () => {
                    runInAction(() => {
                        this.billingInfoFormEditSubmitting = false;
                        onSuccess();
                    });
                },
                onError: () => { runInAction(() => { this.billingInfoFormEditSubmitting = false; }); }
                ,
            });
    }

    initBillingAddressForm(country) {
        this.billingAddressForm = createBillingAddressForm({ billCountry: country }
            , {
                onSuccess: () => { this.billingAddressFormSubmitting = false; this.handleNextStep(); },
                onError: () => { this.billingAddressFormSubmitting = false; }
            });
    }
    initBillingAddressFormEdit(onSuccess) {
        this.billingAddressFormEdit = createBillingAddressForm({ ...this.billingAddressForm.values() }
            , {
                onSuccess: () => {
                    this.billingAddressFormEditSubmitting = false;
                    if (this.billingAddressFormEdit)
                        this.billingAddressForm.update(this.billingAddressFormEdit.values());
                    onSuccess();
                },
                onError: () => { this.billingAddressFormEditSubmitting = false; }
                ,
            });
    }


    submitPersonalForm() {
        this.personalFormSubmitting = true;
        this.personalForm.submit();
    }

    submitBillingInfoForm() {
        if (this.paymentID > 0) {
            this.handleNextStep();
            this.billingInfoForm.clear();
            this.billingInfoForm.reset();
        }
        else {

            this.billingInfoFormSubmitting = true;
            this.billingInfoForm.submit();
        }
    }
    submitBillingInfoEditForm() {
        this.billingInfoFormEditSubmitting = true;
        this.billingInfoFormEdit.submit();
    }
    submitBillingAddressForm() {
        this.billingAddressFormSubmitting = true;
        this.billingAddressForm.submit();
    }
    submitBillingAddressEditForm() {
        this.billingAddressFormEditSubmitting = true;
        this.billingAddressFormEdit.submit();
    }

    closeBillingInfoFormEdit() {
        this.billingInfoFormEdit = null;
    }

    closeBillingAddressFormEdit() {
        this.billingAddressFormEdit = null;
    }


    get pages() {

        return {
            "1": "memberagreement",
            "2": "compensationandprocedures",
            "3": "personalinfo",
            "4": "billinginfo",
            "5": "summary"
        };
    }

    get oneTimeItems() {
        return combineItemsWithRecomendations(this.cart, this.additionalItems, "onetime");
    }

    get recurringItems() {
        return combineItemsWithRecomendations(this.cart, this.additionalItems, "recurring");
    }

    get enrollFeeWaiverQual() {
        if (!this.enrollmentWaiverQual) {
            return false;
        } else {
            if (this.enrollmentWaiverQual && this.enrollmentWaiverQual.enrollmentFeeWaiverQual) {
                return true;
            } else if (this.enrollmentWaiverQual && this.enrollmentWaiverQual.superAppPlusInCartQual) {
                if (this.cart && !!this.cart.cartItems.length && !!this.cart.cartItems.filter(product => product.productSKU.startsWith('eLifeAppSuperPlus')).length) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    get cartTotal() {
        return this.totals && this.totals[0].cartTotal;
    }

    get effectiveAdditionalItems() {
        if (!this.cart || !this.additionalItems) return [];
        let existing = keyBy([...this.oneTimeItems, ...this.recurringItems], "productSKU");
        return this.additionalItems.filter(x => !existing[x.productSKU] && x.productSKU !== "eRenew01" && x.productSKU !== "eRenew02" && x.productSKU !== "eLifeAppSuperPlusDis");
    }

    get renewPrice() {
        if (!this.cart) return 0;
        let p = this.cart.cartItems.find(x => x.productSKU === "eRenew01");
        return p && p.productPrice;
    }

    get renewDiscount() {
        if (!this.cart) return 0;
        let p = this.cart.cartItems.find(x => x.productSKU === "eRenew02");
        return p && p.productPrice;
    }

    resetCart() {
        //this.cart = null
        //this.totals = null
    }
    setError(error, type = "series") {
        error = extractErrorMessage(error);
        if (error && type === "verify") {
            this.expired = true;
        }
        else if (error && type === "addToCart") {
            this.cartError = error;
        }
        else
            this.error = error;
    }

    setActiveStep(activeStep) {
        this.activeStep = activeStep;
    }

    setPaymentId(newVal) {
        this.paymentID = newVal;
    }

    setSignature(newVal) {
        this.signature = newVal;
    }

    handleNextStep() {
        let maxPage = Object.keys(this.pages).length;
        let step = Math.min(maxPage, Number(this.activeStep) + 1);

        // if ( step === 5  && this.paymentID > 0 )
        //     step = Math.min(maxPage, step + 1);
        this.setActiveStep(step.toString());
    }

    handlPrevStep() {
        let step = Math.max(1, Number(this.activeStep) - 1);

        //if ( step === 5  && this.paymentID > 0 )
        //    step = Math.max(1, step - 1);

        this.setActiveStep(step.toString());
    }

    loadEnrollmentWaiverQual = generateLoadEntity("loadEnrollmentWaiverQual", this, "loadingEnrollmentWaiverQual"
        , () => { return services.MemberEnrollment.getEnrollmentFeeQual(); }, "enrollmentWaiverQual");

    verify = generateLoadEntity("verify", this, "loadingReferralInfo"
        , (loginGuid) => { return services.MemberEnrollment.verify(loginGuid); }, "referralInfo");


    loadCart = generateLoadEntity("loadCart", this, "loadingCartInfo"
        , ({ product, language, country }) => {
            return services.MemberEnrollment.cart(language);
        }, "cart", null, canDeleteAdapter);

    loadCartTotal = generateLoadEntity("loadCartTotals", this, "loadingCartTotals"
        , ({ cartID, lang }) => {
            return services.MemberEnrollment.cartTotal(cartID, lang);
        }, "totals");


    loadAdditionalItems = generateLoadEntity("loadAdditionalItems", this, "loadingAdditionalItems"
        , ({ country, lang }) => { return services.MemberEnrollment.additionalItems(country, lang); }
        , "additionalItems"
        , null, (result) => compact(result));


    addToCart = generateCreteEntity("addToCart", this, "addingItemToCart"
        , (params) => { return services.MemberEnrollment.addItemToCart(params); });

    removeFromCart = generateDeleteEntity("removeItemFromCart", this, "removingItemToCart"
        , (params) => { return services.MemberEnrollment.removeItemFromCart(params); });


    create = generateCreteEntity("create", this, "creating"
        , (params) => { return services.MemberEnrollment.create(params); });


    refreshItems = generateLoadEntity("refreshItems", this, "loadingCartInfo"
        , ({ cartID, country, lang }) => { return services.MemberEnrollment.loadItems(cartID, lang); }
        , (result) => {
            if (this.cart) {
                if (this.cart.cartItems)
                    this.cart.cartItems.replace(result);
                else
                    this.cart.cartItems = result;
            }
        }, null, canDeleteAdapter);


}

export default MemberEnrollmentStore;