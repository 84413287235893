import React, { useEffect } from "react";
import { useParams, Link, useRouteMatch, useHistory, Switch, Route } from "react-router-dom";
import { Observer, observer } from "mobx-react-lite";
import { Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import bg from '../bg.jpg';
import { common } from '@material-ui/core/colors';
import { useStores } from "../hooks/use-stores";
import { joinPath } from "../utils/helpers";
import IFrame from "../components/IFrame";
import News from "./News";
import NewsPost from "./NewsPost";
import clsx from 'clsx';
import Drawer from "@material-ui/core/Drawer";
import BurgerMenu from "../components/BurgerMenu";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import OnBoarding from "./OnBoarding";
import Members from "./Members";


const drawerWidth = 400;
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.spacing(3)
    },
    container: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    headerBg: {
        backgroundImage: `url(${bg})`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "100%",
        backgroundPosition: "center",
        minHeight: "20vh"
    },
    backLink: {
        color: "white",
        textDecoration: "none",
        display: "inline-flex",
        alignItems: "center",
        marginRight: 12,
    },
    appTitle: {
        fontSize: 40,
        color: "white",
        padding: "3px 17px",
        border: "1px solid white",
        borderRadius: 1


    },
    offset: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        marginLeft: 0,

    },
    thumb: {
        width: 48,
        marginRight: 12
    },
    links: {
        marginTop: 12
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    badge: {
        background: "#000"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    paper: {
        position: "static",
        color: "white",
        backgroundColor: "black",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },

    toolBarActions: {
        whiteSpace: "nowrap"
    },

    toolBarActionsFloat: {
        position: "absolute",
        right: 0
    },
}));

export default observer(({ prefix, native = true }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const history = useHistory();
    const path = params[0];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { commonStore, statStore, menuStore, authStore } = useStores();
    const { error, statistics } = statStore;
    const { currentUser, loginGuid } = authStore;
    const [open, setOpen] = React.useState(false);
    const { statusMessages, notifications, iFrame } = commonStore;
    const { androidMainMenu, debugMenu } = menuStore;

    const { isCurrentItemOpensWithoutIframe, currentItem, currentItemUrl,
        isCurrentItemExternal, currentLinks, currentBanner, parentItem, parentPath } = androidMainMenu;

    const isMenuOpen = Boolean(anchorEl);

    const handleBellMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBellMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        androidMainMenu.setPath(path);
    }, [androidMainMenu, params, path]);

    useEffect(() => {
        currentItem && (document.title = currentItem.title);
    }, [currentItem]);


    const showConventionAnn = () => {
        handleBellMenuClose();

        history.push(joinPath(prefix, "conventionnews"));
    };

    const showLifeLineAnn = () => {
        handleBellMenuClose();

        history.push(joinPath(prefix, "lifelinenews"));
    };

    const showNews = () => {
        handleBellMenuClose();
        history.push(joinPath(prefix, "news"));
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        authStore.logout();
        history.push('/');
    };

    const openPage = (page) => {
        history.push(joinPath(prefix, page));
        handleDrawerClose();
    };

    const openIframe = (page) => {
        history.push(joinPath(prefix, page));
        handleDrawerClose();
    };


    const menuId = 'notifications-menu';

    return <Container  className={classes.container} disableGutters>
        <Drawer
            open={open}
            onClose={handleDrawerClose}
            variant={"temporary"}
            className={clsx(classes.drawer, classes.drawerOpen)}
            classes={{
                paper: clsx(classes.paper, classes.drawerOpen),
            }}>
            {<BurgerMenu isMember={currentUser.isMember} onLogout={handleLogout} openPage={openPage} openIframe={openIframe} showBack={true} onClose={handleDrawerClose} />}
        </Drawer>
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleBellMenuClose}
        >
            <MenuItem onClick={showLifeLineAnn}><div className={"notif-icon notif-success"}>
                <Avatar src="https://main.secure.footprint.net/public/lifeappicons/listen_to_life_line.jpg" /></div>Life Line</MenuItem>
            <MenuItem onClick={showNews}><div className={"notif-icon notif-success"}>
                <Icon style={{ color: common.white }} className="fa fa-newspaper" /></div>News & Announcements</MenuItem>
            <MenuItem onClick={showConventionAnn}><div className={"notif-icon notif-danger"}>
                <Icon style={{ color: common.white }} className="fa fa-bullhorn" /></div>Convention Announcements</MenuItem>
        </Menu>
        {(!path) && statusMessages.map((x) => {
            return <Alert severity="info" key={x.serviceName}>{x.serviceName}<br />{x.message}</Alert>;
        })
        }

        <Switch>
            { <Route path={`${prefix}/onboarding/:tag`} component={OnBoarding} /> }
            <Route path={`${prefix}/conventionnews`} render={props => {
                return <Observer>{() => <IFrame url={iFrame}></IFrame>}</Observer>;
            }} />
            <Route path={`${prefix}/lifelinenews`} render={props => {
                return <Observer>{() => <IFrame url={iFrame}></IFrame>}</Observer>;
            }} />
            <Route path={`${prefix}/news/:id`} component={NewsPost} />
            <Route path={`${prefix}/news`} component={News} />
            <Route path={`${prefix}/members`} render={() => {
                return <Members />;
            }} />
            <Route render={props => {
                return <>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography
                                className={classes.title}
                                gutterBottom
                                variant="h1"
                                component="h1"
                                bold
                            >
                                New Life App for Android is Now Available!
        </Typography>
                            <p>
                                We are excited to announce the new Life App for Android is now
                                available! The app has been completely rewritten and now matches the
                                look and feel of the iOS version and the website.
        </p>
                            <Divider />
                            <p>
                                It appears that your Life app didn’t update automatically. <br />
          You can manually update it by:
        </p>
                            <p>1. Open Google Play Store</p>
                            <ul>
                                <li>Search for Life Info app </li>
                                <li>Tap “Update” </li>
                            </ul>
                            <p>2. Uninstall Life Info App and Reinstall</p>
                            <ul>
                                <li>Go to Settings &gt; App Settings</li>
                                <li>Select Google Play Store &gt; “Force Stop”</li>
                                <li>Clear the cache for Google Play Store, then “Open”</li>
                                <li>Search for Life Info app &gt; Install</li>
                                <li>Launch and login to the Life Info app</li>
                            </ul>
                            <div className={classes.modalButtons}>
                                <a href={"https://play.google.com/store/apps/details?id=com.lifeleadership.lifestream"}>
                                    <img alt="googleplay_badge" src={"/googleplay_badge.png"} height="60px" />
                                </a>
                            </div>
                        </CardContent>
                    </Card>
                    {/* {currentBanner && <TopBanner banner={currentBanner} match={match} />}
                    {currentItem && <Observer>{() => <Items error={error} statistics={statistics}
                        path={path} currentUser={currentUser}
                        currentItemUrl={currentItemUrl}
                        isCurrentItemOpensWithoutIframe={isCurrentItemOpensWithoutIframe}
                        isCurrentItemExternal={isCurrentItemExternal}
                        currentLinks={currentLinks} match={match}></Items>}</Observer>} */}
                </>;
            }} />
        </Switch>

    </Container>;

});

