import { makeAutoObservable, runInAction, } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import {
    generateLoadEntity,
    generateLoadList,
} from "../utils/mobx";
import * as _ from 'lodash';

const VALID_BITREFILL_AMOUNTS = [20, 25, 50, 60];
const CAMPAIGN_COUNTRIES = ['US', 'CA'];
export const DEFAULT_COUNTRY = "US";

async function getFilteredMerchants(merchants, countryCode, banners,) {
    const filteredMerchants = merchants[countryCode];
    const newBanners = banners.map(merchantInfo => {
        if (merchantInfo.merchant.provider === "Bitrefill") {
            const merchant = _.find(filteredMerchants, { name: merchantInfo.merchant.name });
            if (!merchant) return;
            merchantInfo.merchant.id = merchant.id;
            merchantInfo.merchant.staticPrices = [merchant.denomination];
        }
        return merchantInfo;
    });
    return newBanners
        .filter(banner => banner)
        .sort(function (banner1, banner2) {
            // Ascending: sort by displayOrder
            return banner1.displayOrder - banner2.displayOrder;
        });
}

class ShoppingBossStore {
    merchants = {}; // {'CA': [...merchantList], 'US': [...merchantList]}
    merchantsLoading = false;

    countryCode = null;
    currentPage = 'merchants';
    selectedMerchant = null;
    userBalance = null;
    userCountry = null;
    favorites = [];
    usBanners = [];
    caBanners = [];
    favoritesLoading = false;
    togglingFavorites = false;

    lifetimeBalance = null;
    perksEarnedLifetime = null;
    merchantInfo = null;
    eCodeInfo = null;
    merchantID = null;
    merchantCountry = null;
    merchantTerms = null;
    checkoutInfo = null;
    userInfo = null;
    checkoutInfoLoading = false;
    giftCards = [];
    completeCheckoutLoading = false;

    eAcceleratorValid = false;
    eAcceleratorValidityLoading = null;

    loadingAlbums = null;
    albums = [];

    maximumAllowed = 2000;

    constructor() {
        makeAutoObservable(this);
    }

    async getBanners() {
        await this.getMerchantsByCampaign('US');
        await this.getMerchantsByCampaign('CA');
        const { banners } = await services.ShoppingBoss.starfishBanners();
        const usBanners = await getFilteredMerchants(this.merchants, 'US', banners.filter(merchant => merchant.country === 'US'));
        const caBanners = await getFilteredMerchants(this.merchants, 'CA', banners.filter(merchant => merchant.country === 'CA'));

        //DEBUG TO TEST VIMEO
        //usBanners[0].url = "https://player.vimeo.com/video/744758395"
        //usBanners[0].title = "some title"
        runInAction(() => {
            this.usBanners = usBanners;
            this.caBanners = caBanners;
        });
    }

    setError(error, type = "shoppingBoss") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    get eAcceleratorBannerImageUrl() {
        return 'https://firebasestorage.googleapis.com/v0/b/life-info-b7c3e.appspot.com/o/shop%2Fimages%2Fimage1623788648517_1000.jpg?alt=media&token=3427d7cc-c0b6-4c5e-a30d-a0b49b1b688b';
    }

    formatPercent(data) {
        _.forEach(data, (item) => {
            item.percent = parseFloat(item.percent).toFixed(2) + "%";
        });
        return data;
    };

    getMerchantsByCampaign = (campaign) => {
        // Return cached merchants if they exist for campaign
        if (this.merchants[campaign]) {
            return _.uniqBy(this.merchants[campaign], 'id');
        }

        return (generateLoadEntity("merchants", this, "merchantsLoading", async () => {
            const { merchants } = await services.ShoppingBoss.merchants(campaign);

            let newMerchants = { ...this.merchants };
            newMerchants[campaign] = this.formatPercent(merchants.merchants);

            // Fix goofy shopping boss multiple ids
            return newMerchants;
        }, "merchants"))();
    };

    getFavoriteMerchants = generateLoadList("favorites", this, "favoritesLoading", async (options) => {
        const { merchants } = await services.ShoppingBoss.favorites();
        return merchants;
    }, "favorites");


    verifyEAccelerator = generateLoadEntity("verifyEAccelerator", this, "eAcceleratorValidityLoading", async (options) => {
        return await services.ShoppingCart.verifyEAccelerator();
    }, "eAcceleratorValid", "alreadyPurchased");

    loadAlbums = generateLoadList("albums", this, "loadingAlbums", async (options) => {
        return await services.ShoppingBoss.albums();
    }, "albums");

    async toggleFavorite(isFavorite, merchantId) {
        try {
            this.togglingFavorites = true;

            if (isFavorite) {
                this.favorites.replace(this.favorites.filter(f => f.id !== merchantId));
                await services.ShoppingBoss.removeFromFavorites(merchantId);
            } else {
                const flattenedMerchants = Object.values(this.merchants).flat();
                this.favorites.push(flattenedMerchants.find(m => m.id === merchantId));
                await services.ShoppingBoss.addToFavorites(merchantId);
            }
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
        } finally {
            this.togglingFavorites = false;
        }
    }

    async fetchUserCountry() {
        try {
            let newCountry = DEFAULT_COUNTRY;
            let profile = await services.UserProfile.list();

            const { country } = profile.address;

            if (country && CAMPAIGN_COUNTRIES.includes(country)) {
                newCountry = country;
            }

            runInAction(() => {
                this.userCountry = newCountry;

            });
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        }
    }

    async fetchUserBalance() {
        try {
            const result = await services.ShoppingBoss.userBalance();
            const walletStats = await services.UserProfile.getWalletStats();
            if (result.message == 'success') {
                const perksEarnedLifetime = parseFloat(walletStats?.wallet?.giftCards?.perksEarnedLifetime) || 0;
                runInAction(() => {
                    this.userBalance = result.result.balance;
                    this.perksEarnedLifetime = perksEarnedLifetime;
                    this.lifetimeBalance = result.result.cashEarnedLifetime + perksEarnedLifetime;
                });
            } else {
                throw new Error("Unable to fetch user balance");
            }
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        }
    }

    async fetchMerchantInfo() {
        try {
            if (this.selectedMerchant.provider === 'ShoppingBoss' || this.selectedMerchant.provider === 'sparkwallet') {
                const { eCodeInfo, merchantInfo } = await services.ShoppingBoss.merchantInfo(this.selectedMerchant.id);
                runInAction(() => {
                    this.merchantInfo = merchantInfo;
                    this.eCodeInfo = eCodeInfo;
                });
            } else {
                // We're not getting info from the backend for Bitrefill
                // Fill in the ecode info with
                const percentBack = !!this.selectedMerchant.percent ? this.selectedMerchant.percent : '1%';
                runInAction(() => {
                    this.merchantInfo = this.selectedMerchant;
                    this.eCodeInfo = { percent: percentBack, hasRange: false, staticPrices: this.merchantInfo.staticPrices };
                });
            }
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async startCheckout(params) {
        try {
            this.checkoutInfoLoading = true;
            const userInfo = await services.ShoppingBoss.user();
            const { checkoutInfo } = await services.ShoppingBoss.checkoutToken({
                merchantID: params.merchantID,
                eCodeID: params.eCodeID,
                denomination: params.denomination,
                provider: this.selectedMerchant.provider
            });

            runInAction(() => {
                this.merchantCountry = params.merchantCountry; // passed from Review page
                this.merchantTerms = params.merchantTerms;
                this.checkoutInfo = checkoutInfo;
                this.userInfo = userInfo;
                this.merchantID = params.merchantID;
            });
        }
        catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        }
        finally {
            runInAction(() => {
                this.checkoutInfoLoading = false;
            });
        }
    }

    async checkout(params) {
        try {
            this.completeCheckoutLoading = true;

            const response = await services.ShoppingBoss.checkout(params);//{ giftCards: [{giftcardURL: 'https://google.com'}]};
            runInAction(() => {
                this.giftCards = response.giftCards;
            });
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        } finally {
            runInAction(() => {
                this.completeCheckoutLoading = false;
            });
        }
    }

    isValidBitrefillAmount(value) {
        return VALID_BITREFILL_AMOUNTS.includes(value);
    }

    get validBitrefillAmounts() {
        return VALID_BITREFILL_AMOUNTS;
    }

    get eWalletMaximumAllowed() {
        return this.maximumAllowed;
    }

    get eWalletCanAdd() {
        return Math.max(this.eWalletMaximumAllowed - this.userBalance, 0);
    }

    get balance() {
        let userBalance = this.userBalance || 0;
        return `$${userBalance.toFixed(2)}`;
    }

    get lifetimePerks() {
        let balance = this.lifetimeBalance || 0;
        return `${balance.toFixed(2)}`;
    }
}

export default ShoppingBossStore;