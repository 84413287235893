import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import IFrame from "../../components/IFrame";
import { useStores } from "../../hooks/use-stores";
import { getUniqKey, joinPath } from "../../utils/helpers";
import MembersHome from "./MembersHome";
import { getItemUrl } from "../../utils/menuUtils";
import SalesAchiever from "./SalesAchiever";
import SendOut from "./SendOut";
import Onboard from "./Onboard";
import ShareEnrollment from "../ShareEnrollment";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        color: "white",
        backgroundColor: "black",
    },
    root: {
        flexGrow: 1,
        color: "white"
    },
    whiteColor: {
        color: "white"
    },
    grow: {
        flexGrow: 1
    },
    alignLeft: {
        justifyContent: "start"
    },
    block: {
        borderRadius: 20,
        background: "#b82032",
        display: "flex",
        padding: "28px 30px",
        marginBottom: 30,
        marginRight: 30,
        alignItems: "center",
        justifyContent: "center",
        maxHeight: 105
    },
    verticalBlock: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    verticalLeftGrowBlock: {
        display: "flex",
        alignItems: "start",
        paddingLeft: 20,
        flexGrow: 1,
        flexDirection: "column"
    },
    icon: {
        width: 50,
        height: 50,
    }

}));


export default observer(function Members({ playMedia }) {
    const classes = useStyles();
    const { menuStore } = useStores();
    const membersMatch = useRouteMatch();

    const { topLevelLinks } = menuStore.membersMenu;

    return (!menuStore.menuLoading && menuStore.membersMenu) ? <Switch>
        {/*Place rewriten routes here */}
        {/*<Route path={path={joinPath( membersMatch.url, `ewallet/add_money`)} }  component={eWalletAddmoney} />*/}
        <Route path={joinPath(membersMatch.url, `/quicklinks/customer_enrollment_links`)} component={ShareEnrollment} />
        <Route path={joinPath(membersMatch.url, `/quicklinks/liens_d_inscription_des_clients`)} component={ShareEnrollment} />
        <Route path={joinPath(membersMatch.url, `/salesandenrollment/enroll_customer`)} component={ShareEnrollment} />

        <Route path={joinPath(membersMatch.url, `/marketingsystem/create_sendout`)} render={props => {
            return <SendOut parentMatch={membersMatch} />;
        }} />

        <Route path={joinPath(membersMatch.url, `/onboard/:tag`)} render={props => {
            return <Onboard parentMatch={membersMatch} />;
        }} />

        <Route path={joinPath(membersMatch.url, `/marketingsystem/create_sendout`)} render={props => {
            return <SendOut parentMatch={membersMatch} />;
        }} />

        <Route path={joinPath(membersMatch.url, `/marketingsystem/purchase_sendout/:cart_guid`)} render={props => {
            return <IFrame tag={"marketingsystem/purchase_sendout"} url={`https://shop.lifeinfoapp.com/checkout/index.html?cart_guid=${props.match.params.cart_guid}`} />;
        }} />

        <Route path={joinPath(membersMatch.url, `salesachiever/details`)} render={props => {
            return <IFrame tag={"members/salesachiever/details"} />;
        }} />

        <Route path={joinPath(membersMatch.url, `salesachiever`)} render={props => {
            return <SalesAchiever parentMatch={membersMatch} />;
        }} />

        <Route path={joinPath(membersMatch.url, `dashboard`)} render={props => {
            return <IFrame tag={"member/_dashboard"} />;
        }} />

        <Route path={joinPath(membersMatch.url, `core_4/manage`)} render={props => {
            return <IFrame tag={"members/core_4/manage"} />;
        }} />



        <Route path={joinPath(membersMatch.url, `core_4/super_app`)} render={props => {
            return <IFrame tag={"members/core_4/super_app"} />;
        }} />

        <Route path={joinPath(membersMatch.url, `core_4/autoship`)} render={props => {
            return <IFrame tag={"members/core_4/autoship"} />;
        }} />

        <Route path={joinPath(membersMatch.url, `core_4/marketing_system`)} render={props => {
            return <IFrame tag={"members/core_4/marketing_system"} />;
        }} />

        <Route path={joinPath(membersMatch.url, `core_4/sot`)} render={props => {
            return <IFrame tag={"members/core_4/sot"} />;
        }} />

        <Route path={joinPath(membersMatch.url, `:section/:page`)} render={props => {
            const section = topLevelLinks.find(x => x.tag === props.match.params.section);
            const item = section && section.subsection.find(x => getUniqKey(x) === props.match.params.page);
            return item && <div className={clsx(classes.mainContent, classes.grow)}>
                <IFrame url={getItemUrl(item)} />
            </div>;
        }} />

        <Route path={joinPath(membersMatch.url, `:section`)} render={props => {
            return <MembersHome playMedia={playMedia} section={props.match.params.section} parentMatch={membersMatch} />;
        }} />

        <Route render={() => {
            return <MembersHome playMedia={playMedia} parentMatch={membersMatch} />;
        }} />
    </Switch> : null;
});