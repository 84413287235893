import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { flatten, uniq, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Button, Chip, CircularProgress, Dialog, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Select, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import Search from './components/Search';
import { useStores } from "../../hooks/use-stores";
import { joinPath } from "../../utils/helpers";

const FAVORITES = 'favorites';

const countries = [
    {
        name: "United States",
        code: "US",
        flag: `${process.env.PUBLIC_URL}/flag-us.png`
    },
    {
        name: "Canada",
        code: "CA",
        flag: `${process.env.PUBLIC_URL}/flag-ca.png`
    }
];

const useStyles = makeStyles((theme) => ({
    merchant: {
        textDecoration: "none",
        fontWeight: "bold",
        color: "white",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            color: "white",
            textDecoration: "none",
            cursor: 'pointer'
        }
    },
    countryFlag: {
        height: '30px',
        verticalAlign: 'middle',
        paddingBottom: '1px'
    },
    countryLabel: {
        fontSize: 14,
        marginLeft: theme.spacing(.5),
        [theme.breakpoints.up('sm')]: {
            fontSize: 18
        }
    },
    spacer: {
        height: 30
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginRight: theme.spacing(2),
        backgroundColor: "#fff",
        '& > img': {
            objectFit: 'contain'
        }
    },
    modalContainer: {
        padding: 20,
        borderRadius: 5,
        maxWidth: 400,
        outline: "none",
    },
    filters: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        marginTop: theme.spacing(1),
        paddingBottom: '10px',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    countrySelect: {
        marginBottom: theme.spacing(1),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        width: '100%'
    },
}));

const Merchants = ({ shoppingBossMatch }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [searchQuery, setSearchQuery] = React.useState('');
    const [activeFilter, setActiveFilter] = React.useState(null);

    const { shoppingBossStore, identityVerificationStore, commonStore, } = useStores();
    const {
        identityVerificationStatus,
    } = identityVerificationStore;
    const { error, merchants, favorites } = shoppingBossStore;

    const [loadingMerchants, setLoadingMerchants] = useState(true); // use this over the one in the store for more control
    const [countryCode, setCountryCode] = useState('US');
    const [modalOpen, setModalOpen] = useState(false);

    const didMount = useRef(false);

    useEffect(() => {
        void identityVerificationStore.checkIdentityVerification();
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isFailedKyc = React.useMemo(() => {
        return ["CLOSED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const handleMerchantSelectionCanProceed = () => {
        if (!!isFailedKyc) {
            commonStore.showError("We apologize, but National Brand Stores is currently unavailable for your account. Please contact customer support for more details.");
            return false;
        } else if (!!isUnverified) {
            commonStore.showError("Before making a purchase, we need you to complete the Identity Verification process.", 15000);
            history.push(joinPath(commonStore.onStarfishOrRoyalStarfish ? shoppingBossMatch.url : '/wallet/ewallet/', `add_more_money`));
            return false;
        } else {
            return true;
        }
    };

    const updateMerchants = async (newCountryCode) => {
        setLoadingMerchants(true);

        await Promise.all([
            shoppingBossStore.getMerchantsByCampaign(newCountryCode),
            shoppingBossStore.getFavoriteMerchants()
        ]);

        shoppingBossStore.countryCode = newCountryCode;
        setLoadingMerchants(false);
        setCountryCode(newCountryCode);
        setActiveFilter(null);

        if (didMount.current) {
            setModalOpen(true);
        } else {
            didMount.current = true;
        }
    };

    const getFilters = () => {
        let filters = [FAVORITES];
        //! removed dynamic chips
        // if (merchants[countryCode]) {
        //     const filterableCategories = merchants[countryCode].map(m => m.categories);
        //     filters = filters.concat(flatten(filterableCategories));
        // }
        return uniq(filters);
    };

    const filteredMerchants = () => {
        // Filter by Country
        let filteredMerchants = merchants[countryCode] || [];

        // Filter by selected category
        if (activeFilter) {
            let byCategory;
            if (activeFilter === FAVORITES) {
                byCategory = (m => isMerchantFavorite(m.id));
            } else {
                byCategory = m => m.categories.includes(activeFilter);
            }

            filteredMerchants = filteredMerchants.filter(byCategory);
        }

        // Filter by search query
        if (searchQuery) {
            const regex = new RegExp(searchQuery, 'i');
            filteredMerchants = filteredMerchants.filter(m => m.name.match(regex));
        }


        return uniqBy(filteredMerchants, 'id');
    };

    const onSearchQueryUpdated = (newSearchQuery) => {
        setSearchQuery(newSearchQuery);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const toggleFavorite = async (isFavorite, merchantId) => {
        await shoppingBossStore.toggleFavorite(isFavorite, merchantId);
    };

    const filterByCategory = (category) => {
        if (activeFilter === category) {
            setActiveFilter(null);
        }
        else {
            setActiveFilter(category);
        }
    };

    const isMerchantFavorite = (merchantId) => {
        let isFavorite = false;
        const favoriteIds = favorites.map(f => f.id);

        if (favoriteIds.includes(merchantId)) {
            isFavorite = true;
        }

        return isFavorite;
    };

    const getCountryFlag = (countryCode) => {
        let country = countries.find(country => country.code == countryCode);
        if (country) {
            return <img className={classes.countryFlag} src={country.flag} />;
        }
        return '';
    };

    const countrySelector = () => (
        <Grid container item xs={12} justifyContent="flex-end">
            <Select
                className={classes.countrySelect}
                value={countryCode}
                renderValue={(value) => <span><span className={classes.countryFlag}>{getCountryFlag(value)}</span><span className={classes.countryLabel}>{countryCode}</span></span>}
                onChange={(e) => updateMerchants(e.target.value)}
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {countries.map((country) =>
                    <MenuItem key={country.code} value={country.code}><span className={classes.countryFlag}>{getCountryFlag(country.code)}</span><span className={classes.countryLabel}>{country.code}</span></MenuItem>
                )}
            </Select>
        </Grid>);

    const onSelectMerchant = (merchant) => {
        const canProceed = handleMerchantSelectionCanProceed();
        if (!!canProceed) {
            shoppingBossStore.selectedMerchant = merchant;
            history.push(joinPath(shoppingBossMatch.url, `/review`));
        }
    };

    useEffect(() => {
        if (!!error && error.includes('account has not been created')) {
            return history.push(`/onboarding/shopping_boss`);
        }
    }, [error]);

    useEffect(() => {
        shoppingBossStore.currentPage = 'merchants';
        (async () => {
            shoppingBossStore.selectedMerchant = null;
            shoppingBossStore.merchantInfo = null;
            shoppingBossStore.eCodeInfo = null;

            await shoppingBossStore.fetchUserCountry();
            await shoppingBossStore.fetchUserBalance();
            await updateMerchants(shoppingBossStore.userCountry); // this handles the loading indicator
        })();
    }, []);

    return (
        <Box style={{ paddingBottom: '58px' }}>
            {loadingMerchants ?
                <Box className={classes.progressContainer}>
                    <CircularProgress />
                </Box>
                :
                <>
                    {error &&
                        <Alert severity="error">
                            <AlertTitle>{t("shoppingBoss:error.title")}</AlertTitle>
                            {error}
                        </Alert>
                    }
                    <Box>
                        <Dialog
                            open={modalOpen}
                            onClose={closeModal}
                        >
                            <Typography className={classes.modalContainer}>{t(`shoppingBoss:merchants.viewingMerchants${countryCode == 'CA' ? 'CA' : 'US'}`)}</Typography>
                            <Button variant={"contained"} color={"primary"}
                                onClick={closeModal}
                                type="button"
                                className="btn btn-primary" data-dismiss="modal">{t('shoppingBoss:ok')}
                            </Button>
                        </Dialog>
                        <Grid container xs={12}>{countrySelector()}</Grid>
                        <Search searchQuery={searchQuery} onSearchQueryChanged={onSearchQueryUpdated} />
                        <Box className={classes.filters}>
                            {getFilters().map(f =>
                                <Chip
                                    key={f}
                                    icon={activeFilter == f ? <CheckCircleSharpIcon /> : undefined}
                                    clickable
                                    style={{ textTransform: 'capitalize' }}
                                    label={f}
                                    onClick={() => filterByCategory(f)} />)
                            }
                        </Box>
                        <List>
                            {filteredMerchants().map(merchant => {
                                const isFavorite = isMerchantFavorite(merchant.id);
                                return (
                                    <ListItem
                                        key={merchant.id}
                                        component={Link}
                                        className={classes.merchant}
                                        to="#"
                                        onClick={() => onSelectMerchant(merchant)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                className={classes.avatar}
                                                variant="rounded"
                                                alt={` `}
                                                src={`${merchant.logo}`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={merchant.name} secondary={t('shoppingBoss:merchants.percentBack', { percent: merchant.percent || '0%' })} />
                                        <ListItemSecondaryAction onClick={() => toggleFavorite(isFavorite, merchant.id)}>
                                            <IconButton edge="end" aria-label="delete">
                                                {isFavorite ? <StarIcon /> : <StarBorderIcon />}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })
                            }
                        </List>
                    </Box>
                </>}
        </Box>
    );
};

export default observer(Merchants);