import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import { asyncRules } from "./asyncRules";
import { syncRules } from "./syncRules";


const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
            // here we can access the `validatorjs` instance (plugin) and we
            // can add the rules using the `registerAsyncRule()` method.
            Object.keys(asyncRules).forEach((key) =>
                validator.registerAsync(key, asyncRules[key]));

            Object.keys(syncRules).forEach((key) =>
                validator.register(key, syncRules[key].func, syncRules[key].message ));
            const messages = validator.getMessages('en');
            messages.required = 'This field is required.';
            validator.setMessages('en', messages);
        }
    }),
};

export default plugins;