import RestService from "./RestService";

export default class LifelineService extends RestService {
    constructor() {
        super('https://apiv2.lifeinfoapp.com/api/lifeline');
    }

    async sendVideoMessage(formData, onUploadProgress) {
        return this.post(`${this.baseUrl}/sendVideoMessage`, formData, { 'Content-Type': 'multipart/form-data', onUploadProgress })
    }

    async passMessage(formData, onUploadProgress) {
        return this.post(`${this.baseUrl}/newPass`, formData, { 'Content-Type': 'multipart/form-data', onUploadProgress })
    }

    async getBaseline() {
        return this.get(`${this.baseUrl}/`)
    }

    async getVideosFromNewAPI(loginGUID, listType, forSpouse, page) {
        return this.get(`${this.baseUrl}/getMessages?loginGUID=${loginGUID}&listType=${listType}&forSpouse=${forSpouse}&page=${page}`)
    }

    async getSettings(loginGUID, forSpouse) {
        return this.get(`https://shopapi.mainhomepage.com/api/lifelineuser/GetLifeLineSettings?loginGUID=${loginGUID}&forspouse=${forSpouse}`)
    }

    async getNewMessageCount(loginGUID, listType, forSpouse) {
        return this.get(`https://shopapi.mainhomepage.com/api/lifelineuser/GetNewMessageCount?loginGUID=${loginGUID}&listType=${listType}&forSpouse=${forSpouse}`)
    }

    async markMessage(loginGUID, voicemailID, listened, forSpouse) {
        return this.get(`https://shopapi.mainhomepage.com/api/lifelinemessage/MarkMessage?loginGUID=${loginGUID}&voicemailID=${voicemailID}&listened=${listened}&forSpouse=${forSpouse}`)
    }

    // True to archive; false to delete
    async archive(loginGUID, voicemailID, archive, forSpouse) {
        return this.get(`https://shopapi.mainhomepage.com/api/lifelinemessage/DeleteMessage?loginGUID=${loginGUID}&voicemailID=${voicemailID}&archive=${archive}&forSpouse=${forSpouse}`)
    }
}
