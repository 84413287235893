import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Container, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { useStores } from '../../hooks/use-stores';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: 'rgb(190, 52, 70)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
  },
  container: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  },
  mainHeaderContainer: {
    backgroundColor: 'black',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  header: {
  },
  title: {
    fontSize: '4rem',
    marginBottom: -12,
    [theme.breakpoints.up('md')]: {
      fontSize: '6rem'
    }
  },
  subTitle: {
    fontSize: '2rem',
    marginTop: theme.spacing(.25),
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem'
    }
  },
  datesContainer: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  datesWrapper: {
    width: '75%',
    margin: '0 auto'
  },
  datesContainerItem: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        borderRight: '1px solid white'
      },
    }
  },
  datesHeaderMain: {
    fontWeight: 'bold',
    fontSize: '1rem'
  },
  datesHeaderDescription: {
    fontWeight: '300'
  },
  ticketContainer: {
    marginTop: theme.spacing(2)
  },
  green: {
    color: 'green'
  },
  red: {
    color: 'red'
  },
  ticketTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  usersTicketType: {
    marginBottom: theme.spacing(1)
  },
  ticketButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ticketButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  ticketButtonSeparator: {
    textTransform: 'uppercase',
    margin: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    }
  },
  purchaseTicketButton: {
    backgroundColor: '#ce1f42',
    color: 'white',
    padding: theme.spacing(2),
    width: 'max-content',
    '&:hover': {
      backgroundColor: '#ce1f42',
    }
  },
  bannerContainer: {
    cursor: 'pointer',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: 'rgb(190, 52, 70)'
  },
  bannerText: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const Header = ({ title, subTitle, eventDates, banner, hasVIPTicket, hasStandardTicket, userHasNoTicket, generalTicketPurchaseUrl, vipTicketPurchaseUrl, openVipTicketUpgrade }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authStore } = useStores();

  const { isConventionGuest } = authStore;

  const createDates = ({ dates, description }) =>
    <Grid item md={4} xs={12} className={classes.datesContainerItem}>
      <Box display="flex" flexDirection="column">
        <Typography className={classes.datesHeaderMain}>
          {dates}
        </Typography>
        <Typography className={classes.datesHeaderDescription}>
          ({description})
        </Typography>
      </Box>
    </Grid >;

  const createMainHeader = ({ title, subTitle }) => {
    return (
      <Container maxWidth={"md"} className={classes.mainHeaderContainer}>
        <Typography className={clsx(classes.header, classes.title)} variant="h1">{title}</Typography>
        {subTitle && <Typography className={clsx(classes.header, classes.subTitle)} variant="h2" dangerouslySetInnerHTML={{ __html: subTitle }} />}
      </Container>
    );
  };

  return (
    <Container className={classes.container}>
      {createMainHeader({ title, subTitle })}
      {eventDates && eventDates.length > 0 && (
        <Box className={classes.datesContainer}>
          <Grid container xs={12} className={classes.datesWrapper}>
            {eventDates.map(d => createDates(d))}
          </Grid>
        </Box>
      )}
      {
        isConventionGuest
          ? <Divider className={classes.divider} />
          :
          banner &&
          <Box className={classes.bannerContainer} onClick={() => window.open(banner?.link)}>
            <Typography className={classes.bannerText}>
              {banner?.text}
            </Typography>
          </Box>
      }
      <Grid className={classes.ticketContainer} item>
        <Box className={classes.ticketButtonContainer}>
          {false && /* Disabling this for now - might need in future */
            <>
              <Alert severity="info">{t('conventionProgram:header.noTicket')}</Alert>
              <Box className={classes.ticketButtons}>
                <Button className={classes.purchaseTicketButton} onClick={() => window.open(generalTicketPurchaseUrl)}>
                  {t('conventionProgram:header.purchaseStandardTicket')}
                </Button>
                <Typography variant="body1" className={classes.ticketButtonSeparator}>{t('conventionProgram:header.or')}</Typography>
                <Button className={classes.purchaseTicketButton} onClick={() => window.open(vipTicketPurchaseUrl)}>
                  {t('conventionProgram:header.purchaseTicket')}
                </Button>
              </Box>
            </>
          }
        </Box>
      </Grid>
    </Container>
  );
};

export default Header;
