import { makeAutoObservable, reaction, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import shippingAddressForm from "../forms/shippingAddress";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList, generateLoadList2,
    generateUpdateEntity
} from "../utils/mobx";
import { getCookie, deleteCookie, setCookie, setCookieWithDomain } from "../utils/cookieUtil";

const { REACT_APP_SPARK_DOMAIN = "verifywithspark.lifeinfoapp.com" } = process.env;

class IdentityVerificationStore {
    constructor() {
        makeAutoObservable(this);
    }

    error = null;
    loadingIdentityVerification = null;
    loadingIdentityVerificationUrl = null;
    identityVerificationStatus = null;
    verificationLinkUrl = null;
    inquiryId = null;



    setError(error, type = "") {
        error = extractErrorMessage(error);
        switch (type) {
            default:
                this.error = error;
        }
    }

    checkIdentityVerification = generateLoadEntity("checkIdentityVerification", this, "loadingIdentityVerification",
        async () => {
            const result = await services.UserProfile.checkIdentityVerification();

            runInAction(() => {
                this.identityVerificationStatus = result.accountStatus;
            });
        });

    getIdentityVerificationUrl = generateLoadEntity("loadIdentityVerificationUrl", this, "loadingIdentityVerificationUrl",
        async () => {
            const result = await services.UserProfile.verificationLinkUrl();
            //load and set oauth token
            const oauthTokens = await services.Spark.oauthToken();
            //console.log(`received accessToken:${oauthTokens.access_token.slice(0, 10)}...${oauthTokens.access_token.slice(-10)}`);
            //console.log(`received refreshToken:${oauthTokens.refresh_token.slice(0, 10)}...${oauthTokens.refresh_token.slice(-10)}`);
            setCookie("spark_access_token", oauthTokens.access_token, 1);
            setCookie("spark_refresh_token", oauthTokens.refresh_token, 1);
            return process.env.REACT_APP_OVERRIDE_VERIFICATION_URL
                ? { "message": "success", "verificationLinkUrl": process.env.REACT_APP_OVERRIDE_VERIFICATION_URL, "accountStatus": "OPEN" }
                : result;
        }, "verificationLinkUrl", "verificationLinkUrl");


}

export default IdentityVerificationStore;