import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import {joinPath} from "../utils/helpers";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({

    divider:{
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    colorWhite:{
        color:"white",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));


export default observer(({items, prefix=""}) => {
    const classes = useStyles();

    return <List>
        {items.map(s=>{
            return <Fragment key={s.speakerID}>
                <ListItem className={classes.colorWhite}
                             component={Link}
                             to={`${joinPath( prefix,"/library", "speakers", s.speakerID )}`}>

                <ListItemAvatar>
                    <Avatar variant="rounded" className={classes.large}
                            alt={` `}
                            src={`${s.speakerImage}`}
                    />
                </ListItemAvatar>
                <ListItemText primary={s.speakerName}/>
            </ListItem>
                <Divider  className={classes.divider} component="li" />
            </Fragment>
        }) }

    </List>
})


