import CommonStore from "./commonStore";
import AudioStore from "./audioStore";
import UserShippingInfoStore from "./userShippingInfoStore";
import UserBillingInfoStore from "./userBillingInfoStore";
import AuthStore from "./authStore";
import StatStore from "./statStore";
import LibraryStore from "./libraryStore";
import SeriesStore from "./seriesStore";
import SalesAchieverAudiosStore from "./salesAchieverAudiosStore";
import SpeakerStore from "./speakerStore";
import MenuStore from "./menuStore";
import NewsStore from "./newsStore";
import PacksStore from "./packsStore";
import VideoStore from "./videoStore";
import MediaHandlers from "./MediaHandlers";
import UserProfileStore from "./userProfileStore";
import SpouseProfileStore from "./spouseProfileStore";
import BookStore from "./bookStore";
import SendOutStore from "./sendOutStore";
import EnrollmentStore from "./enrollmentStore";
import MemberEnrollmentStore from "./memberEnrollmentStore";
import ShopStore from "./shopStore";
import ShareStore from "./shareStore";
import OnboardStore from "./onboardStore";
import ConventionProgramStore from "./conventionProgram";
import ShoppingBossStore from "./shoppingBossStore";
import HelpStore from "./helpStore";
import SupportStore from "./supportStore";
import SubscriptionStore from "./subscriptionStore";
import SeminarWebinarStore from "./seminarWebinarStore";
import TransactionsStore from "./transactionsStore";
import LifelineStore from "./lifelineStore";
import IdentityVerificationStore from "./identityVerificationStore";

const libraryStore = new LibraryStore();
const audioStore = new AudioStore(libraryStore);
const videoStore = new VideoStore(libraryStore);
const bookStore = new BookStore(libraryStore);
const mediaHandlers = new MediaHandlers(libraryStore, audioStore, videoStore, bookStore);

const commonStore = new CommonStore();
const statStore = new StatStore();
const packsStore = new PacksStore(libraryStore);
const menuStore = new MenuStore(libraryStore, packsStore, commonStore);
const newsStore = new NewsStore();
const seriesStore = new SeriesStore(libraryStore);

const salesAchieverAudiosStore = new SalesAchieverAudiosStore(libraryStore);
const speakerStore = new SpeakerStore(libraryStore);
const authStore = new AuthStore(commonStore, menuStore, statStore);
const userProfileStore = new UserProfileStore(commonStore);
const spouseProfileStore = new SpouseProfileStore(commonStore);
const userShippingInfoStore = new UserShippingInfoStore(commonStore);
const userBillingInfoStore = new UserBillingInfoStore(authStore,commonStore);
const sendOutStore = new SendOutStore();
const enrollmentStore = new EnrollmentStore(commonStore);
const onboardStore = new OnboardStore(commonStore, authStore);
const shopStore = new ShopStore(commonStore, authStore);
const shareStore = new ShareStore();
const memberEnrollmentStore = new MemberEnrollmentStore(userBillingInfoStore, authStore, commonStore);
const helpStore = new HelpStore();
const supportStore = new SupportStore();
const conventionProgramStore = new ConventionProgramStore();
const shoppingBossStore = new ShoppingBossStore();
const subscriptionStore = new SubscriptionStore();
const seminarWebinarStore = new SeminarWebinarStore();
const transactionsStore = new TransactionsStore();
const identityVerificationStore = new IdentityVerificationStore();
const lifelineStore = new LifelineStore();

const stores = {
    authStore,
    audioStore,
    bookStore,
    commonStore,
    libraryStore,
    menuStore,
    mediaHandlers,
    newsStore,
    packsStore,
    seriesStore,
    salesAchieverAudiosStore,
    speakerStore,
    statStore,
    videoStore,
    userProfileStore,
    userShippingInfoStore,
    userBillingInfoStore,
    spouseProfileStore,
    sendOutStore,
    enrollmentStore,
    memberEnrollmentStore,
    shopStore,
    shareStore,
    onboardStore,
    helpStore,
    supportStore,
    conventionProgramStore,
    shoppingBossStore,
    subscriptionStore,
    seminarWebinarStore,
    transactionsStore,
    lifelineStore,
    identityVerificationStore
};



export default stores;