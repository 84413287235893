import React from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {getUniqKey, joinPath} from "../../utils/helpers";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position:"relative",
        color: "white",
        flexDirection:"column",
        marginBottom:20,
    },
    cardBody:{
        padding: 20,
        paddingBottom: 0,
        borderRadius: 20
    },
    link:{
        textDecoration:"none",
        color: "white",
        display:"inline-flex",
        alignItems:"center",
        justifyContent:"center",
        width:100,
        marginBottom: 20,
        "&:hover":{
            color:"white",
            textDecoration:"none",
        }
    },
    subitems:{
        display: "flex",
    },
    icon:{
        width:50,
        height:50
    },
    text:{
        display:"inline-block",
        overflow:"hidden",
        textOverflow:"ellipsis",
        width:"100%",
        padding: 7,
        [theme.breakpoints.down('sm')]: {
            fontSize:"small"
        },
    }
}));

export default observer(({def, parentMatch}) => {
    const classes = useStyles();

    return def   && <Grid  item xs={12} className={classes.root} key={def.tag}>
        <Typography variant="subtitle1">{def.title}</Typography>
        <Grid  item container className={classes.cardBody}>
            {def.subsection.map(x=>{
            return <Grid item xs={6} sm ={4}
                         key={getUniqKey(x)}
                         component={Link} to={joinPath(parentMatch.url, getUniqKey(def), getUniqKey(x))}
                         className={classes.link}>
                   <Avatar src={x.thumbnail} variant={"rounded"} className={classes.icon}/>
                    <Typography variant="subtitle1"  className={classes.text}> {x.title}</Typography>
            </Grid>
        })}
        </Grid>

    </Grid>
})


