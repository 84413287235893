import { makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import shippingAddressForm from "../forms/shippingAddress";
import {generateDeleteEntity, generateLoadList} from "../utils/mobx";

class UserShippingInfoStore {
    constructor(commonStore) {
        makeAutoObservable(this)
        this.commonStore =commonStore
    }

    addresses = []
    removingAddresses = []
    addressesLoading = false
    error = null
    addressForm = null
    editingAddress = null
    suggestions = null

    setError( error, type = "userProfile") {
        error = extractErrorMessage(error)
        this.error = error
     }

    setEditForm(country, addressID, onSaved ){
        this.suggestions = null
        this.addressForm = new shippingAddressForm( {
            onSuccess: async (form)=>{
                let result;
                let values = form.values()
                try {
                    await services.Utility.verifyAddess({
                        street1: values.address1,
                        street2: values.address2,
                        city: values.city,
                        state: values.region,
                        zip: values.zip
                    })
                }
                catch (e) {
                    if ( e.response.status === 404 )
                        this.setError( "USPS could not validate your address. Please enter a valid address. ");
                    else if( e.response.status === 409 ) {
                        this.suggestions = e.response.data
                    }
                    return
                }
                console.log(country)
                if ( this.editingAddress ){
                    result = await  services.UserProfile.AddressService().update({ ...values, country, addressID } , "shipping" )
                }else{
                     result = await  services.UserProfile.AddressService().create( {...values, country} )
                }
                if (onSaved)
                    onSaved(result)
            },
            onError:(form) =>{
                this.setError( form.errors() );
            }
        } )
        if ( addressID!=null ){
            let address = this.addresses.find(x=> parseInt(addressID) ===  x.addressID)

            if (address) {
                this.editingAddress = address;
                this.addressForm.update({...address, country})
            }
        }

    }

    loadAddresses = generateLoadList( "addresses", this, "addressesLoading"
        ,  ()=>{ return services.UserProfile.AddressService().list() }
        , "addresses" )

    removeAddress = generateDeleteEntity( "addresses", this, "removingAddresses", (addressID)=>{
        return services.UserProfile.AddressService().delete( { addressID} )
    } )

}

export default UserShippingInfoStore