import React, {useMemo,} from "react";
import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core';
import menu from './menu.json';
import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import IFrame from "../../components/IFrame";
import LinksMenu from "./LinksMenu";
import Onboard from "../Members/Onboard";
import {joinPath} from "../../utils/helpers";
import {Route} from "react-router";
import SendOut from "../Members/SendOut";
import Policies from "../Profile/Policies";
import Transactions from "../Profile/Transactions";
import SeminarWebinar from "../Events/SeminarWebinar";

function Members ( {parentMatch} ){
    const match = useRouteMatch();
    const MainLinks = useMemo(()=>{
         return  menu.find(x=>x.tag==="members").items
    },[menu])


    return (
        <Container>
            <>
                <Switch>
                    <Route path={joinPath(match.url, `/marketingsystem/purchase_sendout/:cart_guid`)} render={props => {
                        return <IFrame tag={"marketingsystem/purchase_sendout"} url={`https://shop.lifeinfoapp.com/checkout/index.html?cart_guid=${props.match.params.cart_guid}`} />;
                    }} />
                    <Route path={joinPath(match.url, `/onboard/:tag`)} render={props => {
                        return <Onboard parentMatch={match} navigation={"inplace"}
                                        showLogoImage={false}  showBottomTitle={ props.match.params.tag !=="compensation"} />;
                    }} />
                    <Route
                        path={joinPath(match.url, `/mspremium/create_product_sendout`)}
                        render={() => {
                            return <SendOut parentMatch={match} />;
                        }}
                    />
                    <Route  path={joinPath(match.url, `/profile/policies`)}
                           render={() => {  return <Policies />  }} />

                    <Route  path={joinPath(match.url, `/profile/transactions`)}
                           render={() => {  return <Transactions /> }} />

                    }} />
                    <Route path={joinPath(match.url, `/events/seminar_webinar/:year?/:month?`)} render={props => {
                        return <SeminarWebinar />
                    }} />

                    <Route
                        path={joinPath(match.url, `/explorelife/welcome`)}
                        render={() => {
                            return <Onboard parentMatch={match} tagAsParam={"getstarted"} navigation={"inplace"}
                                            showLogoImage={false}  showBottomTitle/>
                        }}
                    />
                    <Route
                        path={joinPath(match.url, `/explorelife/compensation`)}
                        render={() => {
                            return <Onboard parentMatch={match} tagAsParam={"compensation"} navigation={"inplace"}
                                            showLogoImage={false}  showBottomTitle/>
                        }}
                    />
                    <Route render={props => {
                        return  <LinksMenu items={MainLinks} showBack={false}/>
                    }}/>

                </Switch>
            </>
        </Container>
    );
}

export default observer(Members);