import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import {BottomNavigation, BottomNavigationAction, Box, Container, Divider, Icon, makeStyles} from '@material-ui/core';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router';
import {Observer, observer} from 'mobx-react-lite';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import '../../css/owl.scss';
import BalanceNotification from '../Starfish/BalanceNotification';
import ShoppingBoss from '../shoppingboss';
import { useStores } from '../../hooks/use-stores';
import StarfishHeader from '../../components/Header';
import EWallet from '../../containers/Wallet/eWallet';
import LibraryPack from '../../containers/LibraryPack';
import Wallet from '../Starfish/Wallet';
import Profile from '../Profile';
import StarfishProfile from '../Starfish/Profile';
import News from '../News';
import ShareMenu from './Share';
import Life from "../Starfish/Life";
import { useRouteMatch } from "react-router-dom";
import NewsPost from '../NewsPost';
import {Alert} from "@material-ui/lab";
import { starFishColor } from "../../components/styles";
import ItemWrapper from "../ItemWrapper";
import MediaDecision from "../MediaDecision";
import VideoModal from "../VideoModal";
import Royal from "./Royal";
import UserProfile from "./Profile";
import Members from "./Members";
import TopBanner from "../../components/TopBanner";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import {common} from "@material-ui/core/colors";
import {joinPath} from "../../utils/helpers";
import IFrame from "../../components/IFrame";
import Lifeline from "../Lifeline";
import ShareEnrollment from "../ShareEnrollment";
import clsx from "clsx";
import GoGetter from "./GoGetter";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import EpubView from "../../components/epub";
import Dialog from "@material-ui/core/Dialog";
import TopGun from "./TopGun";
import MarketingSystem from "./MarketingSystem";
import ShopHome from "../shop";
import StarGetter from "./StarGetter";
import PowerPlayer from "./PowerPlayer";

const useStyles = makeStyles((theme) => ({
    container: {
        background: 'black',
        //height: '100vh',
        color: 'white',
        padding: 0,
        margin: 0,
        maxWidth: '100%',
    },
    routeContainer: {
        marginTop: '20px',
        paddingBottom: '75px',
    },
    contentWrapper: {
        paddingTop: '58px', // compensate for the header height
        paddingLeft: '1px',
        paddingRight: '1px',
    },
    content: {
        width: '100%',
        height: '100%',
        paddingLeft: '1px',
        paddingRight: '1px'

    },
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        '@media all and (display-mode: standalone)': {
            height: '80px',
            //add padding to the bottom bar
            paddingBottom: '20px'
        }
    },
    banner: {
        display: "block",
        cursor: 'pointer',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            height: 240,
        }
    },
    bannerImage: {
        height: 240,
        width: "auto !important",
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            width: "100vw !important",
        },
    },
    lifeIframe: {
        width: "100%",
        height: "100%",
        border: 0
    },
    giftCardStatsRoot:{
        display:"flex",
        padding:0,
        justifyContent:"center",
        ...starFishColor(theme, "backgroundColor"),
        marginBottom: theme.spacing(3),
    },
    giftCardStats:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    left:{
        display:"inline-flex",
        paddingRight: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            fontSize:14,
        },
    },
    right:{
        display:"inline-flex",
        paddingLeft: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            fontSize:14,
        },
    }
}));

const carouselOptions = {
    items: 1,
    nav: false,
    center: true,
    autoWidth: true,
    rewind: true,
    autoplay: false
};

function RoyalStarfishContainer({ isPhone }) {
    const { t } = useTranslation();
    const { commonStore, authStore, statStore, shoppingBossStore, identityVerificationStore, mediaHandlers, menuStore, libraryStore, bookStore } = useStores();
    const { mediaURL: bookUrl, mediaTitle: bookTitle } = bookStore;
    const { countryCode, usBanners, caBanners } = shoppingBossStore;
    const { giftCardsStats } = statStore;
    const { notificationsCounter } = commonStore;
    const { currentUser } = authStore;
    const { identityVerificationStatus } = identityVerificationStore;
    const { menu, currentBanner } = menuStore.mainMenu;
    const { detailsLoadingFor } = libraryStore;
    const location = useLocation();
    const match = useRouteMatch();
    const lifeMatch = useRouteMatch(`${match.url}/life`);
    const starFishHome = useRouteMatch( {path: `/royalstarfish`, exact: true });
    const starFishWallet = useRouteMatch( {path: `/royalstarfish/wallet`, exact: true });
    const starFishMembers = useRouteMatch( {path: `/royalstarfish/members`, exact: true });
    const ref = React.useRef(0);
    const classes = useStyles();
    const history = useHistory();
    const [canLoad, setCanLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [banners, setBanners] = useState([]);
    const [media, setMedia] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);


    const goHome = useCallback(() => {
        setCurrentPage(0);
        history.push('/royalstarfish');
    }, [currentPage]);

    const goRoyal = useCallback(() => {
        setCurrentPage(5);
        history.push('/royalstarfish/royal');
    }, [currentPage]);

    const goMembers = useCallback(() => {
        setCurrentPage(6);
        history.push('/royalstarfish/members');
    }, [currentPage]);

    const goWallet = useCallback(() => {
        setCurrentPage(1);
        history.push('/royalstarfish/wallet');
    }, [currentPage]);

    const goLife = useCallback(() => {
        setCurrentPage(2);
        history.push('/royalstarfish/life');
    }, [currentPage]);

    const goProfile = useCallback(() => {
        setCurrentPage(3);
        history.push('/royalstarfish/userprofile');
    }, [currentPage]);

    const goPodcast = useCallback(() => {
        setCurrentPage(4);
        history.push('/royalstarfish/podcast');
    }, [currentPage]);

    const handleLogout = useCallback(() => {
        authStore.logout();
        history.push('/');
    }, []);

    const addMoney = useCallback(() => {
        history.push('/royalstarfish/add_more_money');
    }, []);

    useEffect(() => {
        void shoppingBossStore.getBanners();
        void identityVerificationStore.checkIdentityVerification();
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isFailedKyc = React.useMemo(() => {
        return ["CLOSED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    useEffect(() => {
        if (countryCode === 'CA') {
            setBanners(caBanners);
        }
        else {
            setBanners(usBanners);
        }
    }, [usBanners, caBanners, countryCode]);

    const onClickBanner = (event, merchant) => {
        event.preventDefault();
        const canProceed = handleMerchantSelectionCanProceed();
        if (!!canProceed) {
            shoppingBossStore.selectedMerchant = merchant;
            history.push(`/starfish/review`);
        }
    };


    const showStarfishBanner = useMemo(() => {
        return  (starFishHome )&& banners && banners.length > 0;
    }, [currentPage, banners, starFishMembers, starFishHome]);

    const playMedia = (url, item) => {
        if (  url.indexOf("player.vimeo.com")>-1)
            setMedia({vimeo:true,  ...item})
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(getPage(location.pathname));
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0); // reset scroll in case returning from another page
        (async () => {
            try {
                await shoppingBossStore.fetchUserBalance();
                setCanLoad(true);
            } catch (e) {
                if (!!e && (typeof e == 'string' ? e : e.message)?.includes('account has not been created')) {
                    return history.push(`/onboarding/shopping_boss`);
                }
            }
        })();
    }, []);


    const getPage = (route) => {
        // These are routes that aren't really part of Starfish - give them a page corresponding somewhat
        // the category the belong to
        if (route.includes('news') || route.includes('share')) {
            return 3;
        } else if (route.includes('add_more_money')) {
            return 1;
        } else if (route.includes('wallet')) {
            return 1;
        } else if (route.includes('life')) {
            return 2;
        } else if (route.includes('profile')) {
            return 3;
        } else if (route.includes('podcast')) {
            return 4;
        }
        else if (route.includes('royalstarfish/royal') ) {
            return 5;
        } else if (route.includes('members')) {
            return 6;
        } else {
            return 0;
        }
    };

    if ( !currentUser.isSuperAppPlus ){
        return <Redirect to="/starfish" />
    }


    const handleBellMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBellMenuClose = () => {
        setAnchorEl(null);
    };

    const showConventionAnn = () => {
        handleBellMenuClose();

        history.push(joinPath( match.path, "conventionnews"));
    };

    const showLifeLineAnn = () => {
        handleBellMenuClose();

        history.push(joinPath( match.path, "lifeline"));
    };

    const showNews = () => {
        handleBellMenuClose();
        history.push(joinPath( match.path, "news"));
    };

    const menuId = 'notifications-menu';
    const isMenuOpen = Boolean(anchorEl);

    const handleMerchantSelectionCanProceed = () => {
        if (!!isFailedKyc) {
            commonStore.showError("We apologize, but National Brand Stores is currently unavailable for your account. Please contact customer support for more details.");
            return false;
        } else if (!!isUnverified) {
            commonStore.showError("Before making a purchase, we need you to complete the Identity Verification process.", 15000);
            history.push(`/royalstarfish/add_more_money`);
            return false;
        } else {
            return true;
        }
    };



    return (
        <Container className={classes.container}>
            <Menu
                anchorEl={anchorEl}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleBellMenuClose}
            >
                <MenuItem onClick={showLifeLineAnn}><div className={"notif-icon notif-success"}>
                    <Avatar src="https://main.secure.footprint.net/public/lifeappicons/listen_to_life_line.jpg" /></div>Life Line</MenuItem>
                <MenuItem onClick={showNews}><div className={"notif-icon notif-success"}>
                    <Icon style={{ color: common.white }} className="fa fa-newspaper" /></div>News & Announcements</MenuItem>
                <MenuItem onClick={showConventionAnn}><div className={"notif-icon notif-danger"}>
                    <Icon style={{ color: common.white }} className="fa fa-bullhorn" /></div>Convention Announcements</MenuItem>
            </Menu>
            <StarfishHeader
                handleLogout={handleLogout}
                currentUser={authStore?.currentUser}
                isRoyal={currentUser.isSuperAppPlus}
                isPhone={isPhone}
                { ...{  notificationsCounter, handleBellMenuOpen }}
            />
            <Container className={classes.contentWrapper}>
                <Container maxWidth={lifeMatch ? "xl" : "md"} className={classes.content}>
                    {(shoppingBossStore.currentPage == 'merchants' || shoppingBossStore.currentPage == 'review') && (currentPage == 0)
                        && <BalanceNotification onAddMoney={addMoney} balance={shoppingBossStore.balance} />}
                    { (starFishHome ||starFishWallet || starFishMembers)&& giftCardsStats && <Alert variant="filled" color="info" icon={false} classes={{root: classes.giftCardStatsRoot ,message:classes.giftCardStats}}>
                       <Box display="flex" flexDirection="column">
                           <Box display="flex" justifyContent="center">{new Date().toLocaleString( "default", { month: "long" })}</Box>
                           <Box display="flex">
                                <div className={classes.left}>{t('starfish:home.transactions')}:
                                    {giftCardsStats.transactionCount} </div>
                                <Divider orientation="vertical" flexItem />
                                <div className={ clsx( classes.right, {[classes.left]:starFishMembers}) }>{t('starfish:home.perks_earned')}:
                                    ${ (Number( giftCardsStats.perksEarned )||0).toFixed(2) }</div>
                                {starFishMembers && <>
                                    <Divider orientation="vertical" flexItem />
                                    <div className={classes.right}>{t('starfish:home.pv_earned')}: { (Number( giftCardsStats.giftCardPV )||0) }</div>
                                </>}
                           </Box>
                       </Box>
                    </Alert>  }

                    { showStarfishBanner && <OwlCarousel ref={ref} options={carouselOptions}>
                            {banners.map((banner, i) => {
                                return <ItemWrapper  key={`banner-${i}`}  classes={{
                                    link: classes.link,
                                }} item={banner} menu={null}
                                                     baseComponent={"div"}
                                                     lookupInMenu={false}
                                                     onPlay={playMedia} vertical={false}  notMatchedOnClick={(event) => onClickBanner(event, banner.merchant)}>
                                    <div className={classes.banner} key={`banner-${i}`} >
                                        <img className={classes.bannerImage} src={banner.imageURL} />
                                    </div>
                                </ItemWrapper>;
                            })}
                        </OwlCarousel>
                    }

                    {starFishMembers && currentBanner &&
                        <TopBanner banner={currentBanner}
                                   patchUrls={true}
                                   prefix={"/royalstarfish/members"}
                                   menu={menu} match={match} playMedia={playMedia}
                                   detailsLoadingFor={detailsLoadingFor} />
                    }

                    {   <Box className={classes.routeContainer}>
                            <EWallet />
                            <Switch>
                                <Route
                                    path={"/royalstarfish/shop/*"}
                                    render={(p) => {
                                        return <ShopHome shoppingPath={p.match.params[0]}
                                                         shoppingHome={'/royalstarfish/shop'}
                                                         hideNav={true} currentItem={{rest: "" }}  />
                                    }
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/members/go-getter"}
                                    render={() => <GoGetter />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/members/star-getter"}
                                    render={() => <StarGetter />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/members/power_player"}
                                    render={() => <PowerPlayer />
                                    }
                                /><Route
                                    path={"/royalstarfish/members/programs and explanations/power player"}
                                    render={() => <PowerPlayer />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/members/marketing_systems"}
                                    render={() => <MarketingSystem />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/members/top-gun"}
                                    render={() => <TopGun />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/royal"}
                                    render={() => {
                                        return <Royal parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/members"}
                                    render={() => {
                                        return <Members parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/wallet"}
                                    render={() => {
                                        return <Wallet parentMatch={match} isRoyal={true} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/profile/"}
                                    render={() => <StarfishProfile identityVerificationStatus={identityVerificationStatus} />}
                                />
                                <Route
                                    path={"/royalstarfish/share/shareenrollment"}
                                    component={ShareEnrollment} />
                                <Route
                                    path={"/royalstarfish/share"}
                                    render={() => <ShareMenu />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/podcast"}
                                    render={() => <LibraryPack packID="18386" />
                                    }
                                />

                                <Route path={`/royalstarfish/conventionnews`} render={props => {
                                    return <Observer>{() => <IFrame tag={"conventionnews"}></IFrame>}</Observer>;
                                }} />
                                <Route path={`/royalstarfish/lifeline`} component={Lifeline} />

                                <Route
                                    path={"/royalstarfish/news/:id"}
                                    render={() => <NewsPost />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/news"}
                                    render={() => <News />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/life"}
                                    render={() => <Life />}
                                />
                                <Route
                                    path={"/royalstarfish/userprofile/profile/"}
                                    render={() => <Profile />}
                                />
                                <Route
                                    path={"/royalstarfish/userprofile/"}
                                    render={() => <UserProfile identityVerificationStatus={identityVerificationStatus} />}
                                />
                                <Route
                                    path={"/royalstarfish"}
                                    render={() => {
                                        return currentPage == 0 && <ShoppingBoss fromModule="STARFISH" />;
                                    }}
                                />
                            </Switch>
                        </Box>
                    }
                </Container>
            </Container>
            <BottomNavigation
                value={currentPage}
                onChange={(event, newValue) => {
                    setCurrentPage(newValue);
                }}
                showLabels
                className={classes.bottomNav}
            >
                <BottomNavigationAction onClick={goHome} value={0} label={t('starfish:home.home')} icon={<HomeIcon />} />
                <BottomNavigationAction onClick={goWallet} value={1} label={t('starfish:home.wallet')} icon={<AccountBalanceIcon />} />
                <BottomNavigationAction onClick={goPodcast} value={4} label={t('starfish:home.podcast')} icon={<Icon className="fa fa-podcast" />} />
                <BottomNavigationAction onClick={goRoyal} value={5} label={t('starfish:home.royal')}
                                        icon={<img height={24} src={
                                            currentPage === 5
                                                ? `${process.env.PUBLIC_URL}/img/RoyalLogo.png`
                                                : `${process.env.PUBLIC_URL}/img/RoyalLogoWhite.png`

                                        } />} />

                { currentUser.isMember
                    ?  <BottomNavigationAction onClick={goMembers} value={6} label={t('starfish:home.members')} icon={<AccountCircleIcon />} />
                    :  <BottomNavigationAction onClick={goProfile} value={3} label={t('starfish:home.profile')} icon={<AccountCircleIcon />} />}


            </BottomNavigation>
            <VideoModal isPhone={isPhone} />
            {!!bookUrl && <Dialog
                classes={{ paper: classes.epubPaper }}
                open={!!bookUrl} fullWidth maxWidth={"xl"} onClose={e => { bookStore.closeMedia(); }}>
                <Fab size="small" disableRipple style={{ position: "fixed", right: 20, top: 33, zIndex: 111 }} onClick={e => { bookStore.closeMedia(); }}>
                    <CloseIcon /></Fab>
                <EpubView url={bookUrl} title={bookTitle} />
            </Dialog>}

            <MediaDecision media={media}
                           onOpenBook={mediaHandlers.handleBookOpen}
                           onVideoAlbumOpen={mediaHandlers.handleVideoAlbumOpen}
                           onVideoOpen={mediaHandlers.handleVideoOpen} />
        </Container >
    );
};


export default observer(RoyalStarfishContainer);