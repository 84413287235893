import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaskedMaterialTextField from "../inputs/MaskedMaterialTextField";


function PersonalFormUS({form, onSubmit, submitting}) {
    const {t} = useTranslation();
    return form ? <Grid container spacing={3} justify={"center"} alignItems={"center"}>
        <Grid item xs={12} lg={7}>
            <MaskedMaterialTextField
                fullWidth
                margin="normal"
                field={form.$("ssn")}
                InputLabelProps={{
                    shrink: true,
                }}/>
        </Grid>
        {( onSubmit && ( submitting === undefined || !submitting  )) && <Grid item xs={12} lg={7}>
            <Button  onClick={onSubmit} fullWidth variant={"contained"}
                     color="primary">{t("enrollment:continue")}</Button>

        </Grid>}
        { onSubmit && ( submitting ) && <Grid item xs={12} lg={7}>
            <CircularProgress/>
        </Grid>}
    </Grid>:null
}

export default observer(PersonalFormUS)