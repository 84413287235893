import { observer } from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {MenuItem, Select} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cartSummary: {
        width: "100%",
        backgroundColor: "#f8f8f8",
        padding: 7
    },

    itemCheckBox: {
        marginLeft: -32,
        minWidth: "auto"
    },
    icon: {
        cursor: "pointer"
    },

}));


function CartSection({ items, title, onSwitchAlternative, onDelete, onIconClick, isStarfishSignup }) {

    const classes = useStyles();
    const { t } = useTranslation();

    const subTotal = useMemo(() => {
        return items.reduce((a, x) => {
            return a + ((!x.removed && x.totalCost) ? x.totalCost : 0);
        }, 0);
    }, [items]);
    // console.log(items, subTotal)
    return <>
        <Typography variant={"subtitle2"}>{title}</Typography>
        <List>
            {items.map(x => {
                return <ListItem key={x.productID}>
                    {x.canDelete && <ListItemIcon className={classes.itemCheckBox}>
                        <Checkbox
                            style={{ color: '#007BFF' }}
                            edge="start"
                            checked={!x.removed}
                            onClick={e => { onDelete(x, true); }}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>}
                    <ListItemAvatar>
                        <Avatar variant={"square"}
                            className={classes.icon}
                            onClick={e => { onIconClick(x); }}
                            alt={`icon`}
                            src={x.imageURL}
                        />
                    </ListItemAvatar>
                    {
                        !isStarfishSignup
                            ?
                            <>
                                <ListItemText primary={x.productTitle}
                                    secondary={`${x.productPrice}`}/>
                            </>
                            :
                            <>
                                <ListItemText primary={x.productTitle}
                                    secondary={
                                        <>
                                        {x.alternatives
                                            ? <select variant={"filled"}
                                                      onChange={e=>{onSwitchAlternative(x, e.target.value)}}
                                                      value={x.effectiveProductSKU||x.productSKU}>
                                                {x.alternatives.map(a=>{
                                                    return <option selected={ x.effectiveProductSKU===a.productSKU || x.productSKU === a.productSKU }
                                                                   key={a.productSKU}
                                                                   value={a.productSKU}
                                                                   >{a.productPrice}</option>
                                                }) }
                                            </select>
                                            : <>{x.productPrice}</>}
                                        </>}
                                         />

                            </>
                    }
                    <ListItemSecondaryAction>
                        {(x.canDelete && !x.removed) && <IconButton style={{ color: '#007BFF' }} onClick={e => { onDelete(x, false); }}><DeleteIcon /></IconButton>}
                        <select value={x.quantity} disabled>
                            <option>{x.quantity}</option>
                        </select>
                    </ListItemSecondaryAction>
                </ListItem>;
            })}

            <ListItem>

                <ListItemText primary={t("enrollment:subtotal")} />
                <ListItemSecondaryAction>
                    <ListItemText primary={`$${subTotal.toFixed(2)}` || "$0.00"} />
                </ListItemSecondaryAction>
            </ListItem>

        </List>
    </>;

}

export default observer(CartSection);