import { Container, FormControlLabel, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import BalancePanel from "./BalancePanel";
import { getUniqKey } from "../utils/helpers";
import Fab from "@material-ui/core/Fab";
import LinkIcon from "@material-ui/icons/Link";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import IFrame from "./IFrame";
import LibraryPack from "../containers/LibraryPack";
import ItemWrapper from "../containers/ItemWrapper";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getItemUrl } from "../utils/menuUtils";
import {useStores} from "../hooks/use-stores";
import ShopHome from "../containers/shop";

const useStyles = makeStyles((theme) => ({
    mainContent:  (props) => ({
        color: "white",
        backgroundColor: "black",
        flexGrow: 1,
       // ...(props.isNativeApp ?{height: "calc(100vh - 58px)"}:{})
    }),
    menuLink: {
        color: "white",
        textDecoration: "none"
    },

    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    thumb: {
        width: 48,
        maxHeight: 48
    },
    links: {
        marginTop: 12
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    thumbContainer: {
        position: "relative",
        display: "flex",
        marginRight: 12,
        minWidth: "auto"
    },

    loadOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }

}));
export default observer(({ error, showBalance = true, linkPrefix = "", className, statistics, path, currentUser
    , currentItem
    , detailsLoadingFor
    , playMedia
    , currentItemUrl, currentItemSKU, isCurrentItemOpensWithoutIframe
    , isCurrentItemExternal, currentLinks, match }) => {
    const { commonStore } = useStores();
    const { isNativeApp } = commonStore;
    const classes = useStyles({ isNativeApp });
    useEffect(() => {
        if (isCurrentItemExternal && isCurrentItemOpensWithoutIframe) {
            window.location = currentItemUrl;
        }
    }, [isCurrentItemExternal, currentItemUrl, isCurrentItemOpensWithoutIframe]);

    if (isCurrentItemExternal && isCurrentItemOpensWithoutIframe) {
        return null;
    }
    if ( currentItem.shopping ) {
        return <ShopHome shoppingPath={currentItem.shoppingPath} hideNav={true} currentItem={currentItem}/>
    }
    return <div className={clsx(classes.mainContent, classes.grow, className)}>
        {currentItemSKU
            ? <LibraryPack packID={currentItemSKU} />
            : <>{isCurrentItemExternal
                ? <IFrame url={currentItemUrl} withExternalTitle={true} />
                : <Container>
                    <Grid container>
                        {error ? <Grid container item xs={12}>
                            <Alert severity="error">{error}</Alert>
                        </Grid> : null}
                        {(statistics && !path && showBalance) && <BalancePanel user={currentUser} statistics={statistics} />}
                        <Grid item container spacing={2} xs={12} className={classes.links}>
                            {currentLinks.map((x, i) => {
                                let itemUrl = getItemUrl(x);
                                const mediaID = itemUrl && itemUrl.startsWith("playmedia://") && itemUrl.substr("playmedia://".length);
                                return <Grid item xs={12}
                                    key={i + "_" + getUniqKey(x)}
                                    className={classes.menuLink}>
                                    <ItemWrapper def={currentItem} linkPrefix={linkPrefix} item={x} parentMatch={match} onPlay={playMedia} vertical={false}>
                                        <FormControlLabel control={
                                            (x.thumbnail && x.tag !== "myfavorites")
                                                ? <ListItemAvatar className={classes.thumbContainer}>
                                                    <>
                                                        <img className={classes.thumb} src={x.thumbnail} alt={"icon"} />
                                                        {detailsLoadingFor && detailsLoadingFor.includes(mediaID) &&
                                                            <div className={classes.loadOverlay}>
                                                                <CircularProgress color={"secondary"} thickness={3} />
                                                            </div>}
                                                    </>
                                                </ListItemAvatar>
                                                : <Fab className={classes.thumb} color="secondary" size="medium"><LinkIcon /></Fab>
                                        } label={x.title} />
                                    </ItemWrapper>
                                </Grid>;

                            })}
                        </Grid>

                    </Grid>
                </Container>}
            </>}

    </div>;

});