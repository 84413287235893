import React, {Component, useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import {observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {Link} from "react-router-dom";
import LinkWrapper from "./LinkWrapper";


const useStyles = makeStyles((theme) => ({
  playIcon:{
    fontSize: 35,
    color: "#fff",
    cursor: "pointer"
  },
  audioTitle:{
    flexGrow: 1,
    paddingLeft: 20,
    color: "white"
  },
  audioImg:{
    height: 50,
    width: 50,
  },
  audioIndicator:{

  },
  hidden:{
    display:"none"
  },
  typeAndPlay:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    paddingRight: 40,
    paddingLeft: 20,
  }
}))



function MediaListItem  ({shoppingMatch, showType, audioItem, playTrackAudio, pauseTrack, idx, currentIdx}) {
 // static audioTrack;

  const  [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const  playIndicatorRef = useRef();
  const  {t} = useTranslation();
  const  classes = useStyles();
  useEffect(()=> {
        return () => {
          pauseTrack(idx);
        }
      }
  ,[])


  const playTrack = (track, idx, e) => {
    if (audioIsPlaying && idx === currentIdx) {
      pauseTrack(idx)
      setAudioIsPlaying( false )
    } else {
      playTrackAudio(track, idx)
      setAudioIsPlaying( true )
    }
  }
    return (
      <Grid container item>
        <Grid  item xs={12} container alignItems={"center"}>
          {
            audioItem.mediaURL.length < 1 ? (
              <>
                <Typography >{t(`shopping:audiomodal.audio`)}</Typography>
                <img className={classes.audioImg}  src={audioItem.imageURL} />
              </>
            ) : (
                <>
                 <div className={classes.typeAndPlay} > {( showType && audioItem.productSubType) &&
                    <div>{t(`shopping:audiomodal.${audioItem.productSubType}`)}</div>}
                  <i
                    onClick={ (e) => {
                      playTrack(audioItem.mediaURL, idx, e)
                    }}
                    className={clsx( "fal", {"fa-play-circle": !audioIsPlaying || ( idx!== currentIdx) , "fa-pause-circle": audioIsPlaying && ( idx=== currentIdx)} , classes.playIcon )}>
                  </i>
                 </div>
                  <LinkWrapper audioItem={audioItem} shoppingMatch={shoppingMatch}><img className={classes.audioImg} src={audioItem.imageURL} /></LinkWrapper>
                </>
              )
          }

          <LinkWrapper audioItem={audioItem} shoppingMatch={shoppingMatch} className={classes.audioTitle}>
            <Typography className={classes.audioTitle} variant={"subtitle1"}>{audioItem.productName}</Typography>
          </LinkWrapper>
          <img
            alt="play indicator"
            ref={playIndicatorRef}
            className={clsx( classes.audioIndicator, { [classes.hidden]: !audioIsPlaying || ( idx!== currentIdx) } )}
            src="https://m.media-amazon.com/images/G/01/digital/music/player/web/dragonfly/eqSmBlueLoop.gif">
          </img>
        </Grid>
      </Grid>

    )

}

export default observer(MediaListItem);
