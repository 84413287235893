import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Typography,
    makeStyles,
} from "@material-ui/core";
import Send from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
    logoImg: {
        width: '100px',
        verticalAlign: 'middle'
    },
    appBar: {
        backgroundColor: 'inherit',
        color: 'black',
        boxShadow: 'none'
    },
    navlinks: {
        '& > button': {
            color: 'white',
            backgroundColor: 'black',
            textDecoration: "none",
            borderRadius: '20px',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        }
    },
    logoWrapper: {
        flexGrow: 1,
        flexWrap: 'nowrap',
    },
    sendNewMessage: {
        display: 'flex',
        fontSize: '.6rem',
        whiteSpace: "pre-line",
        [theme.breakpoints.up('md')]: {
            fontSize: '.8rem',
            display: 'block'
        },
    }
}));

const Header = ({ onSendNewMessage }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Box variant="h4" className={classes.logoWrapper}>
                <img src={`${process.env.PUBLIC_URL}/team_talk.png`} className={classes.logoImg} alt="Life Line logo" />
            </Box>
            <div className={classes.navlinks}>
                <Button style={{ borderRadius: '5px' }} onClick={onSendNewMessage} variant="contained">
                    <Send style={{ marginRight: '8px' }} />
                    <Typography className={classes.sendNewMessage} variant="body2">{t('lifeline:home.sendNewMessage')}</Typography>
                </Button>
            </div>
        </Box>
    );
};

export default Header;