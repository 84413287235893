import BaseService from "./BaseService";

class Auth extends BaseService {

    verify() {
        return this.post('/verify')
    }

    verifyConventionGuest() {
        return this.post('/verify/convention/guest')
    }

    async signIn(username, password){

        return this.post("/signin", {username, password})
    }

    async AndroidSignIn(loginGuid){
        return this.post("/signin/android", {}, {headers: { "X-Authorization": `Bearer ${loginGuid}` }})
    }

    checkGuest( guestToken ) {
        return this.post('/verify/guest', {}, {headers: { "Authorization": `Bearer ${guestToken}` }} )
    }

}

export default Auth
