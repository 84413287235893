import {makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";

class BookStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    element = null
    media = null
    error = null

    setError(error, type = "videoPlayer") {
        error = extractErrorMessage(error)
        this.error = error
     }


    async closeMedia( ){
        this.media = null
    }
    async setMedia( media ){
        //if ( media.mediaType !== "video") return
        this.setError(null)
        this.libraryStore.detailsLoadingFor.push( media.mediaID )
        try {
            let details = await services.Library.details (media.mediaID)
            if ( details ){
                runInAction(()=>{
                    this.media = details[0];
                })
            }
            else{
                runInAction(()=>{
                    this.setError("Book not found")
                })
            }
        }
        finally {
            runInAction(() => {
                this.libraryStore.detailsLoadingFor.remove( media.mediaID )
            })
        }
        //console.log( details )
    }
    get mediaURL(){
        return this.media && this.media.mediaURL;
    }
    get mediaID(){
        return this.media && this.media.mediaID;
    }
    get canBuy(){
        return this.media && !this.media.hasPurchased;
    }
    get mediaTitle(){
        return this.media && this.media.title;
    }
    get mediaImageURL(){
        return this.media && this.media.imageURL;
    }
    get mediaSpeakers(){
        return this.media && (this.media.speakers||[]).map(sp=>sp.speakerName).join(",")
    }


}

export default BookStore