import RestService from "./RestService";



export default class LibraryService extends RestService {
    constructor() {
        super('/api/library' )
    }


    async loadSeries(id){
        return this.post( `${this.baseUrl}/series`,{productType: "all", syncDate: null, series: id} )
    }

    async loadSpeaker(id){
        return this.post( `${this.baseUrl}/speaker`,{productType: "all", syncDate: null, speakerID: id} )
    }
    async details(id){
        return this.get( `${this.baseUrl}/details/${id}` )
    }

    async audiosPlayed(){
        return this.get( `${this.baseUrl}/audios/played` )
    }

    async search(searchString){
        return this.get( `${this.baseUrl}/search/${encodeURI( searchString )}` )
    }

    async album(albumID){
        return this.get( `${this.baseUrl}/albums/${albumID}` )
    }

    async audioProgress(audioID, time, completed){
        return this.patch( `${this.baseUrl}/audios/${audioID}/time`, {time, completed} )
    }

}

