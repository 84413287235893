import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import { generateLoadList} from "../utils/mobx";
import keyBy from "lodash/keyBy";

class LibraryStore {
    constructor() {
        makeAutoObservable(this)
    }

    library = []
    audiosPlayed = []
    detailsLoadingFor = []
    libraryLoading = false
    audiosPlayedLoading = false


    path = ""
    searchString = ""
    error = null

    setError(error, type = "library") {
        error = extractErrorMessage(error)
        this.error = error
     }

    setSearchString(value) {
        this.searchString = value
     }
    addDetailsLoadingFor(value) {
        this.detailsLoadingFor.push( value )
     }
    removeDetailsLoadingFor(value) {
        this.detailsLoadingFor.remove( value )
     }


    loadLibrary = ()=>{
        return Promise.all( [ this.loadBaseLibrary(),
        this.loadAudiosPlayed()])
    }


    loadBaseLibrary     = generateLoadList( "library", this, "libraryLoading"
        , ()=>{ return services.Library.list() }, "library", )


    loadAudiosPlayed = generateLoadList( "audiosPlayed", this, "audiosPlayedLoading"
        , ()=>{ return services.Library.audiosPlayed() }, "audiosPlayed", )


    get playedIndex (){
        return keyBy( this.audiosPlayed, "mediaID" )
    }

    get series (){
        return this.library.length>0 ? this.library[0].series:[]
    }

    get speakers (){
        return this.library.length>1 ? this.library[1].speakers:[]
    }

    get recentlyPurchased (){
        return this.library.length>3 ? this.library[3].recentlyPurchased:[]
    }

    get recentlyPlayed (){
        return this.library.length>3 ? this.library[2].recentlyPlayed:[]
    }

}

export default LibraryStore