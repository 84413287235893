import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {isAudio, isBook, isPack, isVideo, isTypeObservable, isVimeo, isVimeoAlbum} from "../utils/mediaUtils";
import services from "../services";
import {useStores} from "../hooks/use-stores";
import {runInAction} from "mobx";


export default observer(({media, onSelect, onVideoOpen, onVideoAlbumOpen, onOpenBook, onAudioPlay, playMatch }) => {

    const history = useHistory();
    const { libraryStore } = useStores();
    const fetchIdRef = React.useRef(0)

    const fetchMediaType = React.useCallback( async (media)=>{
        const fetchId = ++fetchIdRef.current
        try {
            libraryStore.addDetailsLoadingFor( media.mediaID )
            let result = await services.Library.details(media.mediaID)
            if (fetchId === fetchIdRef.current && result && result[0]) {
                handleMedia( result[0] )
            }
        }
        catch(e){
            if (fetchId === fetchIdRef.current) {
            }
        }
        finally{
            runInAction(() => {
                libraryStore.removeDetailsLoadingFor( media.mediaID )
            })
        }
    })

    const handleMedia = (media)=>{
        if ( onSelect ){
            onSelect(media);
            return
        }

        if ( isPack(media) ){
            history.push( `/library/packs/${media.mediaID}`)
            return;
        }
        else if ( isVimeoAlbum(media) ) {
            onVideoAlbumOpen(media)
        }
        else if ( isVideo(media)|| isVimeo(media) ) {
            onVideoOpen(media)
        }
        else if ( isAudio(media) ){
            onAudioPlay(media)
        }
        else if ( isBook(media) ){
            onOpenBook(media)
        }
        if ( playMatch && media.seriesValue ){
             history.push( `/library/series/` + media.seriesValue)
         }
        else if ( playMatch  ){
             history.push( `/library`)
         }

    }

    const handleMediaChange = async (media)=>{
        if ( !media ) return
        if ( isTypeObservable ( media) )
            handleMedia(media)
        else
            fetchMediaType(media)

    }
    useEffect(()=>{
        handleMediaChange(media)
    }, [media])


    return <></>
})


