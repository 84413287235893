import React  from 'react';
import {useHistory} from "react-router"
import {useTranslation} from 'react-i18next';
import {observer} from "mobx-react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";



const useStyles = makeStyles((theme) => ({
    noProductAvailable:{
        width:"100%",
        minHeight:300
    }
}))


function NoProductFound ({error}) {
  //
  // onCountryShoppingHandler = () => {
  //   let access_token = getCookie('access_token');
  //   let isGuest = getCookie('is_guest');
  //   if (isGuest) {
  //     const country = JSON.parse(getCookie('guest_country'));
  //     if (!country.countryValue || !country.customerLevelID  || !country.marketID) {
  //       deleteCookie("access_token");
  //       deleteCookie("cart_guid");
  //       deleteCookie("selected_lang");
  //       deleteCookie("guest_country");
  //       deleteCookie("guest_region");
  //       deleteCookie("is_guest");
  //       deleteCookie("is_quick_buy");
  //       this.props.history.push('/login');
  //     }
  //     this.props.getBaselineLoad(access_token, country.countryValue);
  //     this.props.getFeaturedProducts(access_token, this.props.langApi, country.customerLevelID, country.marketID);
  //     this.props.getPromotionalProducts(access_token, this.props.langApi, country.customerLevelID, country.marketID);
  //   } else {
  //     this.props.getBaselineLoad(access_token, '');
  //     this.props.getFeaturedProducts(access_token, this.props.langApi);
  //     this.props.getPromotionalProducts(access_token, this.props.langApi);
  //   }
  //   if (!isGuest && this.props.userDetails[0].allowToBuy === false) {
  //     this.props.history.push('/category/790');
  //   } else this.props.history.push('/')
  // }

  const history = useHistory()
  const classes = useStyles()
  const onCountryShoppingHandler = () => {
   history.push('/')
  }



    const {t  } =  useTranslation()
    return (
      <Grid container justify={"center"} className={ classes.noProductAvailable} alignItems={"center"} spacing={5} direction={"column"}>
          <Grid item><Typography variant={"h6"} >{t('shopping:error.productnotfound')}</Typography></Grid>
          <Grid item><Typography variant={"subtitle1"}>{error}</Typography></Grid>
          <Grid item>
              <Button variant={"contained"} color={"primary"}  onClick={() => onCountryShoppingHandler()}
                className="btn btn-primary">{t('shopping:error.homepageredirect')}</Button></Grid>
      </Grid>
    )

}


export default observer(NoProductFound)