import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Box, Button, CircularProgress, Container, Grid, IconButton, makeStyles, Slider, Typography, withStyles } from '@material-ui/core';
import Pause from '@material-ui/icons/Pause';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import Delete from '@material-ui/icons/Delete';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CloseIcon from '@material-ui/icons/Close';
import { useStores } from '../../../hooks/use-stores';

const MEDIATYPE = {
    AUDIO: 'Audio',
    VIDEO: 'Video'
}

const useStyles = makeStyles((theme) => ({
    parent: {
        position: 'relative',
        marginBottom: 0,
        padding: 0
    },
    closeButton: {
        padding: theme.spacing(2),
        color: 'white'
    },
    backdrop: {
        position: 'absolute',
        zIndex: 2,
        color: '#fff',
    },
    button: {
        color: 'white',
        fontSize: '1rem',
        padding: theme.spacing(.5),
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
            padding: theme.spacing(1),
        }
    },
    buttonIcon: {
        fontSize: '2.5rem',
    },
    buttonIconLarge: {
        fontSize: '3rem',
    },
    controlWrapper: {
        backgroundColor: '#474747',
    },
    commentAuthor: {
        textAlign: 'center',
        userSelect: 'none',
        color: 'white',
        margin: theme.spacing(.5),
        marginTop: '10px',
        marginBottom: '-10px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '-15px'
        }
    },
    messageFrom: {
        fontWeight: 'bold'
    },
    timeDisplay: {
        padding: theme.spacing(.5),
        paddingTop: 0,
        color: 'white',
        userSelect: 'none'
    },
    video: {
        width: '100%',
        height: 'auto',
        maxHeight: '50vh',
        marginBottom: `-${theme.spacing(.5)}px`,
        [theme.breakpoints.up('sm')]: {
            maxHeight: '50vh',
        }
    },
}));

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '4px 0',
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -10,
        marginLeft: -10,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 4,
    },
    rail: {
        height: 4,
        opacity: 0.8,
    },
})(Slider);

const sliderStyles = makeStyles(({
    override: {
        '& .MuiSlider-rail': {
            background: ({ bufferedWidth }) => `linear-gradient(to right, rgba(0, 125, 181, 0.6) ${bufferedWidth}, rgba(0, 125, 181, 0.2) ${bufferedWidth})`
        }
    }
}));

const StyledInput = ({
    onChange,
    value,
    bufferedWidth,
    max
}) => {
    let bufferedPercent = 0;
    if (max > 0) {
        bufferedPercent = bufferedWidth / max * 100
    }
    const classes = sliderStyles({ bufferedWidth: `${bufferedPercent}%` });
    return <IOSSlider className={classes.override} max={max} type="range" onChange={onChange} value={value} />
}


const AudioVideoPlayer = ({ onPlayed, thumbnail, video, audio, openDeleteModal, onRespond }) => {
    const { t } = useTranslation();
    const { lifelineStore } = useStores();
    let requestRef = useRef(null);

    const updatingPlayer = useRef(false);
    const classes = useStyles();
    const audioIndexRef = useRef(0);
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const hasStartedPlaying = useRef(false); // used to detect if we've started playing the media at least once

    const mediaType = useRef(null)
    const [mediaAuthor, setMediaAuthor] = useState(null)

    const [playing, setPlaying] = useState(false); // used to update visuals
    const [currentSliderValue, setCurrentSliderValue] = useState(0);
    const [sliderMax, setSliderMax] = useState(0);
    const [bufferedAmount, setBufferedAmount] = useState(0);
    const [buffering, setBuffering] = useState(false);
    const [mediaInProgress, setMediaInProgress] = useState(false);
    const [currentDuration, setCurrentDuration] = useState(null);

    const [videoStart, setVideoStart] = useState(false);

    const [optionsOpen, setOptionsOpen] = useState(false);

    const setAudioListener = () => {
        nextAudio();
    }

    const nextAudio = () => {
        setBuffering(true);
        setPlaying(false);
        hasStartedPlaying.current = false;

        if (audioIndexRef.current < audio.length) {
            audioRef.current = new Audio(audio[audioIndexRef.current].Tag);
            mediaType.current = MEDIATYPE.AUDIO;
            setMediaAuthor((cur) => `${audio[audioIndexRef.current].FirstName} ${audio[audioIndexRef.current].LastName}`);

            // This is needed because chrome sucks
            audioRef.current?.addEventListener("durationchange", (e) => {
                if (audioRef.current.duration != Infinity) {
                    setSliderMax(audioRef.current.duration)
                };
            });

            audioRef.current?.addEventListener('loadedmetadata', (e) => {
                setCurrentDuration(calculateTime(audioRef.current?.duration));
                // Copy of canplaythrough - thanks safari
                if (!hasStartedPlaying.current) {
                    hasStartedPlaying.current = true;
                    setCurrentSliderValue(0);
                    audioRef.current.curentTime = 0;

                    setMediaInProgress(true);
                    setBuffering(false);
                    setPlaying(true);
                    audioRef.current?.play();
                }
            });

            audioRef.current?.addEventListener('canplaythrough', () => {
                if (!hasStartedPlaying.current) {
                    hasStartedPlaying.current = true;
                    setCurrentSliderValue(0);
                    audioRef.current.curentTime = 0;

                    setMediaInProgress(true);
                    setBuffering(false);
                    setPlaying(true);
                    audioRef.current?.play();
                }
            });

            audioRef.current?.addEventListener('ended', () => {
                audioIndexRef.current += 1;
                nextAudio();
            });
        } else {
            setVideoStart(true);
        }
    }

    const onChangeSlider = async (e, newValue) => {
        if (mediaType.current == MEDIATYPE.AUDIO) {
            audioRef.current.currentTime = newValue;
        } else {
            videoRef.current.currentTime = newValue;
        }

        setCurrentSliderValue(newValue);
    }

    const startUpdatingPlayer = () => {
        if (!updatingPlayer.current) {
            updatingPlayer.current = true;
            requestAnimationFrame(updatePlayer);
        }
    }

    const stopUpdatingPlayer = () => {
        updatingPlayer.current = false;
        cancelAnimationFrame(requestRef.current);
    }

    // Used to kick off video playing
    const playVideo = () => {
        hasStartedPlaying.current = false;
        setBuffering(true);
        setPlaying(false);

        if (!video.URL) {
            return; // no video to play
        }

        if (!videoRef.current?.src) {
            videoRef.current.src = video.URL;
            videoRef.current.preload = "auto";
        }

        mediaType.current = MEDIATYPE.VIDEO;
        setMediaAuthor(cur => `${video.FirstName} ${video.LastName}`);

        videoRef.current?.addEventListener('loadedmetadata', () => {
            setSliderMax(videoRef.current?.duration)
            setCurrentDuration(calculateTime(videoRef.current?.duration));
        });

        videoRef.current?.addEventListener('ended', () => {
            pause();
        });

        videoRef.current?.addEventListener('canplaythrough', () => {
            if (!hasStartedPlaying.current) {
                setPlaying(true);
                setCurrentSliderValue(0);
                setBuffering(false);

                hasStartedPlaying.current = true;
                videoRef.current.curentTime = 0;
                videoRef.current?.play();
                setMediaInProgress(true);
            }
        });
        videoRef.current.load();

    }

    // Used for responding to pressing the play button
    const play = async () => {
        onPlayed();
        startUpdatingPlayer();

        // If we've already pressed play before, then the
        // media will auto cue and we should control it directly.
        // Because we stop updating the range bar during pauses, we need
        // to reenable it 
        if (mediaInProgress) {
            setPlaying(true);
            if (mediaType.current == MEDIATYPE.AUDIO) {
                await audioRef.current?.play();
            } else {
                await videoRef.current?.play();
            }
        } else {
            // Otherwise, we need to do set up for the media to load itself and play
            // If there is any audio, set up the audio - this happens first time you play
            if (audio.length && !audioRef.current?.src) {
                audioRef.current = 0;
                setAudioListener();
            } // otherwise, set up the video to play directly
            else {
                setVideoStart(true);
            }
        }
    }

    const pause = async () => {
        setPlaying(false);

        if (mediaType.current == MEDIATYPE.AUDIO) {
            await audioRef.current?.pause();
        } else {
            await videoRef.current?.pause();
        }
        stopUpdatingPlayer();
    }

    const showResponse = useCallback((type) => {
        setOptionsOpen(false);
        onRespond(type)
    }, [optionsOpen]);

    const respondPassUp = useCallback(() => {
        showResponse('passUp')
    }, []);

    const respondPassDown = useCallback(() => {
        showResponse('passDown')
    }, []);

    const respondPassDownToGroup = useCallback(() => {
        showResponse('passDownToGroup')
    }, []);

    const respondPassReply = useCallback(() => {
        showResponse('reply')
    }, []);

    const openOptions = useCallback(() => {
        setOptionsOpen(true);
    }, [optionsOpen]);

    const closeOptions = useCallback(() => {
        setOptionsOpen(false);
    }, [optionsOpen]);

    const calculateTime = useCallback((secs) => {
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }, []);

    const updatePlayer = () => {
        const current = (mediaType.current == MEDIATYPE.AUDIO ? audioRef?.current : videoRef?.current);
        if (current) {
            setCurrentSliderValue(current.currentTime);

            let newBufferedAmount = 0;
            if (current.buffered && current.buffered.length > 0) {
                newBufferedAmount = current.buffered.end(current.buffered.length - 1);
                if (newBufferedAmount != bufferedAmount) {
                    setBufferedAmount(newBufferedAmount);
                }
            }
        }


        requestRef.current = requestAnimationFrame(updatePlayer);
    }

    useEffect(() => {
        if (videoStart) {
            playVideo();
        }
    }, [videoStart])

    useEffect(() => {
        return () => {
            pause();
            stopUpdatingPlayer();
        }
    }, [])

    return (
        <Container className={classes.parent}>
            <Backdrop
                className={classes.backdrop}
                open={optionsOpen}
            >
                <Grid container style={{ width: '100%', height: '100%' }} justifyContent="center">
                    <Grid item container style={{ height: '10%', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                        <Button className={classes.closeButton} onClick={closeOptions}>
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <Grid item container style={{ height: '90%', alignItems: 'flex-start' }}>
                        {lifelineStore.settings.CanPassUpline && <Grid container justifyContent="center" alignItems="flex-start" item xs={12}><Button className={classes.button} onClick={respondPassUp}>{t('lifeline:player.passUp')}</Button></Grid>}
                        {lifelineStore.settings.CanPassDownline && <Grid container justifyContent="center" item xs={12}><Button className={classes.button} onClick={respondPassDown}>{t('lifeline:player.passDown')}</Button></Grid>}
                        {lifelineStore.settings.CanPassDownline && <Grid container justifyContent="center" item xs={12}><Button className={classes.button} onClick={respondPassDownToGroup}>{t('lifeline:player.passToGroup')}</Button></Grid>}
                        {lifelineStore.settings.CanPassDownline && <Grid container justifyContent="center" item xs={12}><Button className={classes.button} onClick={respondPassReply}>{t('lifeline:player.reply')}</Button></Grid>}
                    </Grid>
                </Grid>
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={buffering}
            ><CircularProgress /></Backdrop>
            <video
                playsInline
                ref={videoRef}
                className={classes.video}
                poster={thumbnail}
            >
            </video>
            <audio ref={audioRef}>
            </audio>
            <Grid container className={classes.controlWrapper}>
                <Grid item xs={12} container justifyContent="center" alignItems="center" wrap="nowrap">
                    <Typography variant="body1" className={classes.commentAuthor}>
                        {mediaInProgress && <span><span className={classes.messageFrom}>{(`${mediaType.current == MEDIATYPE.VIDEO ? 'Message From:' : 'Comment From:'}`)}&nbsp;</span><span>{mediaAuthor}</span></span>}
                    </Typography>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" wrap="nowrap" style={{ padding: '4px' }}>
                    <IconButton className={classes.button} onClick={() => playing ? pause() : play()}>{playing ? <Pause className={classes.buttonIconLarge} /> : <PlayCircleFilledIcon className={classes.buttonIconLarge} />}</IconButton>
                    <Box style={{ width: '100%', flexGrow: 1, padding: '0px 20px' }}>
                        <Box flexDirection="column">
                            <StyledInput max={sliderMax} onChange={onChangeSlider} value={currentSliderValue} bufferedWidth={bufferedAmount} />
                            {false && <Box display="flex" justifyContent="space-between">
                                <Box component={'span'} className={classes.timeDisplay}>
                                    {currentSliderValue || '0:00'}
                                </Box>
                                <Box component={'span'} className={classes.timeDisplay}>
                                    {currentDuration}
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Box>
                    <IconButton className={classes.button} onClick={openOptions}><PresentToAllIcon className={classes.buttonIcon} /></IconButton>
                    <IconButton className={classes.button} onClick={openDeleteModal}><Delete style={{color: 'red'}} className={classes.buttonIcon} /></IconButton>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AudioVideoPlayer;
