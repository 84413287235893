import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faCheckCircle, faChevronRight} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const SuccessMessageModal = ({ message, onClickHandler }) => {
    const {t} = useTranslation()
    return (

        <Dialog open={true} onClose={onClickHandler} maxWidth={"sm"} fullWidth>
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Typography>{t('Success')}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => onClickHandler()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <Grid container justify={"flex-start"} direction={"column"} alignContent={"center"}>
                    <FontAwesomeIcon icon={faCheckCircle} size="10x" />
                    <p>{(message === null || message === undefined) ? '' : message }</p>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={() => onClickHandler()} className="btn btn-primary">
                    {t('shopping:home.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    )
  }

export default SuccessMessageModal;
