import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import greenButtonTheme from "../themes/greenButton";
import { ThemeProvider } from "@material-ui/styles";
import { isAudio, isPack } from "../utils/mediaUtils";
import { Link } from "react-router-dom";
import { joinPath } from "../utils/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStores } from "../hooks/use-stores";
import MediaDecision from "../containers/MediaDecision";
import { CheckCircle } from "@material-ui/icons";
import { Box, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    divider: {
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    colorWhite: {
        color: "white",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),

    },

    currentItem: {
        color: "#007BFF"
    },

    thumbContainer: {
        marginRight: theme.spacing(2),
        position: "relative"
    },

    loadOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    },
    playedMark: {
        fontSize: 16,
        marginLeft: 5
    },
    playedProgress: {
        width: 75,
        height: 2,
        marginLeft: 5
    }
}));



export default observer(({ items, parentMatch, onSelect
    , onVideoOpen, onOpenBook, canBuy, onAudioPlay, onAudioPause
    , currentMediaID, audioPaused, loginGuid, hidePlay }) => {
    const classes = useStyles();
    const { commonStore, libraryStore } = useStores();
    const { detailsLoadingFor, playedIndex } = libraryStore;
    const [media, setMedia] = React.useState(null);
    canBuy = false;

    const handleMediaButton = (media) => {
        if (currentMediaID === media.mediaID && !audioPaused)
            onAudioPause(media);
        else
            onAudioPlay(media);
    };



    const handleMediaRowClick = async (media) => {
        setMedia(media);
    };


    return <>
        <List>
            {items.map(s => {
                const isCurrentlyPlayed = currentMediaID === s.mediaID;
                const audio = isAudio(s);
                const canPlay = !hidePlay && audio;
                let url = isPack(s) && (parentMatch ? joinPath(parentMatch.url, `packs`, s.mediaID) : `packs/${s.mediaID}`);
                let minutesLeft = Math.max(0, (audio && playedIndex[s.mediaID] && playedIndex[s.mediaID].played === false)
                    ? Math.round((playedIndex[s.mediaID].totalMediaTime - playedIndex[s.mediaID].resumeTime) / 60)
                    : 0);
                return <Fragment key={s.mediaID}><ListItem disableGutters
                    button={!canPlay} component={isPack(s) ? Link : undefined} to={url} onClick={e => { handleMediaRowClick(s); }}>
                    {canPlay
                        && <IconButton className={classes.colorWhite} onClick={e => { handleMediaButton(s); }}>
                            {(isCurrentlyPlayed && !audioPaused) ? <Pause /> : <PlayArrow />}
                        </IconButton>}

                    <ListItemAvatar className={classes.thumbContainer}>
                        <>
                            <Avatar variant="rounded" className={classes.large}
                                alt={` `}
                                src={`${s.imageURL}`}
                            />
                            {detailsLoadingFor && detailsLoadingFor.includes(s.mediaID) &&
                                <div className={classes.loadOverlay}>
                                    <CircularProgress color={"secondary"} thickness={3} />
                                </div>}
                        </>
                    </ListItemAvatar>
                    <ListItemText classes={{ secondary: clsx(classes.colorWhite, { [classes.currentItem]: isCurrentlyPlayed }) }}
                        className={clsx(classes.colorWhite, { [classes.currentItem]: isCurrentlyPlayed })}
                        primary={s.title}
                        secondary={<>{(s.speakers || []).map(sp => sp.speakerName).join(",")}
                            {audio && playedIndex[s.mediaID] && playedIndex[s.mediaID].played
                                && <Box fontSize={13} display={"flex"} alignItems={"center"}>Played <ThemeProvider theme={greenButtonTheme}>
                                    <CheckCircle className={classes.playedMark} color="secondary" />   </ThemeProvider></Box>}
                            {audio && playedIndex[s.mediaID] && playedIndex[s.mediaID].played === false && playedIndex[s.mediaID].resumeTime > 0
                                && <Box fontSize={13} display={"flex"} alignItems={"center"}>
                                    {minutesLeft.toFixed(0)}{" "}
                                    {pluralize("minute", minutesLeft)} left
                                    <ThemeProvider theme={greenButtonTheme}>
                                        <LinearProgress className={classes.playedProgress} variant="determinate" color="secondary" value={100 * (playedIndex[s.mediaID].resumeTime / playedIndex[s.mediaID].totalMediaTime)} />
                                    </ThemeProvider></Box>}
                        </>} />
                    {isCurrentlyPlayed && canBuy && <ListItemSecondaryAction>
                        {
                            !commonStore.onStarfishOrRoyalStarfish &&
                            <ThemeProvider theme={greenButtonTheme}>
                                <Button component={"a"} href={`https://shop.mainhomepage.com/quickcheckout.aspx?login=${loginGuid}&type=product&id=${s.mediaID}`}
                                    variant={"contained"}
                                    color={"secondary"}>Buy Now</Button>
                            </ThemeProvider>
                        }
                    </ListItemSecondaryAction>}

                </ListItem>
                    <Divider className={classes.divider} component="li" />
                </Fragment>;
            })}

        </List>
        <MediaDecision media={media} onSelect={onSelect} onVideoOpen={onVideoOpen} onOpenBook={onOpenBook} onAudioPlay={onAudioPlay} />
    </>;
});


