import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, Card, CircularProgress, Container, makeStyles } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Content from './Content';
import services from '../../services';
import { useStores } from "../../hooks/use-stores";
import ErrorModal from "../../components/ErrorModal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    minHeight: '100vh',
    overflow: 'auto',
    justifyContent: "center",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: '20%',
    maxWidth: '100%',
    padding: 0,
    '& > *': {
      fontFamily: "'Barlow', sans-serif"
    },
  },
  progressContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  information: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      maxWidth: "1024px",
    }
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20
  },
  errorButton: {
    marginTop: theme.spacing(2),
    color: 'black',
    width: 'max-content',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    }
  }
}));

const ContainerWithBackground = (props) => {
  return (
    <Container
      className={props.className}
      style={{ backgroundImage: `url(${props.backgroundImage})` }}
    >{props.children}</Container>
  );
};

const ConventionProgram = () => {
  const { conventionProgramStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const date = params && params.year && params.month ? (`${params.year}/${params.month}`) : '';

  const [headerError, setHeaderError] = useState(null);
  const [bodyError, setBodyError] = useState(null);

  const [vipUpgradeError, setVipUpgradeError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(true);
  const [loadingBody, setLoadingBody] = useState(true);

  const { headerInfo, bodyInfo } = conventionProgramStore;
  const userHasNoTicket = headerInfo && !headerInfo.hasStandardTicket && !headerInfo.hasVIPTicket;

  const loadHeader = async () => {
    if (!conventionProgramStore.headerInfo) {

      setHeaderError(null);
      setLoadingHeader(true);

      try {
        const headerInfo = await services.ConventionProgramService.fetchHeaderInfo(date);
        conventionProgramStore.headerInfo = headerInfo;
      } catch (e) {
        setHeaderError(e);
      } finally {
        setLoadingHeader(false);
      }
    }
  };

  const loadBody = async () => {
    if (!conventionProgramStore.bodyInfo) {
      setBodyError(null);
      setLoadingBody(true);

      try {
        const bodyInfo = await services.ConventionProgramService.fetchBodyInfo(date);
        conventionProgramStore.bodyInfo = bodyInfo;
      } catch (e) {
        setBodyError(e);
      } finally {
        setLoadingBody(false);
      }
    }

  };

  useEffect(() => {
    (async () => {
      await loadHeader();
      await loadBody();
      setInitialLoading(false);
    })();
  }, []);

  const openVipTicketUpgrade = async () => {
    if (headerInfo) {
      let { cart_guid = '' } = await conventionProgramStore.purchaseUpgradeTicket();
      if (!!cart_guid) {
        window.open(`${headerInfo.vipTicketUpgradeUrl}${cart_guid}`);
      } else {
        setVipUpgradeError(true);
      }
    }
  };

  // ensure we're using the most up to date version of our format
  // with the header defined in the language sections
  const isNewestFormat = () => {
    let hasNewestHeaderFormat = true;
    Object.keys(bodyInfo).forEach(language => {
      if (!bodyInfo[language].header) {
        hasNewestHeaderFormat = false;
      }
    });

    return hasNewestHeaderFormat;
  };
  const closeVipErrorModal = () => {
    setVipUpgradeError(false);
  };

  const showBodyInfo = () => {
    // We are treating all tickets as VIP tickets now
    return (
      <>
        <Content
          sections={bodyInfo}
          hasVIPTicket={headerInfo.hasVIPTicket || headerInfo.hasStandardTicket}
          hasFutureTicket={headerInfo.hasFutureTicket}
          vipTicketPurchaseUrl={headerInfo.vipTicketPurchaseUrl}
          openVipTicketUpgrade={openVipTicketUpgrade} />
      </>
    );
  };

  const showBodyNetworkError = () => (
    headerInfo && <Container className={classes.errorContainer} maxWidth="sm">
      <Alert severity="error">{t('conventionProgram:error.fetchingContent')}</Alert>
    </Container>
  );

  const showNoInfoFoundError = () => (
    headerInfo && <Container className={classes.errorContainer} maxWidth="sm">
      <Alert severity="error">{t('conventionProgram:error.notFound')}</Alert>
    </Container>
  );

  return (
    <ContainerWithBackground className={classes.container} backgroundImage={initialLoading ? undefined : headerInfo?.backgroundImageURL}>
      {initialLoading ? (
        <Box className={classes.progressContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Card className={classes.information}>
            {!headerInfo &&
              <Container className={classes.errorContainer} maxWidth="sm">
                <Alert severity="error">{headerError?.response?.status === 404 ? t('conventionProgram:error.notFound') : t('conventionProgram:error.failed')}</Alert>
              </Container>
            }
            { /* We only want to show the body if the header succeeded as well. If only fetching the header succeeded, then show an error  */}
            {headerInfo && bodyInfo && isNewestFormat() ? showBodyInfo() : !isNewestFormat() ? showNoInfoFoundError() : showBodyNetworkError()}
            <ErrorModal allowUserToClose={true} handleClose={closeVipErrorModal} open={vipUpgradeError} error={t('conventionProgram:error.vipTicketUpgradeError')} />)
          </Card>
        </>
      )}
    </ContainerWithBackground>
  );
};

export default ConventionProgram;
