import {makeAutoObservable, runInAction} from 'mobx';
import services from "../services";
import { extractErrorMessage, getUniqKey } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";
import { getCookie } from "../utils/cookieUtil";

import Menu from "./models/Menu";
import MembersMenu from "./models/MembersMenu";
import {showItemNativeAppModal} from "../utils/menuUtils";

class MenuStore {
    constructor(libraryStore, packsStore, commonStore) {
        makeAutoObservable(this);
        this.libraryStore = libraryStore;
        this.packsStore = packsStore;
        this.commonStore = commonStore;
    }

    rawMenu = null;
    webMenu = null;
    debugMenu = null;
    rawMenuLoading = false;
    webMenuLoading = false;
    menuItemsStatus = null;
    forcePDF = null;
    forceNativeAppModal = false;
    showCustomerOnboardingModal = false;
    showNationalBrandstoresModal = false;
    showShoppingBossModal = false;
    error = null;


    get mainMenu() {
        return new Menu(this.rawMenu && this.rawMenu[1], this.libraryStore, this.packsStore);
    }

    get androidMainMenu() {
        let items = this.rawMenu && { ... this.rawMenu[1] };
        if (items) {
            items.section.push({
                "title": "Help & Support",
                "summary": "",
                "tag": "helpandsupport",
                "thumbnail": "https://main.secure.footprint.net/public/icon/v2/life_products.png",
                "url": "https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1742&section=support&ssl=1",
            });
        }
        return new Menu(items, this.libraryStore, this.packsStore);
    }


    get membersMenu() {

        return new MembersMenu(this.rawMenu && this.rawMenu[0], this.libraryStore, this.packsStore);
    }

    get walletMenu() {
        return new Menu(this.rawMenu && this.rawMenu[2], this.libraryStore, this.packsStore);
    }

    setError(error, type = "menu") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    setMenuItemsStatus(menuItemsStatus) {
        this.menuItemsStatus = menuItemsStatus;
    }

    get isCurrentItemOpensWithoutIframe() {
        if (!this.currentItem) return false;
        return this.currentItem.tag === "thingor2travel" || this.currentItem.tag === "thingor2resorts" || this.currentItem.tag === "coupons" || this.currentItem.tag === "shareproduct" || this.currentItem.tag === "bannerconnect" || this.currentItem.tag === "realhomerewards" || this.currentItem.tag === "connect" || this.currentItem.tag === "watertree";
    }

    setForceNativeAppModal(value) {
        this.forceNativeAppModal = value;
    }

    handleCustom(item, currentUser) {
        const tag = getUniqKey(item);
        switch (tag) {
            case "customeronboardingbanner":
                this.showCustomerOnboardingBanner()
                break;

            case "nationalbrandstores":
               // if (  currentUser.hasSBAccess && !currentUser.isShoppingBossSetup )
               //     this.showShoppingBossBanner()
               // else
                    if(  !currentUser.hasSBAccess )
                        this.showNationalBrandstoresBanner()
                break;
            default:
                break;
        }
    }

    showCustomerOnboardingBanner(){
        if ( process.env.REACT_APP_CUSTOMER_ONBOARD )
            this.showCustomerOnboardingModal = true
    }
   closeCustomerOnboardingBanner(){
        this.showCustomerOnboardingModal = false
    }

    showNationalBrandstoresBanner(){
        this.showNationalBrandstoresModal = true
    }
    closeNationalBrandstoresBanner(){
        this.showNationalBrandstoresModal = false
    }

    showShoppingBossBanner(){
        this.showShoppingBossModal = true
    }
    closeShoppingBossBanner(){
        this.showShoppingBossModal = false
    }

    setPDFView(value) {
        this.forcePDF = value;
    }

    get showNativeAppModal() {
        return showItemNativeAppModal( this.currentItem, this.forceNativeAppModal )
    }

    get parentItemSegments() {
        if (!this.mainMenu.path)
            return null;
        let parts = this.mainMenu.path.split("/");
        let pathParts = [];

        let currentLevel = this.mainMenu.menu;
        let segments = [{ title: "Life Home", tag: "home", path: "/", trKey: "lifeHome" }];

        if (parts[0] === "members") {
            segments.push({ title: (this.membersMenu && this.membersMenu.menu && this.membersMenu.menu.title) ||  "Members Only", path: "/members" });
        }

        if (parts[0] === "wallet") {
            segments.push({ title: "Wallet", path: "/wallet" });
            if (parts[1]) {
                let item = this.walletMenu
                    && this.walletMenu.topLevelLinks
                    && this.walletMenu.topLevelLinks.find(x => x.tag === parts[1]);
                if (item && item.tag !=="ewallet") {
                    segments.push({title: item.title, path: `/wallet/${parts[1]}`});
                }
                else{
                   return segments
                }
            }
        }

        if (parts[0] === "profile") {
            segments.push({ title: "Profile", tag: "profile" });
            if (parts.length >= 3 && parts[1].startsWith("subscriptions") ) {
                segments.push({ title: "Subscriptions",  path: `/profile/${parts[1]}` });
            }
            if (parts.length >= 3 && parts[1].startsWith("billinginfo") ) {
                segments.push({ title: "paymentSources",  path: `/profile/${parts[1]}` });
            }
        }
        if (parts[0] === "events") {
            segments.push({ title: "Events", tag: "events" });
        }
        if (parts[0] === "library") {
            segments.push({ title: "Library", path: "/library" });

            if (parts[1] === "series" && parts[2]) {
                let serie = this.libraryStore.series.find(x => x.seriesValue == parts[2]);
                if (serie)
                    segments.push({ title: serie.seriesName, path: `/library/series/${parts[2]}` });
                if (parts[3] === "packs" && parts[4]) {
                    if (this.packsStore.pack)
                        segments.push({ title: this.packsStore.pack.title, path: `/library/series/${parts[2]}/packs/${parts[4]}` });
                }
            }
            if (parts[1] === "speakers" && parts[2]) {
                let speaker = this.libraryStore.speakers.find(x => x.speakerID == parts[2]);
                if (speaker)
                    segments.push({ title: speaker.speakerName, path: `/library/speakers/${parts[2]}` });
            }
            if (parts[1] === "packs" && parts[2]) {
                if (this.packsStore.pack)
                    segments.push({ title: this.packsStore.pack.title, path: `/library/packs/${parts[2]}` });
            }
            return segments;
        }


        for (let i = 0; i < parts.length - 1; i++) {
            if (!currentLevel) return segments;
            currentLevel = (currentLevel.section || currentLevel.subsection||[]).find(x => getUniqKey(x) === parts[i]);
            if (!currentLevel) return segments;
            pathParts.push(getUniqKey(currentLevel));
            segments.push({ ...currentLevel, path: pathParts.join("/") });
        }
        return segments;
    }

    loadMenu() {
        return Promise.all([
            generateLoadEntity("menu", this, "rawMenuLoading"
                , async () => {
                    let menu = await services.MenuItems.fetch(
                        (this.menuItemsStatus && this.menuItemsStatus.latestmenuversion)
                        || process.env.REACT_APP_MENU_VERSION);
                    runInAction(() => {
                        this.debugMenu = menu
                    });

                    //DEBUG TO TEST VIMEO
                    //menu[0].menu[0].parent[1].section[0].banner[0].url = "https://player.vimeo.com/video/744758395"

                    return menu

                }, "rawMenu", "[0].menu[0].parent")(),

            generateLoadEntity("webMenu", this, "webMenuLoading"
                , () => { return services.MenuItems.web(); }, "webMenu")()]);
    }

    get menuLoading() {
        return this.rawMenuLoading || this.webMenuLoading;
    }

}

export default MenuStore;