import { makeAutoObservable, observable, runInAction, } from 'mobx';
import services from "../services";
import keyBy from "lodash/keyBy";
import sumBy from "lodash/sumBy";
import i18n from "../tools/i18n";
import { setItem, getItem } from "../utils/storage";
import { generateLoadEntity, generateLoadList } from "../utils/mobx";
import { extractErrorMessage } from "../utils/helpers";
import { getCookie } from "../utils/cookieUtil";


class CommonStore {
    onStarfish = null;
    onRoyalStarfish = null;
    message = false;
    appLoaded = false;
    messageType = null;
    messageShown = false;
    confirmationRequired = false;
    confirmationShowCancel = true;
    confirmationDescription = "";
    confirmationAction = "";
    confirmationOnConfirmAction;
    confirmationTitle = "";
    confirmationCancelTitle = "Cancel";
    languagesLoaded = false;
    statusLoading = false;
    notificationsLoading = false;
    language = getItem("language") || "en";
    languages = ["en", "fr", "es"];
    i18n = null;
    status = null;
    iFrame = null;
    modalViewTitle = null;
    notifications = observable([]);


    constructor() {
        makeAutoObservable(this);
        this.languagesLoader = new Promise((resolve) => {
            this.languagesLoaderResolver = resolve;
        });
    }

    get onStarfishOrRoyalStarfish() {
        return !!this.onStarfish || !!this.onRoyalStarfish;
    }


    setError(error, type = "menu") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    loadStatus = generateLoadEntity("status", this, "statusLoading"
        , () => { return services.Status.fetch(this.language); }, "status");

    loadNotifications = generateLoadList("notifications", this, "notificationsLoading"
        , () => { return services.Notifications.list(); }, "notifications");


    get statusMessages() {
        if (!this.status || !this.status.serviceStatus) return [];
        return this.status.serviceStatus.filter(x => x.displayMessage);
    }

    setOnStarfish(value) {
        runInAction(() => {
            this.onStarfish = value;
        });
    }

    setOnRoyalStarfish(value) {
        if ( value )//do only one way, for reset use resetOnRoyalStarfish method
            runInAction(() => {
                this.onRoyalStarfish = value;
            });
    }

    resetOnRoyalStarfish() {
        runInAction(() => {
            this.onRoyalStarfish = false;
        });
    }


    setIFrame(url) {
        this.iFrame = url;
    }

    messageTimeout;

    hideMessage() {
        this.message = null;
        this.messageShown = false;
        this.messageShown = false;
        try {
            clearTimeout(this.messageTimeout);
        }
        catch (e) {
        }
        this.messageTimeout = null;
    }

    setAppLoaded() {
        this.appLoaded = true;
    }

    success(message) {
        this.showMessage(message, "success");
    }

    warn(message) {
        this.showMessage(message, "warning");
    }

    showError(message, delay = 10000) {
        this.showMessage(message, "error", delay);
    }

    showMessage(message, type = "info", delay = 10000) {
        this.hideMessage();
        if (message) {
            this.message = message;
            this.messageShown = true;
            this.messageType = type;
            this.messageTimeout = setTimeout(() => {
                this.hideMessage();
            }, delay);
        }
    }

    getEntityName(entityType, entity) {

        switch (entityType) {
            case "Address":
                return `address: "${entity.addressName}"`;
            case "User":
                return entity.name || entity.email;
            default:
                return entity;
        }
    }


    showDeleteConfirm(entityType, entity, callback) {
        this.showConfirm(`Confirming that you want to DELETE ${this.getEntityName(entityType, entity)}`, "Delete", `Delete ${entityType}`, callback);
    }

    showConfirm(confirmationDescription, confirmationAction, confirmationTitle, confirmationOnConfirmAction, confirmationCancelTitle = 'Cancel', { htmlDescription = false, onCancelAction } = {}) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.confirmationTitle = confirmationTitle;
        this.confirmationCancelTitle = confirmationCancelTitle;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationRequired = true;
        this.confirmationHtmlDescription = htmlDescription;
        this.onCancelAction = onCancelAction;
    }

    async cancelConfirm() {
        if ( this.onCancelAction )
            await this.onCancelAction();
        this.confirmationDescription = null;
        this.confirmationAction = null;
        this.confirmationTitle = null;
        this.confirmationOnConfirmAction = null;
        this.confirmationCancelTitle = null;
        this.confirmationRequired = false;
        this.confirmationHtmlDescription = false;
    }

    doConfirm(args) {
        if (this.confirmationOnConfirmAction)
            this.confirmationOnConfirmAction(args);

        this.cancelConfirm();
    }


    //potential ability to load available languages dynamically
    async loadLanguages() {
        this.languagesLoaded = false;

        try {

            this.i18n = await i18n.init();

            this.languagesLoaderResolver();
            this.languagesLoaded = true;
        }
        catch (e) {
            console.error(e);
            setTimeout(async () => { await this.loadLanguages(); }, 5000);
        }
    }


    async setLanguage(language_code, skipSaving) {
        await this.languagesLoader;
        if (!skipSaving) {
            // TODO: save user language if need
            //await services.Users.switchLanguage(language_code);
            setItem('language', language_code);
        }
        this.language = language_code;

        this.i18n.changeLanguage(language_code);
        //this.loadServerData()

    }

    get viewContext() {
        return { modal: this.modalViewTitle !== null, title: this.modalViewTitle };
    }

    setModalViewTitle(value) {
        this.modalViewTitle = value;
    }

    get notificationsCounter() {
        return sumBy(this.notifications, "count");
    }

    get isNativeApp() {
        if (getCookie("nativeapp") === "true") return true;
    }

    get languagesIndex() {
        return keyBy(this.languages, "code");
    }


    get languagesCodes() {
        return this.languages.map(x => x.code);
    }



}

export default CommonStore;