import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faTimes} from "@fortawesome/pro-light-svg-icons";
import React, {useState} from "react";
import {observer} from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {Link} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    img:{
        height:300,
        width: "min-content"
    }
}))


const ShareProductModal = ({ t, product,  showShareProductModal,
                               shareProductRecipientName,
                               shareProductRecipientEmail,
                               shareProductMessage,
                               shareProductErrorMessage,
                               toggleShowShareProductModal,
                               clearShareProductErrorMessage,
                               onRecipientNameChange,
                               onRecipientEmailChange,
                               onShareProductMessageChange,
                               togglePreviewShareProductEmail,
                               shareProductInEmail, sendProductEmailError

}) => {
    const  classes = useStyles()


    const [shareProductShowMessage, setShareProductShowMessage] = useState(false)
    const toggleShareProductShowMessage = (e) => {
        e.preventDefault();
        setShareProductShowMessage(!shareProductShowMessage)
    }

    if (!showShareProductModal) {
        return null;
    }

    return (
        <Dialog open={showShareProductModal} onClose={toggleShowShareProductModal} maxWidth={"sm"} fullWidth>
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Typography>{t('shopping:shareproduct.share')}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => toggleShowShareProductModal()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <Grid container justify={"flex-start"} direction={"column"} alignContent={"center"}>
                    <Grid item xs={12} className="modal-share-product-alert" hidden={shareProductErrorMessage === null}>
                        <span className="modal-share-product-closebtn" onClick={() => clearShareProductErrorMessage()}>&times;</span>
                        <strong>{t('shopping:error.errortitle')}</strong> {shareProductErrorMessage}
                    </Grid>

                    <Grid container item  xs={12}  justify={"center"}>
                        <img className={classes.img} src={product.productMedia[0].imageURL} alt="" />
                    </Grid>
                    <Grid container item  xs={12}  >
                        <Typography variant={"h5"}>{product.productName}</Typography>
                    </Grid>
                    <Grid container item  xs={12}  >
                        <Typography variant={"subtitle1"}><div  dangerouslySetInnerHTML={{
                            __html: product.productSummary
                        }}></div> </Typography>
                    </Grid>

                    <div className="modal-share-product__fields">
                        <TextField fullWidth
                                   value={shareProductRecipientName}
                                   onChange={onRecipientNameChange}
                                   label="Send To"
                                   name="recipientName" placeholder="Recipient Name"

                        />

                        <TextField fullWidth
                                   value={shareProductRecipientEmail}
                                   onChange={onRecipientEmailChange}
                                   label="Email"
                                   name="recipientEmail" placeholder="Recipient Email"

                        />

                            {shareProductShowMessage
                                ? <div className="modal-share-product__textarea">
                                    <TextField fullWidth
                                               multiline
                                               rows={3}
                                               value={shareProductMessage}
                                               onChange={onShareProductMessageChange}
                                               name="recipientEmail" placeholder="Type in personalized message here:"

                                    />
                                    <Link onClick={toggleShareProductShowMessage} href="#">Hide Personal Message</Link>
                                </div>
                                :<div className="modal-share-product__textarea">
                                    <Link onClick={toggleShareProductShowMessage} href="#"><FontAwesomeIcon className="far" icon={faChevronRight} size="1x"  /> Add Personal Message </Link>
                                </div>}
                        </div>


                </Grid>
            </DialogContent>
            <DialogActions>
                {sendProductEmailError && <Alert color={"error"}>{sendProductEmailError}</Alert>}
                <Button color={"primary"} variant={"contained"} onClick={() => shareProductInEmail()} className="btn btn-primary"
                        disabled={(shareProductRecipientName === '' || shareProductRecipientEmail === '')} >
                    {t('shopping:shareproduct.send')}
                </Button>
                <Button  color={"primary"} variant={"contained"}  onClick={() => togglePreviewShareProductEmail()} className="btn btn-primary"
                        disabled={(shareProductRecipientName === '' || shareProductRecipientEmail === '')} >
                    {t('shopping:shareproduct.preview')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export  default observer(ShareProductModal)