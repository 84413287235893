import React, { useEffect } from "react";
import { Route, Switch, useLocation } from 'react-router';
import Helmet from 'react-helmet';
import './App.css';
import { useStores } from "./hooks/use-stores";
import { I18nextProvider } from 'react-i18next';
import { Observer, observer } from 'mobx-react-lite';
import AuthRoute from "./hocs/AuthRoute";
import PrivateRoute from "./hocs/PrivateRoute";
import SignIn from "./containers/SignIn";
import Home from "./containers/AndroidHome";
import ShopHome from "./containers/shop";
import DesktopHome from "./containers/DesktopHome";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCookie } from "./utils/cookieUtil";

import ConfirmationModal from "./containers/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";

import EbookReader from "./containers/EbookReader";
import AndroidLoginRoute from "./hocs/AndroidLoginRoute";
import CustomerEnrollment from "./containers/CustomerEnrollment";
import MemberEnrollment from "./containers/MemberEnrollment";
import ConventionProgram from './containers/ConventionProgram';
import PrivateRouteWithGuest from "./hocs/PrivateRouteWithGuest";
import Share from "./containers/share";
import NativeApp from "./containers/NativeApp";
import Dialog from "@material-ui/core/Dialog";
import ShoppingBossOnboarding from "./components/ShoppingBossOnboarding";
import SnackbarContainer from "./containers/SnackbarContainer";
import Lifeline from "./containers/Lifeline";
import Starfish from "./containers/Starfish";
import PrivateRouteWithConventionalGuest from "./hocs/PrivateRouteWithConventionalGuest";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import RoyalStarfish from "./containers/RoyalStarfish";
import {useRouteMatch} from "react-router-dom";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
    }
}));

const LifeHead = () => (
    <Helmet>
        <link rel="icon" href="https://main.secure.footprint.net/public/icon/life.png" />
        <link rel="apple-touch-icon" href="https://main.secure.footprint.net/public/icon/life.png" />
        <link rel="manifest" href="https://main.secure.footprint.net/public/icon/life.png" />
        <meta name="description" content="Life Info App" />
        <title>Life Platform</title>
    </Helmet>
);

const StarfishHead = () => (
    <Helmet>
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/starfish.png`} />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/Startfish_Black.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
        <meta name="description" content="Starfish Perks" />
        <title>Starfish Perks</title>
    </Helmet>
);

export default observer(function App() {
    const location = useLocation();
    const { commonStore, authStore } = useStores();
    const { languagesLoaded, appLoaded } = commonStore;
    const { currentUser, sbSetupCompleted } = authStore;
    const classes = useStyles();

    const onStarfish = React.useMemo(() => {
        let redirect = new URLSearchParams(location.search).get("redirect");
        let onStarfish = location.pathname.includes('/starfish') || location.pathname.includes('/customer/enroll') || redirect?.includes('/starfish');
        commonStore.setOnStarfish(onStarfish);
        let onRoyalStarfish = location.pathname.includes('/royalstarfish') || redirect?.includes('/starfish')
        commonStore.setOnRoyalStarfish(onRoyalStarfish);
        return onStarfish;
    }, [location.pathname, commonStore.onStarfish]);

    useEffect(() => {
        var starfishManifest = {
            "name": "Starfish Perks",
            "short_name": "Starfish Perks",
            "start_url": "https://my.lifeinfoapp.com/starfish",
            "display": "standalone",
            "icons": [
                {
                    "src": `https://firebasestorage.googleapis.com/v0/b/life-info-b7c3e.appspot.com/o/images%2FStartfish_Black.png?alt=media&token=2f3d294d-244c-46d8-bd22-35407e3a45d4`,
                    "sizes": "512x512",
                    "type": "image/png"
                },
                {
                    "src": `${process.env.PUBLIC_URL}/Startfish_Black.jpg`,
                    "sizes": "512x512",
                    "type": "image/jpeg"
                }
            ]
        };
        var lifeManifest =
        {
            "name": "Life Platform",
            "short_name": "Life Platform",
            "start_url": "https://my.lifeinfoapp.com",
            "display": "standalone",
            "icons": [
                {

                    "src": "https://main.secure.footprint.net/public/icon/life.png",
                    "sizes": "512x512",
                    "type": "image/png"
                }
            ]
        };
        if (commonStore.onStarfishOrRoyalStarfish ) {
            const stringManifest = JSON.stringify(starfishManifest);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const manifestURL = URL.createObjectURL(blob);
            document.querySelector('#manifest-pwa').setAttribute('href', manifestURL);
        } else {
            const stringManifest = JSON.stringify(lifeManifest);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const manifestURL = URL.createObjectURL(blob);
            document.querySelector('#manifest-pwa').setAttribute('href', manifestURL);
        }
    }, [commonStore.onStarfishOrRoyalStarfish]);

    //Emit initial  loading
    useEffect(() => {
        (async () => {
            try {
                await commonStore.loadLanguages();
                await commonStore.loadStatus();
                await authStore.pullUser();
            }
            catch (e) {
                console.error(e);
            }
            finally {
                await commonStore.setAppLoaded();
            }

        })();
    }, []);

    const themeOptions = (isDark) => ({
        palette: {
            type: isDark ? 'dark' : 'light',
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#007BFF',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#FFFFFF'
            },
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },

        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1180,
                lg: 1380,
                xl: 1920,
            },
        },
    });
    const theme = createTheme(themeOptions(true));
    const lightTheme = createTheme(themeOptions(false));

    const isPhone = useMediaQuery(theme.breakpoints.down(700));
    const isNative = getCookie("nativeapp") === "true";


    const isShopping =  useRouteMatch("/shopping")


    const gradientBg = !( commonStore.onStarfishOrRoyalStarfish  || isShopping )

    return (
        <>
            {commonStore.onStarfishOrRoyalStarfish ? <StarfishHead  /> : <LifeHead />}
            {languagesLoaded ?
                <I18nextProvider i18n={commonStore.i18n}>
                    <Switch>
                        <Route path="/customer/enroll" render={() => {
                            return <ThemeProvider theme={lightTheme}>
                                <CustomerEnrollment />
                                <ConfirmationModal />
                            </ThemeProvider>;
                        }} />
                        <PrivateRoute path="/member/enroll" render={() => {
                            return <ThemeProvider theme={lightTheme}>
                                <MemberEnrollment />
                                <ConfirmationModal />
                            </ThemeProvider>;
                        }} />
                        <PrivateRoute path="/lifelinenews" render={() => {
                            return <ThemeProvider theme={lightTheme}>
                                <Lifeline />
                                <SnackbarContainer />
                            </ThemeProvider>;
                        }} />

                        {appLoaded && <PrivateRouteWithConventionalGuest path="/conventionprogram/:year?/:month?" render={() => {
                            return <ThemeProvider theme={lightTheme}>
                                <ConventionProgram />
                            </ThemeProvider>;
                        }} />}
                        <Route render={() => {
                            return <Observer>{() => (<div className={`App ${gradientBg ? 'bg-dark-gradient' : ''}`}
                                                          style={ { ...(isShopping?{ overflowX: "hidden"}:{})  }  }
                                                          version={process.env.REACT_APP_VERSION}>
                                <ThemeProvider theme={theme}>
                                    {appLoaded ? <Switch>
                                        <Route path="/androidlogin" exact component={AndroidLoginRoute} />
                                        <Route path={`/sharelife`} render={() => { return <Share theme={lightTheme} />; }} />
                                        <AuthRoute path="/signin" exact component={SignIn} theme={lightTheme} />
                                        <PrivateRoute path="/ebook/:sku" exact component={EbookReader} />
                                        <PrivateRouteWithGuest path="/shopping" component={ShopHome} />

                                        <PrivateRoute path="/nativeapp/*" native={isNative} component={NativeApp} prefix={"/nativeapp"} />
                                        <PrivateRoute path="/nativeapp" native={isNative} exact component={NativeApp} prefix={"/nativeapp"} />

                                        <PrivateRoute path="/androidwebview/*" native={isNative} component={Home} prefix={"/androidwebview"} />
                                        <PrivateRoute path="/androidwebview" native={isNative} exact component={Home} prefix={"/androidwebview"} />


                                        <PrivateRoute path="/starfish" render={() => {
                                            return <Starfish isPhone={isPhone} />;
                                        }} />

                                        <PrivateRoute path="/royalstarfish" render={() => {
                                            return <RoyalStarfish isPhone={isPhone} />;
                                        }} />

                                        <PrivateRoute path="/*" render={() => {
                                            return <DesktopHome isPhone={isPhone} />;
                                        }} />
                                        <PrivateRoute path="/" exact render={() => {
                                            return <DesktopHome isPhone={isPhone} />;
                                        }} />
                                    </Switch> : <div className={classes.progressContainer}><CircularProgress size={90} /></div>}
                                    {(false && currentUser && !sbSetupCompleted) &&
                                        <Dialog fullScreen open={true}>
                                            <ShoppingBossOnboarding />
                                        </Dialog>
                                    }
                                    <SnackbarContainer />
                                    <ConfirmationModal />
                                </ThemeProvider>
                            </div>)}</Observer>;
                        }} />

                    </Switch>

                </I18nextProvider>
                : ""}
        </>
    );
});
