import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Avatar, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContentText, DialogTitle, Divider, Grid, Link, MenuItem, Select, Typography } from "@material-ui/core";
import ValueWithCurrency from "./components/StyledValueWithCurrency";
import { useStores } from "../../hooks/use-stores";
import ErrorMessageModal from "../../components/shop/ErrorMessageModal";
import ErrorModal from "../../components/ErrorModal";
import { joinPath } from "../../utils/helpers";
import Header from "./components/Header";
import InfoModal from "./components/InfoModal";
import services from "../../services";

const SHOPPING_BOSS = 'ShoppingBoss';
const BITREFILL = 'Bitrefill';
const SPARK_WALLET = 'sparkwallet';

const formatterUSD = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  useGrouping: false,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  bold: {
    fontWeight: 'bold'
  },
  termsModalContent: {
    padding: theme.spacing(2)
  },
  heading: {
    fontSize: '1.0rem',
    color: 'rgb(118, 118, 118)',
    marginBottom: theme.spacing(.5)
  },
  subheader: {
    margin: '0 auto'
  },
  checkoutForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  applyWallet: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  youWillEarn: {
    marginBottom: theme.spacing(1)
  },
  rewardsContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  earningsHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  },
  earningsValue: {
    fontSize: '3rem',
    color: '#007BFF',
    fontWeight: 'bold'
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  finalInfo: {
    textAlign: 'center',
    height: '25vh',
  },
  checkoutButton: {
    flexGrow: 1,
    backgroundColor: "#007BFF",
    color: "white",
    marginTop: theme.spacing(1),
    "&:hover": {
      color: "#007BFF",
      backgroundColor: "white",
    },
  },
  termsLink: {
    fontSize: 14,
    marginTop: theme.spacing(.5),
    cursor: 'pointer'
  },
  progressContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    margin: '0 auto',
    backgroundColor: "#fff",
    '& > img': {
      objectFit: 'contain'
    }
  },
  merchantInfoName: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0,
    fontSize: '.9rem',
  },
}));

const Checkout = ({ shoppingBossMatch }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { commonStore, shoppingBossStore } = useStores();
  const { completeCheckoutLoading, merchantCountry, merchantInfo, merchantTerms, eCodeInfo, checkoutInfo, userCountry } = shoppingBossStore;

  const [loading, setLoading] = useState(false);
  const [ccDisabledModalOpen, setCCDisabledModalOpen] = useState(false);
  const [purchaseErrorModalOpen, setPurchaseErrorModalOpen] = useState(false);
  const [purchaseErrorModalMessage, setPurchaseErrorModalMessage] = useState(null);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);

  const closeCCDisabledModal = () => setCCDisabledModalOpen(false);

  const returnToMerchantPage = () => history.push(shoppingBossMatch.url);

  const showMerchantCurrency = (country) => {
    if (country == "CA") {
      return "CAD";
    }
    return "USD";
  };

  const showDisclaimerModal = () => { setDisclaimerModalOpen(true); };
  const hideDisclaimerModal = () => { setDisclaimerModalOpen(false); };

  const closePurchaseErrorModal = () => {
    setPurchaseErrorModalOpen(false);
    setPurchaseErrorModalMessage('');
  };

  const completeCheckout = async () => {
    if (checkoutInfo.checkoutTotal > shoppingBossStore.userBalance) {
      setPurchaseErrorModalOpen(true);
      setPurchaseErrorModalMessage(t('shoppingBoss:error.insufficentBalance'));
    } else {
      let cashEarned = checkoutInfo.cashbackYouWillEarn;
      let satsEarned = checkoutInfo.satsEarned;
      let provider;

      if (merchantInfo.provider === SHOPPING_BOSS) {
        provider = SHOPPING_BOSS;
      } else if (merchantInfo.provider === SPARK_WALLET) {
        provider = SPARK_WALLET;
      } else {
        provider = BITREFILL;
      }

      const params = {
        sbCheckoutToken: checkoutInfo.checkoutToken,
        merchantCountry,
        merchantName: checkoutInfo.merchant,
        giftCardTotalAmount: checkoutInfo.checkoutTotal,
        cashEarned,
        satsEarned,
        provider
      };

      try {
        await shoppingBossStore.checkout(params);
        history.push(joinPath(shoppingBossMatch.url, '/complete'));
      } catch (e) {
        setPurchaseErrorModalOpen(true);
        setPurchaseErrorModalMessage(t(`shoppingBoss:error.${commonStore.onStarfishOrRoyalStarfish ? 'checkoutFailureStarfish' : 'checkoutFailure'}`));
      }
    }
  };

  // Format currency for display
  // Remove thousandths place that could cause issues
  const formatCurrency = (value) => {
    var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    let newValue = formatterUSD.format(with2Decimals);
    return newValue;
  };

  // The user's country might possibly be null when the come to this page, so fetch it again
  const loadUserCountry = async () => {
    setLoading(true);
    try {
      let profile = await services.UserProfile.list();
      const { country } = profile.address;
      shoppingBossStore.userCountry = country;
    } catch (e) {
      // if we can't get the profile of the user at all, let them checkout, but they won't earn sats
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    shoppingBossStore.currentPage = 'checkout';
    setTimeout(() => window.scrollTo(0, 0), 100);
    (async () => {
      if (!userCountry) {
        await loadUserCountry();
      }
    })();
  }, []);

  return (
    !checkoutInfo ?
      <ErrorMessageModal t={t} message={t("shoppingBoss:error.sessionFailed")} onClickHandler={returnToMerchantPage} />
      :
      loading ? <Box className={classes.progressContainer}><CircularProgress /></Box> : <Container className={classes.container} maxWidth="sm">
        <Dialog open={disclaimerModalOpen} onClose={hideDisclaimerModal} maxWidth={"sm"} fullWidth>
          <DialogTitle disableTypography >
            <Grid container justify={"space-between"}>
              <Typography>{t("shoppingBoss:checkout.termsAndConditions")}</Typography>
              <div style={{ cursor: "pointer" }} onClick={hideDisclaimerModal}>
                <CloseIcon />
              </div>
            </Grid>
          </DialogTitle>
          <DialogContentText className={classes.termsModalContent}>{merchantTerms}</DialogContentText>
          <DialogActions>
            <Button color={"primary"} variant={"contained"} onClick={hideDisclaimerModal} className="btn btn-primary">
              {t("shoppingBoss:checkout.accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Box flex={1}>
          <Header buttonText={t('shoppingBoss:header.startOver')} onBackButtonPressed={returnToMerchantPage} />
          <Grid className={classes.subheader} flexDirection="column" container xs={12} justifyContent="center" alignItems="center">
            <Grid xs={12} item alignSelf="flex-start">
              <Typography variant="body1" className={classes.heading}>{t('shoppingBoss:checkout.preview')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container flexDirection="column" justifyContent="center" spacing={1}>
                <Grid item xs={12} className={classes.merchantInfo}>
                  <Avatar
                    className={classes.avatar}
                    variant="rounded"
                    alt={checkoutInfo.merchant}
                    src={checkoutInfo.logo}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Grid xs={12} item>
                    <Typography variant="h6" noWrap className={classes.merchantInfoName}>{checkoutInfo.merchant}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ValueWithCurrency
                disabled
                value={checkoutInfo.checkoutTotal * 100} // checkoutTotal is in dollars, component expects it in cents
                currency={showMerchantCurrency(merchantCountry)}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center', cursor: 'pointer' }}>
              <Link onClick={() => history.goBack()}>{t('shoppingBoss:checkout.goBack')}</Link>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography variant="body1" className={classes.heading}>{t("shoppingBoss:checkout.youWillEarn")}</Typography>
          <Grid xs={12} container>
            <Grid item xs={12} className={classes.rewardsContainer}>
              <Typography className={classes.earningsValue}>
                ${formatCurrency(checkoutInfo.cashbackYouWillEarn)}
              </Typography>
              <Typography variant="body2" className={classes.earningsHeader}>{t("shoppingBoss:checkout.cashback")}</Typography>
            </Grid>
            {!commonStore.onStarfishOrRoyalStarfish && checkoutInfo.satsEarned > 0 &&
              <Grid item xs={12} className={classes.rewardsContainer}>
                <Typography className={classes.earningsValue}>
                  {checkoutInfo.satsEarned}
                </Typography>
                <Typography variant="body2" className={classes.earningsHeader}>{t("shoppingBoss:checkout.satsRewards")}</Typography>
              </Grid>
            }
            <Grid xs={12} container item display="flex" flexDirection="column" className={classes.finalInfo}>
              <Grid item xs={12} style={{ marginTop: 'auto' }}>
                <Button
                  fullWidth
                  className={classes.checkoutButton}
                  size={"large"}
                  variant={"contained"}
                  onClick={completeCheckout}
                  disabled={completeCheckoutLoading}
                >{completeCheckoutLoading ? t("shoppingBoss:checkout.loading") : t("shoppingBoss:checkout.completePurchase")}</Button>
                <Grid item xs={12} style={{ marginTop: '4px' }}>
                  <Typography variant="caption" style={{ color: 'red', fontSize: '.8rem' }}>
                    {t("shoppingBoss:checkout.nonRefundable")}
                  </Typography>
                </Grid>
                {eCodeInfo?.disclaimer && <Link onClick={showDisclaimerModal} className={classes.termsLink}>{t("shoppingBoss:checkout.disclaimer")}</Link>}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {purchaseErrorModalOpen &&
          <ErrorModal allowUserToClose={true} errorTitle={t("shoppingBoss:error.title")} handleClose={closePurchaseErrorModal} open={purchaseErrorModalOpen} error={purchaseErrorModalMessage} />
        }
        {
          <InfoModal open={ccDisabledModalOpen} onClose={closeCCDisabledModal} message={t('shoppingBoss:error.ccDisabled')} confirmButtonText={t('shoppingBoss:ok')} />
        }
      </Container>
  );
};

export default observer(Checkout);