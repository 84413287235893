import React, {useEffect, useMemo,} from "react";
import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core';
import menu from './menu.json';
import {useRouteMatch} from "react-router-dom";
import LinksMenu from "./LinksMenu";
import {useStores} from "../../hooks/use-stores";

function Royal ( {parentMatch} ){
    const match = useRouteMatch();
    const MainLinks = useMemo(()=>{
         const items = menu.find(x=>x.tag==="userprofile").items
        return items
    },[menu])

    const { shoppingBossStore } = useStores();
    const { eAcceleratorValidityLoading  } = shoppingBossStore;


    useEffect(() => {
        shoppingBossStore.verifyEAccelerator();
    }, []);

    if ( eAcceleratorValidityLoading == null || eAcceleratorValidityLoading )  return   null;

    return (
        <Container>
            <>
                <LinksMenu items={MainLinks} showBack={false} />
            </>
        </Container>
    );
}

export default observer(Royal);