import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    img: {
        height: 300,
        width: "min-content"
    }
}));
const AutoshipUpdatedModal = ({ type, onProceedToShopHomeHandler, closeUpdatedModal }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog open={true} onClose={onProceedToShopHomeHandler} >
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Typography>{t('shopping:autoshipmodal.title.' + type)}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => closeUpdatedModal()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div className="info">
                    <p>{t('shopping:autoshipmodal.content.' + type)}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={() => onProceedToShopHomeHandler()} className="btn btn-light">
                    {t('shopping:autoshipmodal.home')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};



export default observer(AutoshipUpdatedModal);