import RestService from "./RestService";



export default class Members extends RestService {
    constructor() {
        super('/api/members' )
    }

    async pid(){
        return this.get( `${this.baseUrl}/pid`)
    }

}
