import {makeAutoObservable} from 'mobx'
import services from "../services"
import {flatten, uniqBy} from "lodash";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity } from "../utils/mobx";
import {isAudio, isBook, isVideo} from "../utils/mediaUtils";

class SeriesStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    pack = null
    packLoading = false
    mediaType = false
    error = null

    setError(error, type = "series") {
        error = extractErrorMessage(error)
        this.error = error
    }

    setMediaType( mediaType ){
        this.mediaType = mediaType
    }


    loadPack = generateLoadEntity( "pack", this, "packLoading"
        , async (id)=>{
            if ( this.libraryStore.library.length ===0 )
                await this.libraryStore.loadLibrary();
            return services.Library.details(id)
        }, "pack", "[0]")



    get title(){
        if (!this.pack)  return null
        return  this.pack.title
    }

    get imageURL(){
        if (!this.pack)  return null
        return  this.pack.imageURL
    }

    get series(){
        if (!this.pack)  return []
        return  this.pack.files
    }

    get audios(){
        if (!this.series)  return []
        return  this.series.filter( x=>this.pack.productType ==="audio" ||isAudio(x)  )
    }

    get videos(){
        if (!this.series)  return []
        return this.series.filter( x=>this.pack.productType ==="video" ||isVideo(x) )
    }

    get books(){
        if (!this.series)  return []
        return this.series.filter( x=>this.pack.productType ==="book" ||isBook(x)  )
    }

    get speakers(){
        if (!this.series)  return []
        return uniqBy( flatten( this.series.map(x=>(x.speakers||[])) ), 'speakerID')
    }

    get sections(){
        let result =[];
        if ( this.audios.length>0 )
            result.push( { mediaType:"audio", title :"Audio"})
        if ( this.videos.length>0 )
            result.push( { mediaType:"video", title :"Video"})
        if ( this.books.length>0 )
            result.push( { mediaType:"book", title :"eBook"})
        if ( this.speakers.length>0 )
            result.push( { mediaType:"speaker", title :"Speakers"})
        return result
    }

}

export default SeriesStore