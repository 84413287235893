
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faSync } from "@fortawesome/pro-light-svg-icons";
import OwlCarousel from "react-owl-carousel2";
import React from "react";
import { observer } from "mobx-react";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";


const useStyles = makeStyles((theme) => ({

    productImage: {
        width: "100%"
    },
    productPrice: {
        color: "#c9252d",
        fontSize: 25,
        fontWeight: 700,
        whiteSpace: "nowrap"
    },
    productTC: {
        color: "#187DFE",
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    formControl: {
        height: 35,
        padding: "6px 10px",
        fontSize: 16,
        lineHeight: 1.5,
        color: "#495057",
        backgroundColor: "#fff",
        border: "1px solid #ebedf2",
        borderRadius: 4,
        marginLeft: 10,
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out"
    }
    , productDescriptionImg: {
        maxHeight: 150,
        paddingRight: 20,
        cursor: "pointer"

    },
    playIcon: {
        color: "#fefefe",
        position: "absolute",
        top: "50%",
        left: "calc(50% - 10px)",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        backgroundColor: "rgba(0, 0, 0, 0.742)",
        fontSize: 40

    },
    carousel: {
        alignItems: "flex-end"
    }
}));


const ProductDetails = ({ t, isGuest, isGeneratingCustomerEnrollWithProductLink
    , selectedQty, onQuantityChange, addingItemToCart
    , onAddToCart, onModalImageClick, physicalAndDigitalDropdownValue, productLanguage
    , width, onPhysicalAndDigitalDropdownChange, physicalAndDigitalDropdownEnabled
    , isProductDigital, shareButtonClickHandler
    , productName, bannerImage, productPrice, currency, productTC, productSKU, productType, productPV, productSummary, productMedia, options }) => {

    const classes = useStyles();

    return (
        <Grid container >
            <Grid container item direction={"column"} spacing={3} >

                <Grid container item alignItems={"center"} justify={"space-between"}>
                    <Grid item> <Typography variant={"h6"}>{productName}</Typography></Grid>
                    {
                        isGuest ?
                            ''
                            : (
                                <div className="button-container">
                                    <Button onClick={shareButtonClickHandler} color={"primary"} variant={"contained"}>
                                        {isGeneratingCustomerEnrollWithProductLink ? <FontAwesomeIcon icon={faSync} size="1x" spin /> : t('shopping:shareproduct.share')}
                                    </Button>
                                </div>
                            )
                    }
                </Grid>

                {bannerImage &&  <Grid container item justify={"center"}>
                    <img src={bannerImage} alt="Product Banner" className={classes.productImage} />
                </Grid>}
                <Fade in={true}>
                    <Grid container item spacing={2}>
                        <Grid item container direction={"column"} spacing={1} md={3} xs={12}>
                            <Grid item><Typography>{t('shopping:description.price')}:
                                <span className={classes.productPrice}>
                                    { (productPrice && productPrice.toFixed)? "$"+productPrice.toFixed(2):productPrice}
                                    <span style={{
                                        fontSize: 16,
                                        color: '#fff',
                                        fontWeight: 400,
                                        paddingLeft: 4
                                    }}>({currency})</span>
                                </span>
                            </Typography></Grid>
                            <Grid item><Typography>{t('shopping:description.tripcredits')}: <span
                                className={classes.productTC}>{(!productTC || productTC === 0) ? productTC
                                : ( (productTC && productTC.toFixed)?productTC.toFixed(2): productTC ) }</span>
                            </Typography></Grid>
                            <Grid item><Typography className="product-description__product-pv"> {t('shopping:description.sku')}: <span
                                style={{ fontWeight: 600 }}>{productSKU}</span></Typography></Grid>
                            {
                                productType !== 'marketplace' && productType !== null ? (
                                    <Grid item>
                                        <Typography className="product-description__product-pv"> {t('shopping:description.format')}: <span
                                            style={{ fontWeight: 600 }}>{isProductDigital}</span></Typography>
                                        <Typography className="product-description__product-pv"> {t('shopping:description.lang')}: <span
                                            style={{ fontWeight: 600 }}>{productLanguage}</span></Typography>
                                    </Grid>
                                ) : ('')
                            }
                            <Grid item><Typography className="product-description__product-pv"> {t('shopping:description.pv')}: <span
                                style={{ fontWeight: 600 }}>{(productPV && productPV.toFixed)?productPV.toFixed(2):productPV}</span></Typography></Grid>
                            <Grid item> <div className="product-description__product-qty-cont">
                                <span className="product-description__product-qty"> Qty:</span>
                                {
                                    isProductDigital === 'Physical' ? (
                                        <input
                                            style={{ width: "70px" }}
                                            className={classes.formControl}
                                            type="text" id="quantity"
                                            pattern="[0-9]*" inputMode="decimal"
                                            maxLength="2" minLength="1"
                                            value={selectedQty}
                                            onChange={onQuantityChange}
                                            name="quantity" min="1" max="20" />

                                    ) : (
                                        1
                                    )
                                }
                            </div></Grid>
                            <Grid item>
                                <div className="button-container">
                                    <Button color={"primary"} variant={"contained"}
                                        onClick={() => onAddToCart()}
                                        className="btn btn-primary btn-lg">{addingItemToCart ?
                                            <FontAwesomeIcon icon={faSync} size="1x" spin /> : t('shopping:description.addtocart')}
                                    </Button>{physicalAndDigitalDropdownEnabled &&
                                        <div className="cart-dropdown">
                                            <Select
                                                options={['Physical', 'Digital', 'Both']}
                                                onChange={onPhysicalAndDigitalDropdownChange}
                                                value={physicalAndDigitalDropdownValue} />
                                        </div>}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item md={9} xs={12}>
                            <p style={{ color: '#fff' }} dangerouslySetInnerHTML={{
                                __html: productSummary
                            }}
                            />
                            {
                                productMedia.length >= 1 ? (
                                    <OwlCarousel
                                        options={{
                                            ...options,
                                        }}>
                                        {productMedia.map((image, i) => {
                                            return (
                                                <div key={i} className={classes.productDescriptionImg}
                                                    onClick={() => onModalImageClick(image)}>
                                                    {image.mediaURL ?
                                                        <FontAwesomeIcon data-toggle="modal" className={classes.playIcon}
                                                            data-target="#productImageModal" id='play-icon'
                                                            icon={faPlayCircle} size="5x" />
                                                        : ''
                                                    }
                                                    <img
                                                        data-toggle="modal"
                                                        data-target="#productImageModal"
                                                        src={image.imageURL}
                                                        alt=""
                                                    />
                                                </div>
                                            );
                                        })}
                                    </OwlCarousel>
                                ) : ('')}
                        </Grid>
                    </Grid>
                </Fade>
            </Grid>
        </Grid>
    );
};


export default observer(ProductDetails);