import React from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";



export default ({anchorOrigin={vertical: "top", horizontal: "right"}, autoHideDuration, variant, message, open, onClose })=> {
   return <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}>
        <Alert variant="filled" className={`snackbar-content`} severity={variant} onClose={onClose}>
            {message}
        </Alert>
    </Snackbar>
}