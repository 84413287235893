import React, { useCallback, useEffect, useState} from 'react'
import Carousel from '@brainhubeu/react-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons';
import {useHistory} from 'react-router-dom';
import {observer} from "mobx-react";
import {useStores} from "../../../hooks/use-stores";
import {joinPath} from "../../../utils/helpers";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  carouselImg: {
    height: 350,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 200,
      width: "100%",
    },
  }
}));

function PromotionalProductsCarousel ({shoppingMatch, parentCategoryID, products}) {

  const classes = useStyles()
  const history = useHistory()
  const [width, setWidth ] = useState(0)
  const { shopStore }  = useStores()
  const {  parentCategoryIds }  = shopStore

  const updateWindowDimensions = useCallback( () => {
    console.log(window.innerWidth)
    setWidth( window.innerWidth);
      }, [])

  useEffect(()=>{
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Clean up the subscription
      window.removeEventListener('resize', updateWindowDimensions);
    };
  },[])


  const onCarouselItemClick = (id, productSKU, productCategory) => {
    history.push( joinPath(shoppingMatch.url, `category/${id}/product-detail/${productSKU}/${productCategory}`))
  }

  let getParentCategoryID = useCallback((productCategoryID)=>{
    if (parentCategoryIds) {
      const id = parentCategoryIds.filter((category) => {
        return category.parentCategoryID === productCategoryID
      })
      if (id[0]) {
        return id[0].parentCategoryID
      }

    }
  }, [parentCategoryIds] )


    if (!products) return ('');
    if( !width )return null;
    return (
       <Carousel
        keepDirectionWhenDragging={false}
        className={'promotional-products'}
        arrowRight={width < 600 ? '' : <FontAwesomeIcon icon={faAngleRight} size='5x' />}
        arrowLeft={width < 600 ? '' : <FontAwesomeIcon icon={faAngleLeft} size='5x' />}
        stopAutoPlayOnHover={true}
        arrows={width < 500 ? false : true}
        autoPlay={3500}
        animationSpeed={1000}
        dots={true}
        slidesPerPage={1}
        addArrowClickHandler={width < 500 ? false : true}
        infinite={true}
        minDraggableOffset={122}
      >
        {
          products.map((item, index) => {
            let id = parentCategoryID;
            if (!id) {
              id = getParentCategoryID(item.parentCategoryID)
            }
            return (
              <div
                key={id + item.productSKU + item.parentCategoryID}
                onClick={() => onCarouselItemClick(id, item.productSKU, item.parentCategoryID)}
              >
                <img
                  alt="product2"
                  className={classes.carouselImg}
                  src={item.bannerImage}
                />
              </div>
            )
          })
        }
      </Carousel >
    )

}

export default observer(PromotionalProductsCarousel)