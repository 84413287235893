import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@brainhubeu/react-carousel/lib/style.css';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import MainCategoryListContainer from '../MainCategoryListContainer/MainCategoryListContainer';
import ProductList from '../../../containers/shop/ProductList/ProductList';
import FeaturedProductsCarousel from '../../../containers/shop/Carousel/FeaturedProductsCarousel';
import PromotionalProductsCarousel from '../../../containers/shop/Carousel/PromotionalProductsCarousel';
import OwlCarousel from 'react-owl-carousel2';

import './owl.carousel.css';
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/use-stores";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    link: {
        width: "100%",
    },
    pic: {
        cursor: "pointer",
        maxWidth: 120,
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0)",
        height: 120,
        marginBottom: 40,
        borderRadius: "10%",
        [theme.breakpoints.down('sm')]: {
            height: 80,
            width: 80,
        },
    },
    featuredProducts:{
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    }
}));


function HomeMainContent({ shoppingMatch }) {
    const history = useHistory();
    const params = useParams();
    const match = useRouteMatch();
    const classes = useStyles();
    const { categoryId } = params;
    const { t } = useTranslation();
    const { authStore, shopStore, commonStore } = useStores();
    const [homeIconDisplay, setHomeIconDisplay] = useState({
        marketplaceIconDisplay: {
            displayicon: false,
            link: "/category/790",
            imageURL: "https://main.secure.footprint.net/public/icon/marketplace_en.jpg"
        },
        zinzinoIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/786",
            imageURL: "https://main.secure.footprint.net/public/icon/zinzino.png"
        },
        muscadineIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/788",
            imageURL: "https://main.secure.footprint.net/public/icon/mighty_muscadine.jpg"
        },
        snaxIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/784",
            imageURL: "https://main.secure.footprint.net/public/icon/snax.png"
        },
        xebecIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/782",
            imageURL: "https://main.secure.footprint.net/public/icon/xebec.png"
        },
        satoshiIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/781",
            imageURL: "https://main.secure.footprint.net/public/icon/satoshi.png"
        },
        honeyBarsIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/794",
            imageURL: "https://main.secure.footprint.net/public/icon/honeybar.jpg"
        },
        autoShipIconDisplay: {
            displayicon: false,
            link: "/autoship",
            imageURL: "https://main.secure.footprint.net/public/icon/marketplace_autoship_en.png"
        },
        goldshieldIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/795",
            imageURL: "https://main.secure.footprint.net/public/icon/goldshield.png"
        },

        olivierIconDisplay: {
            displayicon: false,
            link: "/category/790/sub-category/812",
            imageURL: "https://main.secure.footprint.net/public/icon/olivier.jpg"
        }
    });
    const { isGuest } = authStore;
    const { memberCategories, publicCategories, promotionalProducts, featuredProducts, market, customerLevelID } = shopStore;
    const { language } = commonStore;
    const { currentUser, shoppingUserDetails, country } = authStore;
    // const {address, shoppingUserDetails} = currentUser;
    //const {country} = address;


    useEffect(() => {
        if (language && (!isGuest || (market && customerLevelID))) {
            shopStore.loadPromotionalProducts(
                {
                    customerLevelID
                    , marketID: market, language
                });
            shopStore.loadFeaturedProducts(
                {
                    customerLevelID
                    , marketID: market, language
                });
        }
    }, [market, customerLevelID, language]);


    useEffect(() => {
        var marketplaceCountries = ["US", "CA", "BS", "DO", "HT", "AU", "FR", "MX"];
        var muscadineCountries = ["US", "CA", "BS", "DO", "HT", "MX"];
        var zinzinoCountries = ["US", "CA", "BS", "DO", "HT", "AU", "FR"];
        var snaxXebecCountries = ["US", "CA", "BS", "DO", "HT"];
        var satoshiCountries = ["US", "BS", "DO", "HT"];
        var bShowMarketplace = (marketplaceCountries.indexOf(country) > -1);
        var bShowZinzino = (zinzinoCountries.indexOf(country) > -1);
        var bShowMuscadine = (muscadineCountries.indexOf(country) > -1);
        var bShowSnax = (snaxXebecCountries.indexOf(country) > -1);
        var bShowXebec = (snaxXebecCountries.indexOf(country) > -1);
        var bShowSatoshi = (satoshiCountries.indexOf(country) > -1);
        var bShowHoneyBars = (snaxXebecCountries.indexOf(country) > -1);
        var bShowGoldShield = (snaxXebecCountries.indexOf(country) > -1);
        var bShowOlivier = (snaxXebecCountries.indexOf(country) > -1);
        var bShowAutoship = true;

        setHomeIconDisplay(prevState => ({
            marketplaceIconDisplay: {
                ...prevState.marketplaceIconDisplay,
                displayicon: bShowMarketplace,
            },
            goldshieldIconDisplay: {
                ...prevState.goldshieldIconDisplay,
                displayicon: bShowGoldShield,
            },
            olivierIconDisplay: {
                ...prevState.olivierIconDisplay,
                displayicon: bShowOlivier,
            },
            zinzinoIconDisplay: {
                ...prevState.zinzinoIconDisplay,
                displayicon: bShowZinzino,
            },
            muscadineIconDisplay: {
                ...prevState.muscadineIconDisplay,
                displayicon: bShowMuscadine,
            },
            snaxIconDisplay: {
                ...prevState.snaxIconDisplay,
                displayicon: bShowSnax,
            },
            xebecIconDisplay: {
                ...prevState.xebecIconDisplay,
                displayicon: bShowXebec,
            },
            satoshiIconDisplay: {
                ...prevState.satoshiIconDisplay,
                displayicon: bShowSatoshi,
            },
            honeyBarsIconDisplay: {
                ...prevState.honeyBarsIconDisplay,
                displayicon: bShowHoneyBars,
            },
            autoShipIconDisplay: {
                ...prevState.autoShipIconDisplay,
                displayicon: bShowAutoship,
            }
        }));
    }, [country]);


    useEffect(() => {

    }, [market, customerLevelID]);


    const onFeaturedProductsHandler = () => {
        history.push(`${match.url}/category/${793}`);
    };

    const onAutoShipClick = () => {
        shopStore.toggleAutoshipBanner();
        window.scrollTo(0, 0);
    };


    const homeIcons = useMemo(() => {
        let homeIcons = [];
        for (const key of Object.keys(homeIconDisplay)) {
            if (homeIconDisplay[key].displayicon === true) {
                homeIcons.push(
                    <div key={homeIconDisplay[key].imageURL} className="owl-stage">
                        <Link className={classes.link} to={`${match.url}${homeIconDisplay[key].link}`} key={homeIconDisplay[key].link}>
                            <img
                                className={classes.pic}
                                src={homeIconDisplay[key].imageURL}
                                alt=""
                            />
                        </Link>
                    </div>);
            }
        }
        return homeIcons;
    }, [homeIconDisplay]);

    let displayLoop = false;
    let center = false;
    if (homeIcons.length > 4) {
        displayLoop = true;
        center = true;
    }
    const options = {
        loop: displayLoop,
        nav: false,
        center: center,
        autoplay: false,
        dots: false,
        mouseDrag: true,
        touchDrag: true,
        responsive: {
            0: {
                items: 3,
            },
            350: {
                items: 4,
            },
            1000: {
                items: 6,
            },
        }
    };

    return <Container maxWidth={"md"} className="main-content__wrapper">
        {promotionalProducts
            && <div className="main-content__promotional-products animated fadeIn delay-.1s">
                <PromotionalProductsCarousel
                    parentCategoryID={categoryId}
                    shoppingMatch={shoppingMatch}
                    products={promotionalProducts}
                />
            </div>}
        <Grid container className={classes.featuredProducts}>
            {featuredProducts
                && <>
                    <Grid container item className="main-content__featured-products__header">
                        <Grid container item alignItems={"center"} xs={6}><Typography variant={"subtitle1"}>{t('shopping:home.featuredproducts')}</Typography></Grid>
                        <Grid container item alignItems={"center"} xs={6} justify={"flex-end"}><Typography variant={"subtitle1"} onClick={onFeaturedProductsHandler}>{t('shopping:subcategories.seeall')}</Typography></Grid>
                    </Grid>
                    {
                        featuredProducts.length < 4 ? (
                            <div>
                                <ProductList
                                    category={'featured-products'}
                                    products={featuredProducts}
                                />
                            </div>
                        ) : (
                            <FeaturedProductsCarousel products={featuredProducts} parentMatch={match} />
                        )
                    }
                </>}

        </Grid>
        <div className="main-content__sub-categories">
            {
                homeIcons.length > 0 ?
                    (
                        <OwlCarousel options={options}>
                            {homeIcons}
                        </OwlCarousel>
                    ) : ('')
            }
        </div>

        <div className="main-content__categories">
            <MainCategoryListContainer
                homeContainerTitle={'Categories'}
                categoryItems={publicCategories}
            />
        </div>

        {

            (isGuest || !currentUser || !currentUser.isMember) ? (
                ''
            ) : (
                <div className="main-content__member">
                    <hr />
                    <MainCategoryListContainer
                        homeContainerTitle={'Membery Categories Only'}
                        categoryItems={memberCategories}
                        onAutoShipClick={onAutoShipClick}
                    />
                </div>
            )
        }
    </Container>;

}

export default observer(HomeMainContent);