import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/styles";
import { useStores } from "../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Alert from "@material-ui/lab/Alert";
import MaterialTextField from "../../components/inputs/MaterialTextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import IOSSwitch from "../../components/IOSSwitch";
import PageTitle from "../../components/PageTitle";
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: theme.spacing(1),
        position: "relative",
        marginBottom: theme.spacing(3),

    },
    spouseEnableSection: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    infoIcon: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    popoverBody: {
        padding: theme.spacing(1)
    },
    textField: {

    },
}));

export default observer(function UserProfile() {
    const classes = useStyles();
    const { authStore, userProfileStore, commonStore } = useStores();
    const [anchorEl, setAnchorEl] = useState(null);

    const { spouseUsername } = authStore;

    const { userEditForm, spouseEditForm, userProfile, userPid, userProfileLoading, userPidLoading
        , userCountry, userAvailableRegions, error, spouseEnabled } = userProfileStore;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        (async () => {
            await userProfileStore.createEditForm(() => { commonStore.success("User Profile updated"); });
            await Promise.all([
                await userProfileStore.loadUserProfile()
                , await userProfileStore.loadUserPid()
                , await userProfileStore.loadAllCountries()]);

            await userProfileStore.loadRegionsByCountry(userProfileStore.userProfile.address.country);
            await userProfileStore.updateZipRules();
        })();

    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRegionChange = (e) => {
        userProfileStore.setRegion(e.target.value);
    };

    return <>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {(userProfileLoading || userPidLoading || !userProfile || userPidLoading)
            ? <CircularProgress />
            : <>
                <PageTitle>Personal Info ({commonStore.onStarfishOrRoyalStarfish ? 'Account' : 'Life'} #: {userProfile.userID})</PageTitle>
                {commonStore.onStarfishOrRoyalStarfish && <Typography style={{ color: '#265dbf', marginBottom: '8px' }} variant="body1">To make a name or email address change, please contact us using Profile &gt; Help.</Typography>}
                <Typography variant={"subtitle1"}> Primary Account Information </Typography>

                <Paper className={classes.section}>
                    {!commonStore.onStarfishOrRoyalStarfish && <>
                        <IconButton onClick={handleClick} className={classes.infoIcon}><InfoIcon /></IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Paper elevation={3} className={classes.popoverBody}>
                                If you need to change this <br /> number, please contact <br /><a
                                    href="mailto:support@mainhomepage.com">support@mainhomepage.com</a>.
                            </Paper>
                        </Popover>
                    </>
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                            <TextField
                                fullWidth
                                label="First Name"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true
                                }}
                                value={userProfile.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true
                                }}
                                value={userProfile.lastName}
                            />
                        </Grid>
                        {!commonStore.onStarfishOrRoyalStarfish && <Grid item xs={12} md={5}>
                            <TextField
                                fullWidth
                                label="SSN"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true
                                }}
                                value={userPid}
                            />
                        </Grid>
                        }
                        <Grid item xs={12} md={12}>
                            <MaterialTextField
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true
                                }}
                                field={userEditForm.$("email")}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MaterialTextField
                                fullWidth
                                label="Mobile Phone"
                                margin="normal"
                                field={userEditForm.$("phone")}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Select
                                fullWidth
                                label="Language"
                                className={classes.textField}
                                {...userEditForm.$("preferredLanguage").bind()}>
                                <MenuItem value={'en'}>English</MenuItem>
                                <MenuItem value={'fr'}>Français</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

                </Paper>

                {!commonStore.onStarfishOrRoyalStarfish &&
                    <>
                        {(spouseUsername && !userProfileStore.deletedSpouse) &&
                            <Paper className={clsx(classes.section, classes.spouseEnableSection)}>
                                <Typography>Spouse Credentials</Typography>
                                <IOSSwitch checked={spouseEnabled} onChange={e => { userProfileStore.setSpouseEnabled(!spouseEnabled); }} />
                            </Paper>
                        }

                        {spouseEnabled &&
                            <>
                                <Typography variant="subtitle1">Secondary Account Information</Typography>
                                <Paper className={classes.section}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={spouseEditForm.$("firstName")}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={spouseEditForm.$("lastName")}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={spouseEditForm.$("email")}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={spouseEditForm.$("phone")}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </>}
                    </>
                }
                <Typography variant="subtitle1">Address Information</Typography>
                <Paper className={classes.section}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <MaterialTextField
                                fullWidth
                                margin="normal"
                                field={userEditForm.$("address1")}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MaterialTextField
                                fullWidth
                                margin="normal"
                                field={userEditForm.$("address2")}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MaterialTextField
                                fullWidth
                                margin="normal"
                                field={userEditForm.$("city")}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputLabel shrink>State/Province</InputLabel>
                            <Select
                                fullWidth
                                autoWidth={true}
                                className={classes.textField}
                                value={userProfile.address.region || ""}
                                onChange={handleRegionChange}>
                                {userAvailableRegions.map(x => {
                                    return <MenuItem key={x.shortCode} value={x.shortCode}>{x.name}</MenuItem>;
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MaterialTextField
                                fullWidth
                                margin="normal"
                                field={userEditForm.$("zip")}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="Country"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true
                                }}
                                value={userCountry ? userCountry.countryName : ""}
                            />
                        </Grid>

                    </Grid>
                </Paper>
                <Button fullWidth variant="contained"
                    onClick={userEditForm.onSubmit}
                    color="primary" className={classes.textField} >Save</Button>
            </>
        }
    </>;
});