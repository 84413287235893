import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useStores } from "../hooks/use-stores";
import { observer } from "mobx-react-lite";


function ConfirmationModal() {
    const { commonStore } = useStores();
    let { confirmationRequired, confirmationDescription, confirmationAction
        , confirmationTitle, confirmationShowCancel, confirmationHtmlDescription
        , confirmationCancelTitle = 'Cancel' } = commonStore;

    const onConfirm = async (arg) => {
        return commonStore.doConfirm(arg);
    };

    const renderAction = (confirmationAction) => {
        if ( confirmationAction == null ) return null;
        if (Array.isArray(confirmationAction)) {
            return confirmationAction.map((action, i) => (
                <Button
                    color={action.color || "primary"}
                    variant={action.variant}
                    key={i}
                    onClick={() => onConfirm(action.key)}
                // style={{marginTop:"0.5rem"}}
                >
                    {action.value}
                </Button>
            )
            );
        } else {
            return (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onConfirm}
                // style={{marginTop:"0.5rem"}}
                >
                    {confirmationAction}
                </Button>
            );
        }
    };

    const handleClose = () => {
        commonStore.cancelConfirm();
    };

    return (
        confirmationRequired
        && <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                {confirmationTitle}
            </DialogTitle>
            <DialogContent dividers>
                {confirmationHtmlDescription
                    ? <div dangerouslySetInnerHTML={{__html:confirmationDescription}}/>
                    : confirmationDescription }
            </DialogContent>
            <DialogActions>
                {confirmationShowCancel &&
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="contained"
                    >
                        {confirmationCancelTitle}
                    </Button>
                }
                {renderAction(confirmationAction)}{' '}
            </DialogActions>
        </Dialog>
    );
}

export default observer(ConfirmationModal);