import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import { joinPath } from "../../utils/helpers";
import Home from "../../components/shop/Home/Home";
import ProductCategory from "../../components/shop/ProductCategory/ProductCategory";
import SubCategoryList from './SubCategoryList/SubCategoryList';
import { useStores } from "../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/shop/Header";
import Footer from "../../components/shop/Footer";
import { makeStyles } from "@material-ui/core/styles";
import ProductDetail from "./ProductDetail/ProductDetail";
import Search from "./Search/Search";
import Cart from "./Cart/Cart";
import AutoShipBanner from "../../components/shop/AutoShipBanner";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import AutoShip from "./AutoShip";
import ReactMarkdown from 'react-markdown'
import Subscriptions from "./Subscriptions";
import {ShopContext, shopTypes } from "./shopContext"
import {matchPath} from "react-router";

const useStyles = makeStyles((theme) => ({

    shopWrapper: {
        height: "100%",
        minHeight: "100vh",
        background: "black",
        color: "white"
    },
    spacer: {
        height: 30
    }
}));


export default observer(function ShopIndex({ hideNav, shoppingPath, shoppingHome = "/shop", currentItem }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showFooter, setShowFooter] = useState(false);
    const shoppingMatch = useRouteMatch();
    const { authStore, shopStore } = useStores();
    const { isGuest, isQuickBuy, currentUser } = authStore;
    const { showAutoshipModal, baselineLoading, parentCategoryIds, market, userCartGuid, customerLevelID, userCustomerLevelID } = shopStore;


    let autoshipMatch = useRouteMatch({
        path: joinPath(shoppingMatch.url, 'autoship')
    });

    let autoshipProMatch = useRouteMatch({
        path: joinPath(shoppingMatch.url, 'autoshippro')
    });

    let subscriptionsMatch = useRouteMatch({
        path: joinPath(shoppingMatch.url, 'subscriptions')
    });


    let inlineCartMatch = matchPath(shoppingMatch.url, {
        path: joinPath( shoppingHome, '/cart' ),
        exact: true,
        strict: true
    })


    const shopParams = useMemo(()=>{
        if ( subscriptionsMatch ){
            return shopTypes.subscriptions
        }
        else if ( autoshipMatch || autoshipProMatch ){
            return shopTypes.autoship
        }
        else {
            return shopTypes.standard
        }

    }, [subscriptionsMatch,autoshipMatch, autoshipProMatch])


    let subCategoryMatch = useRouteMatch({
        path: joinPath(shopParams.home, 'category/:categoryId/sub-category/:subCategoryId')
    }) || matchPath( joinPath( "/", shoppingPath), {
        path: '/category/:categoryId/sub-category/:subCategoryId',
        exact: true,
        strict: true
    }) || matchPath(joinPath( shoppingPath, (currentItem && currentItem.rest) ), {
        path: '/category/:categoryId/sub-category/:subCategoryId',
        exact: true,
        strict: true
    });


    let categoryMatch = useRouteMatch({
        path: joinPath(shopParams.home, 'category/:categoryId')
    }) || matchPath( joinPath( "/", shoppingPath), {
        path: '/category/:categoryId',
        exact: true,
        strict: true
    })

    let legacyProductMatch = useRouteMatch({
        path: joinPath(shopParams.home, 'category/:categoryId/product-detail/:productSKU/:subCategoryId')
    }) ||  matchPath(joinPath('/', shoppingPath, (currentItem && currentItem.rest) ), {
        path: '/category/:categoryId/product-detail/:productSKU/:subCategoryId',
        exact: true,
        strict: true
    });


    let productMatch = useRouteMatch({
        path: joinPath(shopParams.home, 'category/:categoryId/product-detail/:productSKU')
    }) ||  matchPath(joinPath("/", shoppingPath, (currentItem && currentItem.rest) ), {
        path: '/category/:categoryId/product-detail/:productSKU',
        exact: true,
        strict: true
    });


    let productMatchWithSubCategory = useRouteMatch({
        path: joinPath(shopParams.home, 'category/:categoryId/sub-category/:subCategoryId/product-detail/:productSKU')
    }) ||  matchPath(joinPath( "/", shoppingPath, (currentItem && currentItem.rest) ), {
        path: '/category/:categoryId/sub-category/:subCategoryId/product-detail/:productSKU',
        exact: true,
        strict: true
    });
    //
    // console.log( "subCategoryMatch", subCategoryMatch);
    // console.log( "productMatch", productMatch);
    // console.log( "productMatchWithSubCategory", productMatchWithSubCategory);

    useEffect(() => {
        if (userCartGuid && (userCustomerLevelID || customerLevelID))
            shopStore.getUserCart({ cartGuid: userCartGuid, customerLevelID: customerLevelID || userCustomerLevelID });
    }, [userCartGuid, userCustomerLevelID, customerLevelID]);


    useEffect(() => {
        if (currentUser)
            authStore.loadShopUserDetails();
    }, [currentUser]);




    useEffect(() => {
        shopStore.loadBaseline();
        shopStore.createCartGuid();

        if (isGuest) {
            // const country = JSON.parse(getCookie('guest_country'));
            // if (country && country.countryValue) {
            //   this.setMarketplaceStatus(country.countryValue);
            // } else {
            //   this.setMarketplaceStatus('');
            // }
            setShowFooter(true);
        } else {
            setShowFooter(true);
        }
    }, [market]);

    const parentCategory = useMemo(() => {
        if (!parentCategoryIds || !categoryMatch) return null;
        return parentCategoryIds.find((category) => {
            return category.parentCategoryID === Number(categoryMatch.params.categoryId);
        });
    }, [parentCategoryIds, categoryMatch]);

    const childCategory = useMemo(() => {
        if (!parentCategory || !subCategoryMatch || !parentCategory.childCategories) return null;
        return parentCategory.childCategories.find((childCategory) => {
            return childCategory.childCategoryID === Number(subCategoryMatch.params.subCategoryId);
        });

    }, [parentCategory, subCategoryMatch]);

    const toggleAutoshipModal = function () {
        shopStore.toggleAutoshipModal();
    };

    const subscribeAutoShipPro = async () => {
        const cart_guid = await shopStore.purchaseAutoshopPro();

        window.location.replace(`${window.location.origin}/checkout?cart_guid=${cart_guid}`);
    };

    const inlineShop= useMemo(()=>{
        return inlineCartMatch|| ( shoppingPath && ( subCategoryMatch  || productMatchWithSubCategory || productMatch || categoryMatch || legacyProductMatch ))
        }
        ,[inlineCartMatch, subCategoryMatch, productMatchWithSubCategory, productMatch, categoryMatch, legacyProductMatch])

    if ( legacyProductMatch )
        return <Redirect to={joinPath( inlineShop? shoppingHome : shopParams.home,
            'category', legacyProductMatch.params.categoryId,
            'sub-category', legacyProductMatch.params.subCategoryId,
            'product-detail', legacyProductMatch.params.productSKU)} />

    return <ShopContext.Provider value={shopParams}>
        <div className={classes.shopWrapper}>
        {(hideNav || isQuickBuy) ? <div className={classes.spacer} /> : <Header isGuest={isGuest}
            autoship={autoshipMatch || autoshipProMatch}
            subscriptions={subscriptionsMatch}
            shoppingMatch={shoppingMatch}
            parentCategory={parentCategory}
            childCategory={childCategory} />}

        {baselineLoading ? <CircularProgress /> :<>
            {(inlineShop)
                ? <>
                    {inlineCartMatch  && <Cart shoppingMatch={shoppingMatch} shoppingRoot={{url:shoppingHome}} />}
                    {subCategoryMatch && !productMatch && !productMatchWithSubCategory &&
                    <SubCategoryList shoppingMatch={shoppingMatch}
                                     categoryId={subCategoryMatch.params.categoryId}
                                     subCategoryId={subCategoryMatch.params.subCategoryId}
                    />}
                    {productMatch &&
                    <ProductDetail shoppingMatch={shoppingMatch}
                                   shoppingRoot={{url:shoppingHome}}
                                     categoryId={productMatch.params.categoryId}
                                     productSKU={productMatch.params.productSKU}
                    />}

                    {productMatchWithSubCategory &&
                    <ProductDetail shoppingMatch={shoppingMatch}
                                   shoppingRoot={{url:shoppingHome}}
                                     categoryId={productMatchWithSubCategory.params.categoryId}
                                     productSKU={productMatchWithSubCategory.params.productSKU}
                    />}

                    {categoryMatch &&
                        <ProductCategory
                            shoppingMatch={shoppingMatch}
                            categoryId={categoryMatch.params.categoryId} />}
                </>
                : <Switch>
                    <Route path={shoppingMatch.url} exact render={() => <Home shoppingMatch={shoppingMatch}/>}/>

                    <Route
                        path={joinPath(shoppingMatch.url, `category/:categoryId/sub-category/:subCategoryId/product-detail/:productSKU`)}
                        render={() => <ProductDetail shoppingMatch={shoppingMatch}
                                                     categoryId={productMatchWithSubCategory.params.categoryId}
                                                     subCategoryId={productMatchWithSubCategory.params.subCategoryId}
                                                     productSKU={productMatchWithSubCategory.params.productSKU}/>}/>

                    <Route path={joinPath(shoppingMatch.url, `category/:categoryId/product-detail/:productSKU`)}
                           render={() => <ProductDetail shoppingMatch={shoppingMatch}
                                                        categoryId={productMatch.params.categoryId}
                                                        productSKU={productMatch.params.productSKU}/>}/>

                    <Route path={joinPath(shoppingMatch.url, `category/:categoryId/sub-category/:subCategoryId`)}
                           render={(params) => <SubCategoryList
                               shoppingMatch={shoppingMatch}
                               categoryId={subCategoryMatch && subCategoryMatch.params.categoryId}
                               subCategoryId={subCategoryMatch && subCategoryMatch.params.subCategoryId}/>}
                           />

                    <Route path={joinPath(shoppingMatch.url, `category/:categoryId`)}
                           render={(props) => <ProductCategory
                               shoppingMatch={shoppingMatch}
                               categoryId={props.match.params.categoryId} />}/>

                    <Route path={joinPath(shoppingMatch.url, `autoship`)}
                           render={() => <AutoShip/>}/>

                    <Route path={joinPath(shoppingMatch.url, `subscriptions`)}
                           render={() => <Subscriptions/>}/>

                    <Route path={joinPath(shoppingMatch.url, `search`)}
                           render={() => <Search shoppingMatch={shoppingMatch}/>}/>

                    <Route path={joinPath(shoppingMatch.url, `cart`)}
                           render={() => <Cart shoppingMatch={shoppingMatch}/>}/>
                </Switch>
            }
        </>
            }
        {showAutoshipModal && <Dialog open={showAutoshipModal} onClose={toggleAutoshipModal} maxWidth={"sm"}>
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Typography></Typography>
                    <div style={{ cursor: "pointer" }} onClick={toggleAutoshipModal}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <ReactMarkdown>
                    {t('shopping:autoship.subscribeautoshippro_fulltext')}
                </ReactMarkdown>
            </DialogContent>

            <DialogActions>
                <Button variant={"contained"} color={"secondary"}
                    onClick={subscribeAutoShipPro}
                    type="button"
                    className="btn btn-primary" data-dismiss="modal">{t('shopping:autoship.subscribeautoshippro')}
                </Button>
            </DialogActions>

        </Dialog>}

        {showFooter && <Footer />}
    </div></ShopContext.Provider>;
});