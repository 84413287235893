import React from "react";
import {observer} from "mobx-react";


class Signature extends React.Component {
    componentDidMount() {
        // eslint-disable-next-line no-undef
        this.$el = $(this.el);
        // eslint-disable-next-line no-undef
        this.$canvas = $(this.canvas_el);
        this.$canvas.attr("width", this.$el.width()-2)
        this.signaturePad = this.$el.signaturePad({drawOnly: true
            , defaultAction:"drawIt"
            , lineTop:65
            , onDrawEnd: ( )=>{ this.handleChange()}
            , onDraw: ( )=>{ this.handleChange()}
        });


    }

    componentDidUpdate(prevProps) {

    }

    componentWillUnmount() {
       // this.signaturePad.dispose();
    }

    handleChange(e) {
       // setImmediate( ()=>{
            this.props.onChange(this.signaturePad.getSignature());
       // })

    }

    render() {
        return <form method="post" action="" className="sigPad" ref={el => this.el = el} >

                <div className="sig sigWrapper">
                    <canvas className="pad"  height="78" ref={el => this.canvas_el = el}/>
                </div>
                <ul className="sigNav">
                    <li className="clearButton" onClick={e=>{ this.handleChange()} }><a href="#clear">Clear</a></li>
                </ul>
                <input type="hidden" name="output" className="output" ref={el => this.outputEl = el}/>
        </form>

    }
}

export default observer( Signature )