import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { joinPath } from "../../utils/helpers";
import ProductCategory from "../../components/shop/ProductCategory/ProductCategory";
import { useStores } from "../../hooks/use-stores";
import ProductDetail from "./ProductDetail/ProductDetail";
import Cart from "./Cart/Cart";
import AutoShipStatusBanner from "../../components/shop/AutoshipStatusBanner";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import AutoShipBanner from "../../components/shop/AutoShipBanner";
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    newAutoshipText: {
        whiteSpace: "pre-line"
    }

}));

export default observer(function ShopIndex() {
    const MyContext = React.createContext("autoship");
    const autoShipMatch = useRouteMatch();
    const { authStore, shopStore } = useStores();
    const { currentUser } = authStore;
    const { autoshipIsCancelled, autoshipIsExisting, autoshipIsNew } = shopStore;
    const { t } = useTranslation("shopping");
    const classes = useStyles();


    useEffect(() => {
        if (currentUser)
            authStore.loadShopUserDetails();
        shopStore.loadCurrentAutoShipInfo();
    }, [currentUser]);


    const toggleAutoshipModal = function () {
        shopStore.toggleAutoshipModal();
    };


    useEffect(() => {
        shopStore.loadAutoShipCartItems();
    }, []);


    return <Switch>
        <Route path={autoShipMatch.url} exact
            render={() =>
                <>
                    <Container maxWidth={"md"}>
                        {autoshipIsCancelled &&
                            <Alert color={"error"} variant={"filled"}>
                                {t("autoship_messages.welcome_back")}<br /><br />
                                {t("autoship_messages.cancelled")}
                            </Alert>
                        }

                        {autoshipIsExisting &&
                            <Alert color={"success"} variant={"filled"}>
                                {t("autoship_messages.welcome_back")}<br /><br />
                                {t("autoship_messages.existing")}
                            </Alert>
                        }

                        {autoshipIsNew &&
                            <Alert color={"info"} variant={"filled"}>
                                {t("autoship_messages.welcome_new")}<br /><br />
                                <ReactMarkdown>
                                    {t('shopping:autoship_messages.new')}
                                </ReactMarkdown>
                            </Alert>
                        }
                    </Container>
                    <ProductCategory shoppingMatch={autoShipMatch} autoship={true} />
                </>
            } />

        <Route path={joinPath(autoShipMatch.url, `category/:categoryId/product-detail/:productSKU`)}
            render={(p) => <ProductDetail shoppingMatch={autoShipMatch} autoship={true}
                                               categoryId={p.match.params.categoryId}
                                               productSKU={p.match.params.productSKU}
                                               params />} />

        <Route path={joinPath(autoShipMatch.url, `category/:categoryId`)}
            render={(p) => <ProductCategory shoppingMatch={autoShipMatch} autoship={true}
                                            categoryId={p.match.params.categoryId}
            />} />

        <Route path={joinPath(autoShipMatch.url, `cart`)}
            render={(p) => <Cart shoppingMatch={autoShipMatch} autoship={true} />} />
    </Switch>;
});