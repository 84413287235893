import React from 'react';
import {
    Box,
    Button,
    Container,
    makeStyles,
} from "@material-ui/core";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: 'white',
        marginBottom: theme.spacing(2),
        padding: 0,
        alignItems: 'center',
        display: 'grid',
        gridGap: '1px',
        gridTemplateColumns: `repeat(4, 1fr)`,
        justifyContent: 'center',
    },
    notification: {
        background: '#c0000d',
        borderRadius: '10px',
        color: 'white',
        padding: `0px ${theme.spacing(.6)}px`,
        marginRight: `2px`,
        marginTop: '2px',
        alignSelf: 'flex-start',
        fontSize: '.6rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '.7rem',
        }
    },
    navButton: {
        textAlign: 'center',
        fontSize: '11px',
        color: 'white',
        textTransform: 'none',
        backgroundColor: 'rgba(73,161,221, 1)',
        borderRadius: 0,
        padding: `${theme.spacing(.5)}px 0px`,
        height: '35px',
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            height: '50px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem',
        },
        '&:hover': {
            backgroundColor: 'rgba(73,161,221, .5)',
        },
        '& > span': {
            height: '100%'
        },
    },
    listName: {
        display: 'flex',
        marginRight: `${theme.spacing(.4)}px`,
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    selectedList: {
        fontWeight: '900',
        borderRadius: 0,
    }
}));

const NavBar = ({ items, currentTab, onSwitchTab, notificationCount }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container className={classes.wrapper}>
            {
                Object.values(items).map(tab =>
                    <Button key={tab.name} className={clsx(classes.navButton, currentTab == tab && classes.selectedList)} onClick={() => onSwitchTab(tab)}>
                        <span className={classes.notification}>{notificationCount[tab.notificationID]}</span>
                        <Box className={classes.listName}>
                            <span>{t(tab.name)}</span>
                        </Box>
                    </Button>
                )
            }
        </Container>
    );
};
export default NavBar;
