import Auth from "./AuthService";
import UserProfile from "./UserProfileService";
import Categories from "./CategoryService";
import Library from "./LibraryService";
import MenuItems from "./MenuItemsService";
import Members from "./MembersService";
import Status from "./StatusService";
import Notifications from "./NotificationsService";
import News from "./NewsService";
import Web from "./WebService";
import Utility from "./UtilityService";
import Spouse from "./SpouseService";
import Sendout from "./SendoutService";
import ShoppingCart from './ShoppingCartService';
import Enrollment from "./EnrollmentService";
import MemberEnrollment from "./MemberEnrollmentService";
import AutoShipService from "./AutoShipService";
import ShareService from "./ShareService";
import OnboardService from "./OnboardService";
import ConventionProgramService from "./ConventionProgramService";
import SubscriptionService from "./SubscriptionService";
import BaseLoadService from "./BaseLoadService";
import ShoppingBossService from "./ShoppingBossService";
import HelpService from "./HelpService";
import SupportService from "./SupportService";
import SeminarWebinarService from "./SeminarWebinarService";
import LifelineService from "./LifelineService";
import Spark from "./Spark";

export default {
    Auth: new Auth(),
    Base: new BaseLoadService(),
    Spark: new Spark(),
    UserProfile: new UserProfile(),
    Categories: new Categories(),
    Library: new Library(),
    Members: new Members(),
    MenuItems: new MenuItems(),
    Status: new Status(),
    Notifications: new Notifications(),
    News: new News(),
    Spouse: new Spouse(),
    Web: new Web(),
    Utility: new Utility(),
    Sendout: new Sendout(),
    ShoppingCart: new ShoppingCart(),
    Enrollment: new Enrollment(),
    MemberEnrollment: new MemberEnrollment(),
    AutoShip: new AutoShipService(),
    Subscription: new SubscriptionService(),
    Share: new ShareService(),
    Onboard: new OnboardService(),
    ConventionProgramService: new ConventionProgramService(),
    ShoppingBoss: new ShoppingBossService(),
    Help: new HelpService(),
    Support: new SupportService(),
    SeminarWebinarService: new SeminarWebinarService(),
    LifelineService: new LifelineService()
};

