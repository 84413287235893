import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import MaterialTextField from "../inputs/MaterialTextField";
import MaterialSelect from "../inputs/MaterialSelect";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import some from "lodash/some";
import MaskedMaterialTextField from "../inputs/MaskedMaterialTextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardBlock from "../inputs/CardBlock";
import Alert from "@material-ui/lab/Alert";
import {makeStyles} from "@material-ui/core/styles";
import {securityBanner} from "../styles";

const useStyles = makeStyles((theme) => ({
    ...securityBanner(theme)
}));



function BillingInfoForm({ existingPayments, form, paymentID, regionsByCountry, allCountries
    , handlePaymentMethodChanged, buttonText
    , handleCountryChanged, onSubmit, translationPrefix = "enrollment", cardFirst=true , securityNameBanner= false  }) {
    const { t } = useTranslation();

    const classes = useStyles()
    const haveCards = existingPayments && some(existingPayments, x => x.paymentID !== 0);
    useEffect(() => {
        if (haveCards) {
            let defaultCard = existingPayments.find(x => x.primary);
            if (defaultCard) {
                handlePaymentMethodChanged(defaultCard.paymentID);
            }

        }
    }, []);

    const [showCard, setShowCard] = useState(!haveCards);
    const handleExisting = (e) => {
        if (e.target.value !== 0)
            setShowCard(false);
        handlePaymentMethodChanged(e.target.value);
    };

    const handleAddNew = (e) => {
        setShowCard(true);
        form.showErrors(false);
        handlePaymentMethodChanged(0);
    };


    return form ? <Grid container spacing={3} justify={"center"} alignItems={"center"}>
        {haveCards &&
            <>
                <Grid item xs={12} lg={9}>
                    <Typography variant={"subtitle1"}>{t(`${translationPrefix}:usepaymentmethod`)}</Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel >{t(`${translationPrefix}:selectpaymentmethod`)}</InputLabel>
                        <Select
                            fullWidth
                            value={paymentID}
                            onChange={handleExisting}
                            label={t(`${translationPrefix}:selectpaymentmethod`)}
                        >
                            {[{ paymentID: 0 }, ...existingPayments].map(x => {
                                return <MenuItem key={x.paymentID} value={x.paymentID}>
                                    {
                                        (x.paymentID === 0)
                                            ? t(`${translationPrefix}:selectpaymentmethod`)
                                            : `${x.type} *******${x.last4}`
                                    }
                                </MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Button disabled={showCard} onClick={handleAddNew}
                        color={"primary"}
                        variant={"text"}>{t(`${translationPrefix}:addnewpaymentmethod`)}</Button>
                </Grid>
            </>
        }

        {(showCard || !haveCards) &&
            <>
            {securityNameBanner &&  <Alert severity={"info"}
                                              className={classes.securityBanner}
                                              variant={"filled"}>{t("security:cardNameBanner")}</Alert>}

                {cardFirst && <CardBlock form={form}/> }

                {form.has("billFirstName") && <Grid item xs={12} lg={9}>
                    <MaterialTextField
                        fullWidth
                        margin="normal"
                        field={form.$("billFirstName")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>}
                {form.has("billLastName") && <Grid item xs={12} lg={9}>
                    <MaterialTextField
                        fullWidth
                        margin="normal"
                        field={form.$("billLastName")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>}
                {form.has("billCountry") && <Grid item xs={12} lg={9}>
                    <MaterialSelect
                        fullWidth
                        label="Language"
                        margin="normal"
                        labelAccessor={(x => x.countryName)}
                        valueAccessor={(x => x.countryShortCode)}
                        field={form.$("billCountry")}
                        options={allCountries}
                        onChange={handleCountryChanged} />
                </Grid>}
                {form.has("billAddress") && <Grid item xs={12} lg={9}>
                    <MaterialTextField
                        fullWidth
                        margin="normal"
                        field={form.$("billAddress")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>}
                {form.has("billCity") && <Grid item xs={12} lg={9}>
                    <MaterialTextField
                        fullWidth
                        margin="normal"
                        field={form.$("billCity")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>}
                {form.has("billPostalCode") && <Grid item xs={12} lg={9}>
                    {(form.$("billPostalCode").extra
                        && form.$("billPostalCode").extra.mask)
                        ? <MaskedMaterialTextField
                            margin="normal"
                            field={form.$("billPostalCode")}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        : <MaterialTextField
                            fullWidth
                            margin="normal"
                            field={form.$("billPostalCode")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    }
                </Grid>}
                {(form.has("billRegion") && form.has("billCountry")) && <Grid item xs={12} lg={9}>
                    <MaterialSelect
                        fullWidth
                        label="Language"
                        margin="normal"
                        labelAccessor={(x => x.name)}
                        valueAccessor={(x => x.shortCode)}
                        field={form.$("billRegion")}
                        options={regionsByCountry[form.$("billCountry").value] || []} />
                </Grid>}

                {!cardFirst && <CardBlock form={form}/> }
            </>}
        {onSubmit && <Grid item xs={12} lg={7}>
            <Button onClick={onSubmit} fullWidth variant={"contained"}
                color="primary">{buttonText?buttonText: t(`${translationPrefix}:continue`)}</Button>

        </Grid>}
    </Grid> : null;
}

export default observer(BillingInfoForm);