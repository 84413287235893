import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
const useStyles = makeStyles((theme) => ({


    large: {
        width: theme.spacing(16),
        height: theme.spacing(16)
    },

}));


function ItemDialog({ item, hideItemModal, onConfirm }) {
    const { t } = useTranslation();
    const classes = useStyles();
    return <Dialog maxWidth={"xs"} fullWidth={"xs"} open={true} onClose={hideItemModal}>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid container item justify={"center"} xs={12}>
                    <Avatar variant={"square"} className={classes.large} src={item.imageURL} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">{item.productTitle}</Typography>
                </Grid>
                <Grid item justify={"center"} xs={12}>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.productSummary }} />
                </Grid>
                <Grid item justify={"center"} xs={12}>
                    <Typography variant="body1"
                        dangerouslySetInnerHTML={{ __html: item.productDescription }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">{t("enrollmember:price")}:{item.productPrice}</Typography>
                    {!!item.productTC && item.productTC > 0
                        ? <Typography variant="body2">{t("enrollmember:tripcredits")}:{item.productTC}</Typography>
                        : ''
                    }
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </DialogContent>
        {onConfirm && <DialogActions>
            <Button onClick={hideItemModal} variant={"contained"}
                color="secondary">{t("enrollmember:cancel")}</Button>

            <Button onClick={e => {
                onConfirm(item);
            }} variant={"contained"}
                color="primary">{t("enrollmember:addtocart")}</Button>
        </DialogActions>}
    </Dialog>;
}


export default observer(ItemDialog);