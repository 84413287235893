import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@material-ui/core";
import {createTheme, makeStyles} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {joinPath} from "../../utils/helpers";

const useStyles = makeStyles((theme) => ({

    img: {
       width:32,
       height:32
    }
}));



function VerificationStatus({status, showLabel=true, layout="row", textVariant="subtitle1"}){
    const {t} = useTranslation("wallet");
    const classes = useStyles()



    const colorTheme = useMemo(()=>{
        let textColor;
        switch (status) {
            case "PENDING":
                textColor = "#f57c00"
                break;
            case "APPROVED":
                textColor = "#388e3c"
                break;
            case "NOACCOUNT":
            case "OPEN":
            case "CLOSED":
            default:
                textColor = "#d32f2f"
                break;

        }
        console.log(status, textColor)
        return createTheme({
            palette: {
                text:{
                    primary:textColor
                }
            },
        });
    }, [status])

    const label = useMemo(()=>{
        if ( ["NOACCOUNT", "OPEN" ].includes( status )  )
            return t("verificationStatuses.REQUIRED")
        return  t(`verificationStatuses.${status}`)
    }, [status])


    const img = useMemo(()=>{
        if ( ["NOACCOUNT", "OPEN", "CLOSED" ].includes( status )  )
            return  `${process.env.PUBLIC_URL}/img/closed.png`
        return  `${process.env.PUBLIC_URL}/img/${status?.toLowerCase()}.png`
    }, [status])

    if ( !status )
        return null
    return  <ThemeProvider theme={colorTheme}>
        <Box display={"flex"} alignItems={"center"} style={{gap:"5px"}} flexDirection={layout}>
            <img src={img} className={classes.img}></img>
            {showLabel && <Typography variant={textVariant} color={"textPrimary"} >{label}</Typography>}</Box>
    </ThemeProvider>

}

export default VerificationStatus