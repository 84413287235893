import {Route} from "react-router-dom";
import LibraryPack from "./LibraryPack";
import LibrarySeries from "./LibrarySeries";
import LibrarySpeaker from "./LibrarySpeaker";
import Library from "./Library";


function LibraryRoutes(prefix) {
    return [
        <Route path={`${prefix}/library/packs/:packId`}  render={()=><LibraryPack prefix={prefix} />} />,
        <Route path={`${prefix}/library/series/:seriesId/packs/:packId`} render={()=><LibraryPack prefix={prefix} />} />,
        <Route path={`${prefix}/library/series/:seriesId`} render={()=><LibrarySeries prefix={prefix} />}  />,
        <Route path={`${prefix}/library/speakers/:speakerId`} render={()=><LibrarySpeaker prefix={prefix} />}  />,
        <Route path={`${prefix}/library`} component={Library} />
    ]
}

export default LibraryRoutes