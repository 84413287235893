import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Divider, Grid, Link, List, ListItem, makeStyles, Tab, Typography } from '@material-ui/core';
import { Alert, TabContext, TabList, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import VideoModal from './VideoModal';
import Header from './Header';
import { useStores } from '../../hooks/use-stores';

const useStyles = makeStyles((theme) => ({
    container: {
        color: 'white',
        padding: 0,
        margin: 0,
        backgroundColor: 'rgba(255,255,255, .2)',
    },
    mainHeader: {
        fontSize: "4em",
        textTransform: 'uppercase',
    },
    childlessViewProgramHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sectionDivider: {
        margin: '0 auto',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        backgroundColor: 'rgba(255,255, 255, .4)',
        width: '40%'
    },
    tab: {
        '&.MuiButtonBase-root': {
            backgroundColor: '#ce1f42'
        },
        '&.MuiTab-root': {
            flexGrow: 1,
            backgroundColor: '#ce1f42',
            maxWidth: 'initial'
        },
        '&.MuiTabs-indicator': {
            display: "none"
        }
    },
    tabsList: {
        marginLeft: '0px',
        marginRight: '0px'
    },
    tabPanel: {
        padding: 0,
        paddingTop: theme.spacing(2)
    },
    tabPanelHeader: {
        padding: 0,
    },
    contentGrid: {
        width: '100%'
    },
    programGrid: {
        paddingTop: theme.spacing(.5),
    },
    programContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0
        }
    },
    sectionList: {
        paddingTop: 0,
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4)
    },
    sectionTitle: {
        fontWeight: 'bold',
        color: '#ce1f42',
        textTransform: 'uppercase',
        paddingLeft: 0,
    },
    eventMediaItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        justifyContent: 'center',
        marginBottom: theme.spacing(1)
    },
    programListItem: {
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        paddingLeft: 0,
    },
    programLink: {
        color: '#f8f9fa',
        textDecoration: "underline",
        '&:hover': {
            cursor: 'pointer'
        }
    },
    eventMediaButton: {
        backgroundColor: '#ce1f42',
        color: 'white',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        borderRadius: '25px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ce1f42',
        }
    },
    eventMediaListHeading: {
        fontWeight: 'bold',
        fontSize: '1.25rem',
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
    },
    playlistLink: {
        color: '#f8f9fa',
        textDecoration: "underline",
        "&:hover": {
            cursor: 'pointer',
        }
    },
    link: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    additionalSectionHeading: {
        fontSize: '2rem',
        textAlign: 'center'
    },
    additionalSectionSubeading: {
        color: 'lightgrey',
        fontSize: '1rem',
    },
    additionalSectionButton: {
        marginTop: theme.spacing(1)
    },
    unavailableText: {
        fontSize: "2rem",
        color: '#ce1f42',
    },
    watchEventContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    ticketButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ticketButtons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        }
    },
    purchaseTicketButton: {
        backgroundColor: '#ce1f42',
        color: 'white',
        padding: theme.spacing(2),
        width: 'max-content',
        '&:hover': {
            backgroundColor: '#ce1f42',
        }
    },
}));


const Content = ({ sections, hasVIPTicket, vipTicketPurchaseUrl, openVipTicketUpgrade, hasFutureTicket }) => {
    const { commonStore, authStore } = useStores();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState('en');
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [currentVideoInfo, setCurrentVideoInfo] = useState(null); // title, vimeoID
    const [videoModalTitle, setVideoModalTitle] = useState('');
    const [relatedVideos, setRelatedVideos] = useState([]);

    const { isConventionGuest } = authStore;
    const { language, isNativeApp } = commonStore;

    const onTabSelected = (newValue) => {
        setSelectedTab(newValue);
        i18n.changeLanguage(newValue);
    };

    const openPlaylist = (details, sectionTitle) => {
        if (details && sectionTitle) {
            setVideoModalOpen(true);
            setVideoModalTitle(sectionTitle);
            setRelatedVideos(details); //section details contains all related videos
            setCurrentVideoInfo(details[0]); // show the first video in list as default
        }
    };

    const openSingleVideo = (sectionDetails) => {
        if (sectionDetails.vimeoID && sectionDetails.title) {
            setVideoModalOpen(true);
            setCurrentVideoInfo(sectionDetails);
            setVideoModalTitle(sectionDetails.title);
            setRelatedVideos([]);
        }
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        setCurrentVideoInfo(null);
        setRelatedVideos([]);
    };

    const openLink = (link) => {
        const urlParams = (new URL(link)).searchParams;
        const openInBrowser = urlParams.get('oib');

        if (openInBrowser) {
            if (isNativeApp) {
                window.open(link, "_self");
            } else {
                window.open(link);
            }
        } else {
            window.location.href = link;
        }
    };

    const createHeader = (section, key) => {
        const { header } = section;
        return (
            <TabPanel className={classes.tabPanelHeader} value={key} key={key}>
                <Grid container>
                    <Header
                        title={header.title}
                        subTitle={header.subTitle}
                        eventDates={header.eventDates}
                        banner={header.banner}
                    />
                </Grid>
            </TabPanel>
        );
    };

    const createContentPanel = (section, key) => {
        const { eventMediaDetail, program } = section;

        return (
            <TabPanel className={classes.tabPanel} value={key} key={key}>
                <Grid container>
                    {program.hideChildLinks &&
                        <>
                            <Box
                                className={classes.childlessViewProgramHeader}
                            >
                                <Typography className={clsx(classes.mainHeader, program.urlLink && classes.link, classes.viewProgramHeading)} onClick={program.urlLink && (() => openLink(program.urlLink))} variant="h4">{t('conventionProgram:content.viewProgram')}</Typography>
                                {!program.urlLink && <Typography className={classes.unavailableText} variant="h2">{t('conventionProgram:content.notAvailable')}</Typography>}
                            </Box>
                            <Divider className={classes.sectionDivider} />
                        </>
                    }
                    {createEvent(eventMediaDetail, program.hideChildLinks)}
                    {!program.hideChildLinks && createProgram(program)}
                </Grid>
            </TabPanel>
        );
    };

    const createEvent = (eventMediaDetail) =>
        <Grid item xs={12} className={classes.watchEventContainer}>
            <Typography className={classes.mainHeader} variant="h4">{t('conventionProgram:content.watchEvent')}</Typography>
            <Box className={classes.contentGrid}>
                {eventMediaDetail && eventMediaDetail.sections && eventMediaDetail.sections.length > 0 ? (
                    <>
                        {eventMediaDetail.sections.map((section, index) =>
                            section.sectionDetails && section.sectionDetails.length > 0 &&
                            <Box key={index} display="flex" justifyContent="center">
                                {section.title && section.sectionDetails && section.sectionDetails.length > 0 && <Link display="block" className={clsx(classes.eventMediaListHeading, classes.playlistLink)} onClick={() => openPlaylist(section.sectionDetails, section.title)} variant="h5">{section.title}</Link>}
                                {/* In the future we may want to make the children's rendering controlled by some boolean on the section, so don't render it for now. Keep it around in case we want it in the future*/}
                                {false &&
                                    <List className={classes.sectionList}>
                                        {section.sectionDetails && section.sectionDetails.map((sd, index) =>
                                            <ListItem className={classes.eventMediaItem} key={index}>
                                                {/* If there is no title, then the children links should have the styling of the parent */}
                                                <Link
                                                    display="block"
                                                    className={clsx(!section.title && classes.eventMediaListHeading, classes.playlistLink)}
                                                    onClick={() => openSingleVideo(sd)}>
                                                    <span>{sd.title}</span> {sd.futureTicketRequired && <span>({t('conventionProgram:content.ticketRequiredForEvent', { event: sd.futureEventTitle || t('conventionProgram:content.upcomingEvent') })})</span>}
                                                </Link>
                                                {!!sd.futureTicketRequired && !hasFutureTicket && <Button className={classes.eventMediaButton} onClick={() => openLink(sd.futureTicketPurchaseURL)}>{t('conventionProgram:content.purchaseTicketForMonth', { month: sd.nextConventionMonth })}</Button>}
                                                {/* {!!sd.vipTicketRequired && !hasVIPTicket && <Button className={classes.eventMediaButton} onClick={openVipTicketUpgrade}>{t('conventionProgram:header.upgradeToVipTicket')}</Button>} */}
                                                {sd.showAgoDonateButton && <Button className={classes.eventMediaButton} onClick={() => openLink(sd.agoDonateButtonURL)}>{t('conventionProgram:content.donate')}</Button>}
                                            </ListItem>
                                        )}
                                    </List>
                                }
                            </Box>
                        )}
                        <Divider className={classes.sectionDivider} />
                        {createAdditionalSections(eventMediaDetail.additionalSections)}
                        <VideoModal
                            videoModalOpen={videoModalOpen}
                            onCloseVideoModal={closeVideoModal}
                            setCurrentVideoInfo={setCurrentVideoInfo}
                            currentVideoInfo={currentVideoInfo}
                            videoModalTitle={videoModalTitle}
                            relatedVideos={relatedVideos}
                            hasVIPTicket={hasVIPTicket}
                            hasFutureTicket={hasFutureTicket}
                            customStringMap={eventMediaDetail.additionalKeys}
                        />
                    </>
                ) : <Typography className={classes.unavailableText} variant="h2">{t('conventionProgram:content.notAvailable')}</Typography>}
            </Box>
        </Grid >
        ;

    const createAdditionalSections = (additionalSections) =>
        !!additionalSections && (
            <List className={classes.sectionList}>
                {additionalSections.length > 0 && additionalSections.map(as =>
                    isConventionGuest && as.tag === 'buyticket'
                        ? ''
                        :
                        <>
                            <ListItem className={classes.eventMediaItem} key={as.title + as.buttonLink}>
                                {as.title && <Typography className={classes.additionalSectionHeading} variant="h3" dangerouslySetInnerHTML={{ __html: as.title }} />}
                                {as.subTitle && <Typography className={classes.additionalSectionSubeading} variant="body1" dangerouslySetInnerHTML={{ __html: as.subTitle }} />}
                                <Button className={clsx(classes.eventMediaButton, classes.additionalSectionButton)} onClick={() => openLink(as.buttonLink)}><Typography dangerouslySetInnerHTML={{ __html: as.buttonText }} /></Button>
                            </ListItem>
                            <Divider className={classes.sectionDivider} />
                        </>
                )}
            </List>
        );

    const createProgram = (program) => program && (
        <Grid className={classes.programContainer} item xs={12}>
            <Typography className={clsx(classes.mainHeader, program.urlLink && classes.link)} onClick={program.urlLink && (() => openLink(program.urlLink))} variant="h4">{t('conventionProgram:content.viewProgram')}</Typography>
            <Box className={clsx(classes.contentGrid, classes.programGrid)}>
                {program.sections && program.sections.length > 0 ? (
                    program.sections.map((section, index) => (
                        <React.Fragment key={index}>
                            {section.title && <Link display="block" className={clsx(classes.sectionTitle, section.urlLink && classes.link)} variant="h6" onClick={section.urlLink && (() => openLink(section.urlLink))}>{section.title}</Link>}
                            <List className={classes.sectionList}>
                                {section.links.map(link =>
                                    <ListItem key={link.title + link.urlLink} className={classes.programListItem}>
                                        <Link className={classes.programLink} onClick={() => openLink(link.urlLink)}> {link.title}</Link>
                                    </ListItem>
                                )}
                            </List>
                        </React.Fragment>
                    ))
                ) : <Typography className={classes.unavailableText} variant="h2">{t('conventionProgram:content.notAvailable')}</Typography>}
            </Box>
        </Grid>
    );

    const keyToLanguage = (key) => {
        const keyToLanguage = {
            'en': t('conventionProgram:content.english'),
            'es': t('conventionProgram:content.spanish'),
            'fr': t('conventionProgram:content.french')
        };

        return keyToLanguage[key];
    };

    useEffect(() => {
        // Use the user's default language to load the appropriate text
        // instead of having them click on tabs manually; only show french
        // if it exists in the sections
        if (language == 'fr' && Object.keys(sections).includes('fr')) {
            onTabSelected(language);
        } else {
            onTabSelected('en');
        }
    }, []);

    return (
        <Container className={classes.container}>
            <TabContext value={selectedTab}>
                {Object.keys(sections).map(key => {
                    const section = sections[key];
                    return createHeader(section, key);
                })}
                { /* For now, this is a catch all for not having a standard or VIP ticket */
                    !hasVIPTicket ?
                        <Box className={classes.ticketButtonContainer}>
                            <Alert severity="info">{t('conventionProgram:header.noTicket')}</Alert>
                            <Box className={classes.ticketButtons}>
                                <Button className={classes.purchaseTicketButton} onClick={() => window.open(vipTicketPurchaseUrl)}>
                                    {t('conventionProgram:header.purchaseTicket')}
                                </Button>
                            </Box>
                        </Box>
                        :
                        <>
                            <Box>
                                <TabList className={classes.tabsList} onChange={(e, val) => onTabSelected(val)} >
                                    {
                                        Object.keys(sections).map(key => {
                                            return <Tab className={classes.tab} label={keyToLanguage(key)} value={key} key={key} />;
                                        })
                                    }
                                </TabList>
                            </Box>
                            {Object.keys(sections).map(key => {
                                const section = sections[key];
                                return createContentPanel(section, key);
                            })}
                        </>
                }
            </TabContext >
        </Container >
    );
};

export default (Content);