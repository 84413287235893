export const colors = {
    colorCategoryProfessional: "#DC5445",
    colorCategoryFinancial: "#008751",
    colorCategoryPersonal: "#CA1F40",
    colorCategorySubscription: "#5C5D5F",
    colorCategoryMarketplace: "#6610F2",
    colorCategoryMerchandise: "#007BFF",
    colorCategoryPromogear: "#fffafa",
    colorCategoryLifetraining: "#00A4E3",
    colorCategoryAutoship: "#ab7b0c",
    colorCategorySalesaid: "#111",

    colorAutoshipDisabled: "#007BFF",
    colorAutoshipEnabled: "#008751",
    colorAutoshipCancelled: '#f0ad4e'
};


export const lineClamp = {
    margin: "5px 0",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2 !important",
    overflow: "hidden",
    lineClamp: 2,
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    lineHeight: "1.3em",
    textAlign: "center"
};