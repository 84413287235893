import RestService from "./RestService";
const querystring = require('querystring');


export default class WalletService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId }/wallet`)
        }
        else if (parentService ) {
            super(`${parentService.baseUrl}/wallet`)
        }
        else
            throw new Error("Wallet service  cannot be used in standalone mode")
    }


    async stats(){
        return this.get( `${this.baseUrl}/stats`)
    }


    deposit(cardId, amount) {
        return this.post(`${this.baseUrl}/deposit`, {
            amount,
            cardId
        })
    }

    depositBank(id, amount, loginGuid) {
        return this.post(`https://shopapi.mainhomepage.com/api/bank/eWalletDeposit?${querystring.stringify({id, amount, loginGuid})}`, {
            amount,
            id
        })
    }

    withdraw(btcDepositAddress) {
        return this.post(`${this.baseUrl}/withdraw`, {
            btcDepositAddress,
        })
    }
}

